import { Action, createReducer, on } from '@ngrx/store';

import * as FromModels from '../../models';

import { CreateAccountStatus } from '../../models';
import * as FromActions from '../actions/activate.actions';
import { ActivateState, initialActivateState } from '../activate.state';

const reducer = createReducer(
  initialActivateState,
  on(FromActions.activateParticipant, (state, action) => ({
    ...state,
    isLoading: true,
    error: undefined,
    codeStatus: undefined,
    shortcode: action.shortcode,
    userActivationStatus: FromModels.UserActivationStatus.CheckingCode,
  })),
  on(FromActions.activateParticipantSuccess, (state, action) => ({
    ...state,
    codeStatus: action.codeStatus,
    error: undefined,
    isLoading: false,
    participantType: action.participantType,
    participantId: action.participantId,
    userActivationStatus:
      action.codeStatus === 'active'
        ? FromModels.UserActivationStatus.LooksGood
        : FromModels.UserActivationStatus.RequireUserInput,
  })),
  on(FromActions.activateParticipantFail, (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false,
    userActivationStatus: FromModels.UserActivationStatus.RequireUserInput,
  })),
  on(FromActions.declineTermsAndConditions, (state, action) => ({
    ...state,
    isLoading: false,
    codeStatus: 'unknown' as FromModels.ShortCodeStatus,
    userActivationStatus: FromModels.UserActivationStatus.RequireUserInput,
  })),
  on(FromActions.getSecurityQuestions, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
  })),
  on(FromActions.getSecurityQuestionsSuccess, (state, action) => ({
    ...state,
    error: undefined,
    isLoading: false,
    securityQuestions: action.payload,
  })),
  on(FromActions.getSecurityQuestionsFail, (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false,
  })),
  on(FromActions.setSecurityQuestionsAnswers, (state, action) => ({
    ...state,
    securityQuestionsAnswers: action.payload,
  })),
  on(FromActions.createSubjectUserAccountSuccess, (state) => ({
    ...state,
    error: undefined,
    isLoading: false,
    createAccountStatus: CreateAccountStatus.AccountCreatedSuccess,
  })),
  on(FromActions.createSiteUserAccount, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
    createAccountStatus: CreateAccountStatus.CreatingAccount,
  })),
  on(FromActions.createSiteUserAccountSuccess, (state) => ({
    ...state,
    error: undefined,
    isLoading: false,
    createAccountStatus: CreateAccountStatus.AccountCreatedSuccess,
  })),
  on(FromActions.createSiteUserAccountFail, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
    createAccountStatus: CreateAccountStatus.RequireUserInput,
  })),
  on(FromActions.createAccountComplete, (state) => ({
    ...state,
    error: undefined,
    isLoading: false,
    createAccountStatus: CreateAccountStatus.RequireUserInput,
  })),
  on(FromActions.clearState, () => initialActivateState)
);

export function activateReducer(
  state: ActivateState | undefined,
  action: Action
) {
  return reducer(state, action);
}
