<ng-container *transloco="let transl">
  <ng-container
    [ngTemplateOutlet]="
      (emulationServiceLost$ | async) ? deviceErrorTmpl : genericErrorTmpl
    "
  ></ng-container>

  <ng-template #genericErrorTmpl>
    <mat-icon class="icon" aria-hidden="false" aria-label="visit error"
      >error_outline</mat-icon
    >
    <span class="text">{{
      transl('chrome_only_error_screen.try_again', {
        fallback: 'Please try again'
      })
    }}</span>
  </ng-template>

  <ng-template #deviceErrorTmpl>
    <div class="emulation-error-icon">
      <mat-icon
        class="emulation-error-icon__tablet"
        aria-hidden="false"
        aria-label="visit error"
        >tablet</mat-icon
      >
      <mat-icon
        class="emulation-error-icon__error"
        aria-hidden="false"
        aria-label="visit error"
        >error_outline</mat-icon
      >
    </div>
    <span class="text">{{
      transl('visit.emulation.device_service_failed')
    }}</span>
  </ng-template>
</ng-container>
