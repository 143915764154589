import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nx/angular';
import * as FromSelectors from '../selectors/provisioner.selector';
import { sortBy } from 'lodash';
import { map, withLatestFrom } from 'rxjs/operators';
import { ProvisionerHttpService } from '../http/provisioner-http.service';
import * as FromProvisionerActions from '../actions/provisioner.actions';
import { SiteListItem } from '../../models';
import { ProvisionerState } from '../provisioner.state';
import { SessionState } from '@virtual-trials-workspace/store';
import { StudyModel } from '@virtual-trials-workspace/models';

@Injectable()
export class ProvisionerEffects {
  constructor(
    private provisionerHttpService: ProvisionerHttpService,
    private actions$: Actions,
    private store: Store<ProvisionerState>
  ) {}
  getStudies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromProvisionerActions.getSitesAction),
      fetch({
        run: () => {
          return this.provisionerHttpService.getStudies$().pipe(
            map((studies) => {
              const sites = new Array<SiteListItem>();

              studies = this.getStudiesWithEmulation(studies);

              studies = studies = sortBy(studies, [
                (s) => s.studyName.toLowerCase(),
              ]);
              studies.forEach((study) => {
                study.sites = sortBy(study.sites, [
                  (s) => s.siteName.toLowerCase(),
                ]);
              });

              studies.forEach((study) => {
                study.sites.forEach((site) => {
                  sites.push({
                    studyName: study.studyName,
                    siteId: site.siteId,
                    siteName: site.siteName,
                    provisioned: site.provisioned,
                  });
                });
              });

              return FromProvisionerActions.getSitesSuccessAction({ sites });
            })
          )
        },
        onError: (action, error) => {
          return FromProvisionerActions.getSitesFailureAction();
        },
      })
    )
  );

  setProvisioningStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromProvisionerActions.setProvisioningStatusAction),
      fetch({
        run: (action) => {
          return this.provisionerHttpService
          .setProvisionedState$(action.siteId, action.provisionedState)
          .pipe(
            map(() => {
              return FromProvisionerActions.setProvisioningStatusSuccessAction({
                siteId: action.siteId,
                provisionedState: action.provisionedState,
              });
            })
          )
        },
        onError: (_, error) => {
          return FromProvisionerActions.setProvisioningStatusFailureAction();
        },
      })
    )
  )

  getIsDeviceInUse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromProvisionerActions.getDeviceStatus),
      withLatestFrom(this.store.select(FromSelectors.getSelectedSiteId)),
      fetch({
        run: (action, selectedSiteId) => {
          return this.provisionerHttpService
          .getIsDeviceInUse$(selectedSiteId)
          .pipe(
            map((response) => {
              return FromProvisionerActions.getDeviceStatusSuccess({
                inUse: response.inUse,
                isSetup: response.isSetup,
              })
            })
          )
        },
        onError: (_, error) => {
          return FromProvisionerActions.getDeviceStatusFailure();
        },
      })
    )
  )

  private getStudiesWithEmulation = (
    studies: Array<StudyModel>
  ): Array<StudyModel> => {
    return studies.filter((study) =>
      study.licenses.find((l) => l.licenseName === 'Emulation')
    );
  };
}
