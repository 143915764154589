import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'vt-account-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss'],
})
export class ConfirmPasswordComponent {
  @Input()
  passwordFormControl: AbstractControl;

  @Input()
  passwordControlRequiredError: boolean;

  @Input()
  passwordControlCriteriaError: boolean;

  @Input()
  confirmPasswordControl: AbstractControl;

  @Input()
  confirmPasswordControlRequiredError: boolean;

  @Input()
  confirmPasswordControlMatchError: boolean;

  hidePassword = true;

  hideConfirmPassword = true;
}
