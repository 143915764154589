import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BaseConfig } from '@virtual-trials-workspace/models';
import { LocalStorageService, TokenService } from '@virtual-trials-workspace/shared-core-services';
import {
  RootActions,
  RootSelectors,
  SessionState,
} from '@virtual-trials-workspace/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageControleService } from '@virtual-trials-workspace/shared-ui';

@Component({
  selector: 'vt-web-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly ACCOUNT_LOCK_ERROR_CODE = 403;
  private readonly INVALID_CREDENTIALS_ERROR_CODE = 401;

  isLoading$ = this.store.pipe(select(RootSelectors.getIsLoading));

  buildVersion: string;
  formGroup: UntypedFormGroup;

  errorMessage: string;
  errorCode: number;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private config: BaseConfig,
    private router: Router,
    private store: Store<SessionState>,
    private tokenService: TokenService,
    private localStorageService: LocalStorageService,
    private languageControleService: LanguageControleService
  ) {}

  get disableButton(): boolean {
    return (
      !this.getFormControl('password').value ||
      !this.getFormControl('username').value ||
      this.getFormControl('username').hasError('pattern')
    );
  }

  ngOnInit(): void {
    this.buildVersion = this.config.appVersion;
    this.tokenService.removeToken();
    this.languageControleService.setAllAvailableLanguages();
    this.dispatchActionToClearStorage();
    this.initFormGroup();
    this.initErrorHandleSubscription();
    this.initInvalidCredentialsSubscription();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.languageControleService.ngOnDestroy();
  }

  handleSubmitFormClick = (): void => {
    this.store.dispatch(
      RootActions.login({
        username: this.getFormControl('username').value,
        password: this.getFormControl('password').value,
      })
    );
  };

  handleForgotPasswordClick = (): void => {
    this.router.navigateByUrl('/account/forgot-password');
  };

  handleActivateAccountClick = (): void => {
    this.router.navigateByUrl('/activate');
  };

  private dispatchActionToClearStorage = (): void => {
    this.store.dispatch(RootActions.endActiveSessionOnInitApp());
    this.store.dispatch(RootActions.clearStorage());
    this.store.dispatch(
      RootActions.setFilterLanguagesSuccess({
        languages: undefined,
      })
    );
    console.log("clear storage filterLanguages..........login compo....")
    this.localStorageService.delete('filterLanguages')
  };

  private initFormGroup = (): void => {
    this.formGroup = new UntypedFormGroup({
      username: new UntypedFormControl(undefined, [Validators.required]),
      password: new UntypedFormControl(undefined, [Validators.required]),
    });
  };

  private getFormControl = (name: string): AbstractControl => {
    return this.formGroup.controls[name];
  };

  private initInvalidCredentialsSubscription = (): void => {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.errorMessage) {
        this.errorMessage = undefined;
      }
    });
  };

  private initErrorHandleSubscription = (): void => {
    this.store
      .pipe(select(RootSelectors.getError), takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        if (error?.code) {
          this.errorCode = error.code;
        }
      });
  };
}
