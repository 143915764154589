import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vt-ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {

  @Input() current: number;
  @Input() total: number;
  @Input() totalPages: number;
  @Input() pageSizeOptions: Array<number>;

  @Output() goTo: EventEmitter<number> = new EventEmitter<number>()
  @Output() next: EventEmitter<number> = new EventEmitter<number>()
  @Output() previous: EventEmitter<number> = new EventEmitter<number>()
  @Output() changeRowsPerPage: EventEmitter<number> = new EventEmitter<number>()
  

  @Input() startIndex: number;
  @Input() endIndex: number;
  
  selectedRowsPerPage = 10;

  

  public onGoTo(page: number): void {
    this.goTo.emit(page)
  }
  public onChangeRowsPerPage(rows: number): void {
    this.selectedRowsPerPage = rows;
    this.changeRowsPerPage.emit(rows);
  }
  public onNext(): void {
    this.next.emit(this.current)
  }
  public onPrevious(): void {
    this.previous.next(this.current)
  }


}
