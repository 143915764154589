
<div class="es-popup  -es-closeable -es-modal -es-open  es-custom-dialog-panel" *transloco="let transl">
	<div class="es-popup-content">
		<header class="es-popup-header">
      <div class="header-icon warningIcon"></div>
			<h2>{{ transl('notification.subject_code_sent_fail.header') }}</h2>
		</header>
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <p>{{ transl('notification.subject_code_sent_fail.description') }}</p>
            </div>
          </div>
        </div>
      </div>
    <footer class="es-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">
            <button
              id="ok-button-id"
              class="es-button  -es-primary -es-modal"
              (click)="closeDialog()"
            >
              {{ transl('general.ok') }}
            </button>
          </li>
        </ul>
      </div>
    </footer>	  
  </div>
</div>


<!-- <div *transloco="let transl">
  <h1 mat-dialog-title>
    {{ transl('notification.subject_code_sent_fail.header') }}
  </h1>
  <div mat-dialog-content>
    <p>
      {{ transl('notification.subject_code_sent_fail.description') }}
    </p>
  </div>
  <div mat-dialog-actions align="end">
    <button
      id="ok-button-id"
      mat-flat-button
      color="primary"
      (click)="closeDialog()"
    >
      {{ transl('general.ok') }}
    </button>
  </div>
</div> -->
