
<ng-container *transloco="let transl">
  <main class="es-gateway-body">
    <section
      class="es-timer-popup-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
    >
      <div class="es-column es-visit-timer-column">
        <div class="es-content-box">
          <div class="es-panel es-custom-panel">

            <ng-container
              *ngIf="!!timedOut; else manualTmpl"
              [ngTemplateOutlet]="timeoutTmpl"
            ></ng-container>
            
          </div>
        </div>
      </div>
    </section>
  </main>

  <ng-template #timeoutTmpl>
    <ng-container>
      <header class="es-card-header">
        <div class="timer-icon"></div>
        <h2>{{ transl('visit.subject.visit_end.timeout.title') }}</h2>
      </header>
    </ng-container>

    <footer class="es-timer-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">
            <button class="es-button -es-primary" id="continue" (click)="onClose()">
              {{ transl('general.continue') | titlecase }}
            </button>
          </li>
        </ul>
      </div>
    </footer>
  </ng-template>

  <ng-template #manualTmpl>
    <ng-container>
      <header class="es-card-header">
        <div class="timer-icon"></div>
        <h2>{{ transl('visit.subject.visit_end.success.title') }}</h2>
      </header>
    </ng-container>

    <footer class="es-timer-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">
            <button class="es-button -es-primary" id="continue" (click)="onClose()">
              {{ transl('general.continue') | titlecase }}
            </button>
          </li>
        </ul>
      </div>
    </footer>
  </ng-template>

</ng-container>
<!-- <div class="full-modal-container">
  <ng-container *transloco="let transl">
    <div
      class="full-modal__header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      
      <div class="logo"></div>
      <vt-ui-language-select [setUserLanguage]="false"></vt-ui-language-select>
    </div>
    <div class="full-modal__content" fxLayout="column">
      <ng-container
        *ngIf="!!timedOut; else manualTmpl"
        [ngTemplateOutlet]="timeoutTmpl"
      ></ng-container>
    </div>

    <ng-template #timeoutTmpl>
      <div
        class="full-modal__message"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="12px"
      >
        <mat-icon
          class="timer-icon material-icons-outlined full-modal__main-icon"
          svgIcon="timer-icon"
        ></mat-icon>
        <div
          class="full-modal__text"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <h4>{{ transl('visit.subject.visit_end.timeout.title') }}</h4>
        </div>
      </div>
      <div
        class="full-modal__control-panel"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <button id="continue" mat-button color="primary" (click)="onClose()">
          {{ transl('general.continue') | titlecase }}
        </button>
      </div>
    </ng-template>

    <ng-template #manualTmpl>
      <div
        class="full-modal__message"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="12px"
      >
        <mat-icon class="material-icons-outlined full-modal__main-icon"
          >check_circle</mat-icon
        >
        <div
          class="full-modal__text"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <h4>{{ transl('visit.subject.visit_end.success.title') }}</h4>
        </div>
      </div>
      <div
        class="full-modal__control-panel"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <button id="continue" mat-button color="primary" (click)="onClose()">
          {{ transl('general.continue') | titlecase }}
        </button>
      </div>
    </ng-template>
  </ng-container>
</div> -->
