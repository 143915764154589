export const getPersistentKeys = <T>(
  object: T,
  key: string,
  toExclude: Array<string> = []
): Array<string> => {
  return Object.getOwnPropertyNames(object).reduce((accumulated, current) => {
    const shouldInclude = toExclude.indexOf(current) === -1;
    return shouldInclude
      ? accumulated.concat(`${key}.${current}`)
      : accumulated;
  }, []);
};
