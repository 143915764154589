import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { select, Store } from '@ngrx/store';

import * as FormUtils from '@virtual-trials-workspace/shared-utils';
import { Subject, Observable, Subscription } from 'rxjs';
import { Actions, Selectors, SiteState } from '../store';
import * as FromSelectors from '../store/selectors/site.selectors';

import {
  LanguageHttpService,
  RootActions,
} from '@virtual-trials-workspace/store';
import { DeviceStatus, LicenseType } from '@virtual-trials-workspace/models';
import { Subject as RxjsSubject } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { TranslocoService } from '@ngneat/transloco';

import { take, takeUntil } from 'rxjs/operators';
import { LanguageControleService } from '@virtual-trials-workspace/shared-ui';
import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';
@Component({
  selector: 'vt-site-journey-select-study-site',
  templateUrl: './select-study-site.component.html',
  styleUrls: ['./select-study-site.component.scss'],
})
export class SelectStudySiteComponent implements OnInit, OnDestroy {
  private done$ = new Subject<void>();

  readonly siteControlName = 'site';
  readonly studyControlName = 'study';

  formGroup: UntypedFormGroup;

  deviceStatus: DeviceStatus;
  deviceStatusEnum = DeviceStatus;

  isLoading$ = this.store.pipe(select(Selectors.getIsLoading));
  studies$ = this.store.pipe(select(Selectors.getStudies));
  sites$ = this.store.pipe(select(Selectors.getSitesForStudy));
  deviceStatus$ = this.store.pipe(select(Selectors.getDeviceStatus));
  subjects$: Observable<any>;
  isECOALive = false;
  isECOALiveNextButtonDisabled = true;
  private unsubscribe$ = new RxjsSubject<void>();
  private languageSubscription$ = new Subscription();

  get siteFormControl(): AbstractControl {
    return this.getFormControl(this.siteControlName);
  }
  selectedLanguage: string;

  get studyFormControl(): AbstractControl {
    return this.getFormControl(this.studyControlName);
  }

  get siteControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.siteFormControl.errors);
  }

  get studyControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.studyFormControl.errors);
  }

  constructor(
    private store: Store<SiteState>,
    private languageService: LanguageHttpService,
    private translocoService: TranslocoService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private languageControleService: LanguageControleService
  ) {}

  ngOnInit(): void {    
    this.initFormGroup();
    this.store.dispatch(
      RootActions.setFilterLanguagesSuccess({
        languages: undefined,
      })
    );
    this.localStorageService.delete('filterLanguages');
    this.subscribeToIsDeviceStatus();
    this.languageControleService.setAllAvailableLanguages();
    this.store.dispatch(Actions.getStudies());
  }

  private initFormGroup = (): void => {
    this.formGroup = new UntypedFormGroup({
      site: new UntypedFormControl(
        {
          value: undefined,
          disabled: true,
        },
        Validators.required
      ),
      study: new UntypedFormControl(undefined, Validators.required),
    });
  };

  private getFormControl = (name: string): AbstractControl => {
    return this.formGroup.controls[name];
  };

  private subscribeToIsDeviceStatus = (): void => {
    this.deviceStatus$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status: DeviceStatus) => {
        this.deviceStatus = status;
        this.isECOALiveNextButtonDisabled =
          this.deviceStatus !== this.deviceStatusEnum.Available;
      });
  };

  private dispatchActionToGetDeviceStatus = (): void => {
    this.store.dispatch(Actions.getIsDeviceStatus());
  };

  private setSelectedSite = (): void => {
    this.store.dispatch(
      Actions.setSelectedSite({
        siteId: this.siteFormControl.value,
      })
    );
  };

  handleNextClick = (): void => {
    this.formGroup.valid
      ? this.handleFormIsValid()
      : FormUtils.runFormFieldValidation(this.formGroup);
  };

  handleFormIsValid = (): void => {
    this.handleLanguageControlDialog();
  };

  handleLanguageControlDialog = (): void => {
    this.store
      .pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        select(FromSelectors.getStudyLanguagesForSelectedStudy)
      )
      .subscribe((studyLanguages) => {
        this.languageSubscription$ =
          this.languageControleService.navigateToSubjectList
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              if (data) {
                this.navigateToSubjectList();
              }
            });
        this.languageControleService.handleLanguageControlDialog(
          studyLanguages.toString()
        );
      });
  };

  navigateToSubjectList = (): void => {
    this.store.dispatch(
      Actions.setSelectedStudySite({
        studyId: this.studyFormControl.value,
        siteId: this.siteFormControl.value,
      })
    );
    if (this.isECOALive) {
      this.startEcoaStandaloneVisit();
    }
  };

  startEcoaStandaloneVisit = (): void => {
    this.subjects$.pipe(takeUntil(this.done$)).subscribe((subjects) => {
      if (subjects && subjects.length > 0) {
        this.store.dispatch(
          RootActions.startVisitWithSubject({
            siteId: this.siteFormControl.value,
            subjectId: subjects[0].subjectId,
            subjectParticipantId: subjects[0].subjectParticipantId,
            modules: this.getEcoaVisitService(),
            isEcoaLiveStandalone: true,
            isDeviceProvisioned: undefined,
          })
        );
      }
    });
  };

  getEcoaVisitService = (): Array<LicenseType> => {
    const visitServices = new Array<LicenseType>();
    visitServices.push('Emulation');
    return visitServices;
  };

  handleStudyChanged = (): void => {
    this.store.dispatch(
      Actions.setSelectedStudy({
        studyId: this.studyFormControl.value,
      })
    );
    this.deviceStatus = this.deviceStatusEnum.Unknown;
    this.store
      .pipe(
        takeUntil(this.done$),
        select(FromSelectors.getisECOALiveForSelectedStudy)
      )
      .subscribe((isECOALive) => {
        this.store.dispatch(
          RootActions.setEcoaLiveStandalone({
            isEcoaLiveStandalone: isECOALive,
          })
        );
        this.isECOALive = isECOALive;
        this.isECOALiveNextButtonDisabled = isECOALive;
      });
    this.siteFormControl.setValue(undefined);
    this.siteFormControl.enable();
  };
  handleSiteChanged = (): void => {
    if (this.isECOALive) {
      this.setSelectedSite();
      this.dispatchActionToGetDeviceStatus();

      this.subjects$ = this.store.pipe(
        select(FromSelectors.getSubjects),
        takeUntil(this.done$)
      );
    }
  };

  onClickHereLinkClick = (): void => {
    this.setSelectedSite();
    this.dispatchActionToGetDeviceStatus();
  };

  handleLogoutClick = (): void => {
    this.store.dispatch(RootActions.endActiveSession());
  };

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
    this.languageSubscription$.unsubscribe();
    this.unsubscribe$.unsubscribe();
    this.languageControleService.ngOnDestroy();
  }
}
