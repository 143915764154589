import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { HttpError } from '@virtual-trials-workspace/models';
import { LanguageHttpService, SessionActions, SessionSelectors } from '@virtual-trials-workspace/store';
import { LanguageControleService } from '@virtual-trials-workspace/shared-ui';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { concatMap, delay, filter, take, takeUntil } from 'rxjs/operators';
import * as FromModels from '../models';
import * as FromStore from '../store';
import { TokenService } from '@virtual-trials-workspace/shared-core-services';

@Component({
  selector: 'vt-activate-home',
  templateUrl: './activate-home.component.html',
  styleUrls: ['./activate-home.component.scss'],
})
export class ActivateHomeComponent implements OnDestroy, OnInit {
  apiError: string;
  formGroup: UntypedFormGroup;

  submitInProgress: boolean;
  showShortCodeAlreadyActive: boolean;

  activationStatusSubject$ = new BehaviorSubject<FromModels.UserActivationStatus>(
    FromModels.UserActivationStatus.RequireUserInput
  );

  readonly shortCodeNotFoundErrorMessage = 'error.short_code_not_found';
  readonly shortCodeExpiredErrorMessage = 'error.short_code_expired';
  readonly twoSecondDelay = 2000;

  private readonly unsubscribe$ = new Subject<void>();

  get activationStatus$(): Observable<FromModels.UserActivationStatus> {
    return this.activationStatusSubject$.asObservable();
  }

  get getShortCodeControl(): AbstractControl {
    return this.formGroup.controls.shortcode;
  }
  get studyLanguages$(): Observable<string[]> {
    return this.store.pipe(
      select(SessionSelectors.getSutdyLanguagesByParticipantId)
    );
  }
  isLoading$ = this.store.pipe(select(FromStore.Selectors.getIsLoading));
  constructor(
    private store: Store<FromStore.ActivateState>,
    private translocoService: TranslocoService,
    private languageService: LanguageHttpService,
    private languageControleService: LanguageControleService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.tokenService.removeToken();
    this.dispatchActionToClearState();
    this.initFormGroup();
    this.initShortcodeIsInactiveSubscription();
    this.initSubscriptionToApiError();
    this.initSubscriptionToQueryParams();
    this.resetFormFromInvalidShortCode();
    this.hideShortCodeActiveOnFormValueChanges();
    this.initUserActivationStatusSubscription();
    this.initActivateParticipantCompleteSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.languageControleService.ngOnDestroy();
  }

  handleShortCodeInQueryString = (shortcode: string): void => {
    if (!shortcode) {
      this.formGroup.reset();
    } else {
      this.getShortCodeControl.setValue(shortcode);
      this.formGroup.markAllAsTouched();
    }
  };

  handleLanguageCodeInQueryString = (languageCode: string): void => {
    this.languageService.updateSelectedLanguage(languageCode);
    this.translocoService.setActiveLang(languageCode);
    this.store.dispatch(
      SessionActions.getTranslationFiles({ language: languageCode })
    );
  };

  handleContinueClick = (): void => {
    this.submitInProgress = true;

    setTimeout(() => {
      this.activationStatusSubject$.next(
        FromModels.UserActivationStatus.CheckingCode
      );
      this.store.dispatch(
        FromStore.Actions.activateParticipant({
          shortcode: this.getShortCodeControl.value,
        })
      );
    }, this.twoSecondDelay);
  };

  private dispatchActionToClearState = (): void => {
    this.store.dispatch(FromStore.Actions.clearState());
  };

  private initShortcodeIsInactiveSubscription = (): void => {
    this.store
      .pipe(
        select(FromStore.Selectors.getIsShortCodeInActive),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((isInActive) => {
        this.showShortCodeAlreadyActive = isInActive;
        this.submitInProgress = false;
      });
  };

  private initUserActivationStatusSubscription = (): void => {
    this.store
      .pipe(
        select(FromStore.Selectors.getUserActivationStatus),
        takeUntil(this.unsubscribe$),
        concatMap((status) => of(status).pipe(delay(this.twoSecondDelay)))
      )
      .subscribe((status) => this.activationStatusSubject$.next(status));
  };

  private initActivateParticipantCompleteSubscription = (): void => {
    this.activationStatus$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(
          (status) => status === FromModels.UserActivationStatus.LooksGood
        ),
        concatMap((status) => of(status).pipe(delay(this.twoSecondDelay)))
      )
      .subscribe(() => {
        this.dispatchStudyLanguages();
        this.subscribeToStudyLanguages();
      });
  };
  private dispatchactivateParticipantComplete = (): void => {
    this.store.dispatch(
      FromStore.Actions.activateParticipantComplete({
        acceptedShortcode: this.getShortCodeControl.value,
      })
    );
  };
  private dispatchStudyLanguages = (): void => {
    this.store.dispatch(SessionActions.getSutdyLanguagesByParticipantId());
  };

  private subscribeToStudyLanguages = (): void => {
    this.studyLanguages$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((languages) => {
        if (languages) {
          this.languageControleService.navigateToSubjectList.subscribe(
            (data) => {
              this.dispatchactivateParticipantComplete();
            }
          );
          this.languageControleService.handleLanguageControlDialog(
            JSON.stringify(languages)
          );
        }
      });
  };
  private initSubscriptionToQueryParams = (): void => {
    this.store
      .pipe(
        select(FromStore.Selectors.getShortcodeFromRouter),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((shortcode) => this.handleShortCodeInQueryString(shortcode));

    this.store
      .pipe(
        take(1),
        select(FromStore.Selectors.getLanguageCodeFromRouter),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (languageCode) =>
          languageCode && this.handleLanguageCodeInQueryString(languageCode)
      );
  };

  private initFormGroup = (): void => {
    this.formGroup = new UntypedFormGroup({
      shortcode: new UntypedFormControl(undefined, [Validators.required]),
    });
  };

  private initSubscriptionToApiError = (): void => {
    this.store
      .pipe(select(FromStore.Selectors.getError), takeUntil(this.unsubscribe$))
      .subscribe((error) => this.handleApiErrors(error));
  };

  private handleApiErrors = (error: HttpError): void => {
    switch (error?.code) {
      case 404:
        this.apiError = this.shortCodeNotFoundErrorMessage;
        break;
      case 403:
        this.apiError = this.shortCodeExpiredErrorMessage;
        break;
    }

    this.showShortCodeAlreadyActive = false;
    this.submitInProgress = false;
  };

  private resetFormFromInvalidShortCode = (): void => {
    this.getShortCodeControl.valueChanges
      .pipe(
        filter(() => !!this.apiError),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.handleResetFormFromApiError());
  };

  private handleResetFormFromApiError = (): void => {
    this.submitInProgress = false;
    this.apiError = undefined;
    this.getShortCodeControl.markAsTouched();
  };

  private hideShortCodeActiveOnFormValueChanges = (): void => {
    this.getShortCodeControl.valueChanges
      .pipe(
        filter(() => !!this.showShortCodeAlreadyActive),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => (this.showShortCodeAlreadyActive = false));
  };
}
