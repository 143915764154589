import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RootSelectors, RouteState, SessionState, SessionSelectors } from '@virtual-trials-workspace/store';

import { AccountState, ACCOUNT_STATE_KEY } from '../account.state';

export const getAccountState = createFeatureSelector<AccountState>(
  ACCOUNT_STATE_KEY
);

export const getError = createSelector(
  getAccountState,
  (state: AccountState) => state.error
);

export const getIsLoading = createSelector(
  getAccountState,
  SessionSelectors.getIsLoading,
  (state: AccountState, sessionIsLoading: boolean) =>
    state.isLoading || sessionIsLoading
);

export const getPasswordResetSuccess = createSelector(
  getAccountState,
  (state: AccountState) => state.passwordResetSuccess
);

export const getSecurityQuestionForPasswordReset = createSelector(
  getAccountState,
  (state: AccountState) => state.resetPasswordSecurityQuestion
);

export const getPasswordResetToken = createSelector(
  RootSelectors.getRouteState,
  (route: RouteState) => route.queryParams.token
);
