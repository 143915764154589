import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, Subscription } from 'rxjs';

import { SecurityQuestion } from '@virtual-trials-workspace/models';

export class QuestionsFilterService {
  private question1SelectedValue: string;
  private question2SelectedValue: string;
  private question3SelectedValue: string;

  private formChangesSubscriptions = new Array<Subscription>();

  private question1Sub$ = new BehaviorSubject<Array<SecurityQuestion>>(
    this.questions
  );
  private question2Sub$ = new BehaviorSubject<Array<SecurityQuestion>>(
    this.questions
  );
  private question3Sub$ = new BehaviorSubject<Array<SecurityQuestion>>(
    this.questions
  );

  get questionsForFirstControl$() {
    return this.question1Sub$.asObservable();
  }

  get questionsForSecondControl$() {
    return this.question2Sub$.asObservable();
  }

  get questionsForThirdControl$() {
    return this.question3Sub$.asObservable();
  }

  static create = (
    questions: Array<SecurityQuestion>,
    formGroup: UntypedFormGroup
  ): QuestionsFilterService => {
    return new QuestionsFilterService(questions, formGroup);
  };

  unsubscribe = (): void => {
    this.formChangesSubscriptions.forEach((sub) => sub.unsubscribe());
    this.question1Sub$.unsubscribe();
    this.question2Sub$.unsubscribe();
    this.question3Sub$.unsubscribe();
  };

  private constructor(
    private readonly questions: Array<SecurityQuestion>,
    private readonly formGroup: UntypedFormGroup
  ) {
    this.initQuestion1Changes();
    this.initQuestion2Changes();
    this.initQuestion3Changes();
  }

  private getFormControl = (name: string): AbstractControl => {
    return this.formGroup.controls[name];
  };

  private initQuestion1Changes = (): void => {
    this.formChangesSubscriptions.push(
      this.getFormControl('question1').valueChanges.subscribe(
        (value: string) => {
          this.question1SelectedValue = value;
          this.handleFirstControlValueChanges(value);
        }
      )
    );
  };

  private initQuestion2Changes = (): void => {
    this.formChangesSubscriptions.push(
      this.getFormControl('question2').valueChanges.subscribe(
        (value: string) => {
          this.question2SelectedValue = value;
          this.handleSecondControlValueChanges(value);
        }
      )
    );
  };

  private initQuestion3Changes = (): void => {
    this.formChangesSubscriptions.push(
      this.getFormControl('question3').valueChanges.subscribe(
        (value: string) => {
          this.question3SelectedValue = value;
          this.handleThirdControlValueChanges(value);
        }
      )
    );
  };

  private handleFirstControlValueChanges = (value: string) => {
    this.question2Sub$.next(
      this.getFilteredQuestions([value, this.question3SelectedValue])
    );
    this.question3Sub$.next(
      this.getFilteredQuestions([value, this.question2SelectedValue])
    );
  };

  private handleSecondControlValueChanges = (value: string) => {
    this.question1Sub$.next(
      this.getFilteredQuestions([value, this.question3SelectedValue])
    );
    this.question3Sub$.next(
      this.getFilteredQuestions([value, this.question1SelectedValue])
    );
  };

  private handleThirdControlValueChanges = (value: string) => {
    this.question1Sub$.next(
      this.getFilteredQuestions([value, this.question2SelectedValue])
    );
    this.question2Sub$.next(
      this.getFilteredQuestions([value, this.question1SelectedValue])
    );
  };

  private getFilteredQuestions = (
    idsToRemove: Array<string>
  ): Array<SecurityQuestion> => {
    return this.questions.filter(
      (question) => !idsToRemove.includes(question.questionId)
    );
  };
}
