import { createAction, props, union } from '@ngrx/store';
import {
  LanguageModel,
  LoginResponse,
  ParticipantType,
  TranslationFile,
} from '@virtual-trials-workspace/models';
import { createHttpErrorAction } from '../utils';

export const login = createAction(
  '[Session] Login',
  props<{ username: string; password: string }>()
);

export const loginSuccess = createAction(
  '[Session] Login Success',
  props<LoginResponse>()
);

export const clearStorage = createAction('[Session] Clear Storage');

export const loginFailure = createHttpErrorAction('[Session] Login Failure');

export const failureErrorMessage = createAction(
  '[Session] Generic Failure Error message',
  props<{ genericErrorMessage: string }>()
);

export const endActiveSession = createAction('[Session] End Active Session');

export const endActiveSessionOnInitApp = createAction(
  '[Session] End Active Session on app init'
);

export const clearSessionData = createAction('[Session] Clear Session Data');

export const getLanguages = createAction('[Visit] Get Languages');

export const setDefaultLanguageCode = createAction(
  '[Visit] Set Default Language Code',
  props<{ defaultSetLanguageCode: string }>()
);

export const getLanguagesSuccess = createAction(
  '[Visit] Get Languages Success',
  props<{ languages: LanguageModel[] }>()
);

export const setLanguagesFallback = createAction(
  '[Visit] Set Languages Fallback',
  props<{ isLanguagesFallback: boolean }>()
);

export const setAllLanguagesSuccess = createAction(
  '[Visit] Set All Languages Success',
  props<{ languages: LanguageModel[] }>()
);
export const setFilterLanguagesSuccess = createAction(
  '[Visit] Set Filter Languages Success',
  props<{ languages: LanguageModel[] }>()
);

export const setParticipantId = createAction(
  '[Visit] set set ParticipantId',
  props<{ participantId: string; participantType: ParticipantType }>()
);
export const getLanguagesFailure = createAction(
  '[Visit] Get Languages Failure'
);

export const getTranslationFiles = createAction(
  '[Visit] Get Translation File',
  props<{ language: string }>()
);

export const getTranslationFilesSuccess = createAction(
  '[Visit] Get Translation File Success',
  props<{ translationFiles: TranslationFile[] }>()
);

export const getTranslationFilesFailure = createHttpErrorAction(
  '[Visit] Get Translation File Failure'
);

export const translationsLoadDone = createAction(
  '[Visit] Translations Load Done'
);

export const isPrefferedLanguageDialogOpen = createAction(
  '[Site] Is Preffered Language Dialog Open',
  props<{ isDialogOpen: boolean }>()
);

export const joinStandaloneVisit = createAction(
  '[Session] Join Standalone Visit',
  props<{ visitCode: string }>()
);

export const joinStandaloneVisitSuccess = createAction(
  '[Session] Join Standalone Visit Success',
  props<{
    participantId: string;
    participantType: ParticipantType;
    visitCode: string;
  }>()
);

export const joinStandaloneVisitFailure = createHttpErrorAction(
  '[Session] Join Standalone Visit Failure'
);

export const endStandaloneVisit = createAction(
  '[Session] End Standalone Visit'
);

export const endStandaloneVisitOnError = createAction(
  '[Session] End Standalone Visit On Error'
);

export const refreshToken = createAction('[Session] Refresh Token');

export const refreshTokenFailure = createAction(
  '[Session] Refresh Token on Failure'
);

export const getSutdyLanguagesByParticipantId = createAction(
  '[Visit] Get Study Languages'
);

export const getSutdyLanguagesByParticipantIdSuccess = createAction(
  '[Visit] Get Study Languages Success',
  props<{ studyLanguages: string[] }>()
);

export const getSutdyLanguagesByParticipantIdFailure = createHttpErrorAction(
  '[Visit] Get Study Languages Failure'
);

const sessionActions = union({
  login,
  loginSuccess,
  loginFailure,
  endActiveSession,
  endActiveSessionOnInitApp,
  clearSessionData,
  clearStorage,
  setDefaultLanguageCode,
  getLanguages,
  getLanguagesSuccess,
  getLanguagesFailure,
  getTranslationFiles,
  getTranslationFilesSuccess,
  getTranslationFilesFailure,
  translationsLoadDone,
  joinStandaloneVisit,
  joinStandaloneVisitSuccess,
  joinStandaloneVisitFailure,
  endStandaloneVisit,
  endStandaloneVisitOnError,
  refreshToken,
  getSutdyLanguagesByParticipantId,
  getSutdyLanguagesByParticipantIdSuccess,
  getSutdyLanguagesByParticipantIdFailure,
});

export type SessionActionsUnion = typeof sessionActions;
