import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SESSION_ROUTE_CONFIG_KEY } from '@virtual-trials-workspace/models';
import {
  AuthenticationGuard,
  AuthPageTranslationsGuard,
  UnauthPageTranslationsGuard,
} from '@virtual-trials-workspace/shared-guards';

import { VisitComponent } from './visit/visit.component';
import { JoinVisitComponent } from './join-visit/join-visit.component';
import { VisitErrorComponent } from './visit-error/visit-error.component';
import { VisitEndComponent } from './visit-end/visit-end.component';
import { LayoutComponent } from './layout/layout.component';

export const visitRoutes: Routes = [
  {
    path: '',
    redirectTo: 'visit',
    pathMatch: 'full',
  },
  {
    path: 'visit',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: JoinVisitComponent,
        canActivate: [UnauthPageTranslationsGuard],
      },
      {
        path: 'room',
        component: VisitComponent,
        canActivate: [AuthenticationGuard, AuthPageTranslationsGuard],
        data: {
          [SESSION_ROUTE_CONFIG_KEY]: {
            redirectTo: '/standalone/visit/error',
            skipLocationChange: true,
          },
        },
      },
      {
        path: 'error',
        component: VisitErrorComponent,
      },
      {
        path: 'end',
        component: VisitEndComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(visitRoutes)],
  exports: [RouterModule]
})
export class StandaloneRoutingModule {}
