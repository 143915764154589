import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import * as FormUtils from '@virtual-trials-workspace/shared-utils';
import { LanguageHttpService, SessionSelectors } from '@virtual-trials-workspace/store';

import * as FromStore from '../store';

@Component({
  selector: 'vt-account-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: UntypedFormGroup;
  resendEmailConfirmed: boolean;
  isLoading$ = this.store.pipe(select(FromStore.Selectors.getIsLoading));
  private validators = [
    Validators.required,
    Validators.maxLength(256),
    Validators.minLength(2),
  ];

  get usernameFormControl(): AbstractControl {
    return this.formGroup.controls.username;
  }

  get usernameRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.usernameFormControl.errors);
  }

  get usernameHasLengthError(): boolean {
    return FormUtils.hasLengthErrors(this.usernameFormControl.errors);
  }

  constructor(private store: Store<FromStore.AccountState>,
    private languageService: LanguageHttpService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      username: new UntypedFormControl(undefined, this.validators),
    });
  }

  handleSubmitClick = (): void => {
    this.formGroup.valid ? this.onFormValid() : this.runFormValidation();
  };

  handleBackClick = (): void => {
    this.router.navigateByUrl('/login');
  };

  private onFormValid = (): void => {
    this.store.dispatch(
      FromStore.Actions.requestResetPassword({
        username: this.usernameFormControl.value,
        languageCode: this.languageService.getSelectedLanguage()
      })
    );
    setTimeout(() => {
      this.resendEmailConfirmed = true;
    }, 1000);
  };

  private runFormValidation = (): void => {
    this.usernameFormControl.markAsTouched({ onlySelf: true });
  };
}
