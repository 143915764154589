<div *transloco="let transl">
  <span class="play-button" [class]="isRtl ? 'es-tooltip-left' : 'es-tooltip-right'" [attr.data-tooltip]="transl('site_user.subject.action.start_a_visit')">
    <img src="assets/web/images/play.svg" alt="{{ transl('site_user.subject.action.start_a_visit')}}" (click)="callSubject.emit()" />
  </span>
  <span class="invite-button" [class]="isRtl ? 'es-tooltip-left' : 'es-tooltip-right'"  [attr.data-tooltip]="transl('site_user.subject.action.resend_invite_code')">
    <img src="assets/web/images/mail.svg" alt="{{ transl('site_user.subject.action.resend_invite_code')}}" (click)="inviteSubject.emit()" />
  </span>
  <span class="remove-button" [class]="isRtl ? 'es-tooltip-left' : 'es-tooltip-right'"  [attr.data-tooltip]="transl('site_user.subject.action.remove')">
    <img src="assets/web/images/bin.svg" alt="{{ transl('site_user.subject.action.remove')}}" (click)="removeSubject.emit()" />
  </span>
  
</div>













<!-- <span [ngClass]="isOpenClass">
  <button
    mat-icon-button
    class="vv-actions__mob"
    (click)="mobileChevronRightClick.emit()"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
  <div class="vv-actions__nav"  *transloco="let transl">
    <div class="vv-actions__back">
      <button
        mat-icon-button
        class="vv-actions__mob"
        (click)="mobileChevronLeftClick.emit()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="vv-actions__number">
        {{ transl('site_journey.subject.subject_list.subject_list_action.subject_name',
        { subjectName:subjectName}) }}
      </div>
    </div>
    <button mat-icon-button (click)="callSubject.emit()" title="{{transl('site_user.subject.action.start_a_visit')}}">
      <mat-icon>play_arrow</mat-icon>
      <span class="mat-icon-button-text">{{ transl('site_user.subject.action.start_a_visit')}}</span>
    </button>
    <button mat-icon-button (click)="inviteSubject.emit()" title="{{ transl('site_user.subject.action.resend_invite_code')}}">
      <mat-icon>mail_outline</mat-icon>
      <span class="mat-icon-button-text">{{ transl('site_user.subject.action.resend_invite_code')}}</span>
    </button>
    <button mat-icon-button (click)="removeSubject.emit()" title="{{ transl('site_user.subject.action.remove')}}">
      <mat-icon>clear</mat-icon>
      <span class="mat-icon-button-text">{{ transl('site_user.subject.action.remove')}}</span>
    </button>
  </div>
</span> -->
