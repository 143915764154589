import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-back-menu-item',
  templateUrl: './back-menu-item.component.html',
  styleUrls: ['./back-menu-item.component.scss'],
})
export class BackMenuItemComponent {
  @Output()
  menuItemClick = new EventEmitter();

  handleClick(): void {
    this.menuItemClick.emit();
  }
}
