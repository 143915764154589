import { fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

export function isOnline$() {
  return merge<boolean>(
    fromEvent(window, 'offline').pipe(map(() => false)),
    fromEvent(window, 'online').pipe(map(() => true)),
    new Observable((sub: Observer<boolean>) => {
      sub.next(navigator.onLine);
      sub.complete();
    })
  );
}
