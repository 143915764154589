import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store';

import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';
import { genericMetaReducer } from '@virtual-trials-workspace/store';

import { provisionerActions } from '../actions/provisioner.actions';
import { ProvisionerState } from '../provisioner.state';

export const PROVISIONER_STATE_STORAGE_KEYS = new InjectionToken<
  keyof Array<ProvisionerState>
>('ProvisionerStateStorageKeys');

export const PROVISIONER_STATE_LOCAL_STORAGE_KEY = new InjectionToken<
  Array<string>
>('ProvisionerStateStorageState');

export const PROVISIONER_STATE_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<ProvisionerState, typeof provisionerActions>
>('ProvisionerStateConfigToken');

export const getProvisionerStateConfig = (
  saveKeys: Array<string>,
  localStorageKey: string,
  storageService: LocalStorageService
): any => ({
  metaReducers: [genericMetaReducer(saveKeys, localStorageKey, storageService)],
});
