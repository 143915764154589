import { state } from '@angular/animations';
import { Action, createReducer, on, State } from '@ngrx/store';
import { ProvisioningStatus, VisitInviteStatus } from '@virtual-trials-workspace/models';

import * as FromSessionActions from '../actions/session.actions';
import * as FromActions from '../actions/visit.actions';
import * as FromState from '../sub-states';
import { VisitEnd } from '../models';

const reducer = createReducer(
  FromState.initialVisitState(),
  on(FromActions.subjectVisitReady, (state, action) => {
    const services = action.visitServices.map((m) => ({
      type: m,
      serverStatus: 'unknown' as const,
      clientStatus: 'unknown' as const,
    }));

    return {
      ...state,
      isLoading: false,
      hasActiveVisit: true,
      visitId: action.visitId,
      services,
    };
  }),
  on(FromActions.endVisit, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(FromActions.endVisitResponseSuccess, (state, action) => ({
    ...FromState.initialVisitState(),
    hasActiveVisit: false,
    services: state.services,
    visitEnd: action.visitEnd,
    selectedCameraId: state.selectedCameraId,
    isDeviceProvisioned: state.isDeviceProvisioned,
    isEcoaLiveStandalone: state.isEcoaLiveStandalone,
    siteId: state.siteId,
  })),
  on(FromActions.endVisitResponseFail, (state) => ({
    ...FromState.initialVisitState(),
    hasActiveVisit: false,
    services: state.services,
    visitEnd: 'manual' as VisitEnd,
  })),
  on(FromActions.endVisitClientSideOnError, (state) => ({
    ...FromState.initialVisitState(),
    services: state.services,
    visitEnd: 'error' as VisitEnd,
    hasActiveVisit: false,
    selectedCameraId: state.selectedCameraId,
    isDeviceProvisioned: state.isDeviceProvisioned,
    isEcoaLiveStandalone: state.isEcoaLiveStandalone,
    siteId: state.siteId,
  })),
  on(FromActions.getDeviceProvisioningStatus, (state) => ({
    ...state,
    isDeviceProvisioned: undefined,
    isLoading: true,
  })),
  on(FromActions.getDeviceProvisioningStatusSuccess, (state, action) => ({
    ...state,
    isDeviceProvisioned: action.provisioned,
    isLoading: false,
  })),
  on(FromActions.getDeviceProvisioningStatusFailure, (state) => ({
    ...state,
    isDeviceProvisioned: false,
    isLoading: false,
  })),
  on(FromActions.setProvisioningStatus, (state) => ({
    ...state,
    deviceProvisioningStatus: 'unset' as ProvisioningStatus,
    isLoading: true,
  })),
  on(FromActions.setProvisioningStatusSuccess, (state) => ({
    ...state,
    deviceProvisioningStatus: 'success' as ProvisioningStatus,
    isLoading: false,
  })),
  on(FromActions.setProvisioningStatusFailure, (state) => ({
    ...state,
    deviceProvisioningStatus: 'fail' as ProvisioningStatus,
    isLoading: false,
  })),
  on(FromActions.skipProvisioning, (state) => ({
    ...state,
    deviceProvisioningStatus: 'skip' as ProvisioningStatus,
    isLoading: false,
  })),
  on(FromActions.subjectJoinVisit, (state) => {
    return {
      ...state,
      isLoading: true,
      readyToJoinVisit: true,
      hasMediaDevicesPermissions: true,
    };
  }),
  on(FromActions.changeUiState, (state, action) => ({
    ...state,
    uiState: action.targetState,
  })),
  on(FromActions.checkForActiveVisit, (state) => ({
    ...state,
    hasActiveVisit: undefined,
    isLoading: true,
    visitId: undefined,
  })),
  on(FromActions.checkHasDeviceControl, (state) => ({
    ...state,
    hasDeviceControl: undefined,
  })),
  on(FromActions.checkHasDeviceControlSuccess, (state, action) => ({
    ...state,
    hasDeviceControl: action.hasControl,
  })),
  on(FromActions.checkForSubjectActiveVisit, (state) => ({
    ...state,
    readyToJoinVisit: false,
    deviceConnect: false,
    hasActiveVisit: false,
    visitId: undefined,
  })),
  on(FromActions.activeVisitExists, (state, action) => {
    {
      const services = action.visitServices.map((m) => ({
        type: m,
        serverStatus: 'ready' as const,
        clientStatus: 'unknown' as const,
      }));

      return {
        ...state,
        hasActiveVisit: true,
        rejoinVisit: true,
        isLoading: false,
        visitId: action.visitId,
        visitCode: action.visitCode,
        siteId: action.siteId,
        isEcoaLiveStandalone: action.isEcoaLiveStandalone,
        services,
        visitEnd: undefined,
      };
    }
  }),
  on(FromActions.noActiveVisit, (state) => ({
    ...state,
    hasActiveVisit: false,
    isLoading: false,
    visitId: undefined,
  })),

  on(FromActions.startVisitWithSubject, (state, action) => {
    const services = action.modules.map((m) => ({
      type: m,
      serverStatus: 'unknown' as const,
      clientStatus: 'unknown' as const,
    }));
    return {
      ...state,
      siteId: action.siteId,
      services,
      visitEnd: undefined,
      visitEndedOnError: undefined,
      videoServiceEnded: false,
      emulationServiceEnded: false,
      visitId: undefined,
      subjectParticipantId: action.subjectParticipantId,
      isEcoaLiveStandalone: action.isEcoaLiveStandalone,
      isDeviceProvisioned: action.isDeviceProvisioned,
      // deviceProvisioningStatus: 'unset',
    };
  }),
  on(FromActions.setEcoaLiveStandalone, (state, action) => ({
    ...state,
    isEcoaLiveStandalone: action.isEcoaLiveStandalone,
  })),
  on(FromActions.createVisit, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
  })),
  on(FromActions.createVisitSuccess, (state, action) => {
    const services = action.visitServices.map((m) => ({
      type: m,
      serverStatus: 'unknown' as const,
      clientStatus: 'unknown' as const,
    }));
    return {
      ...state,
      hasActiveVisit: true,
      error: undefined,
      isLoading: false,
      services: services,
      visitId: action.visitId,
    };
  }),
  on(FromActions.createVisitFail, (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
    hasActiveVisit: false,
    services: [],
    subjectParticipantId: undefined,
  })),
  on(FromActions.getVisitCode, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
  })),
  on(FromActions.getVisitCodeSuccess, (state, action) => ({
    ...state,
    error: undefined,
    isLoading: false,
    visitCode: action.visitCode,
  })),
  on(FromActions.getVisitCodeFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(FromActions.startVisit, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FromActions.startVisitServiceSuccess, (state, action) => {
    const services = [...state.services].map((s) =>
      s.type === action.visitService
        ? {
            clientStatus: 'unknown' as const,
            serverStatus: 'ready' as const,
            type: s.type,
          }
        : s
    );

    const waitingOnServices = services.some(
      (s) => s.serverStatus === 'unknown'
    );

    return {
      ...state,
      isLoading: waitingOnServices,
      services,
    };
  }),
  on(FromActions.startVisitServiceFail, (state, action) => {
    const services = [...state.services].map((s) =>
      s.type === action.visitService
        ? {
            clientStatus: 'unknown' as const,
            serverStatus: 'errored' as const,
            type: s.type,
          }
        : s
    );

    const waitingOnServices = services.some(
      (s) => s.serverStatus === 'unknown'
    );

    return {
      ...state,
      isLoading: waitingOnServices,
      services,
    };
  }),
  on(FromActions.joinVisitResponseFailure, (state, action) => {
    let lostEmulation;

    const services = [...state.services].map((s) => {
      if (s.type === action.visitService) {
        lostEmulation =
          s.type.toLowerCase() === 'emulation' &&
          s.serverStatus.toLowerCase() === 'ready';
        return {
          clientStatus: s.clientStatus,
          serverStatus: 'errored' as const,
          type: s.type,
        };
      }
      return s;
    });

    return {
      ...state,
      emulationServiceLost: lostEmulation,
      services,
    };
  }),
  on(FromActions.startVisitComplete, (state, action) => ({
    ...state,
    inviteStatus: !action.sendInvite ? 'skipped' : state.inviteStatus,
    isLoading: true,
  })),
  on(FromActions.setDevicesPermissionsAction, (state) => ({
    ...state,
    hasMediaDevicesPermissions: true,
  })),
  on(FromActions.setAllDevicesAction, (state, action) => ({
    ...state,
    allUserDevices: action.devices,
  })),
  on(FromActions.setMicrophoneAction, (state, action) => ({
    ...state,
    selectedMicrophoneId: action.deviceId,
  })),
  on(FromActions.setSpeakerAction, (state, action) => ({
    ...state,
    selectedSpeakerId: action.deviceId,
  })),
  on(FromActions.setCameraAction, (state, action) => ({
    ...state,
    selectedCameraId: action.deviceId,
  })),
  on(FromActions.sendInvitation, (state) => ({
    ...state,
    inviteStatus: 'unset' as VisitInviteStatus,
    isLoading: true,
  })),
  on(FromActions.sendInvitationSuccess, (state) => ({
    ...state,
    inviteStatus: 'success' as VisitInviteStatus,
    isLoading: false,
  })),
  on(FromActions.sendInvitationFail, (state) => ({
    ...state,
    inviteStatus: 'fail' as VisitInviteStatus,
    isLoading: false,
  })),
  on(FromActions.joinVisitReady, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FromActions.initVideoService, (state, action) => ({
    ...state,
    isLoading: true,
    roomKey: action.roomKey,
    roomPin: action.token,
  })),
  on(FromActions.setVisitSessionTimers, (state, action) => ({
    ...state,
    isLoading: false,
    visitSessionTimer: action.visitSessionTimer,
  })),
  on(FromActions.isVisitJoin, (state, action) => {
    const isVisitJoin = !!state.visitSessionTimer.visitJoinTime;
    return {
      ...state,
      isVisitJoin: isVisitJoin,
    };
  }),
  on(FromActions.clearVideoService, (state, action) => ({
    ...state,
    roomKey: undefined,
    roomPin: undefined,
  })),
  on(FromActions.clearDeviceConnection, (state) => {
    return {
      ...state,
      deviceConnection: undefined,
      hasDeviceControl: undefined,
      transferControlResult: undefined,
    };
  }),
  on(FromActions.renewVisitServiceInfo, (state) => {
    return {
      ...state,
      deviceConnection: undefined,
    };
  }),
  on(FromActions.initEmulationService, (state, action) => ({
    ...state,
    isLoading: true,
    deviceConnection: action.deviceConnection,
  })),
  on(FromActions.emulationServiceReady, (state) => {
    const services = [...state.services].map((s) =>
      s.type === 'Emulation'
        ? {
            clientStatus: 'ready' as const,
            serverStatus: s.serverStatus,
            type: s.type,
          }
        : s
    );

    return {
      ...state,
      isLoading: false,
      services,
      transferControlResult: undefined,
    };
  }),
  on(FromActions.videoServiceReady, (state) => {
    const services = [...state.services].map((s) =>
      s.type === 'Call'
        ? {
            clientStatus: 'ready' as const,
            serverStatus: s.serverStatus,
            type: s.type,
          }
        : s
    );

    return {
      ...state,
      isLoading: false,
      services,
    };
  }),
  on(FromActions.videoServiceEnded, (state) => {
    const services = state.services.filter((s) => s.type !== 'Call');
    return {
      ...state,
      services: services,
      roomPin: undefined,
      roomKey: undefined,
      videoServiceEnded: true,
    };
  }),
  on(FromActions.emulationServiceEnded, (state) => {
    const services = state.services.filter((s) => s.type !== 'Emulation');
    return {
      ...state,
      services: services,
      emulationServiceEnded: true,
    };
  }),
  on(FromActions.emulationServiceLost, (state) => ({
    ...state,
    isLoading: false,
    emulationServiceLost: true,
  })),
  on(FromActions.emulationTransferControl, (state) => ({
    ...state,
    hasDeviceControl: false,
    transferControlResult: undefined,
    emulationRequestControlConfirmationPending: false,
    emulationRequestControlDeclined: false,
  })),
  on(FromActions.emulationTransferControlSuccess, (state) => ({
    ...state,
    deviceConnect: false,
    transferControlResult: { success: true },
  })),
  on(FromActions.emulationTransferControlFailure, (state, action) => ({
    ...state,
    hasDeviceControl: true,
    transferControlResult: {
      success: false,
      responseMessage: action.message,
    },
  })),
  on(FromActions.emulationDeviceConnect, (state, action) => ({
    ...state,
    deviceConnection: action.deviceConnection,
    hasDeviceControl: true,
    deviceConnect: true,
  })),
  on(FromSessionActions.clearSessionData, (state) => ({
    ...FromState.initialVisitState(),
    selectedCameraId: state.selectedCameraId,
  })), // wipe feature state on session end,
  on(FromActions.clearVisitEndState, (state) => ({
    ...state,
    visitEnd: undefined,
  })),
  on(FromSessionActions.joinStandaloneVisitSuccess, (state, action) => ({
    ...FromState.initialVisitState(),
    ecoaLiveStandaloneVisitCode: action.visitCode,
  })),
  on(FromActions.emulationRequestControlConfirmation, (state, action) => ({
    ...state,
    emulationRequestControlConfirmationPending: true,
    emulationRequestControlDeclined: false,
  })),
  on(FromActions.emulationRequestControlCancel, (state, action) => ({
    ...state,
    emulationRequestControlConfirmationPending: false,
  })),
  on(FromActions.emulationRequestControl, (state, action) => ({
    ...state,
    emulationRequestControlConfirmationPending: false,
  })),
  on(FromActions.emulationDeclineTransferRequest, (state, action) => ({
    ...state,
    emulationRequestControlConfirmationPending: false,
  })),
  on(FromActions.emulationNotifyTransferRequestDeclined, (state, action) => ({
    ...state,
    emulationRequestControlDeclined: true,
  }))
);

export function visitReducer(
  state: FromState.VisitState | undefined,
  action: Action
) {
  return reducer(state, action);
}
