export class Token {
    content: any;
    expiresAt: Date;

    constructor(from: Partial<Token>) {
        Object.assign(this, from)
    }

    getTimeoutTime = (): number => {
        return this.expiresAt
            ? this.expiresAt.getTime() - new Date().getTime()
            : -1;
    }

    hasExpired = (): boolean => this.getTimeoutTime() <= 0;
}
