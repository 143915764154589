import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RootActions } from '@virtual-trials-workspace/store';
import { take } from 'rxjs/operators';

import { RouterSelectors } from '../store/selectors';

@Component({
  selector: 'vt-standalone-join-visit',
  templateUrl: './join-visit.component.html',
  styleUrls: ['./join-visit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JoinVisitComponent implements OnInit {
  @HostBinding('class')
  private class = 'join-visit-container';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .pipe(select(RouterSelectors.getVisitCode))
      .pipe(take(1))
      .subscribe(this.joinVisit.bind(this));
  }

  private joinVisit = (visitCode: string): void => {
    this.store.dispatch(RootActions.clearSessionData());
    this.store.dispatch(RootActions.joinStandaloneVisit({ visitCode }));
  };
}
