import {
  ActivatedRouteSnapshot,
  Data,
  Params,
  RouterStateSnapshot,
} from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { Injectable } from "@angular/core";
import { RouteState } from './route.state';

@Injectable()
export class CustomSerialiser implements RouterStateSerializer<RouteState> {
  serialize = (routerState: RouterStateSnapshot): RouteState => ({
    url: routerState.url,
    params: mergeRouteParams(routerState.root, (r) => r.params),
    queryParams: mergeRouteParams(routerState.root, (r) => r.queryParams),
    data: mergeRouteData(routerState.root),
  });
}

const mergeRouteParams = (
  route: ActivatedRouteSnapshot,
  getter: (r: ActivatedRouteSnapshot) => Params
): Params => {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild = getRoutePrimaryChild(route);
  return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
};

const mergeRouteData = (route: ActivatedRouteSnapshot): Data => {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = getRoutePrimaryChild(route);
  return { ...currentData, ...mergeRouteData(primaryChild) };
};

const getRoutePrimaryChild = (route: ActivatedRouteSnapshot) => {
  return route.children.find((c) => c.outlet === 'primary') || route.firstChild;
};
