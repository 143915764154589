import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule as NgrxStoreModule } from '@ngrx/store';

// import { DataPersistence, NxModule } from '@nx/angular';

import { buildSpecificModules } from './build-specifics';
import * as FromEffects from './effects';
import * as FromConfig from './root.state.config';
import * as FromRouter from './router';

@NgModule({
  imports: [
    NgrxStoreModule.forRoot(FromConfig.baseReducerMap),
    EffectsModule.forRoot([
      FromEffects.SessionEffects,
      FromEffects.VisitEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      stateKey: FromRouter.ROUTER_STATE_KEY,
    }),
    // NxModule.forRoot(),
    ...buildSpecificModules,
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: FromRouter.CustomSerialiser,
    },
    // DataPersistence,
    ...FromConfig.persistentStateProviders(),
  ],
})
export class StoreModule {}
