import { Injectable, NgZone } from '@angular/core';

import { BaseConfig } from '@virtual-trials-workspace/models';
import { DeviceConnection } from '@virtual-trials-workspace/store';
import { from, Observable, of, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  AnboxStream,
  AnboxStreamGatewayConnector,
} from '../../../assets/scripts/anbox-stream-sdk.js';

@Injectable({ providedIn: 'root' })
export class AnboxService {
  private stream: AnboxStream;

  constructor(private config: BaseConfig, private ngZone: NgZone) {}

  connect = (
    connection: DeviceConnection,
    onReadyCallback: () => void,
    onErrorCallback: (error) => void
  ): Observable<void> => {
    const connector = new AnboxStreamGatewayConnector({
      url: this.config.deviceStreamGatewayUrl,
      authToken: this.config.deviceStreamGatewayAuthToken,
      session: { id: connection.id },
      screen: null,
      extraData: null,
    });

    this.stream = new AnboxStream({
      connector: connector,
      targetElement: 'device-id',
      callbacks: {
        ready: onReadyCallback,
        error: onErrorCallback,
      },
    });

    return from(this.stream.connect(connection)) as Observable<void>;
  };

  disconnect = (): Observable<boolean> => {
    if (this.stream) {
      this.ngZone.run(() => {
        this.stream.disconnect();
      });
      return of(true);
    }
    return of(false);
  };
}
