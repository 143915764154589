import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { SecurityQuestion } from '@virtual-trials-workspace/models';

@Component({
  selector: 'vt-activate-security-question-answer',
  templateUrl: './security-question-answer.component.html',
  styleUrls: ['./security-question-answer.component.scss'],
})
export class SecurityQuestionAnswerComponent {
  @Input()
  questionFormControl: AbstractControl;

  @Input()
  answerFormControl: AbstractControl;

  @Input()
  label: string;

  @Input()
  questionOptions$: Observable<Array<SecurityQuestion>>;

  @Input()
  showErrorForQuestion: boolean;

  @Input()
  showRequiredErrorForAnswer: boolean;

  @Input()
  showLengthErrorForAnswer: boolean;
}
