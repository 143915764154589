import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-activate-success-status',
  templateUrl: './success-status.component.html',
  styleUrls: ['./success-status.component.scss'],
})
export class SuccessStatusComponent {
  @Input()
  titleText: string;
}
