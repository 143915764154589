import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-web-login-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  readonly passwordErrorMessage = 'Please enter a valid password';

  readonly usernameOrEmailErrorMessage =
    'Please enter a valid username / email address';

  @Input()
  buildVersion: string;

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  errorMessage: string;

  @Input()
  submitButtonIsEnabled: boolean;

  @Output()
  activateAccountClick = new EventEmitter();

  @Output()
  forgotPasswordClick = new EventEmitter();

  @Output()
  submitFormClick = new EventEmitter();

  constructor(private translocoService: TranslocoService) {}

  handleActivateAccount = (): void => this.activateAccountClick.emit();

  handleForgottenPassword = (): void => this.forgotPasswordClick.emit();

  handleSubmitForm = (): void => this.submitFormClick.emit();

  isPasswordRequiredErrorState = () =>
    this.formGroup.controls.password.errors?.required;

  invalidCredentialsAPIErrorState = () => !!this.errorMessage;

  usernameControlErrorMatcher = (): ErrorStateMatcher => ({
    isErrorState: (): boolean => {
      const { username } = this.formGroup.controls;
      return (
        !!this.errorMessage ||
        (username.touched &&
          (username.errors?.required || username.errors?.pattern))
      );
    },
  });

  passwordControlErrorMatcher = (): ErrorStateMatcher => ({
    isErrorState: (): boolean => {
      const { password } = this.formGroup.controls;
      return (
        !!this.errorMessage || (password.touched && password.errors?.required)
      );
    },
  });

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
