import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-activate-pending-status',
  templateUrl: './pending-status.component.html',
  styleUrls: ['./pending-status.component.scss'],
})
export class PendingStatusComponent {
  @Input()
  titleText: string;

  @Input()
  subTitleText: string;
}
