<ng-container
  *ngIf="showFinalWarning; then confirmRemoveSubject; else removeSubject"
>
</ng-container>

<ng-template #removeSubject>
  <div
    class="es-popup  -es-closeable -es-modal -es-open es-custom-popup"
    *transloco="let transl"
  >

    <div class="es-popup-content">
      <!-- <header class="es-popup-header">
        <div class="warning-icon"></div>
        <h2>Declining the terms</h2>
      </header> -->
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <p>{{ transl('warning.remove_subject') }}</p>
            </div>
          </div>
        </div>
      </div>
      <footer class="es-popup-footer">
        <div class="es-toolbar">
          <ul class="es-right">
            <li class="es-toolbar-item">
              <button
                id="yes-button-id"
                class="es-button -es-danger -es-modal"
                (click)="showFinalWarningTemplate()"
              >
              {{ transl('general.yes') }}
              </button>
            </li>
            <li class="es-toolbar-item">
              <button
                id="initial-cancel-button-id"
                class="es-button -es-modal"
                (click)="closeDialog()"
              >
              {{ transl('general.cancel') }}
              </button>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </div>
</ng-template>

<ng-template #confirmRemoveSubject>
    <div
    class="es-popup  -es-closeable -es-modal -es-open es-custom-popup"
    *transloco="let transl"
  >
    <div class="es-popup-content">
      <!-- <header class="es-popup-header">
        <div class="warning-icon"></div>
        <h2>Declining the terms</h2>
      </header> -->
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <p>{{ transl('warning.remove_subject_confirmation') }}</p>
            </div>
          </div>
        </div>
      </div>
      <footer class="es-popup-footer">
        <div class="es-toolbar">
          <ul class="es-right">
            <li class="es-toolbar-item">
              <button
                id="remove-subject-button-id"
                class="es-button -es-danger -es-modal"
                (click)="removeSubjectFromService()"
              >
              {{ transl('site_user.subject.action.remove') }}
              </button>
            </li>
            <li class="es-toolbar-item">
              <button
                id="final-cancel-button-id"
                class="es-button  -es-modal"
                (click)="closeDialog()"
              >
              {{ transl('general.cancel') }}
              </button>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </div>
</ng-template>
