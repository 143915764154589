<ng-container *transloco="let transl">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <mat-icon class="logo" svgIcon="ert-logo"></mat-icon> -->
        <div class="logo"></div>
        <vt-ui-language-select [setUserLanguage]="false"></vt-ui-language-select>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="center center">
        <vt-ui-viewport-layout [minWidth]="minViewPortWidth" [minHeight]="minViewPortHeight">
            <ng-container content>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon class="icon" aria-hidden="false" aria-label="platform error">error_outline</mat-icon>
                    <ng-container *ngTemplateOutlet="platformErrorText"></ng-container>
                </div>
            </ng-container>

            <vt-ui-viewport-error error>
                <ng-container before *ngTemplateOutlet="platformErrorText"></ng-container>
            </vt-ui-viewport-error>
        </vt-ui-viewport-layout>
    </div>

    <ng-template #platformErrorText>
        <span [innerHTML]="transl(unSupportedBrowswerErrorKey, { fallback: errorMessage, updateLink: platformUpdateLink })" class="text"></span>
    </ng-template>
</ng-container>