import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-provisioner-journey-toolbar',
  templateUrl: './site-list-toolbar.component.html',
  styleUrls: ['./site-list-toolbar.component.scss'],
})
export class SiteListToolbarComponent {
  status: boolean;
  searchText: string;

  @Output()
  addSubject = new EventEmitter();

  @Output()
  toggleSideNav = new EventEmitter();

  @Output()
  searchTerm = new EventEmitter<string>();

  handleSearchButtonClick = (): void => {
    this.status = !this.status;
    if (!status) {
      this.searchText = '';
      this.searchTerm.next(this.searchText);
    }
  };

  addSubjectClick = (): void => {
    this.addSubject.emit();
  };

  sideNavClick = (): void => {
    this.toggleSideNav.emit();
  };

  handleSearch = (): void => {
    this.searchTerm.next(this.searchText);
  };
}
