import { LanguageModel } from './language.model';

export interface Languages extends Array<LanguageModel> {
  getDefault(): LanguageModel;
  get(lang: string): LanguageModel;
  contains(lang: string): boolean;
}

const getDefault = function (this: Array<LanguageModel>): LanguageModel {
  return this.find((l) => !!l.isDefault);
};

const get = function (this: Array<LanguageModel>, lang: string): LanguageModel {
  return this.find((l) => l.code === lang);
};

const contains = function (this: Array<LanguageModel>, lang: string): boolean {
  return this.findIndex((l) => l.code === lang) >= 0;
};

export function toLanguageArray(arr: Array<LanguageModel>): Languages {
  const _arr = arr as Languages;
  _arr.getDefault = getDefault.bind(_arr);
  _arr.get = get.bind(_arr);
  _arr.contains = contains.bind(_arr);

  return _arr;
}
