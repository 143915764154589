<div class="es-gateway" *transloco="let transl">
    <!-- Header -->
    <vt-ui-subject-header></vt-ui-subject-header>
    <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
        <vt-ui-language-select [setUserLanguage]="false" class="top-right"></vt-ui-language-select>
    </div>
    <vt-ui-viewport-layout [minWidth]="minViewPortWidth" [minHeight]="minViewPortHeight">        
        <router-outlet content></router-outlet>
        <vt-ui-viewport-error error></vt-ui-viewport-error>
    </vt-ui-viewport-layout>
</div>

