import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseConfig, HttpPostResponse } from '@virtual-trials-workspace/models';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  constructor(private httpClient: HttpClient, private config: BaseConfig) {}

  get = <TResponse>(resource: string): Observable<TResponse> => {
    const url = `${this.config.baseApiEndpoint}${resource}`;
    return this.httpClient.get<TResponse>(url);
  };

  post = <TRequest, TResponse = HttpPostResponse>(
    resource: string,
    request: TRequest
  ): Observable<TResponse> => {
    const url = `${this.config.baseApiEndpoint}${resource}`;
    return this.httpClient.post<TResponse>(url, request);
  };

  delete = <TRequest, TResponse>(
    resource: string,
    request: TRequest
  ): Observable<TResponse> => {
    const url = `${this.config.baseApiEndpoint}${resource}`;
    return this.httpClient.delete<TResponse>(url, request);
  };
}
