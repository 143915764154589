<section
  *transloco="let transl"
  class="es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
  >
  <div class="es-column">
    <div class="es-content-box">
      <div class="es-panel es-custom-panel">
        <div class="es-form">
          <div class="es-content-box">
            <div class="es-text-group">
              <h2>{{ transl('activate.title') }}</h2>
            </div>
          </div>
          <form class="form" [formGroup]="formGroup">
            <div class="es-form-field">
              <p>
                <strong>{{ transl('activate.subtitle') }}</strong>
              </p>
              <label class="es-label -es-required">{{transl('activate.code_input_placeholder')}}</label>
              <input
                type="text" 
                class="es-input" 
                [placeholder]="transl('activate.code_input_placeholder')"
                formControlName="shortcode"
                [errorStateMatcher]="shortCodeControlErrorMatcher()"
              />
              <div class="error-container">
                <div 
                  *ngIf="!!apiError"
                  class="es-form-message  -es-invalid">
                  <div class="error-icon"></div>
                  <p> {{ apiError }}</p>
                </div>
                <div 
                  *ngIf="formGroup.controls.shortcode.errors?.required &&
                  formGroup.controls.shortcode.touched
                  "
                  id="emptyElement"
                  class="es-form-message  -es-invalid">
                  <div class="error-icon"></div>
                  <p> {{ transl('error.short_code_required') }}</p>
                </div>
              </div>
            </div>
          </form>
          <ng-content select="[activeShortCode]"></ng-content>
        </div>
        
        <div class="es-toolbar">
          <ul class="es-right">
            <li class="es-toolbar-item">
              <button
                [routerLink]="['/login']"
                class="es-button"
              >
                {{ transl('general.cancel') }}
              </button>
            </li>
            <li class="es-toolbar-item">
              <button
                class="es-button -es-primary"
                id="continue-button"
                color="primary"
                [disabled]="disableButton"
                (click)="handleContinueClick()"
              >
                {{ transl('general.continue') }}
              </button>
              
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div *transloco="let transl">
  <mat-card>
    <div class="card-title">
      {{ transl('activate.title') }}
    </div>
    <div class="card-subtitle">{{ transl('activate.subtitle') }}</div>
    <mat-card-content>
      <form class="form" [formGroup]="formGroup">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{transl('activate.code_input_placeholder')}}</mat-label>
          <input
            matInput
            formControlName="shortcode"
            [errorStateMatcher]="shortCodeControlErrorMatcher()"
          />
          <mat-error *ngIf="!!apiError">
            {{ apiError }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls.shortcode.errors?.required">
            {{ transl('error.short_code_required') }}
          </mat-error>
        </mat-form-field>
      </form>
      <ng-content select="[activeShortCode]"></ng-content>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-flat-button
        id="continue-button"
        color="primary"
        [disabled]="disableButton"
        (click)="handleContinueClick()"
      >
        {{ transl('general.continue') }}
      </button>
    </mat-card-actions>
  </mat-card>
</div> -->
