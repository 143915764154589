<div class="es-toolbar es-paginate">
    <ul class="es-right es-pageinate-list">
        <li class="es-toolbar-item rowText">
            <span>Rows:</span>
        </li>
        <li class="es-toolbar-item">
            <div class="es-select  es-select-paginate-select">
                <select 
                    (change)="onChangeRowsPerPage($event.currentTarget.value)">
                    <option *ngFor="let pageSize of pageSizeOptions"
                        [value]="pageSize"
                        [selected]="pageSize === selectedRowsPerPage"
                        [id]="pageSize"
                        >
                        {{ pageSize }}
                    </option>
                  </select>
            </div>
        </li>
        <li class="es-toolbar-item">
            <div class="es-select-paginate-icon">
                <div class="es-pagination">
                    <button class="es-button"
                        
                        [attr.aria-disabled]="current === 1"
                        [disabled]="current === 1"
                        (click)="onGoTo(1)"
                    >
                        <span class="es-icon es-icon-previous"></span>
                    </button>
                    <button class="es-button"
                        
                        [attr.aria-disabled]="current === 1"
                        [disabled]="current === 1"
                        (click)="onPrevious()"
                    >
                        <span class="es-icon es-icon-chevron-left"></span>
                    </button>
                    <div class="es-number">
                        {{startIndex}} - {{endIndex}} of {{total}}
                    </div>
                    <button class="es-button"
                        
                        [attr.aria-disabled]="current === totalPages"
                        [disabled]="current === totalPages || (total === 0)"
                        (click)="onNext()"
                    >
                        <span class="es-icon es-icon-chevron-right"></span>
                    </button>
                    <button class="es-button"
                        
                        [attr.aria-disabled]="current === totalPages"
                        [disabled]="current === totalPages || (total === 0)"
                        (click)="onGoTo(totalPages)"
                    >
                        <span class="es-icon es-icon-next"></span>
                    </button>
                </div>
            </div>
        </li>

    </ul>
</div>

