<div *transloco="let transl">
  <vt-visit-content-sheet data-testid="emulation-waiting-id"
    [headerText]="transl('visit.emulation.assessment_form.title')">
    <ng-container cardBody>
      <header class="es-card-header">
        <h2>{{transl('visit.emulation.assessment_form.title')}}</h2>
      </header>
      <div  class="es-card-body">
        <div class="es-content-box">
          <div class="es-notification -es-info">
            <span class="es-icon es-icon-info"></span>
            <p id="body-text-paragraph-id">{{ bodyText }}</p>
          </div>
          
          <p class="waiting-text" *ngIf="!deviceHasControl">
            {{ transl('visit.emulation.assessment_form.waiting_control_text') }}
          </p>
          <div class="es-loading-indicator -es-visible">
            <span class="es-loading-icon"></span>
          </div>
        </div>
      </div>

    </ng-container>
    <ng-container esBottomToolbar class="visit-actions" *ngIf="!deviceHasControl">
        <div vtUIBottomDevicePanel class="es-toolbar" >
          <ul class="es-right">
            <li class="es-toolbar-item es-custom-visit-btn">
              
              <button
                class="es-button  -es-danger "
                (click)="handleEndVisit()" 
              >
              <div class="callIcons call_end_icon"></div>
              {{ transl('visit.site.end_session') }}
              </button>
            </li>
            <li class="es-toolbar-item es-custom-visit-btn">  
              <button 
                id="transfer-control-request-button-id" 
                (click)="handleTransferRequestControl()"
                class="es-button transfer-control-request-button">
                {{ transl('visit.site.request_control') }}
                <div class="callIcons request_control"></div>
              </button>
            </li>
          </ul>
        </div>  
      

        

    </ng-container>

  </vt-visit-content-sheet>
  
</div>