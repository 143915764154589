import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { RouteBuilderService } from '@virtual-trials-workspace/shared-core-services';
import { Subject as RxjsSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Subject } from '../../models';
import { SiteJourneyRoutes } from '../../site-journey-routes';
import { SiteState } from '../../store';
import * as siteActions from '../../store/actions/site.actions';
import * as siteSelectors from '../../store/selectors/site.selectors';

@Component({
  selector: 'vt-site-journey-subject',
  templateUrl: './invite-subject.component.html',
  styleUrls: ['./invite-subject.component.scss'],
})
export class InviteSubjectComponent implements OnInit, OnDestroy {
  isLoading$ = this.store.pipe(select(siteSelectors.getIsLoading));

  inviteSubjectForm: UntypedFormGroup;
  showSubjectAlreadyInvited: boolean;

  private subjectIdRegex: RegExp;
  private subjects: Array<Subject>;

  private readonly unsubscribe$ = new RxjsSubject<void>();

  constructor(
    private store: Store<SiteState>,
    private routeBuilderService: RouteBuilderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initInviteSubjectForm();
    this.initStudyRegexSubscription();
    this.initSubjectsSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleNextClick = (): void => {
    const { subjectId } = this.inviteSubjectForm.controls;
    const formValueDoesNotPassRegex = !this.subjectIdRegex.test(
      subjectId.value
    );

    if (formValueDoesNotPassRegex) {
      subjectId.setErrors({ invalidSubjectIdFormat: true });
      return;
    }

    if (
      this.subjects.some((subject) => subject.subjectName === subjectId.value)
    ) {
      this.showSubjectAlreadyInvited = true;
      return;
    }

    this.store.dispatch(
      siteActions.createSubjectAction({ subjectName: subjectId.value })
    );
  };

  handleYesClick = (): void => {
    this.showSubjectAlreadyInvited
      ? this.reinviteSubject()
      : this.router.navigateByUrl(this.getSubjectListUrl());
  };

  reinviteSubject = (): void => {
    const subjectId = this.inviteSubjectForm.controls.subjectId.value;
    const subject = this.subjects.find(
      (source) => source.subjectName === subjectId
    );

    this.store.dispatch(siteActions.reInviteSubjectAction({ subject }));
  };

  handleCancelSubjectAlreadyExistsClick = (): void => {
    this.showSubjectAlreadyInvited = false;
    this.initInviteSubjectForm();
  };

  handleCancelInviteSubjectClick = (): void => {
    this.router.navigateByUrl(this.getSubjectListUrl());
  };

  private initSubjectsSubscription = (): void => {
    this.store
      .pipe(select(siteSelectors.getSubjects), takeUntil(this.unsubscribe$))
      .subscribe((subjects) => (this.subjects = subjects));
  };

  private initStudyRegexSubscription = (): void => {
    this.store
      .pipe(
        select(siteSelectors.getSubjectIdRegex),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => (this.subjectIdRegex = new RegExp(value)));
  };

  private getSubjectListUrl = (): string =>
    this.routeBuilderService
      .withLazyFeature('sites')
      .withRoute(SiteJourneyRoutes.SubjectList)
      .build();

  private initInviteSubjectForm = (): void => {
    this.inviteSubjectForm = new UntypedFormGroup({
      subjectId: new UntypedFormControl(undefined, [Validators.required]),
    });
  };
}
