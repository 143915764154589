export interface DeviceConnection {
  id: string;
  stunServers: Array<StunServer>;
  websocket: string;
}

export interface StunServer {
  password: string;
  urls: Array<string>;
  username: string;
}
