import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  NotificationItem,
  NotificationType,
} from '@virtual-trials-workspace/models';
import { NotificationService } from '@virtual-trials-workspace/shared-core-services';
import { NotificationDisplayDialogComponent } from '@virtual-trials-workspace/shared-ui';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationDisplayService {
  private watchNotificationTypes: NotificationType[] = [
    NotificationType.ActiveVisit,
  ];
  private notificationsSub: Subscription;

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  subscribeToNotifications = (): void => {
    if (!this.notificationsSub) {
      this.notificationsSub = this.notificationService.notifications$
        .pipe(
          filter((notification: NotificationItem) =>
            this.watchNotificationTypes.some((x) => x === notification.type)
          ),
          tap((notification) => this.displayNotification(notification))
        )
        .subscribe();
    }
  };

  private displayNotification = (notification: NotificationItem): void => {
    const ref = this.dialog.open(NotificationDisplayDialogComponent);
    ref.componentInstance.notification = notification;
  };
}
