import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthPageTranslationsGuard,
  UnauthPageTranslationsGuard,
} from '@virtual-trials-workspace/shared-guards';

import { CreateAccountComponent } from './create-account';
import { DisclaimerComponent } from './disclaimer';
import { ActivateHomeComponent } from './home';
import { SetSecurityQuestionsComponent } from './set-security-questions';
import { UnknownParticipantRouteGuard } from './shared';

const routes: Routes = [
  {
    component: ActivateHomeComponent,
    path: '',
    canActivate: [UnauthPageTranslationsGuard],
  },
  {
    path: '',
    canActivate: [UnknownParticipantRouteGuard],
    children: [
      {
        component: DisclaimerComponent,
        path: 'disclaimer',
        canActivate: [AuthPageTranslationsGuard],
      },
      {
        component: SetSecurityQuestionsComponent,
        path: 'security-questions',
        canActivate: [AuthPageTranslationsGuard],
      },
    ],
  },
  {
    component: CreateAccountComponent,
    path: 'create-account',
    canActivate: [AuthPageTranslationsGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
