<div class="es-toolbar">
  <ul class="es-left">
    <li class="es-toolbar-item">
      <span class="es-icon es-icon-search es-custom-seach-icon"></span>
      <input
          type="text"
          class="es-input es-custom-input"
          placeholder="Search"
          (keyup)="handleSearch()"
          [(ngModel)]="searchText"
          name="search"
        />
    </li>
    <!-- <li class="es-toolbar-item">
      <button 
        id="search-button-id"
        class="es-button" 
        (click)="handleSearch()">
        <span class="es-icon es-icon-search"></span>
      </button>
    </li> -->
    <ng-content select="[pagination-list]"></ng-content>
  </ul>
</div>

<!-- 
<mat-toolbar class="vv-toolbar" fxLayout="wrap">
  <button id="sidenav-button-id" mat-icon-button (click)="sideNavClick()">
    <mat-icon class="menu">menu</mat-icon>
  </button>
  <div class="vv-toolbar__title">Sites</div>
  <div
    class="vv-toolbar__logo"
    fxFlex="1 1"
    fxLayout="wrap"
    fxLayoutAlign="center"
  >
    
  </div>
  <div class="vv-search" [ngClass]="status ? '__open' : ''">
    <form class="form">
      <mat-form-field>
        <input
          matInput
          placeholder="Search Subjects"
          (keyup)="handleSearch()"
          [(ngModel)]="searchText"
          name="search"
        />
      </mat-form-field>
    </form>
    <button
      id="search-button-id"
      mat-icon-button
      (click)="handleSearchButtonClick()"
    >
      <mat-icon class="search">search</mat-icon>
    </button>
  </div>
</mat-toolbar> -->
