<ng-container *ngIf="{ value: participantType$ | async } as ctx">
  <ng-container *ngIf="ctx.value === 'subject'; then subject; else site">
  </ng-container>
</ng-container>

<ng-template #site>
  <vt-visit-site></vt-visit-site>
</ng-template>

<ng-template #subject>
  <vt-visit-subject></vt-visit-subject>
</ng-template>
