<!-- Header -->
<!-- <header class="es-gateway-header" *transloco="let transl">
  <div class="header-inner">
    <div class="es-logo">
      <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
    </div>
    <div class="product-name">Virtual Visits</div>
    <div class="ellipses-1"></div>
    <div class="ellipses-2"></div>
    <div class="ellipses-3"></div>
  </div>
</header> -->

<!-- Language select drop down -->
<div
  class="es-lang-select siteUser"
  [ngClass]="participantType"
  *transloco="let transl"
>
  <span *ngIf="showSelectedServiceControl && showControls" class="switch-button">
    <button id="" class="es-button" *ngIf="selectedServiceView === 'emulation'" (click)="handleToggleService()">
      <span class="es-icon es-icon-undo"></span><span>{{ transl('visit.start_visit.virtual_visit_product') }}</span>
    </button>
    <button id="" class="es-button" *ngIf="selectedServiceView === 'video-call'" (click)="handleToggleService()">
      <span class="es-icon es-icon-file-text"></span><span>{{ transl('visit.start_visit.eCOA_live_product') }}</span>
    </button>
  </span>
  <button id="" class="es-button send-invite-button" *ngIf="(!isEcoaLiveStandalone && participantType === 'user' && selectedServiceView !== 'none') && showControls" (click)="handleSendInvite()">
    <span class="es-icon es-icon-email"></span><span>{{ transl('general.send_invite') }}</span>
  </button>
  <vt-ui-language-select
    *ngIf="!isProvisioner"
    class="top-right {{customWelcomeClass}}"
  ></vt-ui-language-select>
  <ng-content *ngIf="showControls" select="[rightMenu]"></ng-content>
</div>

<!-- <ng-container *ngIf="showControls">
  <vt-visit-selected-service
    *ngIf="showSelectedServiceControl"
    [selectedService]="selectedServiceView"
    [selectedServiceState]="selectedServiceState"
    [emulationDeviceConnected]="emulationDeviceConnected"
    (toggleService)="handleToggleService()"
  ></vt-visit-selected-service>
  <ng-content select="[rightMenu]"></ng-content>
</ng-container> -->

<!-- <mat-toolbar>

  <vt-visit-ecoalogo *ngIf="isEcoaLiveStandalone"></vt-visit-ecoalogo>
  <span class="spacer"></span>

  
  
  <span class="spacer"></span>
  <div fxLayout="row" fxLayoutAlign="flex-end">
    
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>
  <ng-container *ngIf="showControls">
    <vt-visit-selected-service
      *ngIf="showSelectedServiceControl"
      [selectedService]="selectedServiceView"
      [selectedServiceState]="selectedServiceState"
      [emulationDeviceConnected]="emulationDeviceConnected"
      (toggleService)="handleToggleService()"
    ></vt-visit-selected-service>
    <ng-content select="[rightMenu]"></ng-content>
  </ng-container>
</mat-toolbar> -->
