import { ActivatedRouteSnapshot } from '@angular/router';

export const SESSION_ROUTE_CONFIG_KEY = 'sessionRouteConfiguration';

export class SessionRouteConfiguration {
  redirectTo: string;
  skipLocationChange: boolean;

  constructor(from: Partial<SessionRouteConfiguration>) {
    Object.assign(this, from);
  }

  static get default(): SessionRouteConfiguration {
    return new SessionRouteConfiguration({ redirectTo: '/login', skipLocationChange: false });
  }

  static resolveFrom = (route: ActivatedRouteSnapshot): SessionRouteConfiguration => {
    let config: SessionRouteConfiguration;
    const getConfig = (route): SessionRouteConfiguration => route && route.data && route.data[SESSION_ROUTE_CONFIG_KEY];

    while (route) {
      config = getConfig(route) ?? config;
      route = route.firstChild;
    }

    if (config) {
      if (typeof config !== 'object') {
        return SessionRouteConfiguration.default;
      }

      return config instanceof SessionRouteConfiguration ? config : new SessionRouteConfiguration(config);
    }

    return undefined;
  }
}
