import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalStorageService } from './local-storage/local-storage.service';
import { MediaDevicesService } from './media-devices/media-devices.service';
import { NotificationService } from './notification/notification.service';
import { RestApiService } from './rest-api/rest-api.service';
import { RouteBuilderService } from './route-builder/route-builder.service';
import { ScopesService } from './scopes/scopes.service';
import { ScriptBuilderService } from './script-builder/script-builder.service';
import { TokenService } from './token/token.service';
import { WebSocketService } from './web-socket/web-socket.service';
import { WINDOW_PROVIDERS } from './window/window.providers';
import { PlatformService } from './platform/paltform.service';
import { LanguageService } from './language/language.service';

@NgModule({
  imports: [CommonModule],
  providers: [WINDOW_PROVIDERS],
})
export class CoreServicesModule {
  providers: [
    LocalStorageService,
    NotificationService,
    RestApiService,
    RouteBuilderService,
    ScopesService,
    TokenService,
    MediaDevicesService,
    WebSocketService,
    ScriptBuilderService,
    PlatformService,
    LanguageService
  ];
}
