<ng-container *ngIf="formGroup">
  <div class="visitOptionPopupHeader">
    <span class="close-icon" (click)="handleClose()" id="cancel-btn"
      ><img src="assets/web/images/close-icon.svg"
    /></span>
    <h3 *transloco="let transl" class="visit-options-title">
      {{ transl('visit.start_visit.label') }}
    </h3>
  </div>
  <div mat-dialog-content *transloco="let transl" class="option-content">
    <div>
      {{ transl('visit.start_visit.select_products_label') }}
    </div>
    <form [formGroup]="formGroup">
      <div>
        <div
          class="product-option-container"
          [class.product-option-checked-container]="ecoaLiveOptionChecked"
          [class.product-not-available]="
            !hasEcoaLiveOption || deviceStatus === deviceStatusEnum.InUse
          "
          [class.product-option-loading]="
            deviceStatus === deviceStatusEnum.Checking
          "
          [title]="
            deviceStatus === deviceStatusEnum.Checking
              ? transl('visit.start_visit.check_device_status')
              : ''
          "
        >
          <div
            class="production-option-loading-spinner"
            *ngIf="deviceStatus === deviceStatusEnum.Checking"
          >
            <mat-spinner
              mode="indeterminate"
              color="accent"
              diameter="50"
            ></mat-spinner>
          </div>
          <span class="product-option">
            <div class="es-checkbox visit-option-checkbox">
              <input
                type="checkbox"
                id="ecoa-option-id"
                formControlName="ecoaLiveOption"
                data-testid="ecoa-checkbox"
              />
              <label for="ecoa-option-id"></label>
            </div>
            <span class="product-item">
              <div class="product-title">
                {{ transl('visit.start_visit.eCOA_live_product') }}
              </div>
              <div class="product-description">
                {{ transl('visit.start_visit.eCOA_live_product_description') }}
              </div>
            </span>
          </span>
        </div>
        <div class="ecoa-device-status">
          <div *ngIf="!hasEcoaLiveOption" class="product-subtext">
            {{ transl('visit.site.access_upgrade') }}
          </div>
          <div
            *ngIf="hasEcoaLiveOption && deviceStatus === deviceStatusEnum.InUse"
            class="product-subtext"
          >
            {{ transl('visit.site.device_ready') }}
          </div>
          <div
            *ngIf="
              hasEcoaLiveOption && deviceStatus === deviceStatusEnum.NotSetup
            "
            class="product-subtext"
          >
            {{ transl('visit.site.emulation_enabled') }}
          </div>
          <div
            *ngIf="hasEcoaLiveOption && deviceStatus === deviceStatusEnum.Error"
            class="product-subtext"
          >
            {{ transl('visit.site.error_device_status') }}

            <a (click)="onClickHereLinkClick()">
              {{ transl('visit.site.click_here_text') }}
            </a>
            {{ transl('visit.site.try_again') }}
          </div>
        </div>
      </div>

      <div
        class="product-option-container"
        [class.product-option-checked-container]="virtualVisitsOptionChecked"
        [class.product-not-available]="!hasVirtualVisitsOption"
      >
        <span class="product-option">
          <div class="es-checkbox visit-option-checkbox">
            <input
              type="checkbox"
              id="vv-option-id"
              formControlName="virtualVisitsOption"
              data-testid="vv-checkbox"
            />
            <label for="vv-option-id"></label>
          </div>
          <span class="product-item">
            <div class="product-title">
              {{ transl('visit.start_visit.virtual_visit_product') }}
            </div>
            <div class="product-description">
              {{
                transl('visit.start_visit.virtual_visit_product_description')
              }}
            </div>
          </span>
        </span>
      </div>
      <div *ngIf="!hasVirtualVisitsOption" class="product-subtext">
        {{ transl('visit.site.upgrade_vv_account') }}
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="action-buttons" *transloco="let transl">
    <button
      id="next-btn"
      class="es-button -es-primary"
      data-testid="next-btn"
      [disabled]="!disableNextButton()"
      (click)="handleNextClick()"
    >
      {{ transl('general.next_lower') }}
    </button>
  </div>
</ng-container>
