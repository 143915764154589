import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'vt-visit-socket-failed-sheet',
  templateUrl: './socket-failed-sheet.component.html',
  styleUrls: ['./socket-failed-sheet.component.scss'],
})
export class VisitSocketFailedSheetComponent {
  @Output()
  acknowledged = new EventEmitter();

  constructor(private translocoService: TranslocoService) {}
  failedServiceText = this.translocoService.translate(
    'visit.unable_to_connect',
    { fallback: 'Unable to connect with Visit' }
  );

  logoutClick(): void {
    this.acknowledged.emit();
  }
}
