import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState, SESSION_STATE_KEY } from '../sub-states';

export const getSessionState = createFeatureSelector<SessionState>(
  SESSION_STATE_KEY
);

export const getError = createSelector(
  getSessionState,
  (state: SessionState) => state.error
);
export const getFailedErrorMsg = createSelector(
  getSessionState,
  (state: SessionState) => state.genericErrorMessage
);

export const getIsLoading = createSelector(
  getSessionState,
  (state: SessionState) => state.isLoading || !state.translationsLoaded
);

export const getParticipantId = createSelector(
  getSessionState,
  (state: SessionState) => state.participantId
);

export const getParticipantType = createSelector(
  getSessionState,
  (state: SessionState) => state.participantType
);

export const getIsSiteUser = createSelector(
  getSessionState,
  (state: SessionState) =>
    state.participantType === 'user' || state.participantType === 'provisioner'
);

export const getIsSubjectUser = createSelector(
  getSessionState,
  (state: SessionState) => state.participantType === 'subject'
);

export const getIsAnonymousParticipant = createSelector(
  getSessionState,
  (state: SessionState) => state.isAnonymous
);

export const getIsProvisioner = createSelector(
  getSessionState,
  (state: SessionState) => state.participantType === 'provisioner'
);

export const getIsAuthenticated = createSelector(
  getSessionState,
  (state: SessionState) =>
    state.participantType !== 'none' && !!state.participantId
);

export const getLanguagesFallback = createSelector(
  getSessionState,
  (state: SessionState) => state.isLanguagesFallback
);

export const getAvailableLanguages = createSelector(
  getSessionState,
  (state: SessionState) => state.languages
);
export const getAllAvailableLanguages = createSelector(
  getSessionState,
  (state: SessionState) => state.allLanguages
);
export const getFilterLanguages = createSelector(
  getSessionState,
  (state: SessionState) => state.filterLanguages
);
export const getTranslationFiles = createSelector(
  getSessionState,
  (state: SessionState) => state.translationFiles
);
export const getTranslationsLoaded = createSelector(
  getSessionState,
  (state: SessionState) => state.translationsLoaded
);
export const getSelectedLanguageCode = createSelector(
  getSessionState,
  (state: SessionState) => state.selectedLanguageCode
);
export const getTranslationsAuthType = createSelector(
  getSessionState,
  (state: SessionState) => state.translationsAuthType
);

export const isPrefferedLanguageDialogOpen = createSelector(
  getSessionState,
  (state: SessionState) => state.isPrefferedLanguageDialogOpen
);
export const getDefaultSetLanguageCode = createSelector(
  getSessionState,
  (state: SessionState) => state.defaultSetLanguageCode
);
export const getSutdyLanguagesByParticipantId = createSelector(
  getSessionState,
  (state: SessionState) => state.studyLanguages
);
