import { animate, style, transition, trigger } from '@angular/animations';

import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  ParticipantType,
  VisitServiceOptions,
} from '@virtual-trials-workspace/models';
import { VisitActions } from '@virtual-trials-workspace/store';
import { isOnline$ } from '@virtual-trials-workspace/shared-utils';

import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { ServiceState, VisitView } from '../models';

import { SubjectFacadeService } from './facade/subject-facade.service';
import { TranslocoService } from '@ngneat/transloco';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'vt-visit-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
    ]),
    trigger('introFade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 })),
      ]),
    ]),
  ],
  providers: [SubjectFacadeService],
})
export class SubjectComponent implements AfterViewInit, OnInit, OnDestroy {
  private done$ = new Subject<void>();

  declinesBrowserPermission = false;

  micEnabled = true;
  videoEnabled = true;

  isInitialConnect = true;
  isValidResolution = true;
  readonly errorMessageKey =
    'chrome_only_error_screen.switch_device_with_bigger_screen';
  readonly resolutionMessageKey =
    'chrome_only_error_screen.minimum_screen_resolution';

  selectedServiceState: ServiceState = 'available';
  selectedServiceView: VisitView;

  showVideoCallView = true; // default
  vidyoCanRender = false;
  isVisitEnded = false;
  deviceControlStatusText =
    'visit.emulation.assessment_form.connecting_device_text';

  showSocketFailedSheet = false;

  get askUserForPermissions$(): Observable<boolean> {
    return this.subjectFacade.askUserForPermissions$;
  }

  get isLoading$(): Observable<boolean> {
    return this.subjectFacade.isLoading$;
  }

  get participantType$(): Observable<ParticipantType> {
    return this.subjectFacade.participantType$;
  }

  get visitServices$(): Observable<VisitServiceOptions> {
    return this.subjectFacade.visitServices$;
  }

  get hasVisitEnded$(): Observable<boolean> {
    return this.subjectFacade.hasVisitEnded$.pipe(
      filter((visitEnded) => !!visitEnded)
    );
  }

  get hasVisitTimedOut$(): Observable<boolean> {
    return this.subjectFacade.hasVisitTimedOut$;
  }

  get deviceId(): string {
    return this.subjectFacade.deviceElementId;
  }

  get mediaDeviceStates$(): Observable<{ mic: boolean; video: boolean }> {
    return this.subjectFacade.mediaDeviceStates$;
  }
  get visitIsReady$(): Observable<boolean> {
    return this.subjectFacade.visitIsReady$;
  }

  get hasDeviceConnection$(): Observable<boolean> {
    return this.subjectFacade.deviceConnection$.pipe(
      map((connection) => !!connection)
    );
  }

  get hasDeviceControl$(): Observable<boolean> {
    return this.subjectFacade.hasDeviceControl$;
  }

  get connectedToDeviceSession$(): Observable<boolean> {
    return this.subjectFacade.connectedToDeviceSession$;
  }

  get emulationServiceLost$(): Observable<boolean> {
    return this.subjectFacade.emulationServiceLost$;
  }

  get emulationConnectionLost$(): Observable<boolean> {
    return this.subjectFacade.emulationConnectionLost$;
  }

  get hasVisitEndedOnError$(): Observable<boolean> {
    return this.subjectFacade.hasVisitEndedOnError$;
  }

  visitHasEmulation$ = this.visitServices$.pipe(
    map((services) => services === 'emulation' || services === 'all')
  );

  readonly localViewId = 'local-view';

  @ViewChild('localView')
  localViewDiv: ElementRef<HTMLDivElement>;

  readonly remoteViewId = 'remote-view';

  @ViewChild('remoteView')
  remoteViewDiv: ElementRef<HTMLDivElement>;

  vidyoHiddenClass = 'hide-render';

  constructor(
    private subjectFacade: SubjectFacadeService,
    private router: Router,
    private ngZone: NgZone,
    private translocoService: TranslocoService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.subscribeToVisitReady();
    this.subscribeToVisitEnd();
    this.subscribeToRecievedDeviceControl();
    this.subscribeToHasDeviceControl();
    this.subscribeToLostNetwork();
    this.subscribeToHasVisitErrored();
  }

  ngAfterViewInit(): void {
    this.subscribeToVideoServiceIsReady();
    this.subjectFacade.subscribeToDeviceConnection();
  }

  private subscribeToLostNetwork = (): void => {
    isOnline$()
      .pipe(
        takeUntil(this.done$),
        filter((isOnline) => !isOnline)
      )
      .subscribe(() => this.router.navigateByUrl('/login'));
  };

  private subscribeToHasDeviceControl = (): void => {
    this.hasDeviceControl$.subscribe((hasControl: boolean) => {
      if (hasControl === false) {
        this.deviceControlStatusText =
          'visit.emulation.assessment_form.connected_device_site_text';
      }
    });
  };

  private subscribeToVisitReady = (): void => {
    this.subjectFacade.visitIsReady$
      .pipe(takeUntil(this.done$))
      .subscribe((visitIsReady) => {
        if (visitIsReady) {
          this.visitServices$
            .pipe(
              take(1),
              takeUntil(this.done$),
              filter((services) => !!services)
            )
            .subscribe((services) => {
              // default display
              this.selectedServiceView =
                services.toLowerCase() === 'emulation'
                  ? 'emulation'
                  : 'video-call';
            });
        }
        this.validateSubjectResolution();
      });
  };

  private subscribeToVideoServiceIsReady = (): void => {
    this.subjectFacade.videoServiceIsReady$
      .pipe(takeUntil(this.done$))
      .subscribe(() => {
        this.vidyoCanRender = true;
        this.vidyoHiddenClass = this.toggleRenderClass(true);
        this.ngZone.run(() => this.attachCameras());
      });
  };

  private toggleRenderClass = (show: boolean): string => {
    return show ? 'show-render' : 'hide-render';
  };

  private subscribeToRecievedDeviceControl = (): void => {
    combineLatest([
      this.subjectFacade.recievedDeviceControl$,
      this.subjectFacade.connectedToDeviceSession$,
    ])
      .pipe(
        filter(
          ([recievedDeviceControl, connectedToDeviceSession]) =>
            !!recievedDeviceControl && !!connectedToDeviceSession
        ),
        takeUntil(this.done$)
      )
      .subscribe(() => {
        if (this.selectedServiceView !== 'emulation') {
          this.subjectFacade.onDeviceControlRecieved();
        }
      });
  };

  private subscribeToVisitEnd = (): void => {
    this.hasVisitEnded$.pipe(takeUntil(this.done$)).subscribe(() => {
      this.vidyoHiddenClass = this.toggleRenderClass(false);
      this.vidyoCanRender = false;
      this.subjectFacade.disconnectDeviceSession(false);
      this.isVisitEnded = true;
      this.selectedServiceView = 'none';
    });
  };

  private subscribeToHasVisitErrored = (): void => {
    this.hasVisitEndedOnError$
      .pipe(
        takeUntil(this.done$),
        filter((failed) => !!failed)
      )
      .subscribe(() => {
        this.showSocketFailedSheet = true;
      });
  };

  private attachCameras = (): void => {
    this.subjectFacade.renderLocalCameraStream({
      viewId: this.localViewId,
      height: this.localViewDiv.nativeElement.offsetHeight,
      width: this.localViewDiv.nativeElement.offsetWidth,
      x: this.localViewDiv.nativeElement.offsetLeft,
      y: this.localViewDiv.nativeElement.offsetTop,
    });

    this.subjectFacade.renderRemoteCameraStream({
      viewId: this.remoteViewId,
      height: this.remoteViewDiv.nativeElement.offsetHeight,
      width: this.remoteViewDiv.nativeElement.offsetWidth,
      x: this.remoteViewDiv.nativeElement.offsetLeft,
      y: this.remoteViewDiv.nativeElement.offsetTop,
    });
  };

  toggleServiceView = (): void => {
    this.selectedServiceView =
      this.selectedServiceView === 'video-call' ? 'emulation' : 'video-call';
    this.showVideoCallView = !this.showVideoCallView;
    this.validateSubjectResolution();
  };

  handleDeclinesAccessDevices = (): void => {
    this.declinesBrowserPermission = true;
  };

  handleGiveBackControl = (): void => {
    this.subjectFacade.disconnectDeviceSession(true);
  };

  handleStartAgain = (): void => {
    window.location.reload();
  };

  handleAcceptAccess = (): void => {
    this.subjectFacade.hasGrantedBrowserPermissions.then((grants) =>
      this.handleBrowserGrantPermissions(grants)
    );
  };

  private handleBrowserGrantPermissions = (grants: boolean): void => {
    grants ? this.browserPermissionConfirmed() : this.browserDenyPermissions();
  };

  private browserPermissionConfirmed = (): void => {
    this.subjectFacade.dispatch(VisitActions.subjectJoinVisit());
  };

  private browserDenyPermissions = (): void => {
    this.declinesBrowserPermission = true;
  };

  handleToggleVideo = (currentValue: boolean): void => {
    this.subjectFacade.toggleVideo(currentValue);
  };

  handleToggleMic = (currentValue: boolean): void => {
    this.subjectFacade.toggleMic(currentValue);
  };

  handleSwitchCamera = (): void => {
    this.subjectFacade.switchCamera();
  };

  navigateToLogin = (): void => {
    this.router.navigateByUrl('/login');
  };

  isMobileDevice = (): boolean => this.subjectFacade.isMobileDevice();

  disconnectVisitServices = (): Observable<boolean> => {
    this.subjectFacade.disconnectDeviceSession();
    return this.subjectFacade.disconnectVideoCall();
  };

  validateSubjectResolution = (): void => {
    if (this.selectedServiceView === 'emulation') {
      if (this.subjectFacade.getWindowWidth() >= 1024 && this.subjectFacade.getDeviceHeight() >= 768) {
        this.isValidResolution = true;
      } else {
        this.isValidResolution = false;
      }
    }
  }

  onResize() {
    this.validateSubjectResolution();
  }

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }
}
