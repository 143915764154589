import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';

import {
  LanguageHttpService,
  RootSelectors,
  SessionActions,
} from '@virtual-trials-workspace/store';
import { Observable, Subject } from 'rxjs';
import { PreferredLanguageControlComponent } from './preferred-language-control/preferred-language-control.component';
import { SelectLanguageControlComponent } from './select-language-control/select-language-control.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { TranslocoService } from '@ngneat/transloco';
import { ParticipantType, TranslationFile } from '@virtual-trials-workspace/models';
import { Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageControleService implements OnDestroy {
  @Output()
  public navigateToSubjectList = new EventEmitter();

  @Output()
  public selectPreviousStudySite = new EventEmitter();

  private unsubscribe$ = new Subject();
  selectLanguageComponentRef: MatDialogRef<SelectLanguageControlComponent>;
  preferedLanguageSaveComponentRef: MatDialogRef<PreferredLanguageControlComponent>;
  dialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    disableClose: true,
    maxHeight: '100%',
    maxWidth: '100%',
    height: '100%',
    width: '100%',
    panelClass: 'session-timeout-dialog-panel',
    scrollStrategy: new NoopScrollStrategy(),
  };
  participantType: string;
  get participantType$(): Observable<ParticipantType> {
    return this.store.pipe(select(RootSelectors.getParticipantType));
  }
  defaultSetLangCode: string;
  get defaultSetLangCode$(): Observable<string> {
    return this.store.pipe(select(RootSelectors.getDefaultSetLanguageCode));
  }
  translationFiles$ = this.store.pipe(
    takeUntil(this.unsubscribe$),
    select(RootSelectors.getTranslationFiles)
  );
  constructor(
    private dialog: MatDialog,
    private store: Store,
    private languageService: LanguageHttpService,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.participantType$.subscribe((data) => {
      this.participantType = data;
    });

    this.defaultSetLangCode$.subscribe((data) => {
      this.defaultSetLangCode = data;
    });
  }

  public handleLanguageControlDialog = (studyLanguagesteamp: string): void => {
    let availableLanguages;

    const studyLanguages = JSON.parse(studyLanguagesteamp.toString());
    this.store
      .select(RootSelectors.getAllAvailableLanguages)
      .subscribe((languages) => {
        availableLanguages = languages;
      });
    let filterAvailableLanguages;
    if (studyLanguages.length > 0) {
      studyLanguages.push('en-US');
      filterAvailableLanguages = availableLanguages.filter((x) =>
        studyLanguages.some((y) => y == x.code)
      );
    } else {
      this.setFilterLanguages(undefined);
      this.store.dispatch(
        SessionActions.setAllLanguagesSuccess({
          languages: availableLanguages,
        })
      );
      this.navigateToSubjectList.emit(
        this.router.url.indexOf('sites/select-study-site') >= 0 ? true : false
      );
      return;
    }
    this.store.dispatch(
      SessionActions.setAllLanguagesSuccess({
        languages: filterAvailableLanguages,
      })
    );
    this.setFilterLanguages(filterAvailableLanguages);

    if (
      !filterAvailableLanguages.find(
        (x) => x.code == this.languageService.getSelectedLanguage()
      )
    ) {
      if (
        !this.selectLanguageComponentRef ||
        (!!this.selectLanguageComponentRef &&
          this.selectLanguageComponentRef.getState() === MatDialogState.CLOSED)
      ) {
        this.selectLanguageComponentRef = this.dialog.open(
          SelectLanguageControlComponent,
          this.dialogConfig
        );
        this.store.dispatch(
          SessionActions.isPrefferedLanguageDialogOpen({
            isDialogOpen: true,
          })
        );
        this.selectLanguageComponentRef.componentInstance.participantType = this.participantType;
        this.selectLanguageComponentRef.afterClosed().subscribe((result) => {
          if (result) {
            this.updateSelectedLanguage();
            this.handleChangeLanguage(
              this.selectLanguageComponentRef.componentInstance.language,
              false
            );
          } else {
            this.setFilterLanguages(undefined);

            if (!(this.router.url.indexOf('sites/select-study-site') >= 0)) {
              this.selectPreviousStudySite.emit(true);
            }

            this.store.dispatch(
              SessionActions.setAllLanguagesSuccess({
                languages: availableLanguages,
              })
            );
            this.dialog.closeAll();
            this.navigateToSubjectList.emit(false);
          }

          this.store.dispatch(
            SessionActions.isPrefferedLanguageDialogOpen({
              isDialogOpen: false,
            })
          );
        });
      }
    } else {
      this.updateSelectedLanguage();
      this.navigateToSubjectList.emit(true);
    }
  };
  private updateSelectedLanguage = () => {
    if (
      this.selectLanguageComponentRef && 
      this.selectLanguageComponentRef.componentInstance &&
      this.selectLanguageComponentRef.componentInstance?.language != '0'
    ) {
      this.updateSelectLanguage(
        false,
        this.selectLanguageComponentRef.componentInstance.language
      );
    }
  };
  private setFilterLanguages = (languages) => {
    this.store.dispatch(
      SessionActions.setFilterLanguagesSuccess({
        languages: languages,
      })
    );
  };
  public handleChangeLanguage(language: string, isRedirect): void {
    this.store.dispatch(
      SessionActions.getTranslationFiles({ language: language })
    );

    this.translationFiles$
      .pipe(
        filter((resonse) => !!resonse),
        take(1)
      )
      .subscribe((resonse) => {
        if (resonse) {
          this.translocoService.setActiveLang(language);
          this.handlePreferredLanguageDialog(language, isRedirect, resonse);
        }
      });
  }
  public handlePreferredLanguageDialog = (
    language: string,
    isLanguageChange = false,
    translationFiles: TranslationFile[]
  ): void => {
    if (
      this.router.url.indexOf('/login') >= 0 ||
      this.router.url.indexOf('/activate') >= 0 ||
      this.router.url.indexOf('/standalone/visit') >= 0 ||
      this.router.url.indexOf('/account/forgot-password') >= 0 ||
      this.router.url.indexOf('/error') >= 0 ||
      this.router.url.indexOf('/platform') >= 0 ||
      window.location.pathname === '/' ||
      language === this.defaultSetLangCode
    ) {
      this.updateSelectLanguage(false, language);
      if (!isLanguageChange) this.navigateToSubjectList.emit(true);
      return;
    }

    if (this.dialog.openDialogs.length <= 0) {
      if (
        !this.preferedLanguageSaveComponentRef ||
        (!!this.preferedLanguageSaveComponentRef &&
          this.preferedLanguageSaveComponentRef.getState() ===
            MatDialogState.CLOSED)
      ) {
        this.preferedLanguageSaveComponentRef = this.dialog.open(
          PreferredLanguageControlComponent,
          this.dialogConfig
        );

        this.preferedLanguageSaveComponentRef
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.updateSelectLanguage(true, language);
            } else {
              this.updateSelectLanguage(false, language);
            }

            // Handle translations/authorised failed - from preferred dialog lang update
            if(translationFiles && translationFiles.length === 0) {
              this.store.dispatch(
                SessionActions.getTranslationFiles({ language: language })
              );
            }

            if (!isLanguageChange) this.navigateToSubjectList.emit(true);
          });
      }
    } else {
      this.updateSelectLanguage(false, language);
    }
  };
  updateSelectLanguage = (saveLanguage: boolean, language: string): void => {
    this.languageService.updateSelectedLanguage(language, saveLanguage);
    this.translocoService.setActiveLang(language);
  };

  setAllAvailableLanguages = (): void => {
    let availableLanguages;
    this.store
      .select(RootSelectors.getAllAvailableLanguages)
      .subscribe((languages) => {
        availableLanguages = languages;
      });

    this.store.dispatch(
      SessionActions.setAllLanguagesSuccess({
        languages: availableLanguages,
      })
    );
  };

  ngOnDestroy(): void {
    if (this.selectLanguageComponentRef?.getState() !== MatDialogState.CLOSED) {
      this.selectLanguageComponentRef?.close();
    }
    if (
      this.preferedLanguageSaveComponentRef?.getState() !==
      MatDialogState.CLOSED
    ) {
      this.preferedLanguageSaveComponentRef?.close();
    }
  }
}
