<!-- <mat-select
  class="lang-select"
  [value]="selectedLanguageCode$ | async"
  (selectionChange)="handleChangeLanguage($event.value)"
>
  <mat-option
    *ngFor="let language of languages$ | async"
    [value]="language.code"
    [id]="language.code"
    >{{ language.displayName }}
  </mat-option>
</mat-select> -->

<div class="es-select">
  <select
    class="lang-select1"
    (change)="handleChangeLanguage($event.currentTarget.value)"
  >
    <option
      *ngFor="let language of languages$ | async"
      [value]="language.code"
      [selected]="(selectedLanguageCode$ | async) === language.code"
      [id]="language.code"
      >{{ language.displayName }}
    </option>
  </select>
</div>
