import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { LanguageModel } from '@virtual-trials-workspace/models';
import { LanguageService } from '@virtual-trials-workspace/shared-core-services';
import * as FromStore from '@virtual-trials-workspace/store';
import { LanguageHttpService } from '@virtual-trials-workspace/store';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AutoDetectLanguageGuard  {
  constructor(
    protected router: Router,
    protected store: Store<FromStore.RootState>,
    protected languageService: LanguageService,
    protected languageHttpService: LanguageHttpService,
    protected translocoService: TranslocoService
  ) { }

  canLoad(): Observable<boolean> {
    return this.store.select(FromStore.RootSelectors.getAvailableLanguages).pipe(
      tap((response: LanguageModel[]) => {
        if (response) {
          const storedLanguageCode = this.languageHttpService.getStoredLanguage();
          const languageCodes = response.map(lang => lang.code);
          if (!storedLanguageCode || !languageCodes.includes(storedLanguageCode)) {
            this.autoDetectUserLanguage(languageCodes);
          }
        }
      }), map(() => true));
  }

  autoDetectUserLanguage(languageCodes: Array<string>): void {
    const userLanguage = this.languageService.autoDetectUserLanguage(languageCodes)
      ?? this.languageHttpService.defaultLanguage;

    this.languageHttpService.updateSelectedLanguage(userLanguage, false);
    this.translocoService.setActiveLang(userLanguage);
  }
}