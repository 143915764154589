import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  Input
} from '@angular/core';

@Component({
  selector: 'vt-account-reset-password-max-attempts',
  templateUrl: './reset-password-max-attempts.component.html',
  styleUrls: ['./reset-password-max-attempts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordMaxAttemptsComponent {
  @Input() maxAnswerAttemptsReached: boolean;
  @Output() newResetPasswordRequestLinkClick = new EventEmitter();
  @Output() backToLoginClick = new EventEmitter();

  handleClick = (): void => {
    this.newResetPasswordRequestLinkClick.emit();
  };

  handleBackToLoginClick = (): void => {
    this.backToLoginClick.emit();
  };
}
