import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProvisionerJourneyRoutingModule } from './provisioner-journey-routing.module';
import { UiModule } from '@virtual-trials-workspace/shared-ui';
import { SetProvisionedDialogComponent } from './set-provisioned-dialog/set-provisioned-dialog.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';
import { Config, PROVISIONER_STATE_KEY, Reducers, Effects } from './store';
import {
  SiteListComponent,
  SiteListActionComponent,
  SiteListToolbarComponent,
} from './site-list';
import { DeviceInfoComponent } from './dialog/device-info/device-info.component';

const components = [
  SiteListComponent,
  SiteListToolbarComponent,
  SiteListActionComponent,
  DeviceInfoComponent,
  SetProvisionedDialogComponent,
  DeviceInfoComponent,
];
@NgModule({
  imports: [
    CommonModule,
    ProvisionerJourneyRoutingModule,
    UiModule,
    StoreModule.forFeature(
      PROVISIONER_STATE_KEY,
      Reducers.provisionerReducer,
      Config.PROVISIONER_STATE_CONFIG_TOKEN
    ),
    EffectsModule.forFeature([Effects.ProvisionerEffects]),
  ],
  declarations: [...components],
  providers: [
    {
      provide: Config.PROVISIONER_STATE_LOCAL_STORAGE_KEY,
      useValue: PROVISIONER_STATE_KEY,
    },
    {
      provide: Config.PROVISIONER_STATE_STORAGE_KEYS,
      useValue: ['selectedSiteId', 'sites'],
    },
    {
      provide: Config.PROVISIONER_STATE_CONFIG_TOKEN,
      useFactory: Config.getProvisionerStateConfig,
      deps: [
        Config.PROVISIONER_STATE_STORAGE_KEYS,
        Config.PROVISIONER_STATE_LOCAL_STORAGE_KEY,
        LocalStorageService,
      ],
    },
  ],
})
export class ProvisionerJourneyModule {}
