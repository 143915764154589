import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nx/angular';
import { RouteBuilderService } from '@virtual-trials-workspace/shared-core-services';
import { SessionState } from '@virtual-trials-workspace/store';
import { sortBy } from 'lodash';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SiteJourneyRoutes } from '../../site-journey-routes';
import * as FromSiteActions from '../actions/site.actions';
import { SiteHttpService } from '../http/site-http.service';
import * as FromSelectors from '../selectors/site.selectors';
import { SiteState } from '../site.state';

@Injectable()
export class SiteEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private routeBuilderService: RouteBuilderService,
    private siteHttpService: SiteHttpService
  ) {}

  loadSubjectsForSiteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.getSubjectsAction),
      withLatestFrom(this.store.select(FromSelectors.getSiteId)),
      fetch({
        run: (action, selectedSiteId) => {
          return this.siteHttpService.getSubjectsForSite$(selectedSiteId).pipe(
            map((response) =>
                FromSiteActions.getSubjectsSuccessAction({
                  subjects: response.subjects,
              })
            )
          )
        },
        onError: (action, error) => {
          return FromSiteActions.getSubjectsFailureAction({ payload: error });
        },
      })
    )
  );

  anonymousSubjectsForSiteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.setSelectedSite),
      withLatestFrom(this.store.select(FromSelectors.getSiteId)),
      fetch({
        run: (action, selectedSiteId) => {
          return this.siteHttpService
            .getSubjectsForSite$(selectedSiteId)
            .pipe(
              map((response) =>
                FromSiteActions.getSubjectsSuccessAction({
                  subjects: response.subjects,
                })
              )
            );
        },
        onError: (_, error) => {
          return FromSiteActions.getSubjectsFailureAction({ payload: error });
        },
      })
    )
  );

  addSubjectSuccessNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.createSubjectSuccessAction),
        tap(
          async (action) =>
            await this.router.navigate([action.resource, action.subjectName])
        )
      ),
    { dispatch: false }
  );

  sentInviteToSubject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.sendInviteToSubjectAction),
      fetch({
        run: (action) => {
          return this.siteHttpService.inviteSubject$(action.apiRequest).pipe(
            map(() =>
              FromSiteActions.sendInviteToSubjectSuccessAction({
                subjectId: action.apiRequest.subjectId,
              })
            )
          );
        },
        onError: (action, error) => {
          return FromSiteActions.sendInviteToSubjectFailureAction({
            payload: error,
          });
        },
      })
    )
  );

  removeSubject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.removeSubjectAction),
      fetch({
        run: (action) => {
          return this.siteHttpService
            .removeSubject$(action)
            .pipe(
              map((response) => FromSiteActions.removeSubjectSuccessAction())
            );
        },
        onError: (action, error) => {
          return FromSiteActions.removeSubjectFailureAction({ payload: error });
        },
      })
    )
  );

  reInviteSubject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.reInviteSubjectAction),
      fetch({
        run: (action) => {
          return FromSiteActions.getSubjectActivationDetailsAction({
            subject: action.subject,
          });
        },
      })
    )
  );

  getActivationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.getSubjectActivationDetailsAction),
      fetch({
        run: (action) => {
          return this.siteHttpService
            .getActivationDetails$(action.subject.subjectId)
            .pipe(
              map((response) => {
                return FromSiteActions.getSubjectActivationDetailsSuccessAction(
                  {
                    apiResponse: {
                      shortcode: response.shortcode,
                      email: response.email,
                      mobileNumber: response.mobileNumber,
                    },
                    selectedSubject: action.subject,
                  }
                );
              })
            );
        },
        onError: (action, error) => {
          return FromSiteActions.getSubjectActivationDetailsFailureAction({
            payload: error,
          });
        },
      })
    )
  );

  addSubject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.createSubjectAction),
      withLatestFrom(this.store.select(FromSelectors.getSiteId)),
      fetch({
        run: (action, siteId) => {
          return this.siteHttpService
            .addSubject$({ siteId: siteId, subjectName: action.subjectName })
            .pipe(
              map((response) =>
                FromSiteActions.createSubjectSuccessAction({
                  apiResponse: response,
                  resource: this.getSendInviteCodeUrl(),
                  subjectName: action.subjectName,
                })
              )
            );
        },
        onError: (action, error) => {
          return FromSiteActions.createSubjectFailureAction({ payload: error });
        },
      })
    )
  );

  inviteSubjectSuccessNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.sendInviteToSubjectSuccessAction),
        tap(async () => await this.router.navigate([this.getSubjectListUrl()]))
      ),
    { dispatch: false }
  );

  inviteSubjectFailureNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.sendInviteToSubjectFailureAction),
        tap(
          async (action) =>
            await this.router.navigate([this.getSubjectListUrl()])
        )
      ),
    { dispatch: false }
  );

  getActivationDetailsSuccessNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.getSubjectActivationDetailsSuccessAction),
        tap(
          async (action) =>
            await this.router.navigate([
              this.getSendInviteCodeUrl(),
              action.selectedSubject.subjectName,
            ])
        )
      ),
    { dispatch: false }
  );

  getStudies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.getStudies),
      fetch({
        run: (action) => {
          return this.siteHttpService
            .getStudies$()
            .pipe(
              map((studies) => {
                studies = sortBy(studies, [(s) => s.studyName]);
                studies.forEach((study) => {
                  study.sites = sortBy(study.sites, [(s) => s.siteName]);
                });
                return FromSiteActions.getStudiesSuccess({ studies });
              })
            );
        },
        onError: (_, error) => {
          return FromSiteActions.getStudiesFailure({
            payload: error,
          });
        },
      })
    )
  );

  getStudiesError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.getStudiesFailure),
        tap(async () => await this.router.navigateByUrl('/error'))
      ),
    { dispatch: false }
  );

  setStudySiteRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.setSelectedStudySite),
        tap(async () => await this.router.navigate([this.getSubjectListUrl()]))
      ),
    { dispatch: false }
  );

  setStudySiteRedirectForReselect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.setSelectedStudySiteForReselect),
        tap(async () => await this.router.navigate([this.getSubjectListUrl()]))
      ),
    { dispatch: false }
  );
  setPrevStudySiteRedirectForReselect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSiteActions.prevSetSelectedStudySiteForReselect),
        tap(async () => await this.router.navigate([this.getSubjectListUrl()]))
      ),
    { dispatch: false }
  );

  getIsDeviceInUse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSiteActions.getIsDeviceStatus),
      withLatestFrom(this.store.select(FromSelectors.getSiteId)),
      fetch({
        run: (action, selectedSiteId) => {
          return this.siteHttpService
          .getIsDeviceInUse$(selectedSiteId)
            .pipe(
              map((response) =>
                FromSiteActions.getIsDeviceStatusSuccess({
                  inUse: response.inUse,
                  isSetup: response.isSetup,
                })
              )
            );
        },
        onError: (_, error) => {
          return FromSiteActions.getIsDeviceStatusFailure();
        },
      })
    )
  );

  getSubjectListUrl = (): string =>
    this.routeBuilderService
      .withLazyFeature('sites')
      .withRoute(SiteJourneyRoutes.SubjectList)
      .build();

  getSendInviteCodeUrl = (): string =>
    this.routeBuilderService
      .withLazyFeature('sites')
      .withRoute(SiteJourneyRoutes.SendInviteCode)
      .build();
}
