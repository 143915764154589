import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { VisitComponent } from './visit.component';

@Injectable()
export class VisitDeactivateGuard  {
  canDeactivate(component: VisitComponent): Observable<boolean | UrlTree> {
    return component.disconnectVisit();
  }
}
