import { Injectable } from '@angular/core';
import { StudyModel } from '@virtual-trials-workspace/models';
import { RestApiService } from '@virtual-trials-workspace/shared-core-services';
import { Observable } from 'rxjs';
import {
  CreateSubjectRequestModel,
  CreateSubjectResponseModel,
  DeviceInUseResponseModel,
  GetActivtaionDetailsResponse,
  GetSubjectsForSiteResponseModel,
  RemoveSubjectRequestModel,
  SendInviteToSubjectRequestModel,
} from '../models';

@Injectable({ providedIn: 'root' })
export class SiteHttpService {
  readonly sitesLoginResource = '/account/login';
  readonly createAccountResource = '/user/setpassword';
  readonly addSubjectResource = '/subject/create';
  readonly inviteSubjectResource = '/subject/sendactivation';
  readonly removeSubjectResource = '/subject/remove';
  readonly setupCallResource = '/call/setupcall';
  readonly endCallResource = '/call/endcall';
  readonly callAliveResource = '/call/alive';

  constructor(private restApiService: RestApiService) {}

  getSubjectsForSite$ = (
    siteId: string
  ): Observable<GetSubjectsForSiteResponseModel> => {
    const resource = `/site/${siteId}/subjects`;
    return this.restApiService.get<GetSubjectsForSiteResponseModel>(resource);
  };

  addSubject$ = (
    request: CreateSubjectRequestModel
  ): Observable<CreateSubjectResponseModel> => {
    return this.restApiService.post<
      CreateSubjectRequestModel,
      CreateSubjectResponseModel
    >(this.addSubjectResource, request);
  };

  inviteSubject$ = (
    request: SendInviteToSubjectRequestModel
  ): Observable<any> => {
    return this.restApiService.post<SendInviteToSubjectRequestModel>(
      this.inviteSubjectResource,
      request
    );
  };

  removeSubject$ = (request: RemoveSubjectRequestModel): Observable<any> => {
    return this.restApiService.post<RemoveSubjectRequestModel>(
      this.removeSubjectResource,
      request
    );
  };

  getActivationDetails$ = (
    subjectId: string
  ): Observable<GetActivtaionDetailsResponse> => {
    const resource = `/subject/${subjectId}/activationdetails`;
    return this.restApiService.get<GetActivtaionDetailsResponse>(resource);
  };

  getStudies$ = (): Observable<Array<StudyModel>> => {
    const resource = `/account/studies`;
    return this.restApiService.get<Array<StudyModel>>(resource);
  };

  getIsDeviceInUse$ = (
    siteId: string
  ): Observable<DeviceInUseResponseModel> => {
    const resource = `/site/${siteId}/device`;
    return this.restApiService.get<DeviceInUseResponseModel>(resource);
  };
}
