import {
  ParticipantType,
  SecurityQuestion,
} from '@virtual-trials-workspace/models';
import { RootState } from '@virtual-trials-workspace/store';

import * as FromModels from '../models';

export interface ActivateState extends RootState {
  codeStatus: FromModels.ShortCodeStatus;
  participantType: ParticipantType;
  shortcode: string;
  userActivationStatus: FromModels.UserActivationStatus;
  participantId: string;
  createAccountStatus: FromModels.CreateAccountStatus;
  securityQuestions: Array<SecurityQuestion>;
  securityQuestionsAnswers: Array<FromModels.SecurityQuestionAnswer>;
}

export const initialActivateState: ActivateState = {
  codeStatus: 'unknown',
  error: undefined,
  isLoading: false,
  participantType: 'none',
  userActivationStatus: FromModels.UserActivationStatus.RequireUserInput,
  shortcode: undefined,
  participantId: undefined,
  createAccountStatus: FromModels.CreateAccountStatus.RequireUserInput,
  securityQuestions: new Array<SecurityQuestion>(),
  securityQuestionsAnswers: new Array<FromModels.SecurityQuestionAnswer>(),
};

export const ACTIVATE_STATE_KEY = 'ACTIVATE_STATE';
