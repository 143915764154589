import { Injectable } from '@angular/core';
import {
  HashMap,
  TranslocoMissingHandler,
  TranslocoMissingHandlerData,
} from '@ngneat/transloco';
import * as _ from 'lodash';
import { TranslocoMissingKeyStore } from './transloco-missing-key-store';

@Injectable()
export class TranslocoMissingKeyHandler implements TranslocoMissingHandler {
  constructor(private translocoMissingKeyStore: TranslocoMissingKeyStore) {}

  handle = (
    key: string,
    data: TranslocoMissingHandlerData,
    params?: HashMap<any>
  ): any => {
    if (!data.prodMode) {
      console.warn(`[${data.activeLang}] Missing key: ${key}`);
    }

    let val = _.get(this.translocoMissingKeyStore.fallbackTranslations, key);

    if (!!val && !!params) {
      for (const [key, value] of Object.entries(params)) {
        const tagSearch = data.interpolation[0] + key + data.interpolation[1];
        val = val.replace(tagSearch, value);
      }
    }

    return val ?? key;
  };
}
