<div class="es-notification " 
	[ngClass]="{
	'-es-success': data.type === 'success',
	'-es-danger': data.type === 'danger',
	'-es-warning': data.type === 'warning',
	'-es-info': data.type === 'info'
}">
	<span class="es-icon es-icon-info"></span>
	<p>{{data.message }}</p>
	<!-- <span class="es-icon es-icon-close"></span> -->
</div>