import { DeviceStatus, StudyModel } from '@virtual-trials-workspace/models';
import { RootState } from '@virtual-trials-workspace/store';
import { Subject } from '../models/subject';
import { NotificationStatus } from './models';

export interface SiteState extends RootState {
  subjects: Array<Subject>;
  selectedSubject: Subject;
  subjectsLoaded: boolean;
  notification: NotificationStatus;
  studies: Array<StudyModel>;
  selectedSiteId: string;
  selectedStudyId: string;
  prevSelectedSiteId: string;
  prevSelectedStudyId: string;
  deviceStatus: DeviceStatus;
  ecoaLiveStandardalone: boolean;
}

export const initialSiteState: SiteState = {
  isLoading: false,
  error: undefined,
  subjects: new Array<Subject>(),
  subjectsLoaded: false,
  notification: NotificationStatus.None,
  selectedSubject: undefined,
  studies: new Array<StudyModel>(),
  selectedSiteId: undefined,
  selectedStudyId: undefined,
  prevSelectedSiteId: undefined,
  prevSelectedStudyId: undefined,
  deviceStatus: DeviceStatus.Unknown,
  ecoaLiveStandardalone: false,
};

export const SITE_STATE_KEY = 'SITE_STATE';
