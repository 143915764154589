import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiModule } from '@virtual-trials-workspace/shared-ui';

import * as FromEmulation from './emulation';
import * as FromLayout from './layout';
import * as FromSite from './site';
import * as FromSubject from './subject';
import * as FromProvisioner from './provisioner';
import * as FromVideoCall from './video-call';

import { VisitRoutingModule } from './routing';
import { VisitDeactivateGuard } from './visit.candeactivate.guard';
import { VisitComponent } from './visit.component';
import { TranslocoModule } from '@ngneat/transloco';
import { VisitEndSheetComponent } from './visit-end-sheet/visit-end-sheet.component';
import { VisitSocketFailedSheetComponent } from './socket-failed-sheet/socket-failed-sheet.component';
@NgModule({
  imports: [CommonModule, UiModule, VisitRoutingModule, TranslocoModule],
  declarations: [
    FromEmulation.DeviceControlInfoComponent,
    FromEmulation.MobileRestrictionSheetComponent,
    FromLayout.BackMenuItemComponent,
    FromLayout.ChildMenuItemComponent,
    FromLayout.ContentSheetComponent,
    FromLayout.HeaderComponent,
    FromLayout.LogoComponent,
    FromLayout.EcoaLogoComponent,
    FromLayout.SelectedServiceComponent,
    FromLayout.VisitInfoMenuItemComponent,
    FromLayout.VisitServiceComponent,
    FromSite.SendInvitationSheetComponent,
    FromSite.SiteComponent,
    FromSite.VisitServiceFailedSheetComponent,
    FromSubject.SubjectComponent,
    FromSubject.WelcomeComponent,
    FromProvisioner.ProvisionerVisitEndSheetComponent,
    FromVideoCall.DevicePermissionsSheetComponent,
    FromVideoCall.VideoCallActionsComponent,
    VisitComponent,
    VisitEndSheetComponent,
    VisitSocketFailedSheetComponent,
  ],
  providers: [VisitDeactivateGuard, FromEmulation.UserDeviceDetailsService],
})
export class VisitModule {}
