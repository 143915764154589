import { SecurityQuestion } from '@virtual-trials-workspace/models';
import { RootState } from '@virtual-trials-workspace/store';

export interface AccountState extends RootState {
  passwordResetSuccess: boolean;
  resetPasswordSecurityQuestion: SecurityQuestion;
}

export const initialAccountState: AccountState = {
  error: undefined,
  isLoading: false,
  passwordResetSuccess: false,
  resetPasswordSecurityQuestion: undefined,
};

export const ACCOUNT_STATE_KEY = 'ACCOUNT_STATE';
