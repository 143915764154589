import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { BaseConfig } from '@virtual-trials-workspace/models';
import { EmulationSelectors, VisitActions, VisitSelectors, VisitState } from '@virtual-trials-workspace/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SiteFacadeService } from '../../site/facade/site-facade.service';

@Component({
  selector: 'vt-visit-device-control-info',
  templateUrl: './device-control-info.component.html',
  styleUrls: ['./device-control-info.component.scss'],
  providers: [SiteFacadeService],
})
export class DeviceControlInfoComponent implements OnInit {
  @Input()
  bodyText: string;
  isEcoaLiveStandalone = false;
  deviceHasControl = true;

  constructor(private store: Store<VisitState>) {}

  ngOnInit() {
    this.subscribeEcoaStandalone();
    this.subscribeHasDeviceControl();
  }

  private subscribeEcoaStandalone = (): void => {
    this.store
      .pipe(select(VisitSelectors.getEcoaLiveStandalone))
      .subscribe((isEcoaLive) => {
        this.isEcoaLiveStandalone = isEcoaLive;
      });
  };

  private subscribeHasDeviceControl = (): void => {
    this.store
      .pipe(select(EmulationSelectors.getHasDeviceControl))
      .pipe(distinctUntilChanged())
      .subscribe((hasControl) => {
        if (hasControl != undefined) {
          this.deviceHasControl = hasControl;
        }
      });
  };

  handleEndVisit = (): void => {
    this.store.dispatch(VisitActions.endVisit());
  };

  handleTransferRequestControl = (): void => {
    this.store.dispatch(VisitActions.emulationRequestControlConfirmation());
  };
}
