import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { VisitFacadeService } from '../visit/facade/visit-facade.service';

@Component({
  selector: 'vt-standalone-visit-error',
  templateUrl: './visit-error.component.html',
  styleUrls: ['./visit-error.component.scss'],
  providers: [VisitFacadeService],
  encapsulation: ViewEncapsulation.None,
})
export class VisitErrorComponent {
  @HostBinding('class')
  private class = 'visit-error-container';

  get emulationServiceLost$(): Observable<boolean> {
    return this.visitFacadeService.emulationServiceLost$;
  }

  constructor(private visitFacadeService: VisitFacadeService) {}
}
