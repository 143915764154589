<ng-container *ngIf="securityQuestion && answerFormControl">
  <div class="es-form-field" *transloco="let transl">
    <label class="es-label label-security-one-line">
      <b>
        {{ transl('security_questions.question') }}:
        {{ transl('security_questions.' + securityQuestion.questionId.toUpperCase()) }}
      </b>
    </label>
    <label class="es-label -es-required">{{transl('security_questions.answer')}}</label>
    <input [formControl]="answerFormControl" type="text" class="es-input"
      [placeholder]="transl('error.reset_password.secret_question.answer_required')" [ngClass]="{'-es-invalid':
          (showNoAnswerMatchApiError ||
          showAnswerRequiredError ||
          showAnswerLengthError) &&
          answerFormControl.touched
        }" />
    <div *ngIf="showNoAnswerMatchApiError" id="noMatchApiError" class="es-form-message -es-invalid">
      <div class="error-icon"></div>
      <p>{{ transl('error.reset_password.secret_question.api_failed') }}</p>
    </div>
    <div *ngIf="showAnswerRequiredError && answerFormControl.touched" id="answerRequiredError"
      class="es-form-message -es-invalid">
      <div class="error-icon"></div>
      <p>{{ transl('error.reset_password.secret_question.answer_required') }}</p>
    </div>
    <div *ngIf="showAnswerLengthError" id="answerLengthError" class="es-form-message -es-invalid">
      <div class="error-icon"></div>
      <p>{{ transl('error.reset_password.secret_question.incorrect_answer_length') }}</p>
    </div>
  </div>
</ng-container>