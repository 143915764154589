import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vt-visit-end-sheet',
  templateUrl: './visit-end-sheet.component.html',
  styleUrls: ['./visit-end-sheet.component.scss'],
})
export class VisitEndSheetComponent {
  @Input()
  timedOut: boolean;
  @Output()
  closed = new EventEmitter();

  onClose(): void {
    this.closed.emit();
  }
}
