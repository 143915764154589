<ng-container *transloco="let transl">
    <!-- <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        
        <div class="logo"></div>
        <vt-ui-language-select [setUserLanguage]="false"></vt-ui-language-select>
    </div> -->
    <!-- <div class="content" fxLayout="column">
        <ng-container *ngIf="countDownValue >= 1000; else expired" [ngTemplateOutlet]="expiring"></ng-container>
    </div> -->

    <main class="es-gateway-body">
      <section
        class="es-timer-popup-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
      >
        <div class="es-column es-visit-timer-column">
          <div class="es-content-box">
            <div class="es-panel es-custom-panel">
              <ng-container *ngIf="countDownValue >= 1000; else expired" 
                [ngTemplateOutlet]="expiring">
              </ng-container>

            </div>
          </div>
        </div>
      </section>
    </main>

    <ng-template #expired>
      <ng-container>
        <header class="es-card-header">
          <div class="timer-icon"></div>
          <h2>{{ transl('session_timeout.expired') }}</h2>
        </header>
        <div  class="es-card-body">
          <div class="es-content-box">
            <div class="es-notification -es-info">
              <span class="es-icon es-icon-info"></span>
              <p id="body-text-paragraph-id">
                {{ transl('session_timeout.logged_out') }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <footer class="es-timer-popup-footer">
        <div class="es-toolbar">
          <ul class="es-right">
            <li class="es-toolbar-item">
              <button class="es-button" id="continue" [mat-dialog-close]="false" color="primary">
                {{ transl('general.continue') | titlecase }}
            </button>
            </li>
          </ul>
        </div>
      </footer>
    </ng-template>

    <ng-template #expiring>

      <ng-container>
        <header class="es-card-header">
          <div class="timer-icon"></div>
          <h2>{{ transl('session_timeout.expiring', { 
            countdown: countDownValue | date:'HH:mm:ss':'UTC' }) }}
          </h2>
        </header>
        <div  class="es-card-body">
          <div class="es-content-box">
            <div class="es-notification -es-info">
              <span class="es-icon es-icon-info"></span>
              <p id="body-text-paragraph-id">
                {{ transl('session_timeout.logging_out', { 
                  buttonName: transl('general.continue') | titlecase }) }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <footer class="es-timer-popup-footer">
        <div class="es-toolbar">
          <ul class="es-right">

            <li class="es-toolbar-item">
              <button class="es-button" id="logout" [mat-dialog-close]="false" >
                {{ transl('user.account.logout') | titlecase }}
            </button>

            </li>
            <li class="es-toolbar-item">
              <button class="es-button -es-primary" id="continue" [mat-dialog-close]="true">
                {{ transl('general.continue') | titlecase }}
            </button>
            </li>
          </ul>
        </div>
      </footer>

    </ng-template>
<!-- 

    <ng-template #expired> 
        <div class="message" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap=12px>
            <mat-icon class="robot zzz" svgIcon="robot-sleeping"></mat-icon>
            <div class="text" fxLayout="column" fxLayoutAlign="center center">
                <h4>{{ transl('session_timeout.expired') }}</h4>
                <span>{{ transl('session_timeout.logged_out') }}</span>
            </div>
        </div>
        <div class="control-panel" fxLayout="row" fxLayoutAlign="end center">
            <button id="continue" mat-button [mat-dialog-close]="false" color="primary">
                {{ transl('general.continue') | titlecase }}
            </button>
        </div>
    </ng-template>
    
    <ng-template #expiring>
        <div class="message" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap=12px>
            <mat-icon class="robot zzz" svgIcon="robot-sleeping"></mat-icon>
            <div class="text" fxLayout="column" fxLayoutAlign="center center">
                <h4>{{ transl('session_timeout.expiring', { countdown: countDownValue | date:'HH:mm:ss':'UTC' }) }}</h4>
                <span>{{ transl('session_timeout.logging_out', { 
                    buttonName: transl('general.continue') | titlecase }) }}</span>
            </div>
        </div>
        <div class="control-panel" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
            <button id="logout" mat-button [mat-dialog-close]="false">
                {{ transl('user.account.logout') | titlecase }}
            </button>
            <button id="continue" mat-button [mat-dialog-close]="true" color="primary">
                {{ transl('general.continue') | titlecase }}
            </button>
        </div>
    </ng-template>

     -->
</ng-container>


