<div *transloco="let transl">
  <vt-visit-content-sheet
    [headerText]="transl('visit.site.visit_start_failed.title')"
    [primaryActionText]="transl('general.ok_upper_case')"
    (primaryAction)="logoutClick()"
  >
  <div cardBody>
    <header class="es-card-header">
      <h2>{{transl('visit.site.visit_start_failed.title')}}</h2>
    </header>
    <div class="es-card-body">
      <div class="es-content-box">
        <p>
          {{ failedServiceText }}
        </p>
        <p> {{ transl('visit.subject.visit_end.failure.visit_failure_message') }}</p>
      </div>
    </div>
  </div>
  </vt-visit-content-sheet>
</div>
