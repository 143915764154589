import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootSelectors, SessionState, SessionSelectors } from '@virtual-trials-workspace/store';
import { ProvisionerState, PROVISIONER_STATE_KEY } from '../provisioner.state';

export const getProvisionerState = createFeatureSelector<ProvisionerState>(
  PROVISIONER_STATE_KEY
);

export const getSites = createSelector(
  getProvisionerState,
  (state: ProvisionerState) => state.sites
);

export const getParticipantId = createSelector(
  RootSelectors.getSessionState,
  (state: SessionState) => state.participantId
);

export const getSelectedSiteId = createSelector(
  getProvisionerState,
  (state: ProvisionerState) => state.selectedSiteId
);

export const getIsLoading = createSelector(
  getProvisionerState,
  SessionSelectors.getIsLoading,
  (state: ProvisionerState, sessionIsLoading: boolean) =>
    state.isLoading || sessionIsLoading
);

export const getDeviceStatus = createSelector(
  getProvisionerState,
  (state: ProvisionerState) => state.deviceStatus
);

export const getIsDeviceProvisioned = createSelector(
  getProvisionerState,
  (state: ProvisionerState) => state.isSelectedDeviceProvisioned
);
