<!-- <header class="es-gateway-header">
  <div class="header-inner">
    <div class="es-logo">
      <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
    </div>
    <div class="product-name">Virtual Visits</div>
    <div class="ellipses-1"></div>
    <div class="ellipses-2"></div>
    <div class="ellipses-3"></div>
  </div>
</header> -->

<div class="es-global-header -es-mode-navigator">
	<header class="es-fixed-global-header">
		<a href="javascript:void(0)" class="es-company-logo"></a>
		<label class="es-label-title" title="Navigator">Virtual Visits</label>
	</header>
</div>