import { Component } from '@angular/core';

@Component({
  selector: 'vt-ui-subject-header',
  templateUrl: './subject-header.component.html',
  styleUrls: ['./subject-header.component.css']
})
export class SubjectHeaderComponent {

}
