<div fxLayout="row" fxLayoutAlign="flex-end" class="pad-10">
  <vt-ui-language-select class="top-right"></vt-ui-language-select>
</div>
<div class="page-wrapper" *transloco="let transl">
  <div
    class="page-container"
    fxFlex="1 1"
    fxLayout="wrap"
    fxLayoutAlign="center center"
  >
    <div class="page-content">
      <mat-card class="vv-error">
        <img src="/assets/web/images/error-splash.png" />
        <div class="card-title">
          {{ transl('error.error_page.not_found') }}
        </div>
        <div class="card-subtitle">
          {{ transl('error.error_page.helpful_links') }}
          <div>
            <a
              href="javascript:void(0);"
              id="{{ homeElementId }}"
              (click)="handleHomeClick()"
              >
              {{ transl('error.error_page.home_link') }}
            </a
            >
          </div>
          <div>
            <a
              href="javascript:void(0);"
              id="{{ loginElementId }}"
              (click)="handleLoginClick()"
              >
              {{ transl('error.error_page.login_link') }}
            </a
            >
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
