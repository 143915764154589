<div *transloco="let transl">
  <div class="es-card1 -es-data">
    <vt-visit-content-sheet
      data-testid="emulation-waiting-id"
      [headerText]="transl('visit.emulation.assessment_form.title')"
    >
      <ng-container cardBody>
        <div class="es-card-body">
          <div class="es-content-box">
            <div class="timer-tick-icon"></div>
            <p class="waiting-text" *ngIf="!deviceHasControl">
              <ng-content></ng-content>
            </p>
            <!-- <div class="es-loading-indicator -es-visible inlineBlock">
              <span class="es-loading-icon"></span>
            </div> -->
          </div>
        </div>
      </ng-container>
    </vt-visit-content-sheet>
  </div>
</div>
