import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationGuard } from './authentication/authentication.guard';

@NgModule({
  imports: [CommonModule],
  providers: [AuthenticationGuard]
})
export class GuardsModule {}
