import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  LanguageModel,
  TranslationFile,
} from '@virtual-trials-workspace/models';
import { RouteNavigationService } from '@virtual-trials-workspace/shared-core-services';
import {
  LanguageHttpService,
  RootActions,
  SessionActions,
  SessionSelectors,
  SessionState,
} from '@virtual-trials-workspace/store';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { translocoConfig } from './shared-transloco.module';

export function translocoConfigurationFactory(
  languageService: LanguageHttpService,
  translocoService: TranslocoService,
  routeNavigationService: RouteNavigationService,
  store: Store<SessionState>
): () => Promise<any> {
  const getStoredLanguage = (langs: Array<string>): string | undefined => {
    const storedLang = languageService.getStoredLanguage();

    return !!storedLang && langs.includes(storedLang) ? storedLang : undefined;
  };

  const handleFailure = (error: any): void => {
    routeNavigationService.navigateInternalErrorPage().finally(() => {
      throw error;
    });
  };

  const handleGetLanguagesFailure = (error: any): Observable<any> => {
    console.error('[APP_INITIALIZER] Failed to load languages');

    return throwError(error);
  };

  const handleLoadlanguages = (langs: LanguageModel[]) => {
    const defaultLang = languageService.defaultLanguage;

    if (!defaultLang) {
      throw new Error('default language is not specified');
    }

    /*
    APP_INITIALIZER does not prevent from importing modules,
    so we should update exisiting config which is going to be improrted with TRANSLOCO_CONFIG injection token later on.
    */
    translocoConfig.availableLangs = langs.map((lang) => lang.code);

    const activeLang =
      getStoredLanguage(translocoConfig.availableLangs) ?? defaultLang;

    translocoConfig.fallbackLang = defaultLang;
    translocoConfig.defaultLang = activeLang;

    // We also need to update config that singleton servcice uses
    translocoService.config.fallbackLang = translocoConfig.fallbackLang;
    translocoService.config.defaultLang = translocoConfig.defaultLang;
    translocoService.config.availableLangs = translocoConfig.availableLangs;

    translocoService.setAvailableLangs(translocoConfig.availableLangs);
    translocoService.setFallbackLangForMissingTranslation(translocoConfig);
    translocoService.setDefaultLang(translocoConfig.defaultLang);
    translocoService.setActiveLang(activeLang);

    languageService.setSelectedLanguage(activeLang);

    return activeLang;
  };

  return () => {
    store.dispatch(SessionActions.getLanguages());
    return combineLatest([
      store.select(SessionSelectors.getAvailableLanguages),
      store.select(SessionSelectors.getTranslationFiles),
      store.select(SessionSelectors.getFilterLanguages),
    ])
      .pipe(
        filter(
          (response: [LanguageModel[], TranslationFile[], LanguageModel[]]) => !!response[0]
        ),
        map((response: [LanguageModel[], TranslationFile[], LanguageModel[]]) => {
          if(response[2]) {
            localStorage.setItem('filterLanguages', JSON.stringify(response[2]));
            const filterLang = localStorage.getItem('filterLanguages');
          
            if(filterLang !== "undefined") {
              store.dispatch(
                RootActions.setFilterLanguagesSuccess({
                  languages: JSON.parse(filterLang),
                })
              );
              store.dispatch(
                RootActions.setAllLanguagesSuccess({
                  languages: JSON.parse(filterLang),
                })
              );
            }
            return handleLoadlanguages(JSON.parse(filterLang));
          }
          return handleLoadlanguages(response[0]);
        }),
        switchMap((activeLang: string) => {
          return translocoService.load(activeLang);
        }),
        catchError(handleGetLanguagesFailure),
        take(1)
      )
      .toPromise()
      .catch(handleFailure);
  };

}
