import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-content-sheet',
  templateUrl: './content-sheet.component.html',
  styleUrls: ['./content-sheet.component.scss'],
})
export class ContentSheetComponent {
  
  @Input()
  headerText: string;

  @Input()
  esColumnClass = '';

  @Input()
  primaryActionText: string;

  @Input()
  secondaryActionText: string;

  @Output()
  primaryAction = new EventEmitter();

  @Output()
  secondaryAction = new EventEmitter();
}
