import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { merge, Observable, Subject, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { VisitSessionExtendDialogComponent } from '@virtual-trials-workspace/shared-ui';

@Injectable({
  providedIn: 'root',
})
export class VisitSessionExtendService {
  private _openedVisitSessionTimeoutDialog: MatDialogRef<any>;
  private _openedJoinSessionTimeoutDialog: MatDialogRef<any>;

  merged$: Observable<string>;
  joined$: Subject<boolean>;
  private joinSubscription: Subscription;
  private endSubscription: Subscription;

  endVisitSub$ = new Subject<boolean>();
  extendVisitSub$ = new Subject<string>();

  constructor(private dialog: MatDialog) {}

  stopVisitTimer() {
    this.endSubscription?.unsubscribe();
    this._openedVisitSessionTimeoutDialog?.close();
  }

  startVisitTimer(
    endTime: number,
    warningAmount: number,
    isProvisioner: boolean,
    canExtend: boolean
  ) {
    this.stopVisitTimer();
    const nowTime = new Date().getTime();
    const endTimePure = endTime - nowTime;
    const warn$ = timer(endTimePure - warningAmount);
    this.endSubscription = warn$.subscribe(() =>
      this.showVisitDialog(endTime, canExtend, isProvisioner, false)
    );
  }

  stopJoinTimer() {
    this.joinSubscription?.unsubscribe();
    this._openedJoinSessionTimeoutDialog?.close();
  }

  startJoinTimer(endTime: number, warningAmount: number, canExtend: boolean) {
    this.stopJoinTimer();
    const date = new Date();
    const now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    const endTimePure = endTime - now_utc;

    const warn$ = timer(endTimePure - warningAmount);

    this.joinSubscription = warn$.subscribe(() =>
      this.showVisitDialog(endTime, canExtend, false, true)
    );
  }

  private showVisitDialog = (
    endTime: number,
    canExtend: boolean,
    isProvisioner: boolean,
    isJoin: boolean
  ): void => {
    if (isJoin && this._openedJoinSessionTimeoutDialog) {
      return;
    } else if (this._openedVisitSessionTimeoutDialog) {
      return;
    }

    const dialogConfig: MatDialogConfig = {
      closeOnNavigation: true,
      autoFocus: true,
      disableClose: false,
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      panelClass: 'session-timeout-dialog-panel',
      scrollStrategy: new NoopScrollStrategy(),
      data: { endTime, canExtend, isProvisioner, isJoin },
    };

    if (isJoin) {
      this.openJoinSessionDialog(dialogConfig);
    } else if (!this._openedJoinSessionTimeoutDialog) {
      this.openVisitSessionDialog(dialogConfig);
    }
  };

  private openJoinSessionDialog = (dialogConfig: MatDialogConfig): void => {
    this._openedJoinSessionTimeoutDialog = this.dialog.open(
      VisitSessionExtendDialogComponent,
      dialogConfig
    );
    this._openedJoinSessionTimeoutDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => this.onVisitDialogClose(result, dialogConfig));
  };

  private openVisitSessionDialog = (dialogConfig: MatDialogConfig): void => {
    this._openedVisitSessionTimeoutDialog = this.dialog.open(
      VisitSessionExtendDialogComponent,
      dialogConfig
    );
    this._openedVisitSessionTimeoutDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => this.onVisitDialogClose(result, dialogConfig));
  };

  private onVisitDialogClose = (
    result: string,
    dialogConfig: MatDialogConfig
  ): void => {
    if (dialogConfig.data.isJoin) {
      this._openedJoinSessionTimeoutDialog = undefined;
    } else {
      this._openedVisitSessionTimeoutDialog = undefined;
    }

    console.log(result);
    switch (result) {
      case 'continue': {
        if (dialogConfig.data.canExtend && !dialogConfig.data.isProvisioner) {
          this.extendVisitSub$.next(dialogConfig.data.isJoin ? 'join' : 'end');
        }
        break;
      }
      case 'end': {
        this.endVisitSub$.next(true);
        break;
      }
    }
  };
}
