import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Component({
  selector: 'vt-ui-emulation-device',
  templateUrl: './emulation-device.component.html',
  styleUrls: ['./emulation-device.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmulationDeviceComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  private class = 'emulation-device-container';
  private done$ = new Subject<void>();

  @Input()
  deviceId: string;

  @Input()
  errored: boolean;

  @Input()
  networkLost: boolean;

  constructor(private meta: Meta) {}

  ngOnInit(): void {
    this.applyMobileZoomDisablingMeta();
  }

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }

  private applyMobileZoomDisablingMeta() {
    const savedMeta = this.meta.addTag({
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
    });

    this.done$.subscribe(() => {
      this.meta.removeTagElement(savedMeta);
    });
  }
}
