import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject as RxjsSubject } from 'rxjs';
import { SiteListItem } from '../models';
import { SetProvisionedDialogComponent } from '../set-provisioned-dialog/set-provisioned-dialog.component';
import { ProvisionerState } from '../store/provisioner.state';
import * as provisionerActions from '../store/actions/provisioner.actions';
import { RootActions } from '@virtual-trials-workspace/store';
import * as FromSelectors from '../store/selectors/provisioner.selector';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { DeviceStatus } from '@virtual-trials-workspace/models';
import { DeviceInfoComponent } from '../dialog/device-info/device-info.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'vt-provisioner-journey-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss'],
})
export class SiteListComponent implements OnInit, OnDestroy {
  // @ViewChild(MatSidenav, { static: true })
  // sideNavViewChild: MatSidenav;

  // @ViewChild(MatSort, { static: false })
  // set content(sort: MatSort) {
  //   this.dataSource.sortingDataAccessor = (item, property) => {
  //     switch (property) {
  //       case 'studyName':
  //         return item.studyName.toLowerCase();
  //       case 'siteName':
  //         return item.siteName.toLowerCase();
  //       default:
  //         return item[property];
  //     }
  //   };
  //   this.dataSource.sort = sort;
  // }

  get participantId$(): Observable<string> {
    return this.store.pipe(select(FromSelectors.getParticipantId));
  }

  get selectedSiteId$(): Observable<string> {
    return this.store.pipe(select(FromSelectors.getSelectedSiteId));
  }

  get deviceStatus$(): Observable<DeviceStatus> {
    return this.store.pipe(select(FromSelectors.getDeviceStatus));
  }

  get isDeviceProvisioned$(): Observable<boolean> {
    return this.store.pipe(select(FromSelectors.getIsDeviceProvisioned));
  }
  get backToTopContainer$(): HTMLElement {
    return document.querySelector(".back-to-top-container"); 
  }

  isLoading: boolean;

  selectedSiteId: string;

  searchTermValue = '';
  
  sortDirection : 'asc' | 'desc' | 'none' = 'none';
  
  sortingClassList: string[] = ['-es-sort', '-es-sort-ascending', '-es-sort-descending'];

  participantIdClassIndex = 0;

  participantStatusClassIndex = 0;

  dataSiteList: SiteListItem[] = [];

  originalDataSiteList: SiteListItem[] = [];

  filteredSiteList: SiteListItem[] = [];

  searchFilteredSiteList: SiteListItem[] = [];

  private readonly unsubscribe$ = new RxjsSubject<void>();

  dataSource: MatTableDataSource<SiteListItem>;

  displayedColumns: string[] = [
    'studyName',
    'siteName',
    'provisioned',
    'actions',
  ];

  public current: number;
  public total: number;
  public totalPages: number;
  public perPage: number;
  public startIndex: number;
  public endIndex: number;

  @ViewChild('paginator') paginator: MatPaginator;
  pageSize = 10;
  pageSizeOptions:Array<number> = [5, 10, 20, 100];
  constructor(
    private dialog: MatDialog,
    private store: Store<ProvisionerState>
  ) {}
  // @HostListener('window:scroll', ['$event']) 
  // scrollHandler(event) {
  //   if (
  //     document.body.scrollTop > 40 ||
  //     document.documentElement.scrollTop > 40
  //   ) {
  //     this.backToTopContainer$.style.display = "block";
  //   } else {
  //     this.backToTopContainer$.style.display = "none";
  //   }
  // }
  ngOnInit(): void {
    this.current = 1;
    this.perPage = 10;
    this.totalPages = 0;

    this.dispatchSiteListAction();
    this.subscribeToSiteList();
    this.subscribeToLoadingSpinner();
  }
  
  // backToTop = (event):void => {
  //   const element = document.querySelector('.vv-dash__container');
  //   element.scrollIntoView({behavior: 'smooth'});
  // }

  onApplyClick(): void {
    if (!this.searchTermValue) {
      this.filteredSiteList = [...this.dataSiteList || [] ];

      this.setPagination(this.dataSiteList, this.originalDataSiteList)
      
    } else {

      this.searchFilteredSiteList = this.searchFilter();

      this.filteredSiteList = [...this.searchFilteredSiteList || [] ];

      this.setPagination(this.searchFilteredSiteList, this.originalDataSiteList)
      
    }

  }

  sortSubjects(key: string) {
    if(this.sortDirection === 'none') {
      this.sortDirection = 'asc';
    } else if(this.sortDirection === 'asc') {
      this.sortDirection = 'desc'
    } else {
      this.sortDirection = 'none'
    }

    if(this.sortDirection === 'none') {
      this.onApplyClick();
    } else {
      
      if(!this.searchTermValue) {

        this.filteredSiteList = this.sortSiteList(this.dataSiteList, key);
        
      } else {

        this.searchFilteredSiteList = this.searchFilter();

        this.filteredSiteList = this.sortSiteList(this.searchFilteredSiteList, key);

      }
      
      this.setPagination(this.filteredSiteList, this.filteredSiteList)
      
    }
    
    this.changeSortingClass(key);

  }
  
  searchFilter(): SiteListItem[] {
    return Object.assign([], this.dataSiteList).filter(
      site => site.siteName.toLowerCase().indexOf(this.searchTermValue.toLowerCase()) > -1 || 
      site.studyName.toLowerCase().indexOf(this.searchTermValue.toLowerCase()) > -1
   )
  }
  setPagination(siteFilterList, originalSiteList): void {
      this.total = siteFilterList?.length || 0;

      this.totalPages = Math.ceil(siteFilterList?.length / this.perPage)

      this.current = 1;

      const siteList = this.searchTermValue ? siteFilterList : originalSiteList;

      this.filteredSiteList = this.paginate(this.current, this.perPage, siteList);
      
  }

  sortSiteList(siteList, key): SiteListItem[]{
    return siteList.sort((a,b) => {
      const valueA = a[key];
      const valueB = b[key];

      if(valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      } else if(valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    })
  }
  changeSortingClass(key: string) {

    switch(key) {
      case 'studyName':
        this.participantIdClassIndex = (this.participantIdClassIndex + 1) % this.sortingClassList.length;
        break;
      case 'siteName':
        this.participantStatusClassIndex = (this.participantStatusClassIndex + 1) % this.sortingClassList.length;
        break;
    }

  }

  public getStartEndIndex(): void {
    if(this.current === 1) {
      this.startIndex = this.current;
      this.endIndex = (this.current * this.perPage);
      
    } else {
      this.startIndex = ((this.current - 1) * this.perPage) + 1;
      this.endIndex = ((this.current - 1) * this.perPage) + this.perPage;
    }
    
    if(this.total === 0 ){
      this.startIndex = 0;
    }
    if(this.total <= this.endIndex) {
      this.endIndex = this.total;
    }
  }

  public onGoTo(page: number): void {
    this.current = page;
    const siteList = (this.searchTermValue) ? this.searchFilteredSiteList : undefined;
    this.filteredSiteList = this.paginate(this.current, this.perPage, siteList);
  }
  public onChangeRowsPerPage(rows: number): void {
    this.perPage = Number(rows);

    const siteList = (this.searchTermValue) ? this.searchFilteredSiteList : undefined;

    this.total = (siteList && siteList?.length) ? siteList.length : ( this.dataSiteList?.length || 0 );

    this.totalPages = Math.ceil((siteList && siteList?.length) 
    ? siteList?.length / this.perPage
    : this.dataSiteList?.length / this.perPage);

    
    this.filteredSiteList = this.paginate(this.current, this.perPage, siteList)
  }
  public onNext(page: number): void {
    this.current = page + 1;
    const siteList = (this.searchTermValue) ? this.searchFilteredSiteList : undefined;
    this.filteredSiteList = this.paginate(this.current, this.perPage, siteList);
  }

  public onPrevious(page: number): void {
    this.current = page - 1;
    const siteList = (this.searchTermValue) ? this.searchFilteredSiteList : undefined;
    this.filteredSiteList = this.paginate(this.current, this.perPage, siteList);
  }

  public paginate(current: number, perPage: number, dataSiteList?: SiteListItem[]): any[] {
    this.getStartEndIndex();
    const siteList = (dataSiteList && dataSiteList.length) || 
                     (this.searchTermValue && dataSiteList && !dataSiteList.length ) ? 
                      dataSiteList : this.dataSiteList;

    return [...siteList.slice((current - 1) * perPage).slice(0, perPage)]
  }

  private subscribeToLoadingSpinner = (): void => {
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(FromSelectors.getIsLoading))
      .subscribe((isLoading) => (this.isLoading = isLoading));
  };

  private subscribeToSiteList = (): void => {
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(FromSelectors.getSites))
      .subscribe((data) => this.initialiseData(data));
  };

  private subscribeToDeviceStatus = (): void => {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromSelectors.getDeviceStatus),
        take(2)
      )
      .subscribe((deviceStatus) => {
        if (
          deviceStatus === DeviceStatus.InUse ||
          deviceStatus === DeviceStatus.NotSetup ||
          deviceStatus === DeviceStatus.Error
        ) {

          const dialogConfig = {
            panelClass: 'es-custom-dialog-panel',
          };

          const ref = this.dialog.open(DeviceInfoComponent, dialogConfig);

          ref.componentInstance.deviceStatus$ = this.deviceStatus$;

          ref.componentInstance.isDeviceAvailable.subscribe(() => {
            ref.close();
          });
        } else if (deviceStatus === DeviceStatus.Available) {
          this.subscribeToConnectDevice();
        }
      });
  };

  private initialiseData = (data: Array<SiteListItem>): void => {
    // this.dataSource = new MatTableDataSource(data);
    // this.dataSource.paginator = this.paginator;
    if(this.searchTermValue && this.searchFilteredSiteList && this.searchFilteredSiteList.length) {
      
      this.dataSiteList = [ ...data || [] ];

      this.originalDataSiteList = [ ...data || [] ];

      this.filteredSiteList = [...this.dataSiteList|| [] ];

      this.searchFilteredSiteList = this.searchFilter();

      this.filteredSiteList = [...this.searchFilteredSiteList || [] ];

      this.filteredSiteList = this.paginate(this.current, this.perPage, this.filteredSiteList)

      return;
    }

    this.dataSiteList = [ ...data || [] ];

    this.originalDataSiteList = [ ...data || [] ];

    this.filteredSiteList = [...this.dataSiteList|| [] ];

    this.total = this.filteredSiteList?.length || 0;

    this.totalPages = Math.ceil(this.filteredSiteList?.length / this.perPage)

    this.filteredSiteList = this.paginate(this.current, this.perPage)

    if (this.searchTermValue) {
      this.handleSearchTerm(this.searchTermValue);
    }
  };

  private dispatchSiteListAction = (): void => {
    this.store.dispatch(provisionerActions.getSitesAction());
  };

  subscribeToConnectDevice = (): void => {
    combineLatest([
      this.participantId$,
      this.selectedSiteId$,
      this.isDeviceProvisioned$,
      this.deviceStatus$,
    ])
      .pipe(
        filter(
          ([a, b, c, d]) =>
            !!a && !!b && c !== undefined && d === DeviceStatus.Available
        ),
        takeUntil(this.unsubscribe$),
        take(1)
      )
      .subscribe(([participantId, siteId, isDeviceProvisioned]) => {
        this.store.dispatch(
          RootActions.startVisitWithSubject({
            siteId: siteId,
            subjectId: siteId,
            subjectParticipantId: participantId,
            modules: ['Emulation'],
            isEcoaLiveStandalone: false,
            isDeviceProvisioned: isDeviceProvisioned,
          })
        );
      });
  };

  onSetProvisioned(site: SiteListItem) {
    const dialogConfig = {
      panelClass: 'es-custom-dialog-panel',
    };
    const ref = this.dialog.open(SetProvisionedDialogComponent, dialogConfig);
    ref.componentInstance.site = site;
    this.store.dispatch(
      provisionerActions.getSelectSiteAction({
        siteId: site.siteId,
      })
    );

    ref.componentInstance.markAsDoneClick.subscribe(() => {
      this.store.dispatch(
        provisionerActions.setProvisioningStatusAction({
          siteId: site.siteId,
          provisionedState: true,
        })
      );
      ref.close();
    });

    ref.componentInstance.cancelClick.subscribe(() => {
      ref.close();
    });
  }
  handleLogoutClick(): void {
    this.store.dispatch(RootActions.endActiveSession());
  }
  // handleToggleSidenav = (): void => {
  //   this.sideNavViewChild.toggle();
  // };

  handleSearchTerm = (value: string): void => {
    this.searchTermValue = value;
    // this.dataSource.filter = value?.trim().toLowerCase();
    this.onApplyClick();
  };

  selectSubject = (site: SiteListItem): void => {
    this.selectedSiteId = site?.siteId;
  };

  unSelectSubject = (): void => {
    this.selectedSiteId = null;
  };

  getIsOpenClass = (siteId: string): string => {
    return this.selectedSiteId === siteId ? '__open' : '';
  };

  handleStartVisit = (siteId: string, provisioned: boolean): void => {
    this.store.dispatch(
      provisionerActions.getDeviceStatus({ siteId, provisioned })
    );
    this.subscribeToDeviceStatus();
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
