import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { SecurityQuestion } from '@virtual-trials-workspace/models';

@Component({
  selector: 'vt-account-security-question-answer',
  templateUrl: './security-question-answer.component.html',
  styleUrls: ['./security-question-answer.component.scss'],
})
export class SecurityQuestionAnswerComponent {
  @Input()
  answerFormControl: AbstractControl;

  @Input()
  showAnswerLengthError: boolean;

  @Input()
  showAnswerRequiredError: boolean;

  @Input()
  showNoAnswerMatchApiError: boolean;

  @Input()
  securityQuestion: SecurityQuestion;

  readonly apiErrorMessage = "The answer provided doesn't match our records";

  readonly requiredErrorMessage = 'Please provide an answer for the question';

  readonly lengthErrorMessage =
    'Please provide an answer between 2 and 256 characters';
}
