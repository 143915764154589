import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@virtual-trials-workspace/shared-guards';

import { ActiveVisitGuard } from './guards/active-visit.guard';
import { ProvisionerJourneyRoutes } from './provisioner-journey-routes';
import { SiteListComponent } from './site-list/site-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: ProvisionerJourneyRoutes.SiteList,
    pathMatch: 'full',
  },
  {
    path: ProvisionerJourneyRoutes.SiteList,
    component: SiteListComponent,
    canActivate: [ActiveVisitGuard, AuthenticationGuard],
    data: { activeVisitGuardRedirect: '/gssologin' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProvisionerJourneyRoutingModule {}
