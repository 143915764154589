import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'vt-activate-home-form',
  templateUrl: './activate-home-form.component.html',
  styleUrls: ['./activate-home-form.component.scss'],
})
export class ActivateHomeFormComponent {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  apiError: string;

  @Input()
  submitInProgress: boolean;

  @Output()
  continueClicked = new EventEmitter();

  get disableButton(): boolean {
    return !this.formGroup.controls.shortcode.value || this.submitInProgress;
  }

  shortCodeControlErrorMatcher = (): ErrorStateMatcher => ({
    isErrorState: (): boolean => {
      const { shortcode } = this.formGroup.controls;
      return (
        !!this.apiError || (shortcode.touched && shortcode.errors?.required)
      );
    },
  });

  handleContinueClick = (): void => this.continueClicked.emit();
}
