<span [ngClass]="isOpenClass">
  <button
    mat-icon-button
    class="vv-actions__mob"
    (click)="mobileChevronRightClick.emit()"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
  <div class="vv-actions__nav">
    <div class="vv-actions__back">
      <button
        mat-icon-button
        class="vv-actions__mob"
        (click)="mobileChevronLeftClick.emit()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="vv-actions__number">
        {{ siteName }}
      </div>
    </div>
    <button mat-icon-button (click)="inviteSubject.emit()" title="Start">
      <mat-icon>play_arrow</mat-icon>

      <span class="mat-icon-button-text">Start</span>
    </button>
  </div>
</span>
