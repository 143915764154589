import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as FromStore from '@virtual-trials-workspace/store';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnonymousParticipantGuard  {
  constructor(
    protected router: Router,
    protected store: Store<FromStore.RootState>
  ) {}
  
  canLoad(): Observable<boolean | UrlTree> {
    return this.store
      .pipe(
        select(FromStore.RootSelectors.getIsAnonymousParticipant),
        map((isAnonymous) => isAnonymous ? this.router.createUrlTree(['/login']) : true),
        take(1)
      );
  }
}
