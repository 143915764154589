import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vt-site-journey-subject-not-activate',
  templateUrl: './subject-not-activate.component.html',
  styleUrls: ['./subject-not-activate.component.scss'],
})
export class SubjectNotActivateComponent {
  constructor(private dialogRef: MatDialogRef<SubjectNotActivateComponent>) {}

  closeDialog = (): void => {
    this.dialogRef.close();
  };
}
