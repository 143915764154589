export class ClientVersion {
  major: number;
  minor: number;
  patch: number;

  constructor(major: number, minor: number, patch: number) {
    this.major = major;
    this.minor = minor;
    this.patch = patch;
  }

  equalsOrHigher = (version: ClientVersion): boolean => {
      return !!version
        && this.major >= version.major
        && this.minor >= version.minor
        && this.patch >= version.patch
  }
}
