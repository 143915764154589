import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { AnimatedDirective } from 'ngx-animations';

import { ServiceState, VisitView } from '../../../models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-selected-service',
  templateUrl: './selected-service.component.html',
  styleUrls: ['./selected-service.component.scss'],
})
export class SelectedServiceComponent {
  @Input()
  selectedService: VisitView = 'video-call';

  @Input()
  selectedServiceState: ServiceState = 'available';

  @Input()
  emulationDeviceConnected: boolean;

  @Output()
  toggleService = new EventEmitter();

  @ViewChild(AnimatedDirective) iconDirectiveRef: AnimatedDirective;

  readonly emulatorIcon = 'tablet';

  readonly videoCallIcon = 'videocam';

  get iconClass(): string {
    return this.selectedServiceState === 'available'
      ? 'service-available'
      : 'service-not-available';
  }

  get showLiveIndicator(): boolean {
    return (
      this.selectedService === 'video-call' && this.emulationDeviceConnected
    );
  }

  handleServiceClick = (): void => {
    this.iconDirectiveRef.animate();
    this.toggleService.emit();
  };
}
