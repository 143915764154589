import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vt-site-journey-code-sent-success',
  templateUrl: './code-sent-success.component.html',
  styleUrls: ['./code-sent-success.component.scss'],
})
export class CodeSentSuccessComponent {
  constructor(private dialogRef: MatDialogRef<CodeSentSuccessComponent>) {}

  closeDialog = (): void => {
    this.dialogRef.close();
  };
}
