import { createAction, props, union } from '@ngrx/store';
import { SiteListItem } from '../../models';
import { ProvisionerActionTypes } from './provisioner.action.type';

export const getSitesAction = createAction(
  ProvisionerActionTypes.PROVISIONER_GET_SITES
);

export const getSitesSuccessAction = createAction(
  ProvisionerActionTypes.PROVISIONER_GET_SITES_SUCCESS,
  props<{ sites: Array<SiteListItem> }>()
);

export const getSitesFailureAction = createAction(
  ProvisionerActionTypes.PROVISIONER_GET_SITES_FAILURE
);

export const getSelectSiteAction = createAction(
  ProvisionerActionTypes.PROVISIONER_SELECT_SITE,
  props<{ siteId: string }>()
);

export const setProvisioningStatusAction = createAction(
  ProvisionerActionTypes.PROVISIONER_SET_PROVISIONING_STATUS,
  props<{ siteId: string; provisionedState: boolean }>()
);

export const setProvisioningStatusSuccessAction = createAction(
  ProvisionerActionTypes.PROVISIONER_SET_PROVISIONING_STATUS_SUCCESS,
  props<{ siteId: string; provisionedState: boolean }>()
);

export const setProvisioningStatusFailureAction = createAction(
  ProvisionerActionTypes.PROVISIONER_SET_PROVISIONING_STATUS_FAILURE
);

export const getDeviceStatus = createAction(
  ProvisionerActionTypes.PROVISIONER_GET_DEVICE_STATUS,
  props<{ siteId: string; provisioned: boolean }>()
);

export const getDeviceStatusSuccess = createAction(
  ProvisionerActionTypes.PROVISIONER_GET_DEVICE_STATUS_SUCCESS,
  props<{ inUse: boolean; isSetup: boolean }>()
);

export const getDeviceStatusFailure = createAction(
  ProvisionerActionTypes.PROVISIONER_GET_DEVICE_STATUS_FAILURE
);

export const provisionerActions = union({
  getSitesAction,
  getSitesSuccessAction,
  getSitesFailureAction,
  setProvisioningStatusAction,
  setProvisioningStatusSuccessAction,
  setProvisioningStatusFailureAction,
  getIsDeviceStatus: getDeviceStatus,
  getIsDeviceStatusSuccess: getDeviceStatusSuccess,
  getIsDeviceStatusFailure: getDeviceStatusFailure,
  getSelectSiteAction,
});
