<ng-container *transloco="let transl">
<h1 mat-dialog-title>{{ transl("notification.remove_subject_fail.header") }}</h1>
<div mat-dialog-content>
  <p>{{ transl("notification.remove_subject_fail.description") }}</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button color="primary" id="ok-button-id" (click)="handleOkClicked()">
    {{ transl("general.ok_upper_case") }}
  </button>
</div>
</ng-container>
