import { Action, createReducer, on } from '@ngrx/store';
import {
  initialProvisionerState,
  ProvisionerState,
} from '../provisioner.state';
import * as FromActions from '../actions/provisioner.actions';
import { DeviceStatus } from '@virtual-trials-workspace/models';
import { RootActions } from '@virtual-trials-workspace/store';

const reducer = createReducer(
  initialProvisionerState,
  on(FromActions.getSitesAction, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
    sites: undefined,
  })),
  on(RootActions.startVisitWithSubject, (state) => ({
    ...state,
    deviceStatus: undefined,
    selectedSiteId: undefined,
  })),
  on(RootActions.clearStorage, (state) => ({
    ...state,
    deviceStatus: undefined,
    selectedSiteId: undefined,
  })),
  on(FromActions.getSitesSuccessAction, (state, { sites }) => ({
    ...state,
    selectedSite: undefined,
    sites: sites,
    sitesLoaded: true,
    isLoading: false,
  })),
  on(FromActions.getSitesFailureAction, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(FromActions.setProvisioningStatusAction, (state, action) => ({
    ...state,
    isLoading: true,
  })),
  on(FromActions.getSelectSiteAction, (state, action) => ({
    ...state,
    selectedSiteId: action.siteId,
  })),
  on(FromActions.setProvisioningStatusSuccessAction, (state, action) => ({
    ...state,
    sites: state.sites.map((site) =>
      site.siteId !== action.siteId
        ? site
        : { ...site, provisioned: action.provisionedState }
    ),
    isLoading: false,
  })),
  on(FromActions.setProvisioningStatusFailureAction, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(FromActions.getDeviceStatus, (state, action) => ({
    ...state,
    deviceStatus: DeviceStatus.Checking,
    selectedSiteId: action.siteId,
    isSelectedDeviceProvisioned: action.provisioned,
    isLoading: true,
  })),
  on(FromActions.getDeviceStatusSuccess, (state, action) => {
    let deviceStatus: DeviceStatus;

    if (!action.isSetup) {
      deviceStatus = DeviceStatus.NotSetup;
    } else {
      deviceStatus = action.inUse ? DeviceStatus.InUse : DeviceStatus.Available;
    }
    return {
      ...state,
      deviceStatus: deviceStatus,
      isLoading: false,
    };
  })
);

export function provisionerReducer(
  state: ProvisionerState | undefined,
  action: Action
) {
  return reducer(state, action);
}
