<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  
  <!-- Header -->
  <vt-ui-subject-header></vt-ui-subject-header>
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>
  
  <!-- Activate Account screen START-->
  <main class="es-gateway-body">
    
        <div [ngSwitch]="activationStatus$ | async">
          <ng-container *ngSwitchCase="0">
            <vt-activate-pending-status
              [titleText]="transl('activate.status.in_progress.title')"
              [subTitleText]="transl('activate.status.in_progress.subtitle')"
            >
            </vt-activate-pending-status>
          </ng-container>
          <ng-container *ngSwitchCase="1">
            <vt-activate-success-status
              [titleText]="transl('activate.status.success')"
            ></vt-activate-success-status>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <vt-activate-home-form
              [submitInProgress]="submitInProgress"
              [apiError]="transl(apiError)"
              [formGroup]="formGroup"
              (continueClicked)="handleContinueClick()"
            >
              <div
                activeShortCode
                *ngIf="showShortCodeAlreadyActive"
                class="label-wrapper"
              >
                <span>
                  {{ transl('activate.status.already_active.part_one') }}
                  <a [routerLink]="['/login']">{{
                    transl('activate.status.already_active.login_link')
                  }}</a>
                  {{ transl('activate.status.already_active.part_two') }}
                </span>
              </div>
            </vt-activate-home-form>
          </ng-container>
        </div>
   
  </main>
  <!-- Activate Account screen END -->
</div>
