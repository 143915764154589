import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vt-site-journey-action',
  templateUrl: './subject-list-action.component.html',
  styleUrls: ['./subject-list-action.component.scss'],
})
export class SubjectListActionComponent {
  @Input()
  isRtl: boolean;

  @Input()
  isOpenClass: string;

  @Input()
  subjectName: string;

  @Output()
  removeSubject = new EventEmitter();

  @Output()
  callSubject = new EventEmitter();

  @Output()
  inviteSubject = new EventEmitter();

  @Output()
  mobileChevronLeftClick = new EventEmitter();

  @Output()
  mobileChevronRightClick = new EventEmitter();
}
