import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  LocalStorageService,
  ScopesService,
  TokenService,
  TOKEN_KEY,
} from '@virtual-trials-workspace/shared-core-services';
import {
  RootActions,
  RootSelectors,
  SessionState,
} from '@virtual-trials-workspace/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, first, flatMap, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private activatedRoute: ActivatedRoute,
    private scopesService: ScopesService,
    private tokenService: TokenService,
    private storageService: LocalStorageService,
    private router: Router,
    private store: Store<SessionState>
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const routeOfRequest = this.activatedRoute.snapshot['_routerState'].url;
    const excludeURLs = ['cdn.translation'];
    const excludeTransUrls = ['/translations/languages', '/translations/authorised'];
    const isFoundCDN = this.isMatchedFound(excludeURLs, request)
    const isFoundTranslationUrl = this.isMatchedFound(excludeTransUrls, request);
    const TOKEN_STORAGE_KEY = 'vv_token';
    const BEARER_TOEKN_KEY = 'Authorization';
    const tokenData = this.storageService.get(TOKEN_STORAGE_KEY);
    const gssoToken = tokenData ? `Bearer ${tokenData}` : null;
    if (
      (this.scopesService.routeRequiresToken(routeOfRequest) ||
        this.scopesService.resourceRequiresResource(request.url)) &&
      this.scopesService.resourceIsInternal(request.url)
    ) {
      const token: string = this.tokenService.getToken();
      if (token) {
        request = request.clone({
          headers: request.headers.set(TOKEN_KEY, `${token}`),
        });
      }
    }
    return this.store.pipe(select(RootSelectors.getParticipantType)).pipe(
      first(),
      flatMap((participantType) => {
        if (participantType !== 'subject' && gssoToken && !isFoundCDN) {
          request = request.clone({
            headers: request.headers.set(BEARER_TOEKN_KEY, `${gssoToken}`),
          });
        }
        return next.handle(request).pipe(
          filter((event) => event instanceof HttpResponse),
          filter((response: HttpResponse<any>) => response.ok),
          tap((okResponse: HttpResponse<any>) =>
            this.handleHttpResponse(okResponse)
          ),
          catchError((error: HttpErrorResponse) => {
            if (participantType !== 'subject' && window.location.pathname === '/gssologin' && !isFoundTranslationUrl ) {
              if (
                error.status === 403 ||
                error.status === 400 ||
                error.status === 401 ||
                error.status === 404 ||
                error.status === 500
              ) {
                this.handleHttpErrorResponse(error);
              }
            } else {
              if (error.status === 401) {
                this.router.navigateByUrl('/login');
              }
            }
            return throwError(error);
          })
        );
      })
    );
  }
  isMatchedFound = (excludeURLs, request): boolean => {
    return excludeURLs.some((element) => request.url.indexOf(element) > -1);
  }
  handleHttpErrorResponse = (errorResponse: HttpErrorResponse): void => {
    this.store.dispatch(
      RootActions.failureErrorMessage({
        genericErrorMessage: errorResponse?.error,
      })
    );
    this.router.navigateByUrl('/generalerror');
  };
  handleHttpResponse = (response: HttpResponse<any>): void => {
    if (!this.scopesService.tokenExcludeResource(response.url)) {
      const token: string = response.headers.get(TOKEN_KEY);
      token && this.tokenService.setToken(token);
    }
  };
}
