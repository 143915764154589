export enum SiteJourneyRoutes {
  CallDetails = 'call-details',
  SubjectList = 'subject-list',
  CreateAccount = 'create-account',
  InviteSubject = 'invite-subject',
  SendInviteCode = 'send-invite',
  SetupCall = 'setup-call',
  StartCall = 'start-call',
  EndCall = 'end-call',
  ErrorCall = 'error-call',
  SelectStudySite = 'select-study-site',
}

export const SiteModuleBaseRoute = 'sites';
