import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  BaseConfig,
  LanguageModel,
  LanguagesRootModel,
  ParticipantType,
  TranslationFile,
  TranslationRootModel,
} from '@virtual-trials-workspace/models';
import {
  ConfigurationService,
  LocalStorageService,
  RestApiService,
  TokenService,
} from '@virtual-trials-workspace/shared-core-services';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  skip,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { SessionState } from '../../sub-states';
import * as SessionActions from '../../actions/session.actions';
import * as SessionSelectors from '../../selectors/session.selectors';

@Injectable({
  providedIn: 'root',
})
export class LanguageHttpService implements OnDestroy {
  readonly defaultLanguage = 'en-US';
  readonly languagesResource = '/translations/languages';
  readonly translationResource = '/components/ui/translations';
  readonly setUserLanguageResource = '/account/setlanguage';
  readonly authorisedTranslationResourse = '/v2/translations/authorised';
  unauthorisedTranslationResourse = '/v2/translations/unauthorised';

  private _selectedLang: BehaviorSubject<string>;
  private _tokenChangeSub$: Subscription;
  private readonly unsubscribe$ = new Subject<void>();

  get selectedLanguageChanges$(): Observable<string> {
    return this._selectedLang && this._selectedLang.asObservable();
  }

  constructor(
    private restApiService: RestApiService,
    private http: HttpClient,
    private config: BaseConfig,
    private configService: ConfigurationService,
    private localStorageService: LocalStorageService,
    private store: Store<SessionState>,
    private tokenService: TokenService
  ) {
    this._selectedLang = new BehaviorSubject(this.getStoredLanguage());
  }

  getStoredLanguage = (): string => {
    const key = this.configService.userLanguageCodeStorageKey;

    return this.localStorageService.get(key);
  };

  setSelectedLanguage = (language: string): void => {
    this._selectedLang.next(language);
  };

  getSelectedLanguage = (): string => {
    return this._selectedLang.value;
  };

  storeLanguage = (languageCode: string): void => {
    const key = this.configService.userLanguageCodeStorageKey;
    this.localStorageService.save(languageCode, key);
  };

  updateSelectedLanguage(
    language: string,
    saveLanguageInAPI = true
  ): void {
    this.storeLanguage(language);
    this.setSelectedLanguage(language);

    this.store
      .pipe(
        select(SessionSelectors.getParticipantType),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((type: ParticipantType) => {
        const nonParticipantType = type === 'none';
        if (nonParticipantType) {
          this.saveLanguageChangeOnLoginPage(nonParticipantType);
        } else {
          if (saveLanguageInAPI) {
            this.setUserLanguage$(language).subscribe((res) => {
              this.clearLanguageChangeState();
              this.setDefaultLangCode(language);
            });
          }
        }
      });
  }
  setDefaultLangCode = (language): void => {
    this.store.dispatch(
      SessionActions.setDefaultLanguageCode({
        defaultSetLanguageCode: language,
      })
    );
  };
  getLanguageChangeState(): boolean {
    const key = this.configService.isUserChangedLanguageBeforeLogin;

    return this.localStorageService.get(key, true);
  }

  private clearLanguageChangeState(): void {
    const key = this.configService.isUserChangedLanguageBeforeLogin;
    this.localStorageService.delete(key, true);
  }

  private saveLanguageChangeOnLoginPage(isLanguageChanged: boolean): void {
    const key = this.configService.isUserChangedLanguageBeforeLogin;
    this.localStorageService.save<boolean>(isLanguageChanged, key, true);
  }

  getLanguages$ = (): Observable<LanguageModel[]> => {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append(
      'x-api-key',
      this.config.translationsApiKey
    );
    httpHeaders.set('Content-Type', 'application/json');
    const options = { headers: httpHeaders };
    return this.http
      .get<LanguagesRootModel>(
        `${this.config.baseApiEndpoint}${this.languagesResource}`,
        options
      )
      .pipe(
        map((response: LanguagesRootModel) => {
          return response.languages.sort(
            (a: LanguageModel, b: LanguageModel) =>
              a.displayOrder - b.displayOrder
          );
        }),
        catchError(this.errorHandler)
      );
  };

  errorHandler(error: HttpErrorResponse) {
    console.log(error.message);
      return of([]);
  }
  setUserLanguage$ = (languageCode: string): Observable<any> => {
    return this.restApiService.post<any>(this.setUserLanguageResource, {
      languageCode,
    });
  };

  getUITranslations$ = (
    language: string,
    fallback = false
  ): Observable<TranslationFile[]> => {
    const token: string = this.tokenService.getToken();
    const url =
      `${this.config.baseApiEndpoint}` +
      `${
        token
          ? this.authorisedTranslationResourse
          : this.unauthorisedTranslationResourse
      }` +
      `${
        fallback
          ? '?useFallback=' + fallback
          : language
          ? '?languageCode=' + language
          : ''
      }`;

    return this.http
      .get<TranslationRootModel>(url, {
        headers: token
          ? { token: token }
          : { 'x-api-key': this.config.translationsApiKey },
      })
      .pipe(
        take(1),
        map((response: TranslationRootModel) => {
            return this.mapTranslations(response.elements);
        }),
        catchError(this.errorHandler)
      );
  };

  private mapTranslations(elements: any[]): any {
    const flattened = {};
    elements.forEach((e) => {
      flattened[`${e.element}`] = e.translation;
    });
    return flattened;
  }
  public unSubscribeToTokenChange = (): void => {
    if (this._tokenChangeSub$) {
      this._tokenChangeSub$.unsubscribe();
    }
  };

  public subscribeToTokenChange = (): void => {
    if (!this._tokenChangeSub$ || this._tokenChangeSub$.closed) {
      const hasToken = new BehaviorSubject(false);
      this.tokenService.tokenChange$
        .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
        .subscribe((token) => hasToken.next(!!token));

      this._tokenChangeSub$ = hasToken
        .pipe(
          distinctUntilChanged(),
          skip(1),
          withLatestFrom(
            this.store.pipe(select(SessionSelectors.getSelectedLanguageCode))
          ),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(([_, languageCode]) => {
          if (!languageCode) {
            const userlang = this.localStorageService.get(
              this.configService.userLanguageCodeStorageKey
            );
            languageCode = userlang ? userlang : 'en-US';
          }

          this.store.dispatch(
            SessionActions.getTranslationFiles({
              language: languageCode,
            })
          );
        });
    }
  };

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
