<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  
  <!-- Header -->
<!--   
  <header class="es-gateway-header">
    <div class="header-inner">
      <div class="es-logo">
        <img class="logo-clario" src="/assets/images/clario-new-logo.svg" alt="Clario-logo">
      </div>
      <div class="product-name">{{ transl('user.account.title_proper_case') }}</div>
      <div class="ellipses-1"></div>
      <div class="ellipses-2"></div>
      <div class="ellipses-3"></div>
    </div>
  </header> -->
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select siteUser">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>

  <main class="es-gateway-body">
    <section
      *transloco="let transl"
      class="es-layout-columns -es-2-column es-gateway-column es-invite-code-layout -es-flex-horizontal"
    >
      <div class="es-column es-invite-code-column">
        <div class="es-content-box">
          <div class="es-panel es-custom-panel invite-code-panel">
            <div class="es-form">
              <div class="es-content-box">
                <div class="es-text-group">
                  <h2>{{ transl('site_user.subject.invite.send.invite_code') }}</h2>
                  <p>{{ transl('site_user.subject.invite.send.subject_invite_code') }}</p>
                  <h2 class="es-short-code">{{ subject.shortcode }}</h2>
                  <p>{{ transl('site_user.subject.invite.send.send_code_to_subject') }}</p>
                </div>
              </div>
              <div class="invite-code-form">
                  <vt-site-journey-code-form
                    [inviteCodeForm]="inviteCodeForm" 
                    [languages]="languages$ | async"
                    [showEmailAddressInvalidControlError]="emailControlHasEmailError"
                    [showMobilePhoneInvalidControlError]="
                      phoneControlHasInvalidFormatError
                    "
                    [showEmailRequiredControlError]="emailControlHasRequiredError"
                    [showPhoneRequiredControlError]="phoneControlHasRequiredError"
                    [showLanguageRequiredControlError]="languageControlRequiredError"
                    (emailInputClick)="handleEmailInputClick()"
                    (phoneInputClick)="handlePhoneInputClick()"
                  >
                  </vt-site-journey-code-form>
              </div>
              <div class="es-toolbar es-invite-code-toolbar">
                <ul class="es-right">
                  <li class="es-toolbar-item">
                    <button
                      id="skipButton"
                      class="es-button"
                      (click)="handleClickSkip()"
                    >
                      {{ transl('general.skip') }}
                    </button>
                  </li>
                  <li class="es-toolbar-item">
                    <button
                      id="sendButton"
                      class="es-button -es-primary"
                      [disabled]="selectionControlRequiredError ||
                      emailControlHasEmailError ||
                      emailControlHasRequiredError ||
                      phoneControlHasInvalidFormatError ||
                      phoneControlHasRequiredError ||
                      languageControlRequiredError
                      "
                      (click)="handleClickSend()"
                    >
                      {{ transl('general.send_notification') }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
