import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { WINDOW } from '@virtual-trials-workspace/shared-core-services';
import { Subject, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { VisitActions } from '@virtual-trials-workspace/store';

@Component({
  selector: 'vt-ui-request-control-dialog',
  templateUrl: './request-control-dialog.component.html',
  styleUrls: ['./request-control-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequestControlDialogComponent implements OnInit, OnDestroy{
  @HostBinding('class')
  private class = 'session-timeout-container';
  private _bodyOverflowValue: string;

  get siteMessage() {
    return this.data?.siteMessage;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private store: Store
  ) {}

  ngOnInit(): void {
    this._bodyOverflowValue = this.window.document.body.style.overflow;
    this.setBodyOverflowTo('hidden');
  }

  private setBodyOverflowTo = (value: string): void => {
    this.window.document.body.style.overflow = value;
  };

  ngOnDestroy(): void {
    this.setBodyOverflowTo(this._bodyOverflowValue);
  }

  handleRequestOKClicked(): void {
    this.store.dispatch(VisitActions.emulationRequestControl());
  }

  handleDeclineClicked(): void {
    this.store.dispatch(VisitActions.emulationDeclineTransferRequest());
  }
}
  