import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConfigurationService,
  PlatformService,
} from '@virtual-trials-workspace/shared-core-services';

@Injectable({
  providedIn: 'root',
})
export class PlatformGuard  {
  constructor(
    private platformService: PlatformService,
    private router: Router,
    private configService: ConfigurationService
  ) {}

  canActivate(): boolean | Promise<boolean> {
    return this.isValid()
      ? true
      : this.router.navigate(['.', 'error', 'platform'], {
          skipLocationChange: true,
        });
  }

  canLoad(): boolean | Promise<boolean> {
    return this.canActivate();
  }

  private isValid = (): boolean => {
    return !this.platformService.isIOSFirefox() && 
    (
      this.platformService.isChrome()
        && this.platformService.getClient().version.equalsOrHigher(
          this.configService.minimumChromeVersion)
      ||
      this.platformService.isSafari()
        && this.platformService.getClient().version.equalsOrHigher(
          this.configService.minimumSafariVersion)
    );
  };
}
