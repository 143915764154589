import { createAction, props, union } from '@ngrx/store';

import { SecurityQuestion } from '@virtual-trials-workspace/models';
import { RootUtils as StoreUtils } from '@virtual-trials-workspace/store';

export const requestResetPassword = createAction(
  '[Account] Request Reset Password',
  props<{ username: string, languageCode: string }>()
);

export const requestResetPasswordSuccess = createAction(
  '[Account] Request Reset Password Success'
);

export const requestResetPasswordFail = StoreUtils.createHttpErrorAction(
  '[Account] Request Reset Password Fail'
);

export const getSecurityQuestion = createAction(
  '[Account] Get Security Question',
  props<{ passwordResetToken: string }>()
);

export const getSecurityQuestionSuccess = createAction(
  '[Account] Get Security Question Success',
  props<{ question: SecurityQuestion }>()
);

export const getSecurityQuestionFail = StoreUtils.createHttpErrorAction(
  '[Account] Get Security Question Fail'
);

export const resetPassword = createAction(
  '[Account] Reset Password',
  props<{ questionId: string; answer: string; password: string }>()
);

export const resetPasswordSuccess = createAction(
  '[Account] Reset Password Success'
);

export const resetPasswordFail = StoreUtils.createHttpErrorAction(
  '[Account] Reset Password Fail'
);

const accountActions = union({
  requestResetPassword,
  requestResetPasswordSuccess,
  requestResetPasswordFail,
  getSecurityQuestion,
  getSecurityQuestionSuccess,
  getSecurityQuestionFail,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
});

export type AccountActionsUnion = typeof accountActions;
