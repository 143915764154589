import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { VisitFacadeService } from '../visit/facade/visit-facade.service';

@Component({
  selector: 'vt-standalone-visit-end',
  templateUrl: './visit-end.component.html',
  styleUrls: ['./visit-end.component.scss'],
  providers: [VisitFacadeService],
  encapsulation: ViewEncapsulation.None,
})
export class VisitEndComponent {
  @HostBinding('class')
  private class = 'visit-end-container';

  get hasVisitTimedOut$(): Observable<boolean> {
    return this.visitFacadeService.hasVisitTimedOut$;
  }

  constructor(private visitFacadeService: VisitFacadeService) {}
}
