import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { BaseConfig, ParticipantType } from '@virtual-trials-workspace/models';
import {
  LocalStorageService,
  RestApiService,
} from '@virtual-trials-workspace/shared-core-services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionCode } from '../models/session-code.enum';
import { GssoUserInfo, User, UserInfo, UserRoleInfo } from '../models/user';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class GssoService {
  private readonly TOKEN_URL_PARAMETER = 'access_token';
  private readonly EXPIRES_IN_URL_PARAMETER = 'expires_in';
  private readonly TOKEN_STORAGE_KEY = 'vv_token';
  private readonly TOKEN_EXPIRES_STORAGE_IN_KEY = 'vv_token_expires_in';
  private readonly ID_TOKEN_PARAMETER = 'id_token';
  private readonly ID_TOKEN_KEY = 'id_token';
  private readonly validateGssoUrl = '/account/validategsso';
  constructor(
    private config: BaseConfig,
    private storageService: LocalStorageService,
    private restApiService: RestApiService,
    private httpClient: HttpClient,
    private router: Router,
    private location: Location
  ) {}

  get token(): string {
    const tokenData = this.storageService.get(this.TOKEN_STORAGE_KEY);
    return tokenData ? tokenData : null;
  }

  isAuthenticated$(): Observable<boolean> {
    const url = window.location.href;
    if (this.isGssoUrl(url)) {
      return of(true);
    }

    if (this.token) {
      return this.httpClient.get(this.config.auth.checkSessionUrl).pipe(
        map((response: any) => response.code === SessionCode.Success),
        catchError(() => of(false))
      );
    }

    return of(false);
  }

  authenticate$(): Observable<User | undefined> {
    const url = window.location.href;

    // If we got redirected from GSSO, process parameters
    if (this.isGssoUrl(url)) {
      const { token, expiresIn } = this.parseGssoParameters(
        window.location.href
      );
      this.storageService.save(token, this.TOKEN_STORAGE_KEY);
      this.storageService.save(expiresIn, this.TOKEN_EXPIRES_STORAGE_IN_KEY);

      // Replaces current url string
      const urlTree = this.router.createUrlTree(['/gssologin']);
      this.location.replaceState(urlTree.toString());
      if (token) return this.getUserFromToken$(token);
    }

    return this.getUserFromToken$(this.token);
  }

  getUserRoles$(): Observable<UserRoleInfo[]> {
    return this.httpClient
      .get<GssoUserInfo>(`${this.config.auth.rolesUrl}`)
      .pipe(
        map((response) => {
          return response?.roles;
        })
      );
  }

  getUserFromToken$(token: string): Observable<User | undefined> {
    const userInfo = this.tryDecodeToken(token);
    const mapToUser = (roles: UserRoleInfo[]) => {
      const { name, email } = userInfo;
      return new User({ name, email, roles });
    };
    return of(undefined);
    //return userInfo ? this.getUserRoles$().pipe(map(mapToUser)) : of(undefined);
  }

  validateGssoToken$(): Observable<{
    participantId: string;
    participantType: ParticipantType;
    encryptionKey: string;
    languageCode: string;
  }> {
    return this.restApiService.get(this.validateGssoUrl);
  }

  private tryDecodeToken(token: string): any {
    try {
      return jwt_decode<UserInfo>(token);
    } catch (e) {
      console.error(e);
    }
  }

  private isGssoUrl(url: string): boolean {
    return (
      url.includes(this.TOKEN_URL_PARAMETER) &&
      url.includes(this.EXPIRES_IN_URL_PARAMETER)
    );
  }

  private parseGssoParameters(
    url: string
  ): { token: string | null; expiresIn: string | null } {
    const httpParams = new HttpParams({
      fromString:
        url.split('#').length > 0
          ? url.split('#')[url.split('#').length - 1]
          : undefined,
    });
    const token = httpParams.get(this.TOKEN_URL_PARAMETER);
    const expiresIn = httpParams.get(this.EXPIRES_IN_URL_PARAMETER);

    const id_token = httpParams.get(this.ID_TOKEN_PARAMETER);
    this.storageService.save(id_token, this.ID_TOKEN_KEY);

    return { token, expiresIn };
  }
}
// function jwt_decode<T>(token: string): UserInfo {
//   throw new Error('Function not implemented.');
// }
