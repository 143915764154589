import { Injectable } from '@angular/core';
import { Token } from '@virtual-trials-workspace/models';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocalStorageService } from '../local-storage/local-storage.service';

export const TOKEN_KEY = 'token';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private _tokenChange$: BehaviorSubject<string>;

  get tokenChange$(): Observable<string> {
    return this._tokenChange$.asObservable();
  }

  constructor(private localStorageService: LocalStorageService) {
    this._tokenChange$ = new BehaviorSubject<string>(this.getToken());
  }
  removeToken = () => {
    this.localStorageService.delete(TOKEN_KEY, false);
    this._tokenChange$.next(null);
  };
  getToken = (): string => {
    return this.localStorageService.get(TOKEN_KEY);
  };

  setToken = (token: string): void => {
    this.localStorageService.save(token, TOKEN_KEY);
    this._tokenChange$.next(token);
  };

  parse = (token: string): Token => {
    const tokenBodyIndex = 1;
    const tokenBodyRaw = token.split('.')[tokenBodyIndex];
    const tokenBody = JSON.parse(atob(tokenBodyRaw));

    return new Token({
      content: JSON.parse(tokenBody.tokenContent),
      expiresAt: new Date(tokenBody.exp * 1000)
    });
  }
}
