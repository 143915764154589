import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VisitService } from '@virtual-trials-workspace/models';

import { VisitState, VISIT_STATE_KEY } from '../../sub-states';

export const getVisitState = createFeatureSelector<VisitState>(VISIT_STATE_KEY);

export const getHasMediaDevicesPermissions = createSelector(
  getVisitState,
  (state: VisitState) => state.hasMediaDevicesPermissions
);

export const hasOnlyEmulation = (services: Array<VisitService>): boolean => {
  return services.some((s) => s.type === 'Emulation') && services.length === 1;
};

export const getHasVideoCallServiceWithPermissions = createSelector(
  getHasMediaDevicesPermissions,
  getVisitState,
  (hasPermissions: boolean, state: VisitState) => {
    return hasPermissions && !hasOnlyEmulation(state.services);
  }
);

export const getHasOnlyEmulationService = createSelector(
  getVisitState,
  (state: VisitState) => hasOnlyEmulation(state.services)
);

export const getHasActiveVisit = createSelector(
  getVisitState,
  (state: VisitState) => state.hasActiveVisit
);

export const getVisitUiState = createSelector(
  getVisitState,
  (state: VisitState) => state.uiState
);

export const getSelectedServiceType = createSelector(
  getVisitState,
  (state: VisitState) => state.selectedServiceType
);

export const getAllVisitServicesServerReady = createSelector(
  getVisitState,
  (state: VisitState) => state.services.every((s) => s.serverStatus === 'ready')
);

export const getVisitEnd = createSelector(
  getVisitState,
  (state: VisitState) => state.visitEnd
);

export const getReadyToJoinVisit = createSelector(
  getVisitState,
  (state: VisitState) => state.readyToJoinVisit
);

export const gettransferControlResult = createSelector(
  getVisitState,
  (state: VisitState) => state.transferControlResult
);
