<ng-container *transloco="let transl">
  <div class="es-gateway">
    <button #myButton id="refresh" value="refresh" [hidden]="true">
      &nbsp; Refresh
    </button>
    <div (window:resize)="onResize()">
      <div *ngIf="!isValidResolution && !displayVisitEndSheet &&          
                  selectedServiceView === 'emulation'" class="platform-error-container">
        <div
          class="es-lang-select siteUser"
          fxLayout="row"
          fxLayoutAlign="flex-end"
        >
          <div class="logo"></div>
          <vt-ui-language-select
            [setUserLanguage]="false"
          ></vt-ui-language-select>
        </div>
        <div class="es-content-box">
          <div
            class="content es-panel es-custom-panel"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <mat-icon
              class="icon"
              aria-hidden="false"
              aria-label="platform error"
              >error_outline</mat-icon
            >
            <span class="text">{{
              transl(errorMessageKey, { fallback: errorMessage })
            }}</span>
            <span class="text">{{
              transl(resolutionMessageKey, { fallback: resolutionMessage })
            }}</span>
          </div>
        </div>
      </div>
      <div >
        <ng-container *ngIf="{ value: isLoading$ | async } as loadingContext">
          <ng-container *ngIf="loadingContext.value">
            <vt-ui-loading-overlay></vt-ui-loading-overlay>
          </ng-container>

          <ng-container *ngIf="!failedVisitService">
            <ng-container
              *ngTemplateOutlet="
                pageHeaderTempl;
                context: { services: visitServices$ | async }
              "
            ></ng-container>

            <ng-container *ngIf="canShowProvisioningSheet">
              <vt-visit-end-provisioner-sheet
                [@fade]
                [retryMessage]="retryProvisioningMessage"
                (YesClick)="handleSetAsProvisioned()"
                (NoClick)="handleSkipProvisioned()"
              >
              </vt-visit-end-provisioner-sheet>
            </ng-container>
            <ng-container *ngIf="showSocketFailedSheet">
              <vt-visit-socket-failed-sheet
                [@introFade]
                [failedVisitService]="failedVisitService"
                (acknowledged)="handleLogout()"
              ></vt-visit-socket-failed-sheet>
            </ng-container>

            <ng-container
              *ngTemplateOutlet="pageMainContentTempl"
            ></ng-container>
          </ng-container>
          <ng-template #pageMainContentTempl>
            <div *ngIf="{ value: !hasVisitEnded$ | async } as visitEndContext">
              <div
                [ngStyle]="{
                  display:
                    selectedServiceView === 'emulation' && emulationCanRender
                      ? 'block'
                      : 'none'
                }"
              >
                <div
                  *ngIf="{ value: hasDeviceControl$ | async } as controlContext"
                  class=""
                >
                  <vt-ui-emulation-device
                    [deviceId]="deviceId"
                    [hidden]="(connectedToDeviceSession$ | async) === false || !isValidResolution" 
                    [errored]="emulationServiceLost$ | async"
                    [networkLost]="emulationConnectionLost$ | async"
                  >
                    <!-- <div
                      vtUITopDevicePanel
                      *ngIf="
                        !isEcoaLiveStandalone &&
                        !isProvisioner &&
                        (emulationServiceLost$ | async) !== true
                      "
                    >
                      <ng-container
                        *ngTemplateOutlet="emulationMenu"
                      ></ng-container>
                    </div> -->
                    <div
                      vtUIBottomDevicePanel
                      class="ecoaStandaloneVisit"
                      *ngIf="isEcoaLiveStandalone || isProvisioner"
                    >
                      <ng-container
                        *ngTemplateOutlet="standaloneEmulationMenu"
                      ></ng-container>
                    </div>
                  </vt-ui-emulation-device>

                  <div
                    *ngIf="
                      !controlContext.value &&
                      !!deviceControlStatusText &&
                      (emulationServiceLost$ | async) !== true
                      && isValidResolution
                      "
                  >
                    <vt-visit-device-control-info
                      [bodyText]="transl(deviceControlStatusText)"
                    >
                    </vt-visit-device-control-info>
                  </div>
                </div>
              </div>

              <div
                [ngStyle]="{
                  display:
                    selectedServiceView === 'video-call' ? 'block' : 'none'
                }"
              >
                <div
                  #remoteView
                  id="{{ remoteViewId }}"
                  [ngClass]="vidyoHiddenClass"
                  class="visit-service-wrapper"
                ></div>
                <div
                  #localView
                  id="{{ localViewId }}"
                  data-testid="local-view-id"
                  [ngClass]="vidyoHiddenClass"
                  class="visit-call-local-preview-container"
                ></div>
              </div>

              <section
                *ngIf="{ value: askUserForPermissions$ | async } as context"
              >
                <vt-visit-device-permissions-sheet
                  *ngIf="context.value && !loadingContext.value"
                  [@introFade]
                  [declinesBrowserPermission]="declinesBrowserPermission"
                  (acceptAccessDevices)="handleAcceptAccess()"
                  (declinesAccessDevices)="handleDeclinesAccessDevices()"
                  (leaveVisit)="handleLeaveVisit()"
                  (startAgain)="handleStartAgain()"
                ></vt-visit-device-permissions-sheet>

                <ng-container
                  *ngIf="{
                    value: showSendInviteSheet$ | async
                  } as inviteContext"
                >
                  <vt-visit-send-invitation-sheet
                    [@fade]
                    *ngIf="inviteContext.value && !loadingContext.value"
                    (sendInvitationClicked)="handleSendInvite($event)"
                  ></vt-visit-send-invitation-sheet>
                </ng-container>
              </section>
            </div>
          </ng-template>

          <ng-container *ngIf="failedVisitService">
            <div fxLayout="row" fxLayoutAlign="flex-end" class="pad-24">
              <vt-ui-language-select
                [setUserLanguage]="false"
              ></vt-ui-language-select>
            </div>
            <vt-visit-service-failed-sheet
              [@introFade]
              [failedVisitService]="failedVisitService"
              (acknowledged)="handleLeaveVisit()"
            ></vt-visit-service-failed-sheet>
          </ng-container>

          <vt-visit-end-sheet
            *ngIf="displayVisitEndSheet"
            [timedOut]="true"
            (closed)="visitEndAlert$.next(true)"
          >
          </vt-visit-end-sheet>
        </ng-container>
      </div>
    </div>
    <ng-template #pageHeaderTempl let-services="services">
      <ng-container>
        <vt-visit-header
          [showControls]="vidyoCanRender || emulationCanRender"
          [showSelectedServiceControl]="services === 'all'"
          [selectedServiceView]="selectedServiceView"
          [selectedServiceState]="selectedServiceState"
          [emulationDeviceConnected]="connectedToDeviceSession$ | async"
          [participantType]="participantType$ | async"
          [isEcoaLiveStandalone]="isEcoaLiveStandalone"
          [isProvisioner]="isProvisioner"
          (toggleService)="toggleServiceView()"
          (sendInvite)="handleSendInviteMenuClick()"
        >
          <div *ngIf="hasDeviceControl$ | async" standaloneControls>
            <div
              vtUIBottomDevicePanel
              fxLayout="row"
              *ngIf="isEcoaLiveStandalone || isProvisioner"
            >
              <ng-container
                *ngTemplateOutlet="standaloneEmulationMenu"
              ></ng-container>
            </div>
            
          </div>
        </vt-visit-header>
      </ng-container>
    </ng-template>
      <div class="transfer-control-button es-custom-visit-btn" *ngIf="
      !isEcoaLiveStandalone &&
      !isProvisioner &&
      (emulationServiceLost$ | async) !== true &&
      selectedServiceView === 'emulation' &&
      isValidResolution
    ">
        <button
          *ngIf="(hasDeviceControl$ | async) === true"
          id="transfer-control-button-id"
          class="es-button"
          (click)="handleTransferControl()"
        >
          <div class="callIcons transfer_icon"></div>
          {{ transl('visit.site.transfer_control') }}
        </button>
      </div>

    <ng-template #standaloneEmulationMenu>
      <ng-container *ngIf="(emulationServiceLost$ | async) !== true">
        <div *ngIf="visitCode" class="visit-code-url es-custom-visit-btn">
          <button
            id=""
            class="es-button"
            (click)="copyVisitCodeToClipboard()"
            data-tooltip="{{ transl('visit.site.copy_link_content_tooltip') }}"
          >
            <div class="callIcons copy_url_icon"></div>
            {{ ' ' + transl('visit.site.copy_link') + ' - ' + visitCode }}
          </button>
        </div>

        <div
          *ngIf="!isProvisioner && selectedServiceView === 'emulation' && emulationCanRender"
          class="transfer-control-button es-custom-visit-btn"
        >
          <button
            id="transfer-control-button-id"
            class="es-button"
            (click)="handleTransferControl()"
          >
            <div class="callIcons transfer_icon"></div>
            {{ transl('visit.site.transfer_control') }}
          </button>
        </div>
      </ng-container>
    </ng-template>
    <div>
      <div
        class="end-visit-button es-custom-visit-btn end-visit-button"
        *ngIf="
          (!canShowProvisioningSheet &&
            (vidyoCanRender || emulationCanRender) &&
            (hasDeviceControl$ | async)) ||
          (showVideoCallView && vidyoCanRender)
        "
      >
        <button id="" class="es-button -es-danger" (click)="handleEndVisit()">
          <div class="callIcons call_end_icon"></div>
          {{ transl('visit.site.end_session') }}
        </button>
      </div>
      <div class="video-call-actions">
        <ng-container *ngIf="showVideoCallView && vidyoCanRender">
          <vt-visit-video-call-actions
            *ngIf="{ value: mediaDeviceStates$ | async } as context"
            [micIsEnabled]="context.value.mic"
            [videoIsEnabled]="context.value.video"
            (micButtonClick)="handleToggleMic(context.value.mic)"
            (videoButtonClick)="handleToggleVideo(context.value.video)"
            (switchButtonClick)="handleSwitchCamera()"
          >
          </vt-visit-video-call-actions>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
