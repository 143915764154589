<div class="es-form-field" *transloco="let transl">
  <label class="es-label -es-required">{{transl('user.account.login_password_placeholder')}}</label>
  <input class="es-input" [placeholder]="transl('user.account.enter_password')" [formControl]="passwordFormControl"
    [type]="hidePassword ? 'password' : 'text'" [ngClass]="{'-es-invalid':
      (passwordControlRequiredError ||
      passwordControlCriteriaError) &&
      passwordFormControl.touched
    }" />

  <!-- VVC-3798 -->
  <!-- <button
    id="hidePasswordButton"
    (click)="hidePassword = !hidePassword"
    attr.aria-label="Hide password"
    [attr.aria-pressed]="hidePassword"
  >
    <div class="error-icon" id="passwordIcon">
      {{hidePassword ? 'visibility_off' : 'visibility'}}
    </div>
  </button> -->

  <div *ngIf="passwordControlRequiredError && passwordFormControl.touched" class="es-form-message  -es-invalid">
    <div class="error-icon"></div>
    <p>{{ transl('error.reset_password.confirm_password.password_required') }}</p>
  </div>
  <div *ngIf="passwordControlCriteriaError && !passwordControlRequiredError" class="es-form-message  -es-invalid">
    <div class="error-icon"></div>
    <p>{{ transl('error.reset_password.confirm_password.incorrect_password') }}</p>
  </div>
</div>

<div class="es-form-field" *transloco="let transl">
  <label class="es-label -es-required">{{transl('user.account.re_enter_password')}}</label>
  <input class="es-input" [placeholder]="transl('user.account.re_enter_password')"
    [formControl]="confirmPasswordControl" [type]="hideConfirmPassword ? 'password' : 'text'" [ngClass]="{'-es-invalid':
      (confirmPasswordControlRequiredError ||
      confirmPasswordControlMatchError) &&
      confirmPasswordControl.touched
    }" />

  <!-- VVC-3798 -->
  <!-- <button
    id="hideConfirmPasswordButton"
    (click)="hideConfirmPassword = !hideConfirmPassword"
    attr.aria-label="Hide confirm password"
    [attr.aria-pressed]="hideConfirmPassword"
  >
    <div class="error-icon" id="confirmPasswordIcon">
      {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
    </div>
  </button> -->

  <div *ngIf="confirmPasswordControlRequiredError && confirmPasswordControl.touched"
    class="es-form-message  -es-invalid">
    <div class="error-icon"></div>
    <p>{{ transl('error.reset_password.confirm_password.confirm_password_required') }}</p>
  </div>
  <div *ngIf="confirmPasswordControlMatchError && !confirmPasswordControlRequiredError"
    class="es-form-message  -es-invalid">
    <div class="error-icon"></div>
    <p>{{ transl('error.reset_password.confirm_password.confirm_password_not_match') }}</p>
  </div>
</div>