import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthPageTranslationsGuard,
  UnauthPageTranslationsGuard,
} from '@virtual-trials-workspace/shared-guards';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    component: ForgotPasswordComponent,
    path: 'forgot-password',
    canActivate: [UnauthPageTranslationsGuard],
  },
  {
    component: ResetPasswordComponent,
    path: 'reset-password',
    canActivate: [AuthPageTranslationsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
