<button
  id="selected-service-button-id"
  data-testid="selected-service-button-id"
  aria-label="icon-button for which service is selected"
  mat-icon-button
  [autofocus]="false"
  (click)="handleServiceClick()"
  class="selected-service-button"
>
  <!-- <div class="icon" [animAted]="{ time: 800, animation: 'flipInX' }"> -->
    <div class="icon">
    <mat-icon
      id="selected-service-icon-id"
      data-testid="selected-service-icon-id"
      [ngClass]="iconClass"
      >{{
        selectedService === 'video-call' ? emulatorIcon : videoCallIcon
      }}</mat-icon
    >
  </div>
  <div *ngIf="showLiveIndicator" class="live-indicator"></div>
</button>
