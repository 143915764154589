import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { select, Store } from '@ngrx/store';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { concatMap, delay, filter, takeUntil } from 'rxjs/operators';

import { HttpError, ParticipantType } from '@virtual-trials-workspace/models';
import * as FormUtils from '@virtual-trials-workspace/shared-utils';

import { CreateAccountStatus } from '../models';
import * as FromStore from '../store';

@Component({
  selector: 'vt-activate-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  apiError: string;

  particpantType: ParticipantType;
  submitInProgress: boolean;

  createAccountStatusSubject$ = new BehaviorSubject<CreateAccountStatus>(
    CreateAccountStatus.RequireUserInput
  );

  readonly passwordRequiredErrorMessage = 'Please enter your password';
  readonly userNameRequiredErrorMessage = 'Please enter your user name';
  readonly emailRequiredErrorMessage = 'Please enter your email';
  readonly emailPatternErrorMessage = 'Please enter a valid email address';
  readonly passwordsDontMatchErrorMessage = 'Passwords do not match';
  readonly confirmPasswordRequiredErrorMessage =
    'Please re-enter your password';
  readonly passwordDoesntMeetCriteriaErrorMessage =
    'Password does not meet the necessary requirements';
  readonly unrecognisedUsernameApiErrorMessage =
    'The username entered is not recognised for this account';

  readonly twoSecondDelay = 2000;

  // since regex doesn't support AND use the OR to match invalid cases and then invert the test
  // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
  readonly passwordRegExp = new RegExp(
    /^(.{0,7}|[^0-9]*|[^a-zA-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/
  );

  private readonly unsubscribe$ = new Subject<void>();

  get createAccountStatus$(): Observable<CreateAccountStatus> {
    return this.createAccountStatusSubject$.asObservable();
  }

  get getUserNameControl(): AbstractControl {
    return this.siteFormGroup.controls.userName;
  }

  get getUserPasswordControl(): AbstractControl {
    return this.siteFormGroup.controls.password;
  }

  get getSubjectEmailControl(): AbstractControl {
    return this.subjectFormGroup.controls.email;
  }

  get getSubjectPasswordControl(): AbstractControl {
    return this.subjectFormGroup.controls.password;
  }

  get isSubjectUser(): boolean {
    return this.particpantType === 'subject';
  }

  siteFormGroup = new UntypedFormGroup({
    userName: new UntypedFormControl(undefined, [Validators.required]),
    password: new UntypedFormControl(undefined, [
      Validators.required,
      FormUtils.doesNotMatchPasswordRegex(),
      this.matchPassword(),
    ]),
    confirmPassword: new UntypedFormControl(undefined, [
      Validators.required,
      this.matchValues('password'),
    ]),
  });

  subjectFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(undefined, [Validators.required, Validators.email]),
    password: new UntypedFormControl(undefined, [
      Validators.required,
      FormUtils.doesNotMatchPasswordRegex(),
      this.matchPassword(),
    ]),
    confirmPassword: new UntypedFormControl(undefined, [
      Validators.required,
      this.matchValues('password'),
    ]),
  });

  constructor(private store: Store<FromStore.ActivateState>) {}

  ngOnInit(): void {
    this.initGetParticipantTypeSubscription();
    this.initSubscriptionToApiError();
    this.initCreateAccountStatusSubscription();
    this.initCreateAccountStatusCompleteSubscription();
    this.resetSitesFormFromApiError();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  matchValues(
    matchTo: string
  ): (c: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { passwordsDontMatch: true };
    };
  }

  matchPassword(): (c: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        !FormUtils.passwordRegExp.test(control.value)
        ? null
        : { passwordInvalid: true };
    };
  }

  handleSiteCreateAccountClick = (): void => {
    this.submitInProgress = true;

    setTimeout(() => {
      this.createAccountStatusSubject$.next(
        CreateAccountStatus.CreatingAccount
      );
      this.store.dispatch(
        FromStore.Actions.createSiteUserAccount({
          password: this.getUserPasswordControl.value,
          username: this.getUserNameControl.value,
        })
      );
    }, this.twoSecondDelay);
  };

  handleSubjectCreateAccountClick = (): void => {
    this.submitInProgress = true;

    setTimeout(() => {
      this.createAccountStatusSubject$.next(
        CreateAccountStatus.CreatingAccount
      );
      this.store.dispatch(
        FromStore.Actions.createSubjectUserAccount({
          email: this.getSubjectEmailControl.value,
          password: this.getSubjectPasswordControl.value,
        })
      );
    }, this.twoSecondDelay);
  };

  private initCreateAccountStatusSubscription = (): void => {
    this.store
      .pipe(
        select(FromStore.Selectors.getCreateAccountStatus),
        takeUntil(this.unsubscribe$),
        concatMap((status) => of(status).pipe(delay(this.twoSecondDelay)))
      )
      .subscribe((status) => this.createAccountStatusSubject$.next(status));
  };

  private initGetParticipantTypeSubscription = (): void => {
    this.store
      .pipe(
        select(FromStore.Selectors.getParticipantType),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((participantType) => {
        this.particpantType = participantType;
      });
  };

  private initCreateAccountStatusCompleteSubscription = (): void => {
    this.createAccountStatus$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(
          (status) => status === CreateAccountStatus.AccountCreatedSuccess
        ),
        concatMap((status) => of(status).pipe(delay(this.twoSecondDelay)))
      )
      .subscribe(() =>
        this.store.dispatch(FromStore.Actions.createAccountComplete())
      );
  };

  private initSubscriptionToApiError = (): void => {
    this.store
      .pipe(select(FromStore.Selectors.getError), takeUntil(this.unsubscribe$))
      .subscribe((error) => this.handleApiErrors(error));
  };

  private handleApiErrors = (error: HttpError): void => {
    if (error?.code === 400) {
      this.apiError = this.unrecognisedUsernameApiErrorMessage;
    }

    this.submitInProgress = false;
  };

  private resetSitesFormFromApiError = (): void => {
    this.getUserNameControl.valueChanges
      .pipe(
        filter(() => !!this.apiError),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.handleResetFormFromApiError());
  };

  private handleResetFormFromApiError = (): void => {
    this.submitInProgress = false;
    this.apiError = undefined;
    this.getUserNameControl.markAsTouched();
  };
}
