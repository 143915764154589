import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SecurityQuestion } from '@virtual-trials-workspace/models';
import * as FormUtils from '@virtual-trials-workspace/shared-utils';

import { SecurityQuestionAnswer } from '../models';
import * as FromStore from '../store';
import { QuestionsFilterService } from './questions-filter-service/questions-filter.service';
import { Location } from '@angular/common';

@Component({
  selector: 'vt-activate-security-questions',
  templateUrl: './set-security-questions.component.html',
  styleUrls: ['./set-security-questions.component.scss'],
})
export class SetSecurityQuestionsComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;

  isLoading$ = this.store.pipe(select(FromStore.Selectors.getIsLoading));

  question1Options$: Observable<Array<SecurityQuestion>>;
  question2Options$: Observable<Array<SecurityQuestion>>;
  question3Options$: Observable<Array<SecurityQuestion>>;

  private dataFilterService: QuestionsFilterService;

  get answerFieldValidators(): Validators {
    return [
      Validators.required,
      Validators.maxLength(256),
      Validators.minLength(2),
    ];
  }

  constructor(private store: Store<FromStore.ActivateState>,
    private location: Location) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.initStore();
  }

  private initStore = (): void => {
    this.store
      .pipe(
        select(FromStore.Selectors.getSecurityQuestions),
        filter((questions) => questions.length > 0)
      )
      .subscribe((questions) => this.initQuestionStreams(questions));

    this.store.dispatch(FromStore.Actions.getSecurityQuestions());
  };

  private initQuestionStreams = (questions: Array<SecurityQuestion>): void => {
    this.dataFilterService = QuestionsFilterService.create(
      questions,
      this.formGroup
    );
    this.question1Options$ = this.dataFilterService.questionsForFirstControl$;
    this.question2Options$ = this.dataFilterService.questionsForSecondControl$;
    this.question3Options$ = this.dataFilterService.questionsForThirdControl$;
  };

  private initFormGroup = (): void => {
    this.formGroup = new UntypedFormGroup({
      question1: new UntypedFormControl(undefined, Validators.required),
      question1Answer: new UntypedFormControl(undefined, this.answerFieldValidators),
      question2: new UntypedFormControl(undefined, Validators.required),
      question2Answer: new UntypedFormControl(undefined, this.answerFieldValidators),
      question3: new UntypedFormControl(undefined, Validators.required),
      question3Answer: new UntypedFormControl(undefined, this.answerFieldValidators),
    });
  };

  getFormControl = (name: string): AbstractControl => {
    return this.formGroup.controls[name];
  };

  questionControlHasErrors = (name: string): boolean => {
    return !!this.getFormControl(name)?.errors;
  };

  answerControlHasRequiredError = (name: string): boolean => {
    return this.getFormControl(name).errors?.required;
  };

  answerControlHasLengthError = (name: string): boolean => {
    const errorsForControl = this.getFormControl(name)?.errors;
    return !!errorsForControl?.minlength || !!errorsForControl?.maxlength;
  };

  handleFormSubmit = (): void => {
    this.formGroup.valid
      ? this.setSecurityQuestions()
      : FormUtils.runFormFieldValidation(this.formGroup);
  };

  private setSecurityQuestions = (): void => {
    this.store.dispatch(
      FromStore.Actions.setSecurityQuestionsAnswers({
        payload: this.getQuestionAnswersFromFormValues(),
      })
    );
  };

  private getQuestionAnswersFromFormValues = (): Array<
    SecurityQuestionAnswer
  > => {
    return [
      {
        answer: this.getFormControl('question1Answer').value,
        questionId: this.getFormControl('question1').value,
      },
      {
        answer: this.getFormControl('question2Answer').value,
        questionId: this.getFormControl('question2').value,
      },
      {
        answer: this.getFormControl('question3Answer').value,
        questionId: this.getFormControl('question3').value,
      },
    ];
  };

  handleCancelClick() {
    this.location.back();
  }
  ngOnDestroy() {
    if (this.dataFilterService) {
      this.dataFilterService.unsubscribe();
    }
  }
}
