import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouteReducerState, ROUTER_STATE_KEY } from '../router';

export const getRouterReducerState = createFeatureSelector<RouteReducerState>(
  ROUTER_STATE_KEY
);

export const getRouteState = createSelector(
  getRouterReducerState,
  (routerReducerState) => routerReducerState.state
);
