import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { LanguageModel } from '@virtual-trials-workspace/models';
import { Observable } from 'rxjs';
import { SessionSelectors, SessionState} from '@virtual-trials-workspace/store';

@Component({
  selector: 'vt-ui-select-language-control',
  templateUrl: './select-language-control.component.html',
  styleUrls: ['./select-language-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectLanguageControlComponent implements OnInit {
  public languages$: Observable<LanguageModel[]>;
  public language='0';
  @HostBinding('class')
  private class = 'session-timeout-container';
  private _bodyOverflowValue: string;
  public participantType: string;
  constructor(private store: Store<SessionState>) { }

  ngOnInit(): void {
    this.languages$ = this.store.select(SessionSelectors.getAvailableLanguages)
  }

  handleChangeLanguage(language: string): void {
    this.language = language;
   }
}
