<div *transloco="let transl">
  <vt-visit-content-sheet
    [headerText]="transl('visit.site.send_invitation.title')"
    [primaryActionText]="transl('general.send_invite')"
    [secondaryActionText]="transl('general.skip_proper_case')"
    (primaryAction)="sendInviteClick()"
    (secondaryAction)="skipClick()"
  >
    <div cardBody>
      <header class="es-card-header">
        <h2>{{transl('visit.site.send_invitation.title')}}</h2>
      </header>
      <div class="es-card-body">
        <div class="es-content-box">
          <p>
            {{ transl('visit.site.send_invitation.description_one') }}
          </p>
          <p>
            {{ transl('visit.site.send_invitation.description_two') }}
          </p>
        </div>
      </div>
    </div>
  </vt-visit-content-sheet>
</div>
