import { Notifier } from '@airbrake/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { BaseConfig } from '@virtual-trials-workspace/models';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: Notifier;
  readonly projectId = 326878;
  readonly environment = environment.name;
  readonly filteredUrls = ['http://localhost'];

  constructor(private config: BaseConfig) {
    this.initialize();
    this.addFilter();
  }

  private initialize = (): void => {
    this.airbrake = new Notifier({
      projectId: this.projectId,
      projectKey: this.config.airbrakeProjectKey,
      environment: this.environment,
    });
  };

  private addFilter = (): void => {
    this.airbrake.addFilter((notice) => {
      const urlIndex = this.filteredUrls.findIndex((url) =>
        notice.context?.url?.startsWith(url)
      );

      if (urlIndex >= 0) {
        return null;
      }

      notice.context.appVersion = this.config.appVersion;

      return notice;
    });
  };

  handleError(error: any): void {
    console.log(error);
    this.handleChunkLoadError(error);
  }

  private handleChunkLoadError = (error: any): void => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      console.log('New version available. Loading New Version...');
      window.location.reload();
    } else {
      this.airbrake.notify(error);
    }
  };
}
