import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  HttpPostResponse,
  SecurityQuestion,
} from '@virtual-trials-workspace/models';
import { RestApiService } from '@virtual-trials-workspace/shared-core-services';

@Injectable({ providedIn: 'root' })
export class AccountHttpService {
  readonly resendPasswordResource = '/account/sendresetpassword';
  readonly getSecurityQuestionForResendPasswordResource =
    '/account/verifyresetpassword';
  readonly resetPasswordResource = '/account/resetpassword';

  constructor(private restApiService: RestApiService) { }

  getSecurityQuestion$ = (resetToken: string): Observable<SecurityQuestion> => {
    return this.restApiService.post<
      { passwordResetToken: string },
      SecurityQuestion
    >(this.getSecurityQuestionForResendPasswordResource, {
      passwordResetToken: resetToken,
    });
  };

  resetPassword$ = (
    questionId: string,
    answer: string,
    password: string
  ): Observable<HttpPostResponse> => {
    return this.restApiService.post(this.resetPasswordResource, {
      questionId,
      answer,
      password,
    });
  };

  requestResetPassword$ = (username: string, languageCode: string): Observable<HttpPostResponse> => {
    return this.restApiService.post(this.resendPasswordResource, { username, languageCode });
  };
}
