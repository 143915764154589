import { Injectable } from '@angular/core';
import { BaseConfig, ClientVersion } from '@virtual-trials-workspace/models';
import { SupportedViewPortType } from '@virtual-trials-workspace/models';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  constructor(private config: BaseConfig) {}

  get userLanguageCodeStorageKey(): string {
    return '__User_Language_Code_Key__';
  }

  get isUserChangedLanguageBeforeLogin(): string {
    return '__Language_Changed_Before_Login__';
  }

  get languagesStorageKey(): string {
    return '__Languages_Cache_Key__';
  }

  get baseApiAddress(): string {
    return this.config.baseApiEndpoint;
  }

  //   get languageListVersion(): string {
  //     return environment.languageListVersion;
  //   }

  get productionMode(): boolean {
    return this.config.production;
  }

  get minimumChromeVersion(): ClientVersion {
    return this.config.supportedClients.min.chrome;
  }

  get minimumSafariVersion(): ClientVersion {
    return this.config.supportedClients.min.safari;
  }

  get viewPort(): SupportedViewPortType {
    return this.config.supportedViewPort;
  }

  get sessionTimeoutWarningTime(): number {
    return this.config.session.timeoutWarningTime;
  }
}
