import { Injectable } from '@angular/core';
import { StudyModel } from '@virtual-trials-workspace/models';
import { RestApiService } from '@virtual-trials-workspace/shared-core-services';
import { Observable } from 'rxjs';
import { DeviceInUseResponseModel } from '../models';

@Injectable({ providedIn: 'root' })
export class ProvisionerHttpService {
  constructor(private restApiService: RestApiService) {}

  getStudies$ = (): Observable<Array<StudyModel>> => {
    const resource = `/account/studies`;
    return this.restApiService.get<Array<StudyModel>>(resource);
  };

  getIsDeviceInUse$ = (
    siteId: string
  ): Observable<DeviceInUseResponseModel> => {
    const resource = `/site/${siteId}/device`;
    return this.restApiService.get<DeviceInUseResponseModel>(resource);
  };

  setProvisionedState$ = (
    siteId: string,
    provisionedState: boolean
  ): Observable<boolean> => {
    const resource = `/site/provision`;
    return this.restApiService.post(resource, {
      provisionedState: provisionedState,
      siteId,
    });
  };
}
