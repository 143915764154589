import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ScriptBuilderService } from '@virtual-trials-workspace/shared-core-services';

import { VidyoScriptLoadResponse } from '../vidyo-script-load-response';
import { VidyoScriptStatus } from '../vidyo-script-status';
import { ScriptModel } from '@virtual-trials-workspace/models';

@Injectable({ providedIn: 'root' })
export class VidyoScriptService {
  private status$ = new BehaviorSubject<VidyoScriptStatus>('NOTAVAILABLE');

  private readonly vidyoCallBack = 'onVidyoClientLoaded';
  private readonly scriptSrc = `../../../../assets/web/scripts/VidyoClient22.1.0.0051.min.js?onload=${this.vidyoCallBack}&webrtc=true&plugin=true`;
  get scriptStatus$(): Observable<VidyoScriptStatus> {
    return this.status$.asObservable();
  }

  constructor(private scriptBuilder: ScriptBuilderService) {
    this.assignVidyoOnloadHandler();
  }

  private assignVidyoOnloadHandler = (): void => {
    this.scriptBuilder.isScriptLoaded$.subscribe((script) => {
      if (script.isLoaded && script.name === 'vidyo') {
        this.status$.next('READY');
      }
    });
  };

  load = (): void => {
    if (['READY', 'RETRYING'].includes(this.status$.value)) {
      this.status$.next(this.status$.value);
    } else {
      const script: ScriptModel = {
        id: 'vidyo-script-id',
        src: this.scriptSrc,
        type: 'text/javascript',
        attributes: undefined,
        name: 'vidyo',
      };
      this.scriptBuilder.addScript(script, () => this.status$.next('FAILED'));
    }
  };
}
