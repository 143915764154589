import { createSelector } from '@ngrx/store';
import { VisitService } from '@virtual-trials-workspace/models';

import { VisitState } from '../../sub-states';
import { getVisitState } from './visit-selectors';

export const getHasEmulation = createSelector(
  getVisitState,
  (state: VisitState) => hasEmulation(state.services)
);

export const getDeviceConnection = createSelector(
  getVisitState,
  (state: VisitState) => state.deviceConnection
);

export const getHasDeviceControl = createSelector(
  getVisitState,
  (state: VisitState) => state.hasDeviceControl
);

export const getDeviceConnect = createSelector(
  getVisitState,
  (state: VisitState) => state.deviceConnect
);

export const missingDeviceConnection = createSelector(
  getVisitState,
  (state: VisitState) => hasEmulation(state.services) && !state.deviceConnection
);

const hasEmulation = (services: Array<VisitService>): boolean => {
  return services.some((s) => s.type === 'Emulation');
};
