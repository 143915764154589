<ng-container *transloco="let transl">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    
    <div class="logo"></div>
    <vt-ui-language-select [setUserLanguage]="false"></vt-ui-language-select>
  </div>
  <div class="content" >
    <div
      class="message"
      
    >
      <main class="es-gateway-body">
        <section
          class="es-timer-popup-layout es-layout-columns -es-1-column es-gateway-column -es-flex-horizontal"
        >
          <div class="es-column es-visit-timer-column">
            <div class="es-content-box">
              <div class="es-panel es-custom-panel">
                
                <ng-container *ngIf="data.canExtend && isJoin">
                  <header class="es-card-header">
                    <div class="timer-icon"></div>
                    <ng-container *ngTemplateOutlet="expiringTmpl"></ng-container>
                  </header>
                  <div  class="es-card-body">
                    <div class="es-content-box">
                      <div class="es-notification -es-info">
                        <span class="es-icon es-icon-info"></span>
                        <p id="body-text-paragraph-id">
                          {{
                            transl('visit.site.visit_end.join_timeout', {
                              buttonName: transl('general.continue')
                            })
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="data.canExtend && !isJoin && !data.isProvisioner">
                  <header class="es-card-header">
                    <div class="timer-icon"></div>
                    <ng-container *ngTemplateOutlet="expiringTmpl"></ng-container>
                  </header>
                  <div  class="es-card-body">
                    <div class="es-content-box">
                      <div class="es-notification -es-info">
                        <span class="es-icon es-icon-info"></span>
                        <p id="body-text-paragraph-id" *ngIf="countDownValue > 0">{{
                          transl('visit.site.visit_end.visit_timeout', {
                            buttonName: transl('general.continue')
                          })
                        }}
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="!data.canExtend">
                  <header class="es-card-header">
                    <div class="timer-icon"></div>
                    <ng-container *ngTemplateOutlet="expiringTmpl"></ng-container>
                  </header>
                  <div  class="es-card-body">
                    <div class="es-content-box" *ngIf="!data.isProvisioner">
                      <div class="es-notification -es-warning">
                        <span class="es-icon es-icon-alert-warning"></span>
                        <p id="body-text-paragraph-id" *ngIf="!data.isProvisioner">{{
                            transl('visit.site.visit_end.max_extension_reached')
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-container>

      
                <footer class="es-timer-popup-footer">
                  <div class="es-toolbar">
                    <ul class="es-right">
                      <li class="es-toolbar-item">
                        <button class="es-button -es-danger" id="logout" [mat-dialog-close]="'end'" >
                          <div class="callIcons call_end_icon"></div>
                          {{ transl('visit.site.end_session') }}
                        </button>
                      </li>
                      <li class="es-toolbar-item">
                        <button
                          id="continue"
                          class="es-button -es-primary"
                          [mat-dialog-close]="'continue'"
                          *ngIf="countDownValue > 0"
                        >
                          {{ transl('general.continue') }}
                        </button>

                      </li>
                    </ul>
                  </div>
                </footer>

  
              </div>
            </div>
          </div>
        </section>
      </main>

    </div>
  </div>


  
  <ng-template #expiringTmpl>
    <h2>
      {{
        transl('visit.site.visit_end.expiring', {
          countdown: countDownValue | date: 'HH:mm:ss':'UTC'
        })
      }}
    </h2>
  </ng-template>

</ng-container>


<!-- <ng-container *transloco="let transl">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    
    <div class="logo"></div>
    <vt-ui-language-select [setUserLanguage]="false"></vt-ui-language-select>
  </div>
  <div class="content" fxLayout="column">
    <div
      class="message"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
    >
      <ng-container *ngIf="data.canExtend && isJoin">
        <mat-icon class="robot zzz" svgIcon="robot-sleeping"></mat-icon>
        <div class="text" fxLayout="column" fxLayoutAlign="center center">
          <ng-container *ngTemplateOutlet="expiringTmpl"></ng-container>
          <span>{{
            transl('visit.site.visit_end.join_timeout', {
              buttonName: transl('general.continue')
            })
          }}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="data.canExtend && !isJoin && !data.isProvisioner">
        <mat-icon
          class="robot material-icons-outlined"
          svgIcon="timer-icon"
        ></mat-icon>
        <div class="text" fxLayout="column" fxLayoutAlign="center center">
          <ng-container *ngTemplateOutlet="expiringTmpl"></ng-container>
          <span *ngIf="countDownValue > 0">{{
            transl('visit.site.visit_end.visit_timeout', {
              buttonName: transl('general.continue')
            })
          }}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="!data.canExtend">
        <mat-icon
          class="robot material-icons-outlined"
          [class.orange]="!data.isProvisioner"
          svgIcon="timer-icon"
          *ngIf="!data.canExtend"
        ></mat-icon>
        <div class="text" fxLayout="column" fxLayoutAlign="center center">
          <ng-container *ngTemplateOutlet="expiringTmpl"></ng-container>
          <span *ngIf="!data.isProvisioner">{{
            transl('visit.site.visit_end.max_extension_reached')
          }}</span>
        </div>
      </ng-container>
    </div>
    <div
      class="control-panel"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="12px"
    >
      <button id="logout" mat-button [mat-dialog-close]="'end'" color="warn">
        <span class="material-icons call-end">call_end</span>
        {{ transl('visit.site.end_session') }}
      </button>
      <button
        id="continue"
        mat-button
        [mat-dialog-close]="'continue'"
        color="primary"
        *ngIf="countDownValue > 0"
      >
        {{ transl('general.continue') }}
      </button>
    </div>
  </div>

  <ng-template #expiringTmpl>
    <h4>
      {{
        transl('visit.site.visit_end.expiring', {
          countdown: countDownValue | date: 'HH:mm:ss':'UTC'
        })
      }}
    </h4>
  </ng-template>
</ng-container> -->
