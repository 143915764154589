import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { GssoRoutingModule } from './gsso-routing.module';
import { UiModule } from '@virtual-trials-workspace/shared-ui';
import { TranslocoModule } from '@ngneat/transloco';
import { ContentSheetComponent } from './content-sheet/content-sheet.component';
import { GssoService } from './http/gsso.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule, 
    HttpClientModule,
    GssoRoutingModule, 
    UiModule, 
    TranslocoModule
  ],
  declarations: [LoginComponent, ContentSheetComponent],
  providers: [ 
    GssoService,
  ]
})
export class GssoModule {}
