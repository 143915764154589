import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BaseConfig } from '@virtual-trials-workspace/models';

/**
 * @ignore
 */

@Injectable({
  providedIn: 'root',
})
export class GssoNavigationService {
  constructor(private config: BaseConfig, private router: Router) {}

  redirectToGssoCallback(): void {
    window.location.href = `${
      this.config.auth.loginUrl
    }?${this.buildGssoQueryParams().toString()}`;
  }

  redirectToAccessDenied(): void {
    this.router.navigate(['access-denied'], { skipLocationChange: true });
  }

  private buildGssoQueryParams(): HttpParams {
    return new HttpParams()
      .append('response_type', 'token')
      .append('scope', 'openid profile')
      .append('nonce', '0')
      .append('client_id', this.config.auth.clientId)
      .append('redirect_uri', `${this.config.auth.redirectUrl}/gssologin`);
  }
}
