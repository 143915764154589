<div class="es-popup  -es-closeable -es-modal -es-open es-custom-popup es-custom-dialog-panel" *transloco="let transl">
	<div class="es-popup-content">
		<header class="es-popup-header">
			<h2>{{
        transl('subject_list.change_study_site')
        }}</h2>
		</header>
    <div class="es-column">
      <div class="es-content-box">
          <div class="es-form">
            <form class="form" [formGroup]="formGroup">
              <div class="es-form-field">
                <label for="select" class="es-label">
                  {{
                    transl('study_and_site_selection.study')
                  }}
                </label>
                <div class="es-select">

                  <select 
                    id="study"
                    data-testid="study-select-id"
                    [formControl]="studyFormControl"
                    (change)="handleStudySelectionChange()">
                      <option value=""></option>
                      <option
                      *ngFor="let study of studies$ | async"
                      [ngValue]="study.studyId"
                      
                      >
                        {{ study.studyName }}
                      </option>
                  </select>
                </div>
              </div>

              <div class="es-form-field">
                <label for="select" class="es-label">
                  {{
                    transl('study_and_site_selection.site')
                  }}
                </label>
                <div class="es-select" >
                
                  <select 
                      id="site"
                      data-testid="site-select-id"
                      [formControl]="siteFormControl"
                      (change)="handleSiteSelectionChange()">
                      <option value=""></option>
                      <option
                      *ngFor="let site of (sites$ | async)(studyFormControl.value)"
                        [ngValue]="site.siteId"
                      >
                        {{ site.siteName }}
                      </option>
                  </select>
                </div>
                <div class="error-container">
                  <div class="es-form-message  -es-invalid" id="select-site-error-id" *ngIf="siteControlRequiredError">
                    <div class="error-icon"></div>
                    <p>{{ transl('study_and_site_selection.select_site') }}</p>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="es-toolbar">
            <ul class="es-right">
              <li class="es-toolbar-item">
                
                <button
                  class="es-button"
                  id="cancel-button-id"
                  (click)="handleCancelClick()"
                >
                  {{ transl('general.cancel') }}
                </button>
              </li>
              <li class="es-toolbar-item">
                
                <button
                  class="es-button  -es-primary "
                  id="ok-button-id"
                  (click)="handleOkClick()"
                  [disabled]="
                    (isEcoaLive$ | async) &&
                    (deviceStatus$ | async) !== deviceStatusEnum.Available
                  "
                >
                  {{ transl('general.ok_upper_case') }}
                </button>
              </li>
            </ul>
          </div>


          <div *transloco="let transl">
            <div *ngIf="isEcoaLive$ | async as isEcoaLive">
              <div
                *ngIf="isEcoaLive && (deviceStatus$ | async) === deviceStatusEnum.InUse"
                class="device-status-error"
              >
                {{ transl('visit.site.device_ready') }}
              </div>
              <div
                *ngIf="
                  isEcoaLive && (deviceStatus$ | async) === deviceStatusEnum.NotSetup
                "
                class="device-status-error"
              >
                {{ transl('visit.site.emulation_enabled') }}
              </div>
              <div
                *ngIf="isEcoaLive && (deviceStatus$ | async) === deviceStatusEnum.Error"
                class="device-status-error"
              >
                {{ transl('visit.site.error_device_status') }}
          
                <a (click)="onClickHereLinkClick()" class="click-here-link">
                  {{ transl('visit.site.click_here_text') }}
                </a>
                {{ transl('visit.site.try_again') }}
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>


<!-- <div mat-dialog-content *transloco="let transl">
  <form class="form" [formGroup]="formGroup">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>{{ transl('study_and_site_selection.study') }}</mat-label>
        <mat-select
          id="study"
          data-testid="study-select-id"
          [formControl]="studyFormControl"
          (selectionChange)="handleStudySelectionChange()"
        >
          <mat-option
            *ngFor="let study of studies$ | async"
            [value]="study.studyId"
          >
            {{ study.studyName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>{{ transl('study_and_site_selection.site') }}</mat-label>
        <mat-select
          id="site"
          data-testid="site-select-id"
          [formControl]="siteFormControl"
          (selectionChange)="handleSiteSelectionChange()"
        >
          <mat-option
            *ngFor="let site of (sites$ | async)(studyFormControl.value)"
            [value]="site.siteId"
          >
            {{ site.siteName }}
          </mat-option>
        </mat-select>

        <mat-error id="select-site-error-id" *ngIf="siteControlRequiredError">
          {{ transl('study_and_site_selection.select_site') }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end" *transloco="let transl">
  <button
    id="cancel-button-id"
    mat-flat-button
    color="secondary"
    (click)="handleCancelClick()"
  >
    {{ transl('general.cancel') }}
  </button>
  <button
    id="ok-button-id"
    mat-flat-button
    color="primary"
    (click)="handleOkClick()"
    [disabled]="
      (isEcoaLive$ | async) &&
      (deviceStatus$ | async) !== deviceStatusEnum.Available
    "
  >
    {{ transl('general.ok_upper_case') }}
  </button>
</div>
<div *transloco="let transl">
  <div *ngIf="isEcoaLive$ | async as isEcoaLive">
    <div
      *ngIf="isEcoaLive && (deviceStatus$ | async) === deviceStatusEnum.InUse"
      class="device-status-error"
    >
      {{ transl('visit.site.device_ready') }}
    </div>
    <div
      *ngIf="
        isEcoaLive && (deviceStatus$ | async) === deviceStatusEnum.NotSetup
      "
      class="device-status-error"
    >
      {{ transl('visit.site.emulation_enabled') }}
    </div>
    <div
      *ngIf="isEcoaLive && (deviceStatus$ | async) === deviceStatusEnum.Error"
      class="device-status-error"
    >
      {{ transl('visit.site.error_device_status') }}

      <a (click)="onClickHereLinkClick()" class="click-here-link">
        {{ transl('visit.site.click_here_text') }}
      </a>
      {{ transl('visit.site.try_again') }}
    </div>
  </div>
</div> -->
