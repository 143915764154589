import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import {
  ParticipantType,
  VisitServiceOptions,
} from '@virtual-trials-workspace/models';
import {
  BaseVisitSelectors,
  CallSelectors,
  DeviceConnection,
  EmulationSelectors,
  RootActions,
  RootSelectors,
  SessionActions,
  SessionSelectors,
  SessionState,
  TransferControlResult,
  VisitActions,
  VisitEnd,
  VisitSelectors,
  VisitState,
} from '@virtual-trials-workspace/store';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { VisitFacade, VisitSnackbarNotificationService } from '../../core';
import { EmulationFacade } from '../../emulation/facade/emulation.facade';
import { VideoCallFacade } from '../../video-call/facade/video-call.facade';
import { RenderTarget } from '../../video-call/vidyo';
import {
  LanguageControleService,
  RequestControlDialogService,
} from '@virtual-trials-workspace/shared-ui';
import { UserDeviceDetailsService } from '../../emulation';
@Injectable()
export class SubjectFacadeService implements VisitFacade, OnDestroy {
  get askUserForPermissions$(): Observable<boolean> {
    return combineLatest([
      this.visitIncludesVideoCall$,
      this.hasMediaDevicesPermissions$,
    ]).pipe(map(([hasCall, hasPermissions]) => hasCall && !hasPermissions));
  }

  get hasGrantedBrowserPermissions(): Promise<boolean> {
    return this.videoCallFacade.hasGrantedBrowserPermissions;
  }

  get isLoading$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getIsLoading));
  }

  get transferControlResult$(): Observable<TransferControlResult> {
    return this.store.pipe(select(VisitSelectors.getTransferControlResult));
  }

  get recievedDeviceControl$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.recievedDeviceControl));
  }

  get requestControlOutputVal$(): Observable<boolean> {
    return this.requestControlService.requestControlOutput$;
  }

  get hasVisitEnded$(): Observable<boolean> {
    return this.store.pipe(
      select(BaseVisitSelectors.getVisitEnd),
      map(
        (visitEnd) =>
          visitEnd === 'manual' ||
          visitEnd === 'error' ||
          visitEnd === 'timeout'
      )
    );
  }

  get hasVisitEndedOnError$(): Observable<boolean> {
    return this.store.pipe(
      select(BaseVisitSelectors.getVisitEnd),
      map((visitEnd) => visitEnd === 'error')
    );
  }

  get hasVisitTimedOut$(): Observable<boolean> {
    return this.store.pipe(
      select(BaseVisitSelectors.getVisitEnd),
      map((visitEnd) => visitEnd === 'timeout')
    );
  }

  get mediaDeviceStates$(): Observable<{ mic: boolean; video: boolean }> {
    return this.videoCallFacade.mediaDeviceStates$;
  }

  get participantType$(): Observable<ParticipantType> {
    return this.store.pipe(select(RootSelectors.getParticipantType));
  }

  get connectedToDeviceSession$(): Observable<boolean> {
    return this.emulationFacade.connectedToDeviceSession$;
  }

  get emulationConnectionLost$(): Observable<boolean> {
    return this.emulationFacade.connectionLost$;
  }

  get videoServiceIsReady$(): Observable<void> {
    return this.videoCallFacade.vidyoConnected$;
  }

  get readyToJoinVisit$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getReadyToJoinVisit));
  }

  get visitIsReady$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getHasActiveVisit));
  }

  get visitServices$(): Observable<VisitServiceOptions> {
    return this.store.pipe(select(VisitSelectors.getVisitServiceOption));
  }

  get videoEnded$(): Observable<boolean> {
    return this.store.pipe(
      select(CallSelectors.getCallHasEnded),
      filter((hasEnded) => !!hasEnded)
    );
  }

  get studyLanguages$(): Observable<string[]> {
    return this.store.pipe(
      select(SessionSelectors.getSutdyLanguagesByParticipantId)
    );
  }

  get deviceConnection$(): Observable<DeviceConnection> {
    return this.emulationFacade.deviceConnection$;
  }

  get deviceConnect$(): Observable<boolean> {
    return this.emulationFacade.deviceConnect$;
  }

  get hasDeviceControl$(): Observable<boolean> {
    return this.emulationFacade.hasDeviceControl$;
  }

  get deviceElementId(): string {
    return this.emulationFacade.deviceElementId;
  }

  get emulationServiceLost$(): Observable<boolean> {
    return this.emulationFacade.emulationServiceLost$;
  }

  constructor(
    private store: Store<VisitState & SessionState>,
    private videoCallFacade: VideoCallFacade,
    private emulationFacade: EmulationFacade,
    private notificationService: VisitSnackbarNotificationService,
    private ngZone: NgZone,
    private languageControleService: LanguageControleService,
    public dialog: MatDialog,
    private requestControlService: RequestControlDialogService,
    private userDeviceDetailsService : UserDeviceDetailsService
  ) {
    this.dispatchStudyLanguages();
    this.subscribeToStudyLanguages();
    this.subscribeToVisitIsReadyCheck();
    this.subscribeToEmulationOnlyVisitReady();
    this.subscribeToReadyToJoinVisit();
    this.subscribeToVisitEnded();
    this.subscribeToVideoCallEnded();
    this.subscribeToReadyToConnectVisitWebSocket();
    this.subscribeToTransferControlResult();
    this.requestControlService.subscribeToShowRequestControlDialog();
    this.subscribeToRequestControlOutputEvent();
    this.store.dispatch(VisitActions.clearDeviceConnection());
    this.store.dispatch(VisitActions.clearVideoService());
  }

  private subjectUserHasActiveVisit$ = this.store.pipe(
    select(VisitSelectors.getSubjectUserHasActiveVisit)
  );

  private hasMediaDevicesPermissions$ = this.store.pipe(
    select(VisitSelectors.getHasMediaDevicesPermissions)
  );

  private visitIncludesVideoCall$ = this.store.pipe(
    select(VisitSelectors.getVisitIncludesVideoCall)
  );

  private visitIncludesEmulation$ = this.store.pipe(
    select(EmulationSelectors.getHasEmulation)
  );

  private done$ = new Subject<void>();

  private subscribeToRequestControlOutputEvent = (): void => {

    this.requestControlOutputVal$
    .pipe(
      takeUntil(this.done$),
      filter((AcceptRequst) => !!AcceptRequst)
    )
    .subscribe(() =>
        this.disconnectDeviceSession(true)
    );
  };

  private subscribeToReadyToConnectVisitWebSocket = (): void => {
    this.subjectUserHasActiveVisit$
      .pipe(
        filter((hasActiveVisit) => !!hasActiveVisit),
        takeUntil(this.done$),
        switchMap(() =>
          this.askUserForPermissions$.pipe(
            filter((askForPermissions) => !askForPermissions),
            takeUntil(this.done$),
            take(1)
          )
        )
      )
      .subscribe(() =>
        this.dispatch(VisitActions.attemptConnectVisitWebSocket())
      );
  };

  private subscribeToVisitIsReadyCheck = (): void => {
    this.visitIsReady$
      .pipe(
        takeUntil(this.done$),
        filter((isActive) => !isActive),
        switchMap(() =>
          this.hasVisitEnded$.pipe(
            takeUntil(this.done$),
            filter((hasVisitEnded) => !hasVisitEnded)
          )
        )
      )
      .subscribe(() =>
        this.dispatch(VisitActions.checkForSubjectActiveVisit())
      );
  };

  private subscribeToEmulationOnlyVisitReady = (): void => {
    combineLatest([this.subjectUserHasActiveVisit$, this.visitServices$])
      .pipe(
        takeUntil(this.done$),
        filter(
          ([hasActiveVisit, services]) =>
            !!hasActiveVisit && services === 'emulation'
        )
      )
      .subscribe(() => this.dispatch(VisitActions.subjectJoinVisit()));
  };

  private subscribeToReadyToJoinVisit = (): void => {
    this.readyToJoinVisit$
      .pipe(
        filter((readyToJoinVisit) => !!readyToJoinVisit),
        takeUntil(this.done$),
        distinctUntilChanged(),
        switchMap(() =>
          this.visitIsReady$.pipe(
            takeUntil(this.done$),
            distinctUntilChanged(),
            filter((visitIsReady) => !!visitIsReady)
          )
        )
      )
      .subscribe(() => this.dispatch(VisitActions.joinVisitReady()));
  };

  private subscribeToVideoCallEnded = (): void => {
    this.videoEnded$.pipe(takeUntil(this.done$)).subscribe(() => {
      this.disconnectVideoCall().subscribe();
    });
  };

  private subscribeToVisitEnded = (): void => {
    combineLatest([
      this.hasVisitEnded$.pipe(filter((hasVisitEnded) => !!hasVisitEnded)),
      this.visitIncludesVideoCall$.pipe(
        filter((visitIncludesVideoCall) => !!visitIncludesVideoCall)
      ),
    ])
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.dispatch(VisitActions.videoServiceEnded()));

    combineLatest([
      this.hasVisitEnded$.pipe(filter((hasVisitEnded) => !!hasVisitEnded)),
      this.visitIncludesEmulation$.pipe(
        filter((visitIncludesEmulation) => !!visitIncludesEmulation)
      ),
    ])
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.dispatch(VisitActions.emulationServiceEnded()));
  };

  public subscribeToDeviceConnection = (): void => {
    this.deviceConnection$
      .pipe(
        filter((connection) => !!connection),
        take(1),
        tap(() => this.dispatch(VisitActions.checkHasDeviceControl())),
        takeUntil(this.done$)
      )
      .subscribe();

    combineLatest([
      this.deviceConnection$,
      this.hasDeviceControl$,
      this.hasVisitEnded$,
    ])
      .pipe(
        filter(
          ([deviceConnection, hasDeviceControl, hasVisitEnded]) =>
            !!deviceConnection && !!hasDeviceControl && !hasVisitEnded
        ),
        tap(([deviceConnection]) => {
          this.connectToDeviceSession(deviceConnection);
        }),
        takeUntil(this.done$)
      )
      .subscribe();
  };

  private subscribeToTransferControlResult = (): void => {
    this.transferControlResult$
      .pipe(
        filter((transferControlResult) => !!transferControlResult),
        takeUntil(this.done$)
      )
      .subscribe((transferControlResult) => {
        this.notificationService.showAttemptTransferDeviceControlError(
          transferControlResult
        );
      });
  };

  onDeviceControlRecieved = (): void => {
    this.notificationService.showControlRecieved();
  };

  renderLocalCameraStream = (target: RenderTarget): void => {
    this.videoCallFacade.renderLocalCameraStream(target);
  };

  renderRemoteCameraStream = (target: RenderTarget): void => {
    this.videoCallFacade.renderRemoteCameraStream(target);
  };

  toggleVideo = (currentValue: boolean): void => {
    this.videoCallFacade.toggleVideo(currentValue);
  };

  toggleMic = (currentValue: boolean): void => {
    this.videoCallFacade.toggleMic(currentValue);
  };

  switchCamera = (): void => {
    this.videoCallFacade.switchCamera();
  };

  connectToDeviceSession = (connection: DeviceConnection): void => {
    this.ngZone.run(() => {
      this.emulationFacade
        .connectToDeviceSession(connection)
        .pipe(
          take(1),
          catchError(() => {
            this.notificationService.showDeviceErrorNotification();
            return of();
          })
        )
        .subscribe();
    });
  };

  disconnectDeviceSession = (handover = false): void => {
    this.emulationFacade
      .disconnectDeviceSession()
      .subscribe((isStreamExist) => {
        if (handover && isStreamExist) {
          this.dispatch(VisitActions.emulationTransferControl());
        }
      });
  };

  private dispatchStudyLanguages = (): void => {
    this.dispatch(SessionActions.getSutdyLanguagesByParticipantId());
  };

  private subscribeToStudyLanguages = (): void => {
    this.studyLanguages$.pipe(takeUntil(this.done$)).subscribe((languages) => {
      if (languages) {
        this.languageControleService.handleLanguageControlDialog(
          JSON.stringify(languages)
        );
      }
    });
  };

  disconnectVideoCall = (): Observable<boolean> => {
    return this.videoCallFacade.disconnect();
  };

  isMobileDevice = (): boolean => this.userDeviceDetailsService.isMobileDevice();

  getWindowWidth = (): number => this.userDeviceDetailsService.getWindowWidth();

  getDeviceHeight = (): number => this.userDeviceDetailsService.getDeviceScreenHeight();

  dispatch = (
    action: RootActions.VisitActionsUnion | RootActions.SessionActionsUnion
  ): void => {
    this.store.dispatch(action);
  };

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
    this.languageControleService.ngOnDestroy();
  }
}
