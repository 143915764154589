import { Injectable } from '@angular/core';
import { SubjectFacadeService } from '@virtual-trials-workspace/features/visit';
import { ParticipantType } from '@virtual-trials-workspace/models';
import {
  DeviceConnection,
  TransferControlResult,
} from '@virtual-trials-workspace/store';
import { Observable } from 'rxjs';

@Injectable()
export class VisitFacadeService {
  get isLoading$(): Observable<boolean> {
    return this.subjectFacadeService.isLoading$;
  }

  get participantType$(): Observable<ParticipantType> {
    return this.subjectFacadeService.participantType$;
  }

  get transferControlResult$(): Observable<TransferControlResult> {
    return this.subjectFacadeService.transferControlResult$;
  }

  get recievedDeviceControl$(): Observable<boolean> {
    return this.subjectFacadeService.recievedDeviceControl$;
  }

  get hasVisitEnded$(): Observable<boolean> {
    return this.subjectFacadeService.hasVisitEnded$;
  }

  get hasVisitTimedOut$(): Observable<boolean> {
    return this.subjectFacadeService.hasVisitTimedOut$;
  }

  get hasVisitEndedOnError$(): Observable<boolean> {
    return this.subjectFacadeService.hasVisitEndedOnError$;
  }

  get mediaDeviceStates$(): Observable<{ mic: boolean; video: boolean }> {
    return this.subjectFacadeService.mediaDeviceStates$;
  }

  get connectedToDeviceSession$(): Observable<boolean> {
    return this.subjectFacadeService.connectedToDeviceSession$;
  }

  get readyToJoinVisit$(): Observable<boolean> {
    return this.subjectFacadeService.readyToJoinVisit$;
  }

  get visitIsReady$(): Observable<boolean> {
    return this.subjectFacadeService.visitIsReady$;
  }

  get deviceConnection$(): Observable<DeviceConnection> {
    return this.subjectFacadeService.deviceConnection$;
  }

  get deviceConnect$(): Observable<boolean> {
    return this.subjectFacadeService.deviceConnect$;
  }

  get hasDeviceControl$(): Observable<boolean> {
    return this.subjectFacadeService.hasDeviceControl$;
  }

  get deviceElementId(): string {
    return this.subjectFacadeService.deviceElementId;
  }

  get emulationServiceLost$(): Observable<boolean> {
    return this.subjectFacadeService.emulationServiceLost$;
  }

  get emulationConnectionLost$(): Observable<boolean> {
    return this.subjectFacadeService.emulationConnectionLost$;
  }

  constructor(private subjectFacadeService: SubjectFacadeService) {}

  subscribeToDeviceConnection = (): void =>
    this.subjectFacadeService.subscribeToDeviceConnection();

  onDeviceControlRecieved = (): void =>
    this.subjectFacadeService.onDeviceControlRecieved();

  connectToDeviceSession = (connection: DeviceConnection): void =>
    this.subjectFacadeService.connectToDeviceSession(connection);

  disconnectDeviceSession = (handover = false): void =>
    this.subjectFacadeService.disconnectDeviceSession(handover);
}
