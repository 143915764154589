import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SessionState, VisitState } from '@virtual-trials-workspace/store';
import {
  EmulationFacade,
  SubjectFacadeService,
  VideoCallFacade,
  VisitSnackbarNotificationService,
  UserDeviceDetailsService
} from '@virtual-trials-workspace/features/visit';
import { UiModule } from '@virtual-trials-workspace/shared-ui';

import { StandaloneRoutingModule } from './standalone-routing.module';
import { JoinVisitComponent } from './join-visit/join-visit.component';
import { VisitWaitComponent } from './visit-wait/visit-wait.component';
import { VisitComponent } from './visit/visit.component';
import { VisitErrorComponent } from './visit-error/visit-error.component';
import { VisitSnackbarNotificationService as NoopVisitSnackbarNotificationService } from './visit/visit-notification/visit-notification.service';
import { VisitEndComponent } from './visit-end/visit-end.component';
import { LayoutComponent } from './layout/layout.component';
import { LanguageControleService, RequestControlDialogService } from '@virtual-trials-workspace/shared-ui';
const subjectFacadeServiceFactory = (
  store: Store<VisitState & SessionState>,
  videoCallFacade: VideoCallFacade,
  emulationFacade: EmulationFacade,
  notificationService: VisitSnackbarNotificationService,
  ngZone: NgZone,
  languageControleService: LanguageControleService,
  matDialog: MatDialog,
  requestControlDialogService: RequestControlDialogService,
  userDeviceDetailsService: UserDeviceDetailsService
) =>
  new SubjectFacadeService(
    store,
    videoCallFacade,
    emulationFacade,
    notificationService,
    ngZone,
    languageControleService,
    matDialog,
    requestControlDialogService,
    userDeviceDetailsService
  );

@NgModule({
  imports: [CommonModule, StandaloneRoutingModule, UiModule],
  declarations: [
    JoinVisitComponent,
    VisitWaitComponent,
    VisitComponent,
    VisitErrorComponent,
    VisitEndComponent,
    LayoutComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: VisitSnackbarNotificationService,
      useClass: NoopVisitSnackbarNotificationService,
    },
    {
      provide: SubjectFacadeService,
      useFactory: subjectFacadeServiceFactory,
      deps: [
        Store,
        VideoCallFacade,
        EmulationFacade,
        VisitSnackbarNotificationService,
        NgZone,
        LanguageControleService,
        MatDialog,
        RequestControlDialogService,
      ],
    },
  ],
})
export class StandaloneModule {}
