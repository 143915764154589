<div class="es-popup  -es-closeable -es-modal -es-open es-custom-popup" *transloco="let transl">
	<div class="es-popup-content">
		<!-- <header class="es-popup-header">
      <div class="warning-icon"></div>
			<h2>Declining the terms</h2>
		</header> -->
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <p>{{ transl('notification.subject_not_activated') }}</p>
            </div>
          </div>
        </div>
      </div>
    <footer class="es-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">
            <button id="ok-button-id" 
                    class="es-button -es-danger  -es-modal" 
                    (click)="closeDialog()">
                    {{ transl('general.ok_upper_case') }}
            </button>
          </li>
        </ul>
      </div>
    </footer>	  
  </div>
</div>


<!-- <div mat-dialog-content  *transloco="let transl">
  <p>
    {{ transl('notification.subject_not_activated') }}
  </p>
</div>
<div mat-dialog-actions align="end" *transloco="let transl">
  <button mat-flat-button color="primary" id="ok-button-id"  (click)="closeDialog()">
    {{ transl('general.ok_upper_case') }}
  </button>
</div> -->
