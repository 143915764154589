<ng-container *transloco="let transl">
  
  <div class="es-gateway">
    <main class="es-gateway-body">
      <section
        class="es-lang-control-popup es-timer-popup-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
      >
        <div class="es-column es-visit-timer-column">
          <div class="es-content-box">
            <div class="es-panel es-custom-panel">
              <ng-container  [ngTemplateOutlet]="selectLanguage">
              </ng-container>

            </div>
          </div>
        </div>
      </section>
    </main>
  </div>


  <ng-template #selectLanguage>
    <ng-container>
      <header class="es-card-header">
        <div class="es-custom-globe-icon es-icon es-icon-globe"></div>
        <h2>{{ transl('select-language.language_not_supported') }}</h2>
      </header>
      <div  class="es-card-body">
        <div class="es-content-box">
          <div class="es-form">
            <form class="form" >
              <div class="es-form-field">
                <label for="select" class="es-label">
                  {{ transl('select-language.select_alternative') }}
                </label>
                <div class="es-select">
                  <select 
                    id="study"
                    data-testid="study-select-id"
                    (change)="handleChangeLanguage($event.currentTarget.value)">
                      <option value="0">{{ transl('select-language.please_select') }}</option>
                      <option
                        *ngFor="let language of languages$ | async"
                        [ngValue]="language.code"
                        value="{{ language.code }}"
                        [id]="language.code"
                        >{{ language.displayName }}
                      </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="es-toolbar">
            <ul class="es-right">
              <li class="es-toolbar-item">
                <button
                  class="es-button"
                  id="cancel"
                  mat-button
                  [mat-dialog-close]="false"
                  *ngIf="participantType!=='subject'"

                >
                  {{ transl('general.cancel') }}
                </button>
              </li>
              <li class="es-toolbar-item">
                <button
                  class="es-button  -es-primary "
                  id="continue"
                  mat-button
                  [mat-dialog-close]="true"
                  [disabled]="(language==='0')"
                >
                {{ transl('general.continue') }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

