

<ng-container *transloco="let transl">
  <ng-container
    [ngTemplateOutlet]="errored ? deviceErrorTmpl : deviceRenderTmpl"
  ></ng-container>

  <ng-template #deviceErrorTmpl>
    <div class="emulation-error-icon">
      <mat-icon
        class="emulation-error-icon__tablet"
        aria-hidden="false"
        aria-label="visit error"
        >tablet</mat-icon
      >
      <mat-icon
        class="emulation-error-icon__error"
        aria-hidden="false"
        aria-label="visit error"
        >error_outline</mat-icon
      >
    </div>
    <h4 class="text-center">
      {{ transl('visit.emulation.device_service_failed') }}
    </h4>
  </ng-template>

  <ng-template #deviceRenderTmpl>
    <div class="device-wrapper">
      <div class="network-error" *ngIf="networkLost">
        <span>{{
          transl('visit.emulation.device_service_network_issue')
        }}</span>
      </div>
      <div [id]="deviceId" class="device"></div>
    </div>
  </ng-template>
</ng-container>
<ng-content select="[vtUIBottomDevicePanel]"></ng-content>
<ng-content select="[vtUITopDevicePanel]"></ng-content>


