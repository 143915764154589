<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select siteUser">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>
  
  <!-- LOGIN screen START-->
    <main class="es-gateway-body">
      
      <section
      *transloco="let transl"
      class="es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
    >
      <div class="es-column">
        <div class="es-content-box">
          <div class="es-panel es-custom-panel select-study-site-form">
            <div class="es-form">
              <div class="es-content-box">
                <div class="es-text-group">
                  <h2>{{ transl('study_and_site_selection.title') }}</h2>
                  <p>{{ transl('study_and_site_selection.select_study_site') }}</p>
                </div>
              </div>
              <form class="form" [formGroup]="formGroup">
                <div class="es-form-field">
                  <label for="select" class="es-label">
                    {{
                      transl('study_and_site_selection.study')
                    }}
                  </label>
                  <div class="es-select" 
                    [ngClass]="{
                      '-es-invalid': studyControlRequiredError && studyFormControl.touched
                    }">

                    <select 
                      id="study"
                      data-testid="study-select-id"
                      [formControl]="studyFormControl"
                      (change)="handleStudyChanged()">
                        <option value=""></option>
                        <option
                        *ngFor="let study of studies$ | async"
                        [ngValue]="study.studyId"
                        
                        >
                          {{ study.studyName }}
                        </option>
                    </select>
                  </div>
                  <div class="error-container">
                    <div 
                      class="es-form-message  -es-invalid"
                      *ngIf="studyControlRequiredError && studyFormControl.touched">
                      <div class="error-icon"></div>
                      <p> {{ transl('study_and_site_selection.select_study') }}</p>
                    </div>
                  </div>
                </div>

                <div class="es-form-field">
                  <label for="select" class="es-label">
                    {{
                      transl('study_and_site_selection.site')
                    }}
                  </label>
                  <div class="es-select" 
                    [ngClass]="{
                      '-es-invalid': showErrorForQuestion && siteFormControl.touched
                    }">
                  
                    <select 
                        id="site"
                        data-testid="site-select-id"
                        [formControl]="siteFormControl"
                        (change)="handleSiteChanged()">
                        <option value=""></option>
                        <option
                        *ngFor="let site of (sites$ | async)(studyFormControl.value)"
                          [value]="site.siteId"
                        >
                          {{ site.siteName }}
                        </option>
                    </select>
                  </div>
                  <div class="error-container">
                    <div 
                      class="es-form-message  -es-invalid"
                      *ngIf="siteControlRequiredError && siteFormControl.touched">
                      <div class="error-icon"></div>
                      <p> {{ transl('study_and_site_selection.select_site') }}</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            
            <div class="es-toolbar">
              <ul class="es-right study-site-btn">
                <li class="es-toolbar-item">
                  
                  <button
                    class="es-button  -es-primary "
                    id="nextButton"
                    type="submit"
                    [disabled]="
                      isECOALive &&
                      ((deviceStatus$ | async) !== deviceStatusEnum.Available ||
                        (subjects$ | async)?.length <= 0)
                    "
                    (click)="handleNextClick()"
                  >
                    {{ transl('general.next_lower') }}
                  </button>
                </li>
              </ul>
            </div>
            
            <div *ngIf="isECOALive">
              <div
                *ngIf="isECOALive && deviceStatus === deviceStatusEnum.InUse"
                class="device-status-error"
              >
                {{ transl('visit.site.device_ready') }}
              </div>
              <div
                *ngIf="isECOALive && deviceStatus === deviceStatusEnum.NotSetup"
                class="device-status-error"
              >
                {{ transl('visit.site.emulation_enabled') }}
              </div>
              <div
                *ngIf="isECOALive && deviceStatus === deviceStatusEnum.Error"
                class="device-status-error"
              >
                {{ transl('visit.site.error_device_status') }}
  
                <a (click)="onClickHereLinkClick()" class="click-here-link">
                  {{ transl('visit.site.click_here_text') }}
                </a>
                {{ transl('visit.site.try_again') }}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  </main>
</div>

<!-- <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
<div class="page-wrapper" *ngIf="formGroup">
  <div fxLayout="row" fxLayoutAlign="flex-end">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>
  <div class="page-container" *transloco="let transl">
    <div class="page-header">
      <vt-ui-header-logo></vt-ui-header-logo>
    </div>
    <div class="page-content">
      <mat-card>
        <div class="card-title">
          {{ transl('study_and_site_selection.title') }}
        </div>
        <div class="card-subtitle">
          {{ transl('study_and_site_selection.select_study_site') }}
        </div>
        <mat-card-content>
          <form class="form" [formGroup]="formGroup">
            <div>
              <mat-form-field appearance="fill">
                <mat-label>{{
                  transl('study_and_site_selection.study')
                }}</mat-label>
                <mat-select
                  id="study"
                  data-testid="study-select-id"
                  [formControl]="studyFormControl"
                  (selectionChange)="handleStudyChanged()"
                >
                  <mat-option
                    *ngFor="let study of studies$ | async"
                    [value]="study.studyId"
                  >
                    {{ study.studyName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="studyControlRequiredError">
                  {{ transl('study_and_site_selection.select_study') }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{
                  transl('study_and_site_selection.site')
                }}</mat-label>
                <mat-select
                  id="site"
                  data-testid="site-select-id"
                  [formControl]="siteFormControl"
                  (selectionChange)="handleSiteChanged()"
                >
                  <mat-option
                    *ngFor="
                      let site of (sites$ | async)(studyFormControl.value)
                    "
                    [value]="site.siteId"
                  >
                    {{ site.siteName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="siteControlRequiredError">
                  {{ transl('study_and_site_selection.select_site') }}
                </mat-error>
              </mat-form-field>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <button
            id="nextButton"
            type="submit"
            [disabled]="
              isECOALive &&
              ((deviceStatus$ | async) !== deviceStatusEnum.Available ||
                (subjects$ | async)?.length <= 0)
            "
            mat-flat-button
            color="primary"
            (click)="handleNextClick()"
          >
            {{ transl('general.next_lower') }}
          </button>
        </mat-card-actions>
        <mat-card-footer>
          <div *ngIf="isECOALive">
            <div
              *ngIf="isECOALive && deviceStatus === deviceStatusEnum.InUse"
              class="device-status-error"
            >
              {{ transl('visit.site.device_ready') }}
            </div>
            <div
              *ngIf="isECOALive && deviceStatus === deviceStatusEnum.NotSetup"
              class="device-status-error"
            >
              {{ transl('visit.site.emulation_enabled') }}
            </div>
            <div
              *ngIf="isECOALive && deviceStatus === deviceStatusEnum.Error"
              class="device-status-error"
            >
              {{ transl('visit.site.error_device_status') }}

              <a (click)="onClickHereLinkClick()" class="click-here-link">
                {{ transl('visit.site.click_here_text') }}
              </a>
              {{ transl('visit.site.try_again') }}
            </div>
          </div>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div> -->
