import { createAction, props, union } from '@ngrx/store';
import {
  LicenseType,
  VisitUiState,
  VisitSessionTimer,
  VisitService,
  ProvisioningStatus,
} from '@virtual-trials-workspace/models';
import { DeviceConnection, VisitEnd } from '../models';
import { createHttpErrorAction } from '../utils';

export const checkForActiveVisit = createAction(
  '[Visit] Check For Active Visit'
);

export const checkForSubjectActiveVisit = createAction(
  '[Visit] Check For Subject Active Visit'
);

export const activeVisitExists = createAction(
  '[Visit] Active Visit Exists',
  props<{
    visitServices: Array<LicenseType>;
    visitId: string;
    visitCode: string;
    siteId: string;
    isEcoaLiveStandalone: boolean;
  }>()
);

export const noActiveVisit = createAction('[Visit] No Active Visit');

export const startVisitWithSubject = createAction(
  '[Visit] Start With Subject',
  props<{
    siteId: string;
    subjectId: string;
    subjectParticipantId: string;
    modules: Array<LicenseType>;
    isEcoaLiveStandalone: boolean;
    isDeviceProvisioned: boolean;
  }>()
);
export const setVisitSessionTimers = createAction(
  '[Visit] Set Visit Session Timers',
  props<{
    visitSessionTimer: VisitSessionTimer;
  }>()
);
export const isVisitJoin = createAction('[Visit] Check Visit join');
export const startVisitEndTimer = createAction(
  '[Visit] Start Visit Session Timer'
);
export const setEcoaLiveStandalone = createAction(
  '[Visit] Set EcoaLiveStandalone',
  props<{
    isEcoaLiveStandalone: boolean;
  }>()
);

export const createVisit = createAction('[Visit] Create');

export const createVisitSuccess = createAction(
  '[Visit] Create Success',
  props<{
    visitId: string;
    visitServices: Array<LicenseType>;
  }>()
);

export const createVisitFail = createHttpErrorAction('[Visit] Create Fail');

export const getVisitCode = createAction('[Visit] Get visit code');

export const getVisitCodeSuccess = createAction(
  '[Visit] Get visit code Success',
  props<{ visitCode: string }>()
);

export const getVisitCodeFail = createAction('[Visit] Get visit code Fail');

export const sendInvitation = createAction('[Visit] Send Invitation');

export const sendInvitationSuccess = createAction(
  '[Visit] Send Invitation Success'
);

export const sendInvitationFail = createAction('[Visit] Send Invitation Fail');

export const startVisit = createAction('[Visit] Start');

export const startVisitFail = createAction('[Visit] Start Fail');

export const startVisitServiceSuccess = createAction(
  '[Visit] Start Visit Service Success',
  props<{
    visitService: LicenseType;
  }>()
);

export const startVisitComplete = createAction(
  '[Visit] Start Visit Complete',
  props<{
    sendInvite: boolean;
  }>()
);

export const startVisitServiceFail = createAction(
  '[Visit] Start Visit Service Fail',
  props<{ visitService: LicenseType }>()
);

export const joinVisitReady = createAction('[Visit] Join Ready');

export const setDevicesPermissionsAction = createAction(
  '[Visit] Set Permissions'
);

export const setAllDevicesAction = createAction(
  '[Visit] Set All Devices',
  props<{ devices: MediaDeviceInfo[] }>()
);

export const setMicrophoneAction = createAction(
  '[Visit] Set Microphone',
  props<{ deviceId: string }>()
);

export const setSpeakerAction = createAction(
  '[Visit] Set Speaker',
  props<{ deviceId: string }>()
);

export const setCameraAction = createAction(
  '[Visit] Set Camera',
  props<{ deviceId: string }>()
);

export const changeUiState = createAction(
  '[Visit] Change Ui State',
  props<{ targetState: VisitUiState }>()
);

export const initVideoService = createAction(
  '[Visit] Init Video Service',
  props<{ token: string; roomKey: string }>()
);

export const clearVideoService = createAction(
  '[Visit] Clear Video Service Connection Details'
);

export const subjectVisitReady = createAction(
  '[Visit] Subject Visit Ready',
  props<{
    visitServices: Array<LicenseType>;
    visitId: string;
    visitCode: string;
    isEcoaLiveStandalone: boolean;
  }>()
);

export const subjectVisitReadyFailure = createAction(
  '[Visit] Subject Visit Ready Fail'
);

export const subjectJoinVisit = createAction('[Visit] Subject Join Visit');

export const videoServiceReady = createAction('[Visit] Video Service Ready');

export const clearDeviceConnection = createAction(
  '[Visit] Clear Emulation Connection Details'
);

export const initEmulationService = createAction(
  '[Visit] Init Emulation Service',
  props<{ deviceConnection: DeviceConnection }>()
);

export const emulationServiceReady = createAction(
  '[Visit] Emulation Service Ready'
);

export const initHealthCheck = createAction(
  '[Visit] Initialize Health Check'
);

export const emulationServiceEnded = createAction(
  '[Visit] Emulation Service Ended'
);

export const videoServiceEnded = createAction('[Visit] Video Service Ended');

export const emulationServiceLost = createAction(
  '[Visit] Emulation Service Lost'
);

export const clearVisitEndState = createAction('[Visit] Clear Visit End State');

export const endVisit = createAction('[Visit] End Visit');

export const endVisitResponse = createAction(
  '[Visit] End Visit Response',
  props<{ message: string; success: boolean }>()
);

export const endVisitResponseSuccess = createAction(
  '[Visit] End Visit Response Success',
  props<{ visitEnd: VisitEnd }>()
);

export const endVisitResponseFail = createAction(
  '[Visit] End Visit Response Fail'
);

export const endVisitClientSideOnError = createAction(
  '[Visit] End Visit Client Side On Error'
);

export const emulationRequestControl = createAction(
  '[Visit] Request Transfer Control'
);

export const emulationRequestControlCancel = createAction(
  '[Visit] Request Transfer Control Cancelled'
);

export const emulationDeclineTransferRequest = createAction(
  '[Visit] Decline Transfer Request'
);

export const emulationNotifyTransferRequestDeclined = createAction(
  '[Visit] Notify Transfer Request Declined'
);

export const emulationRequestControlConfirmation = createAction(
  '[Visit] Request Transfer Control Confirmation'
);

export const emulationTransferControl = createAction(
  '[Visit] Emulation Transfer Control'
);

export const emulationTransferControlSuccess = createAction(
  '[Visit] Emulation Transfer Control Success'
);

export const emulationTransferControlFailure = createAction(
  '[Visit] Emulation Transfer Control Failure',
  props<{ message: string }>()
);

export const emulationDeviceConnect = createAction(
  '[Visit] Emulation Device Connect',
  props<{ deviceConnection: DeviceConnection }>()
);

export const checkHasDeviceControl = createAction(
  '[Visit] Check Has Device Control'
);

export const checkHasDeviceControlSuccess = createAction(
  '[Visit] Check Has Device Control Success',
  props<{ hasControl: boolean }>()
);

export const checkHasDeviceControlFailure = createAction(
  '[Visit] Check Has Device Control Failure'
);

export const joinVisitResponseFailure = createAction(
  '[Visit] Join Visit Response Failure',
  props<{ visitService: LicenseType }>()
);

export const attemptConnectVisitWebSocket = createAction(
  '[Visit] Attempt Connect Visit WebSocket'
);

export const disconnectVisitWebSocket = createAction(
  '[Visit] Disconnect Visit WebSocket'
);

export const setProvisioningStatus = createAction('[Visit] Set As Provisioned');

export const skipProvisioning = createAction('[Visit] Skip Provisioning');

export const getDeviceProvisioningStatus = createAction(
  '[Visit] Get Provisioning Status'
);

export const getDeviceProvisioningStatusSuccess = createAction(
  '[Visit] Get Provisioning Status Success',
  props<{ provisioned: boolean }>()
);

export const getDeviceProvisioningStatusFailure = createAction(
  '[Visit] Get Provisioning Status Failure'
);

export const setProvisioningStatusSuccess = createAction(
  '[Visit] Set As Provisioned Success'
);

export const extendVisitJoin = createAction('[Visit] Extend Visit Join');

export const extendVisitEnd = createAction('[Visit] Extend Visit End');

export const setProvisioningStatusFailure = createAction(
  '[Visit] Set As Provisioned Failure'
);

export const visitSocketPing = createAction('[Visit] Visit Socket Ping');

export const renewVisitServiceInfo = createAction(
  '[Visit] Renew Visit Service Info'
);

const visitActions = union({
  activeVisitExists,
  changeUiState,
  checkForActiveVisit,
  createVisit,
  createVisitSuccess,
  createVisitFail,
  getVisitCode,
  getVisitCodeSuccess,
  getVisitCodeFail,
  noActiveVisit,
  setDevicesPermissionsAction,
  startVisit,
  startVisitComplete,
  startVisitFail,
  startVisitServiceFail,
  startVisitServiceSuccess,
  startVisitWithSubject,
  sendInvitation,
  sendInvitationSuccess,
  sendInvitationFailure: sendInvitationFail,
  joinVisitReady,
  initVideoService,
  clearVideoService,
  videoServiceReady,
  videoServiceEnded,
  emulationServiceLost,
  emulationServiceEnded,
  checkForSubjectActiveVisit,
  subjectVisitReady,
  subjectJoinVisit,
  clearDeviceConnection,
  initEmulationService,
  emulationServiceReady,
  clearVisitEndState,
  endVisit,
  endVisitResponse,
  endVisitClientSideOnError,
  emulationRequestControl,
  emulationRequestControlCancel,
  emulationDeclineTransferRequest,
  emulationNotifyTransferRequestDeclined,
  emulationRequestControlConfirmation,
  emulationTransferControl,
  emulationTransferControlSuccess,
  emulationTransferControlFailure,
  emulationDeviceConnect,
  joinVisitResponseFailure,
  attemptConnectVisitWebSocket,
  disconnectVisitWebSocket,
  checkHasDeviceControl,
  checkHasDeviceControlSuccess,
  checkHasDeviceControlFailure,
  setProvisioningStatus,
  setProvisioningStatusSuccess,
  setProvisioningStatusFailure,
  skipProvisioning,
  getDeviceProvisioningStatus,
  getDeviceProvisioningStatusSuccess,
  getDeviceProvisioningStatusFailure,
  visitSocketPing,
  extendVisitJoin,
  extendVisitEnd,
  renewVisitServiceInfo,
  initHealthCheck
});

export type VisitActionsUnion = typeof visitActions;