import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';

import { map } from 'rxjs/operators';

import * as FromAccountActions from '../actions/account.actions';
import { AccountHttpService } from '../http/account-http.service';

@Injectable()
export class AccountEffects {
  constructor(
    private actions$: Actions,
    private accountHttpService: AccountHttpService
  ) {}

  requestResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromAccountActions.requestResetPassword),
      fetch({
        run: (action) => {
          return this.accountHttpService
            .requestResetPassword$(action.username, action.languageCode)
            .pipe(map(() => FromAccountActions.requestResetPasswordSuccess()));
        },
        onError: (action, error) => {
          return FromAccountActions.requestResetPasswordFail({
            payload: {
              code: error.status,
              message: error.statusText,
            },
          });
        },
      })
    )
  );

  getSecurityQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromAccountActions.getSecurityQuestion),
      fetch({
        run: (action) => {
          return this.accountHttpService
            .getSecurityQuestion$(action.passwordResetToken)
            .pipe(
              map((response) =>
                FromAccountActions.getSecurityQuestionSuccess({
                  question: response,
                })
              )
            );
        },
        onError: (action, error) => {
          return FromAccountActions.getSecurityQuestionFail({
            payload: {
              code: error.status,
              message: error.statusText,
            },
          });
        },
      })
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromAccountActions.resetPassword),
      fetch({
        run: (action) => {
          const { answer, password, questionId } = action;
          return this.accountHttpService
            .resetPassword$(questionId, answer, password)
            .pipe(map(() => FromAccountActions.resetPasswordSuccess()));
        },
        onError: (action, error) => {
          return FromAccountActions.resetPasswordFail({
            payload: {
              code: error.status,
              message: error.statusText,
            },
          });
        },
      })
    )
  );
}
