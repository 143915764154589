<ng-container *transloco="let transl">
  <div class="es-gateway" *transloco="let transl">
    <!-- Loading bar overlay -->
    <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
    
    <!-- Header -->
    <vt-ui-subject-header></vt-ui-subject-header>
    
    <!-- Language select drop down -->
    <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select">
      <vt-ui-language-select class="top-right"></vt-ui-language-select>
    </div>
    
    <!-- Create Account screen START-->
    <main class="es-gateway-body">
      <div [ngSwitch]="createAccountStatus$ | async">
        <ng-container *ngSwitchCase="0">
          <vt-activate-pending-status
            [titleText]="transl('create_account.status.creating.title')"
            [subTitleText]="transl('create_account.status.creating.subtitle')"
          >
          </vt-activate-pending-status>
        </ng-container>
        <ng-container *ngSwitchCase="1">
          <vt-activate-success-status
            [titleText]="transl('create_account.status.created.title')"
          ></vt-activate-success-status>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container
            [ngTemplateOutlet]="isSubjectUser ? subject : site"
          ></ng-container>
        </ng-container>
      </div>
    </main>
  </div>
  <ng-template #site>
    <vt-activate-site-user-form
      [apiError]="apiError"
      [siteFormGroup]="siteFormGroup"
      [submitInProgress]="submitInProgress"
      [userNameRequiredErrorMessage]="transl('error.username_required')"
      [passwordRequiredErrorMessage]="
        transl('error.create_account.password_required')
      "
      [passwordDoesntMeetCriteriaErrorMessage]="
        transl('error.password_invalid')
      "
      [confirmPasswordRequiredErrorMessage]="
        transl('error.create_account.confirm_password_required')
      "
      [passwordsDontMatchErrorMessage]="transl('error.passwords_dont_match')"
      (createSiteAccountClick)="handleSiteCreateAccountClick()"
    >
    </vt-activate-site-user-form>
  </ng-template>

  <ng-template #subject>
    <vt-activate-subject-user-form
      [subjectFormGroup]="subjectFormGroup"
      [submitInProgress]="submitInProgress"
      [emailPatternErrorMessage]="transl('error.email_invalid')"
      [emailRequiredErrorMessage]="
        transl('error.create_account.email_required')
      "
      [passwordRequiredErrorMessage]="
        transl('error.create_account.password_required')
      "
      [passwordDoesntMeetCriteriaErrorMessage]="
        transl('error.password_invalid')
      "
      [confirmPasswordRequiredErrorMessage]="
        transl('error.create_account.confirm_password_required')
      "
      [passwordsDontMatchErrorMessage]="transl('error.passwords_dont_match')"
      (createSubjectAccountClick)="handleSubjectCreateAccountClick()"
    >
    </vt-activate-subject-user-form>
  </ng-template>
</ng-container>
