<section
  *transloco="let transl"
  class="es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal es-create-account-layout"
>
  <div class="es-column">
    <div class="es-content-box">
      <div class="es-panel es-custom-panel">
        <div class="es-form">
          <div class="es-content-box">
            <div class="es-text-group">
              <h2>{{ transl('create_account.title') }}</h2>
              <div class="es-custom-info">
                <div class="info-icon"></div>
                <div class="info-text">
                  <p>
                    {{ transl('create_account.subtitle') }}
                  </p>
                  <!-- <ul>
                    <li>{{ transl('text.resend_password_confirmed.options.one') }}</li>
                    <li>{{ transl('text.resend_password_confirmed.options.two') }}</li>
                    <li>{{ transl('text.resend_password_confirmed.options.three') }}</li>
                    <li>{{ transl('text.resend_password_confirmed.options.four') }}</li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>
          <form class="form" [formGroup]="subjectFormGroup">
            <div class="es-form-field">
              <label class="es-label -es-required">
                {{ transl('create_account.enter_email') }}
              </label>
              <input
                formControlName="email"
                type="text" 
                class="es-input" 
                [ngClass]="{'-es-invalid': 
                  (getErrorForSubjectFormControl('email', 'required') ||
                  !!apiError) && subjectFormGroup.controls.email.touched
                }"
                [placeholder]="transl('create_account.enter_email')"
              />
              
              <div class="error-container">
                <div 
                  *ngIf="
                    getErrorForSubjectFormControl('email', 'required') && 
                    subjectFormGroup.controls.email.touched
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ emailRequiredErrorMessage }}</p>
                </div>
              
                <div 
                  *ngIf="
                    getErrorForSubjectFormControl('email', 'email') && 
                    subjectFormGroup.controls.email.touched
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ emailPatternErrorMessage }}</p>
                </div>
              </div>
            </div>


            <div class="es-form-field">
              <label class="es-label -es-required">
                {{transl('user.account.login_password_placeholder')}}
              </label>
              <input
                formControlName="password"
                type="password"
                
                class="es-input" 
                [ngClass]="{'-es-invalid': 
                  (getErrorForSubjectFormControl('password', 'required') ||
                  (getErrorForSubjectFormControl('password', 'passwordInvalid') &&
                  !getErrorForSubjectFormControl('password', 'required'))) && 
                  subjectFormGroup.controls.password.touched
                }"
                [placeholder]="transl('user.account.login_password_placeholder')"
              />
              <div class="error-container">
                <div 
                  *ngIf="
                    getErrorForSubjectFormControl('password', 'required') && 
                    subjectFormGroup.controls.password.touched
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ passwordRequiredErrorMessage }}</p>
                </div>
              
                <div 
                  *ngIf="getErrorForSubjectFormControl('password', 'passwordInvalid') &&
                        !getErrorForSubjectFormControl('password', 'required') && 
                        subjectFormGroup.controls.password.touched
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ passwordDoesntMeetCriteriaErrorMessage }}</p>
                </div>
              </div>
            </div>

            <div class="es-form-field">
              <label class="es-label -es-required">
                {{transl('user.account.re_enter_password')}}
              </label>
              <input
                formControlName="confirmPassword"
                type="password"
                
                class="es-input" 
                [ngClass]="{'-es-invalid': 
                  (getErrorForSubjectFormControl('confirmPassword', 'required') ||
                  getErrorForSubjectFormControl(
                    'confirmPassword',
                    'passwordsDontMatch'
                  )) && 
                  subjectFormGroup.controls.confirmPassword.touched
                }"
                [placeholder]="transl('user.account.re_enter_password')"
              />
              

              <div class="error-container">
                <div 
                  *ngIf="
                  getErrorForSubjectFormControl('confirmPassword', 'required') && 
                  subjectFormGroup.controls.confirmPassword.touched
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ confirmPasswordRequiredErrorMessage }}</p>
                </div>
              
                <div 
                  *ngIf="getErrorForSubjectFormControl(
                    'confirmPassword',
                    'passwordsDontMatch'
                  ) && 
                  subjectFormGroup.controls.confirmPassword.touched"
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ passwordsDontMatchErrorMessage }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
        
        <div class="es-toolbar">
          <ul class="es-right">
            <li class="es-toolbar-item">
              <button
                id="cancelId"
                class="es-button"
                (click)="handleCancelClick()"
              >
                {{ transl('general.cancel') }}
              </button>
            </li>
            <li class="es-toolbar-item">
              <button
                class="es-button  -es-primary "
                [disabled]="submitInProgress || subjectFormGroup.invalid"
                (click)="createSubjectAccountClick.emit()"
              >
                {{ transl('create_account.create') }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
