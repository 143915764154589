import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UiModule } from '@virtual-trials-workspace/shared-ui';

import { ACTIVATE_STATE_KEY, Effects, Reducers } from './store';

import { AccountRoutingModule } from './activate-routing.module';
import {
  CreateAccountComponent,
  SiteUserFormComponent,
  SubjectUserFormComponent,
} from './create-account';
import { DeclineTermsModalComponent, DisclaimerComponent } from './disclaimer';
import { ActivateHomeComponent, ActivateHomeFormComponent } from './home';
import {
  SecurityQuestionAnswerComponent,
  SetSecurityQuestionsComponent,
} from './set-security-questions';
import * as FromShared from './shared';

@NgModule({
  imports: [
    AccountRoutingModule,
    CommonModule,
    EffectsModule.forFeature([Effects.ActivateEffects]),
    ScrollingModule,
    StoreModule.forFeature(ACTIVATE_STATE_KEY, Reducers.activateReducer),
    UiModule,
  ],
  declarations: [
    ActivateHomeComponent,
    ActivateHomeFormComponent,
    CreateAccountComponent,
    DeclineTermsModalComponent,
    DisclaimerComponent,
    FromShared.PendingStatusComponent,
    FromShared.SuccessStatusComponent,
    SecurityQuestionAnswerComponent,
    SetSecurityQuestionsComponent,
    SiteUserFormComponent,
    SubjectUserFormComponent,
  ],
})
export class ActivateModule {}
