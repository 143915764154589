import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vt-site-journey-re-invite-subject',
  templateUrl: './re-invite-subject.component.html',
  styleUrls: ['./re-invite-subject.component.scss'],
})
export class ReInviteSubjectComponent {
  @Output()
  confirmReinviteSubject = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<ReInviteSubjectComponent>) {}

  closeDialog = (): void => {
    this.dialogRef.close();
  };

  reInviteSubjectAccepted = (): void => {
    this.confirmReinviteSubject.emit();
    this.dialogRef.close();
  };
}
