import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as FromStore from '@virtual-trials-workspace/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class JourneyGuard {
  constructor(
    protected router: Router,
    protected store: Store<FromStore.RootState>
  ) {}

  protected canNavigateToJourney(
    journeyTypes: Array<string>
  ): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(FromStore.RootSelectors.getParticipantType),
      map((type) => {
        if (journeyTypes.some((_) => _ === type)) {
          return true;
        } else {
          return this.router.parseUrl(
            type === 'subject' ? '/login' : '/gssologin'
          );
        }
      })
    );
  }
}
