import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ParticipantType } from '@virtual-trials-workspace/models';

import * as FromStore from '../../store';

@Injectable({ providedIn: 'root' })
export class UnknownParticipantRouteGuard  {
  constructor(
    private router: Router,
    private store: Store<FromStore.ActivateState>
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(FromStore.Selectors.getParticipantType),
      map((type) => this.canNavigate(type))
    );
  }

  private canNavigate = (type: ParticipantType): boolean => {
    const nonParticipantType = type === 'none';

    if (nonParticipantType) {
      this.router.navigateByUrl('/login');
    }

    return !nonParticipantType;
  };
}
