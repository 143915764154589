import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubjectActivationStatus } from '../../../models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-site-journey-status',
  templateUrl: './subject-list-status.component.html',
  styleUrls: ['./subject-list-status.component.scss'],
})
export class SubjectListStatusComponent {
  @Input()
  status: SubjectActivationStatus;

  readonly statusToIconMap = new Map<
    SubjectActivationStatus,
    { className: string; name: string, status: string }
  >([
    [
      SubjectActivationStatus.PendingInvitation,
      { className: 'pending', name: 'grey_ellipse', status: 'subject_list.status.pending_invitation' },
    ],
    [
      SubjectActivationStatus.InvitationSent,
      { className: 'invite-sent', name: 'yellow_triangle', status: 'subject_list.status.invitation_sent' },
    ],
    [
      SubjectActivationStatus.Activated,
      { className: 'ready', name: 'green_ellipse',status: 'subject_list.status.activated' },
    ],
    [
      SubjectActivationStatus.ActivationResent,
      { className: 'activation-resent', name: 'yellow_triangle', status: 'subject_list.status.activation_resent' },
    ],
  ]);

  getIconForStatus = (): string => {
    return this.statusToIconMap.get(this.status).name;
  };

  getStyleForStatus = (): string => {
    return this.statusToIconMap.get(this.status).className;
  };

  getStatus = (): string => {
    return this.statusToIconMap.get(this.status).status;
  }
}
