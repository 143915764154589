export enum SiteActionTypes {
  SITE_GET_SUBJECTS = '[Site] Get Subjects',
  SITE_GET_SUBJECTS_SUCCESS = '[Site] Get Subjects Success',
  SITE_GET_SUBJECTS_FAILURE = '[Site] Get Subjects Failure',
  SITE_CREATE_SUBJECT = '[Site] Create Subject',
  SITE_CREATE_SUBJECT_SUCCESS = '[Site] Create Subject Success',
  SITE_CREATE_SUBJECT_FAILURE = '[Site] Create Subject Failure',
  SITE_SEND_REINVITE_TO_SUBJECT = '[Site] Send Re-Invite To Subject',
  SITE_SEND_INVITE_TO_SUBJECT = '[Site] Send Invite To Subject',
  SITE_SEND_INVITE_TO_SUBJECT_SUCCESS = '[Site] Send Invite To Subject Success',
  SITE_SEND_INVITE_TO_SUBJECT_FAILURE = '[Site] Send Invite To Subject Failure',
  SITE_REMOVE_SUBJECT = '[Site] Remove Subject',
  SITE_REMOVE_SUBJECT_SUCCESS = '[Site] Remove Subject Success',
  SITE_REMOVE_SUBJECT_FAILURE = '[Site] Remove Subject Failure',
  SITE_SELECT_SUBJECT = '[Site] Select Subject',
  SITE_SELECT_SUBJECT_SUCCESS = '[Site] Select Subject Success',
  SITE_SELECT_SUBJECT_FAILURE = '[Site] Select Subject Failure',
  SITE_GET_SUBJECT_ACTIVATION_DETAILS = '[Site] Get Activation Details',
  SITE_GET_SUBJECT_ACTIVATION_DETAILS_SUCCESS = '[Site] Get Activation Details Success',
  SITE_GET_SUBJECT_ACTIVATION_DETAILS_FAILURE = '[Site] Get Activation Details Failure',
  SITE_GET_DEVICE_STATUS = '[Site] Get Device Status',
  SITE_GET_DEVICE_STATUS_SUCCESS = '[Site] Get Device Status Success',
  SITE_GET_DEVICE_STATUS_FAILURE = '[Site] Get Device Status Failure',
  SET_PREVIOUSLY_SELECTED_SITE_AND_STUDY = '[Site] Set Previously Selected Study Site for reselect',
}
