import { Directive, HostBinding } from '@angular/core';

@Directive()
abstract class BasePanelDirective {
  @HostBinding('class.control-panel')
  protected controlPanel = true;
}

@Directive({
  selector: '[vtUITopDevicePanel]',
})
export class TopPanelDirective extends BasePanelDirective {
  @HostBinding('class.top')
  protected top = true;
}

@Directive({
  selector: '[vtUIBottomDevicePanel]',
})
export class BottomPanelDirective extends BasePanelDirective {
  @HostBinding('class.bottom')
  protected bottom = true;
}
