<div class="page-wrapper page-wrapper-login">
  <!-- <img class="vv-login__img" src="/assets/web/images/login-splash.png" /> -->
  <div class="page-container">
    <div class="page-header logo-ert__login">
    </div>
    <div class="page-content">
      <mat-card class="vv-login" *transloco="let transl">
        <div class="card-title">
          {{
            transl('user.account.title', {
              fallback: 'VIRTUAL VISITS'
            })
          }}
        </div>
        <mat-card-content *ngIf="headerText">
          <h1 class="mat-headline center">
            {{
              transl('user.account.logging', {
                fallback: 'Logging you in'
              })
            }}
          </h1>
        </mat-card-content>
        <mat-card-content *ngIf="bodyText">
          <h3 class="mat-headline"></h3>
          <div class="dot-pulse-wrapper">
            <span class="dot-pulse-label">
              {{
                transl('user.account.wait', {
                  fallback: 'Please wait'
                })
              }}
            </span>
            <div class="dot-pulse"></div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>