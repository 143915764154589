import { createSelector } from '@ngrx/store';

import { getIsSiteUser } from '../../selectors/session.selectors';
import { VisitState } from '../../sub-states';
import * as VisitBaseSelectors from './visit-selectors';

export const getSiteUserWithActiveVisit = createSelector(
  VisitBaseSelectors.getHasActiveVisit,
  getIsSiteUser,
  (activeVisit, isSiteUser) => !!activeVisit && isSiteUser
);

export const getSelectedServices = createSelector(
  VisitBaseSelectors.getVisitState,
  (state: VisitState) => state.services
);
