export enum ProvisionerActionTypes {
  PROVISIONER_GET_SITES = '[Provisioner] Get Sites',
  PROVISIONER_GET_SITES_SUCCESS = '[Provisioner] Get Sites Success',
  PROVISIONER_GET_SITES_FAILURE = '[Provisioner] Get Sites Failure',
  PROVISIONER_SET_PROVISIONING_STATUS = '[Provisioner] Set Provisioning Status',
  PROVISIONER_SET_PROVISIONING_STATUS_SUCCESS = '[Provisioner] Set Provisioning Status Success',
  PROVISIONER_SET_PROVISIONING_STATUS_FAILURE = '[Provisioner] Set Provisioning Status Failure',
  PROVISIONER_GET_DEVICE_STATUS = '[Provisioner] Get Device Status',
  PROVISIONER_GET_DEVICE_STATUS_SUCCESS = '[Provisioner] Get Device Status Success',
  PROVISIONER_GET_DEVICE_STATUS_FAILURE = '[Provisioner] Get Device Status Failure',
  PROVISIONER_SELECT_SITE = '[Provisioner] Select Site',
}
