import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@virtual-trials-workspace/shared-guards';

import { ActiveVisitGuard } from './guards';
import { SelectStudySiteComponent } from './select-study-site/select-study-site.component';
import { SiteJourneyRoutes } from './site-journey-routes';
import {
  InviteCodeComponent,
  InviteSubjectComponent,
  SubjectListComponent,
} from './subject';

const routes: Routes = [
  { path: '', redirectTo: SiteJourneyRoutes.SubjectList, pathMatch: 'full' },
  {
    path: SiteJourneyRoutes.SubjectList,
    component: SubjectListComponent,
    canActivate: [AuthenticationGuard, ActiveVisitGuard],
    data: { activeVisitGuardRedirect: '/gssologin' },
  },
  {
    path: SiteJourneyRoutes.InviteSubject,
    component: InviteSubjectComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: SiteJourneyRoutes.SelectStudySite,
    component: SelectStudySiteComponent,
    canActivate: [AuthenticationGuard, ActiveVisitGuard],
  },
  {
    path: `${SiteJourneyRoutes.SendInviteCode}/:subjectName`,
    component: InviteCodeComponent,
    canActivate: [AuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SiteJourneyRoutingModule {}
