<div *transloco="let transl" >
  <vt-visit-content-sheet [headerText]="transl('visit.subject.welcome.title')" 
        [esColumnClass]="es-welcome-column">
    <ng-container>
      <div cardBody>
        <header class="es-card-header">
          <h2>{{transl('visit.subject.welcome.title')}}</h2>
        </header>
        <div class="es-card-body">
          <div class="es-content-box">
            <p>
              {{ transl('visit.subject.welcome.description_one') }}
            </p>

            <p class="es-setting-up">{{ transl('visit.subject.welcome.description_two') }}</p>
            <div class="progress-bar">
              <div class="progress-fill"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </vt-visit-content-sheet>
</div>    

<!-- <div *transloco="let transl">
  <vt-visit-content-sheet [headerText]="transl('visit.subject.welcome.title')">
    <ng-container>
      <p>
        {{ transl('visit.subject.welcome.description_one') }}
      </p>

      <div class="dot-pulse-wrapper">
        <span class="dot-pulse-label">
          {{ transl('visit.subject.welcome.description_two') }}
        </span>
        <div class="dot-pulse"></div>
      </div>
    </ng-container>
  </vt-visit-content-sheet>
</div> -->


