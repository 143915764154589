import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nx/angular';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as FromActivateActions from '../actions/activate.actions';
import { ActivateState } from '../activate.state';
import { ActivateHttpService } from '../http/activate-http.service';
import * as FromSelectors from '../selectors/activate.selectors';
import { SessionActions,RootActions } from '@virtual-trials-workspace/store';
import { LocalStorageService, TokenService } from '@virtual-trials-workspace/shared-core-services';

@Injectable()
export class ActivateEffects {
  constructor(
    private actions$: Actions,
    private activateHttpService: ActivateHttpService,
    private router: Router,
    private store: Store<ActivateState>,
    private tokenService: TokenService,
    private localStorage: LocalStorageService
  ) {}

  activateParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActivateActions.activateParticipant),
      fetch({
        run: (action) =>
          this.activateHttpService.activateParticipant$(action.shortcode).pipe(
            switchMap(({ codeStatus, participantId, participantType }) => [
              FromActivateActions.activateParticipantSuccess({
                codeStatus,
                participantId,
                participantType,
              }),
              SessionActions.setParticipantId({
                participantId,
                participantType,
              }),
            ])
          ),
        onError: (action, response) => {
          return FromActivateActions.activateParticipantFail({
            payload: { code: response.status, message: response.error },
          });
        },
      })
    )
  );

  activateParticipantComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActivateActions.activateParticipantComplete),
        tap(() => this.router.navigateByUrl('/activate/disclaimer'))
      ),
    { dispatch: false }
  );

  acceptTermsAndConditions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActivateActions.acceptTermsAndConditions),
        withLatestFrom(this.store.select(FromSelectors.getParticipantType)),
        tap(() => this.router.navigateByUrl('/activate/security-questions'))
      ),
    { dispatch: false }
  );

  declineTermsAndConditions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActivateActions.declineTermsAndConditions),
        withLatestFrom(this.store.select(FromSelectors.getShortCode)),
        tap(([action, shortcode]) => {
          this.tokenService.removeToken();
          this.store.dispatch(SessionActions.clearSessionData());
          this.store.dispatch(RootActions.clearStorage());
          this.router.navigate(['/activate'], {
            queryParams: { shortcode: shortcode },
          });
        })
      ),
    { dispatch: false }
  );

  getSecurityQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActivateActions.getSecurityQuestions),
      fetch({
        run: () =>
          this.activateHttpService.getSecurityQuestions$().pipe(
            map((questions) =>
              FromActivateActions.getSecurityQuestionsSuccess({
                payload: questions,
              })
            )
          ),
        onError: (action, response) => {
          return FromActivateActions.getSecurityQuestionsFail({
            payload: { code: response.status, message: response.error },
          });
        },
      })
    )
  );

  setSecurityQuestions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActivateActions.setSecurityQuestionsAnswers),
        tap(() => this.router.navigate(['/activate/create-account']))
      ),
    { dispatch: false }
  );

  createSubjectAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActivateActions.createSubjectUserAccount),
      withLatestFrom(this.store.select(FromSelectors.getParticipantId), this.store.select(FromSelectors.getSecurityQuestionsAnswers)),
      fetch({
        run: (action, participantId, securityQuestionsAnswers) => {
          return this.activateHttpService
          .createSubjectUserAccount$(
            participantId,
            action.email,
            action.password,
            securityQuestionsAnswers
          ).pipe(
            map(() => FromActivateActions.createSubjectUserAccountSuccess())
          )
        },
        onError: (action, response) => {
          return FromActivateActions.createSubjectUserAccountFail({
            payload: { code: response.status, message: response.error },
          });
        },
      })
    )
  );

  createSubjectAccountFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActivateActions.createSubjectUserAccountFail),
        tap(() => this.router.navigate(['/error']))
      ),
    { dispatch: false }
  );

  createAccountComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActivateActions.createAccountComplete),
        tap(() => this.router.navigate(['/login']))
      ),
    { dispatch: false }
  );

  createSiteUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActivateActions.createSiteUserAccount),
      withLatestFrom(this.store.select(FromSelectors.getParticipantId), this.store.select(FromSelectors.getSecurityQuestionsAnswers)),
      fetch({
        run: (action, participantId, securityQuestionsAnswers) => {
          return this.activateHttpService
          .createSiteUserAccount$(
            participantId,
            action.username,
            action.password,
            securityQuestionsAnswers
          ).pipe(
            map(() => FromActivateActions.createSiteUserAccountSuccess())
          )
        },
        onError: (action, response) => {
          return FromActivateActions.createSiteUserAccountFail({
            payload: {
              code: response.status,
              message: response.error,
            },
          });
        },
      })
    )
  );
}
