import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  BaseConfig,
  ParticipantType,
  ProvisioningStatus,
  VisitServiceOptions,
  VisitSessionTimer,
} from '@virtual-trials-workspace/models';
import {
  VisitActions,
  VisitEnd,
  VisitSelectors,
  VisitState,
} from '@virtual-trials-workspace/store';
import { isOnline$ } from '@virtual-trials-workspace/shared-utils';

import {
  combineLatest,
  Observable,
  Subject,
  Subscription,
  interval,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { ServiceState, VisitView } from '../models';
import { SiteFacadeService } from './facade/site-facade.service';
import { select, Store } from '@ngrx/store';
import copy from 'copy-to-clipboard';
import { VisitSnackbarNotificationService } from '../core';
import { VisitSessionExtendService } from './visit-timer-service/visit-session-extend.service';
import { ServiceFailedDialogService } from '@virtual-trials-workspace/shared-ui';

@Component({
  selector: 'vt-visit-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
    ]),
    trigger('introFade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 })),
      ]),
    ]),
  ],
  providers: [SiteFacadeService],
})
export class SiteComponent implements AfterViewInit, OnInit, OnDestroy {
  private done$ = new Subject<void>();

  private handleLeaveVisitSub: Subscription;

  declinesBrowserPermission = false;
  isValidResolution = true;
  readonly errorMessageKey =
    'chrome_only_error_screen.switch_device_with_bigger_screen';
  readonly resolutionMessageKey =
    'chrome_only_error_screen.minimum_screen_resolution';

  selectedServiceState: ServiceState = 'available';
  selectedServiceView: VisitView;

  deviceControlStatusText =
    'visit.emulation.assessment_form.connecting_device_text';

  showVideoCallView = true; // default

  failedVisitService: VisitServiceOptions;
  visitCode: string;
  isEcoaLiveStandalone = false;
  isProvisioner = false;
  canShowProvisioningSheet = false;
  retryProvisioningMessage = '';
  displayVisitEndSheet: boolean;
  visitEndAlert$ = new Subject();
  showSocketFailedSheet = false;

  get emulationConnectionLost$(): Observable<boolean> {
    return this.siteFacade.emulationConnectionLost$;
  }

  get showSendInviteSheet$(): Observable<boolean> {
    return this.siteFacade.canSendInvite$;
  }

  get askUserForPermissions$(): Observable<boolean> {
    return this.siteFacade.askUserForPermissions$;
  }

  get isLoading$(): Observable<boolean> {
    return this.siteFacade.isLoading$;
  }

  get isEcoaLiveStandalone$(): Observable<boolean> {
    return this.siteFacade.isEcoaLiveStandalone$;
  }

  get visitServices$(): Observable<VisitServiceOptions> {
    return this.siteFacade.visitServices$;
  }

  get mediaDeviceStates$(): Observable<{ mic: boolean; video: boolean }> {
    return this.siteFacade.mediaDeviceStates$;
  }

  get hasDeviceControl$(): Observable<boolean> {
    return this.siteFacade.hasDeviceControl$;
  }

  get isDeviceProvisioned$(): Observable<boolean> {
    return this.siteFacade.isDeviceProvisioned$;
  }

  get deviceProvisioningStatus$(): Observable<ProvisioningStatus> {
    return this.siteFacade.deviceProvisioningStatus$;
  }

  get visitServiceFailedToStart$(): Observable<boolean> {
    return this.siteFacade.visitServiceFailedToStart$;
  }

  get connectedToDeviceSession$(): Observable<boolean> {
    return this.siteFacade.connectedToDeviceSession$;
  }

  get siteId$(): Observable<string> {
    return this.siteFacade.siteId$;
  }

  get visitTimers$(): Observable<VisitSessionTimer> {
    return this.siteFacade.visitTimers$;
  }

  get hasVisitEnded$(): Observable<boolean> {
    return this.siteFacade.hasVisitEnded$;
  }

  get visitHasEnded$(): Observable<boolean> {
    return this.siteFacade.hasVisitEnded$.pipe(
      filter((visitEnded) => !!visitEnded)
    );
  }

  get visitEnd$(): Observable<VisitEnd> {
    return this.siteFacade.visitEnd$;
  }

  get hasOnlyEmulationService$(): Observable<boolean> {
    return this.siteFacade.hasOnlyEmulationService$;
  }

  emulationCanRender = false;
  vidyoCanRender = false;

  readonly localViewId = 'local-view';

  @ViewChild('localView', { static: false })
  localViewDiv: ElementRef<HTMLDivElement>;

  readonly remoteViewId = 'remote-view';

  @ViewChild('remoteView', { static: false })
  remoteViewDiv: ElementRef<HTMLDivElement>;

  vidyoHiddenClass = 'hide-render';

  get deviceId(): string {
    return this.siteFacade.deviceElementId;
  }

  get emulationServiceLost$(): Observable<boolean> {
    return this.siteFacade.emulationServiceLost$;
  }

  get participantType$(): Observable<ParticipantType> {
    return this.siteFacade.participantType$;
  }

  constructor(
    private ngZone: NgZone,
    private siteFacade: SiteFacadeService,
    private router: Router,
    private store: Store<VisitState>,
    private config: BaseConfig,
    private notificationService: VisitSnackbarNotificationService,
    private timerService: VisitSessionExtendService,
    private serviceFailedService: ServiceFailedDialogService
  ) {}

  ngOnInit(): void {
    this.subscribeToParticipantType();
    this.subscribeToVisitEnded();
    this.subscribeToVisitServices();
    this.subscribeToVisitServiceFailed();
    this.subscribeToHasDeviceConnection();
    this.subscribeToRecievedDeviceControl();
    this.siteFacade.subscribeToDeviceConnection();
    this.subscribeToHasDeviceControl();
    this.subscribeToSkipSendInvite();
    this.subscribeToLostNetwork();
    this.subscribeToEmulationFailedPopup();
  }

  ngAfterViewInit(): void {
    this.subscribeToVideoServiceIsReady();
    this.dispatchEventToResetIdleTime();
  }

  validateSiteResolution = (): void => {
    if (this.selectedServiceView === 'emulation') {
      const screenWidth = this.siteFacade.getWindowWidth();
      const screenHeight = this.siteFacade.getDeviceHeight();
      if (screenWidth >= 1024 && screenHeight >= 768) {
        this.isValidResolution = true;
      } else {
        this.isValidResolution = false;
      }
    }
  }

  onResize() {
    this.validateSiteResolution()
  }

  private dispatchEventToResetIdleTime = (): void => {
    // Script to mock button click to avoid navigator idle timeout during visit
    this.ngZone.runOutsideAngular(() => {
      interval(10 * 6e4)
        .pipe(takeUntil(this.done$))
        .subscribe(() => {
          const element = document.getElementById('refresh');
          const event = new Event('mousedown');
          element.dispatchEvent(event);
        });
    });
  };

  private subscribeToVisitTimers = (): void => {
    this.visitTimers$
      .pipe(
        takeUntil(this.done$),
        distinctUntilChanged(
          (prev, curr) =>
            prev.visitJoinTime === curr.visitJoinTime &&
            prev.visitEndTime === curr.visitEndTime
        )
      )
      .subscribe((timers: VisitSessionTimer) => {
        if (
          timers.visitJoinTime &&
          this.getUtcTimeInMilliseconds(timers.visitJoinTime) <
            this.getUtcTimeInMilliseconds(timers.visitEndTime)
        ) {
          this.handleJoinTimer(timers);
        } else {
          this.timerService.stopJoinTimer();
          this.handleVisitEndTimer(timers);
        }
      });
  };

  private handleVisitEndTimer = (timers: VisitSessionTimer): void => {
    const visitEndTimePure = this.getUtcTimeInMilliseconds(timers.visitEndTime);
    const endWarningTimeInMilliseconds =
      parseInt(timers.endWarning) * 60 * 1000;
    this.timerService.startVisitTimer(
      visitEndTimePure,
      endWarningTimeInMilliseconds,
      this.isProvisioner,
      timers.endExtention
    );
  };

  private handleJoinTimer = (timers: VisitSessionTimer): void => {
    const joinTimePure = this.getUtcTimeInMilliseconds(timers.visitJoinTime);
    const joinWarningTimeInMilliseconds = parseInt(timers.joinWarning) * 1000;
    // console.log("handleJoinTimer - timerService.startJoinTimer")
    this.timerService.startJoinTimer(
      joinTimePure,
      joinWarningTimeInMilliseconds,
      timers.joinExtention
    );
  };

  private subscribeToExtendVisit = (): void => {
    this.timerService.extendVisitSub$
      .pipe(takeUntil(this.done$))
      .subscribe((timerType: string) => {
        switch (timerType) {
          case 'join':
            this.siteFacade.dispatch(VisitActions.extendVisitJoin());
            break;
          case 'end':
            this.siteFacade.dispatch(VisitActions.extendVisitEnd());
            break;
        }
      });
  };

  private subscribeToTimerEndVisit = (): void => {
    this.timerService.endVisitSub$
      .pipe(takeUntil(this.done$))
      .subscribe((isVisitEnd) => {
        if (isVisitEnd) {
          this.handleEndVisit();
        }
      });
  };

  private getUtcTimeInMilliseconds = (time: string): number => {
    return Date.parse(time) - new Date().getTimezoneOffset() * 60 * 1000;
  };

  private handleLogout = (): void => {
    this.router.navigateByUrl('/gssologin');
  };

  private subscribeToLostNetwork = (): void => {
    isOnline$()
      .pipe(
        takeUntil(this.done$),
        filter((isOnline) => !isOnline)
      )
      .subscribe(() => this.router.navigateByUrl('/gssologin'));
  };

  private subscribeToParticipantType = (): void => {
    this.siteFacade.participantType$
      .pipe(takeUntil(this.done$))
      .subscribe((participantType) => {
        if (participantType === 'provisioner') {
          this.isProvisioner = true;
        }
        this.subscribeToVisitTimers();
        this.subscribeToTimerEndVisit();
        if (!this.isProvisioner) {
          this.subscribeToExtendVisit();
        }
      });
  };

  private subscribeToSkipSendInvite = (): void => {
    this.siteFacade.skipSendInvite$
      .pipe(takeUntil(this.done$))
      .subscribe((skip) => {
        if (skip) {
          this.siteFacade.dispatch(
            VisitActions.startVisitComplete({ sendInvite: false })
          );
        }
      });
  };

  private subscribeToHasDeviceControl = (): void => {
    this.hasDeviceControl$.subscribe((hasControl: boolean) => {
      if (hasControl === false) {
        this.deviceControlStatusText =
          'visit.emulation.assessment_form.connected_device_text';
      }
    });
  };

  private subscribeToVisitEnded = (): void => {
    this.handleLeaveVisitSub = this.visitEnd$
      .pipe(
        filter((visitEnd) => !!visitEnd),
        takeUntil(this.done$)
      )
      .subscribe((visitEnd) => {
        this.siteFacade.disconnectDeviceSession();
        this.timerService.stopVisitTimer();
        this.timerService.stopJoinTimer();
        if (visitEnd === 'timeout') {
          this.onVisitTimeout();
        } else if (visitEnd === 'error') {
          this.showSocketFailedSheet = true;
        } else {
          this.handleLeaveVisit();
        }
      });
  };

  private onVisitTimeout = (): void => {
    this.displayVisitEndSheet = true;
    this.deviceControlStatusText = '';
    this.vidyoCanRender = false;
    this.vidyoHiddenClass = this.toggleRenderClass(false);
    this.visitEndAlert$.subscribe(() => {
      this.handleLeaveVisit();
    });
  };

  private subscribeToVisitServices = (): void => {
    this.visitServices$
      .pipe(
        take(1),
        takeUntil(this.done$),
        filter((services) => !!services)
      )
      .subscribe((services) => {
        // default display
        this.selectedServiceView =
          services.toLowerCase() === 'emulation' ? 'emulation' : 'video-call';
      });
  };

  private subscribeToVisitServiceFailed = (): void => {
    this.visitServiceFailedToStart$
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.handleVisitServiceFailed());
  };

  private subscribeToVideoServiceIsReady = (): void => {
    this.siteFacade.videoServiceIsReady$
      .pipe(takeUntil(this.done$))
      .subscribe(() => {
        this.vidyoCanRender = true;
        this.vidyoHiddenClass = this.toggleRenderClass(true);
        this.ngZone.run(() => this.attachCameras());
      });
  };

  private subscribeToHasDeviceConnection = (): void => {
    this.siteFacade.deviceConnection$
      .pipe(takeUntil(this.done$))
      .subscribe((connection) => {
        this.emulationCanRender = !!connection;

        this.subscribeEcoaStandalone();
      });
  };

  private subscribeEcoaStandalone = (): void => {
    this.isEcoaLiveStandalone$
      .pipe(takeUntil(this.done$))
      .subscribe((isEcoaLive) => {
        if (isEcoaLive != undefined) {
          this.isEcoaLiveStandalone = isEcoaLive;
        }
        if (this.isEcoaLiveStandalone) {
          this.store
            .pipe(
              select(VisitSelectors.getVisitCode),
              takeUntil(this.done$),
              takeUntil(this.visitHasEnded$)
            )
            .subscribe((vCode) => {
              if (vCode != undefined) {
                this.visitCode = `${
                  this.config.visitCodeAPIEndpoint
                }?visitCode=${vCode.trim()}`;
              } else {
                this.store.dispatch(VisitActions.getVisitCode());
                this.subscribeGetVisitCode();
              }
            });
        }
        this.validateSiteResolution()
      });
  };

  private subscribeGetVisitCode = (): void => {
    this.store.pipe(select(VisitSelectors.getVisitCode)).subscribe((vCode) => {
      if (vCode != undefined) {
        this.visitCode = `${this.config.visitCodeAPIEndpoint}?visitCode=${vCode}`;
      }
    });
  };

  private copyVisitCodeToClipboard = (): void => {
    copy(this.visitCode);
    this.notificationService.showVisitCodeCopiedSuccess();
  };

  private toggleRenderClass = (show: boolean): string => {
    return show ? 'show-render' : 'hide-render';
  };

  private subscribeToRecievedDeviceControl = (): void => {
    combineLatest([
      this.siteFacade.recievedDeviceControl$,
      this.siteFacade.connectedToDeviceSession$,
    ])
      .pipe(
        filter(
          ([recievedDeviceControl, connectedToDeviceSession]) =>
            !!recievedDeviceControl && !!connectedToDeviceSession
        ),
        takeUntil(this.done$)
      )
      .subscribe(() => {
        if (this.selectedServiceView !== 'emulation') {
          this.siteFacade.onDeviceControlRecieved();
        }
      });
  };

  private attachCameras = (): void => {
    this.siteFacade.renderLocalCameraStream({
      viewId: this.localViewId,
      height: this.localViewDiv.nativeElement.offsetHeight,
      width: this.localViewDiv.nativeElement.offsetWidth,
      x: this.localViewDiv.nativeElement.offsetLeft,
      y: this.localViewDiv.nativeElement.offsetTop,
    });

    this.siteFacade.renderRemoteCameraStream({
      viewId: this.remoteViewId,
      height: this.remoteViewDiv.nativeElement.offsetHeight,
      width: this.remoteViewDiv.nativeElement.offsetWidth,
      x: this.remoteViewDiv.nativeElement.offsetLeft,
      y: this.remoteViewDiv.nativeElement.offsetTop,
    });
  };

  toggleServiceView = (): void => {
    this.selectedServiceView =
      this.selectedServiceView === 'video-call' ? 'emulation' : 'video-call';
    this.showVideoCallView = !this.showVideoCallView;
    this.validateSiteResolution()
  };

  handleDeclinesAccessDevices = (): void => {
    this.declinesBrowserPermission = true;
  };

  handleStartAgain = (): void => {
    window.location.reload();
  };

  handleLeaveVisit = (): void => {
    combineLatest([
      this.isEcoaLiveStandalone$,
      this.siteFacade.participantType$,
    ])
      .pipe(takeUntil(this.done$))
      .subscribe(([isEcoaLive, participantType]) => {
        if (isEcoaLive) {
          this.router.navigateByUrl('/sites/select-study-site');
        } else if (participantType === 'provisioner') {
          this.handleProvisionerEndVisit();
        } else {
          this.router.navigateByUrl('/sites/subject-list');
        }
      });
  };

  handleEndVisit = (): void => {
    this.deviceControlStatusText = '';
    this.siteFacade.dispatch(VisitActions.endVisit());
  };

  handleTransferControl = (): void => {
    this.siteFacade.disconnectDeviceSession(true);
  };

  handleVisitServiceFailed = (): void => {
    this.siteFacade.failedVisitService$.pipe(take(1)).subscribe((services) => {
      this.failedVisitService = services;
      this.handleLeaveVisitSub.unsubscribe();
      this.handleEndVisit();
    });
  };

  handleAcceptAccess = (): void => {
    this.siteFacade.hasGrantedBrowserPermissions.then((grants) =>
      this.handleBrowserGrantPermissions(grants)
    );
  };

  handleSendInviteMenuClick = (): void => {
    this.siteFacade.dispatch(VisitActions.sendInvitation());
  };

  handleSendInvite = (sendInvite: boolean): void => {
    this.siteFacade.dispatch(VisitActions.startVisitComplete({ sendInvite }));
  };

  handleSetAsProvisioned = (): void => {
    this.siteFacade.dispatch(VisitActions.setProvisioningStatus());
  };

  handleSkipProvisioned = (): void => {
    this.siteFacade.dispatch(VisitActions.skipProvisioning());
  };

  isMobileDevice = (): boolean => {
    return this.siteFacade.isMobileDevice();
  }

  private subscribeToEmulationFailedPopup = (): void => {
    this.emulationServiceLost$
      .pipe(
        takeUntil(this.done$),
        filter((serviceLost) => !!serviceLost),
        withLatestFrom(this.hasOnlyEmulationService$)
      )
      .subscribe(([_, hasOnlyEmulationService]) => {
        this.serviceFailedService
          .showEmulationFailedDialog(!hasOnlyEmulationService)
          .subscribe((isVisitEnd) => {
            if (isVisitEnd) {
              this.handleEndVisit();
            }
          });
      });
  };

  private handleProvisionerEndVisit = (): void => {
    this.isDeviceProvisioned$
      .pipe(takeUntil(this.done$))
      .subscribe((isProvisioned) => {
        if (isProvisioned === undefined) {
          this.siteFacade.dispatch(VisitActions.getDeviceProvisioningStatus());
        } else if (isProvisioned || this.failedVisitService) {
          this.router.navigate(['provisioner']);
        } else {
          this.showProvisioningSheet();
        }
      });
  };

  private showProvisioningSheet = (): void => {
    this.deviceProvisioningStatus$
      .pipe(takeUntil(this.done$))
      .subscribe((status) => {
        switch (status) {
          case 'unset':
            this.canShowProvisioningSheet = true;
            this.displayVisitEndSheet = false;
            break;
          case 'success':
            this.router.navigate(['provisioner']);
            break;
          case 'fail':
            this.retryProvisioningMessage =
              'There is a problem in setting the site as provisioned. Please try again from the site list page.';
            break;
          case 'skip':
            this.router.navigate(['provisioner']);
            break;
        }
      });
  };

  private handleBrowserGrantPermissions = (grants: boolean): void => {
    grants ? this.browserPermissionConfirmed() : this.browserDenyPermissions();
  };

  private browserPermissionConfirmed = (): void => {
    this.siteFacade.dispatch(VisitActions.setDevicesPermissionsAction());
  };

  private browserDenyPermissions = (): void => {
    this.declinesBrowserPermission = true;
  };

  handleToggleVideo = (currentValue: boolean): void => {
    this.siteFacade.toggleVideo(currentValue);
  };

  handleToggleMic = (currentValue: boolean): void => {
    this.siteFacade.toggleMic(currentValue);
  };

  handleSwitchCamera = (): void => {
    this.siteFacade.switchCamera();
  };

  disconnectVisitServices = (): Observable<boolean> => {
    this.siteFacade.disconnectDeviceSession();
    return this.siteFacade.disconnectVideoCall();
  };

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }
}
