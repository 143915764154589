import { Component, HostBinding, HostListener, Inject, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { WINDOW } from '@virtual-trials-workspace/shared-core-services';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'vt-ui-viewport-layout, div[vt-ui-viewport-layout]',
  templateUrl: './viewport-layout.component.html',
  styleUrls: ['./viewport-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewportLayoutComponent implements OnChanges {
  @HostBinding('class')
  private class = 'viewport-layout-container';

  @Input()
  minWidth = 0;
  
  @Input()
  minHeight = 0;
  hasError: boolean;

  get windowWidth(): number {
    return Math.ceil(this.window.innerWidth);
  }

  get windowHeight(): number {
    return this.isTabletDevice() ? Math.ceil(this.window.screen.height) : window.innerHeight
  }
  
  constructor(@Inject(WINDOW) private window: Window, private deviceDetectorService: DeviceDetectorService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minWidth || changes.minHeight) {
      this.onWindowResize();
    }
  }

  @HostListener('window:resize')
  @HostListener('window:orientationchange')
  private onWindowResize = () => {
    this.hasError = this.isViewPortExceeded(this.windowWidth, this.windowHeight);
  }

  private isViewPortExceeded = (width: number, height: number): boolean => {
    return width < this.minWidth || height < this.minHeight || this.isMobileDevice();
  }

  private isMobileDevice = (): boolean => {
    return this.deviceDetectorService.userAgent.indexOf('Mobile') > -1 && this.deviceDetectorService.isMobile();
  }

  private isDesktopDevice = (): boolean =>  this.deviceDetectorService.isDesktop();

  private isTabletDevice = (): boolean =>  this.deviceDetectorService.userAgent.indexOf('Mobile') === -1 && this.deviceDetectorService.isMobile();

}
