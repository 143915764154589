import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ParticipantType } from '@virtual-trials-workspace/models';
import { RootSelectors, RootState } from '@virtual-trials-workspace/store';

@Component({
  selector: 'vt-web-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  private homePageUrl: string;
  private readonly unsubscribe$ = new Subject<void>();

  get homeElementId(): string {
    return 'home';
  }

  get loginElementId(): string {
    return 'login';
  }

  get loginUrl(): string {
    return '/login';
  }

  get homePageUrlForParticipantType(): string {
    return this.homePageUrl;
  }

  get sitesHomeUrl(): string {
    return '/sites';
  }

  get subjectsHomeUrl(): string {
    return '/visit';
  }

  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnInit(): void {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(RootSelectors.getParticipantType)
      )
      .subscribe((value) => this.handleCreatingHomePageUrl(value));
  }

  handleHomeClick = (): void => {
    this.router.navigateByUrl(this.homePageUrlForParticipantType);
  };

  handleLoginClick = (): void => {
    this.router.navigateByUrl(this.loginUrl);
  };

  private handleCreatingHomePageUrl = (
    participantType: ParticipantType
  ): void => {
    this.homePageUrl = this.participantTypeIsUnknown(participantType)
      ? this.loginUrl
      : this.getUrlForRecognisedParticipantType(participantType);
  };

  private participantTypeIsUnknown = (
    participantType: ParticipantType
  ): boolean => {
    return !participantType || participantType === 'none';
  };

  private getUrlForRecognisedParticipantType = (
    participantType: ParticipantType
  ): string => {
    return participantType === 'user'
      ? this.sitesHomeUrl
      : this.subjectsHomeUrl;
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
