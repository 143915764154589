import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RootSelectors, VisitState } from '@virtual-trials-workspace/store';

@Injectable({ providedIn: 'root' })
export class DevicePermissionsService {
  get grantedPermissions$(): Observable<boolean> {
    return this.store.pipe(
      select(RootSelectors.getHasMediaDevicesPermissions),
      filter((hasPermissions) => !!hasPermissions)
    );
  }

  constructor(private store: Store<VisitState>) {}

  grantsPermissions = async (): Promise<boolean> => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      this.releaseTracks(stream);
      return Promise.resolve(true);
    } catch (e) {
      return Promise.resolve(false);
    }
  };

  private releaseTracks(stream: MediaStream) {
    stream?.getTracks().forEach(function (track) {
      track.stop();
    });
  }
}
