import { Action, createReducer, on } from '@ngrx/store';
import { DeviceStatus } from '@virtual-trials-workspace/models';
import { Subject } from '../../models';
import * as FromActions from '../actions/site.actions';
import { NotificationStatus } from '../models';
import { initialSiteState, SiteState } from '../site.state';

const reducer = createReducer(
  initialSiteState,
  on(FromActions.getSubjectsAction, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
    notification: NotificationStatus.None,
    subjects: [],
  })),
  on(FromActions.getSubjectsSuccessAction, (state, { subjects }) => ({
    ...state,
    selectedSubject: undefined,
    subjects: subjects,
    subjectsLoaded: true,
    isLoading: false,
  })),
  on(FromActions.getSubjectsFailureAction, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload,
  })),
  on(FromActions.createSubjectAction, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
  })),
  on(FromActions.createSubjectSuccessAction, (state, action) => {
    const newSubject = {
      activationDetails: undefined,
      shortcode: action.apiResponse.shortcode,
      subjectId: action.apiResponse.subjectId,
      subjectName: action.subjectName,
      subjectStatus: 'pending invitation',
      subjectParticipantId: '',
    };
    return {
      ...state,
      selectedSubject: newSubject,
      subjects: [newSubject, ...state.subjects],
      subjectsLoaded: true,
      isLoading: false,
    };
  }),
  on(FromActions.createSubjectFailureAction, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload,
  })),
  on(FromActions.sendInviteToSubjectAction, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
  })),
  on(FromActions.sendInviteToSubjectSuccessAction, (state, action) => ({
    ...state,
    notification: NotificationStatus.SendInviteToSubjectSuccess,
    selectedSubject: getSubjectById(state.subjects, action.subjectId),
    isLoading: false,
  })),
  on(FromActions.sendInviteToSubjectFailureAction, (state, { payload }) => ({
    ...state,
    notification: NotificationStatus.SendInviteToSubjectFail,
    isLoading: false,
    error: payload,
  })),
  on(FromActions.removeSubjectAction, (state, action) => ({
    ...state,
    isLoading: true,
    error: undefined,
    selectedSubject: getSubjectById(state.subjects, action.subjectId),
  })),
  on(FromActions.removeSubjectSuccessAction, (state) => {
    const newSubjects = [...state.subjects].filter(
      (subject) => subject.subjectId !== state.selectedSubject.subjectId
    );
    return {
      ...state,
      subjects: newSubjects,
      isLoading: false,
      selectedSubject: undefined,
    };
  }),
  on(FromActions.removeSubjectFailureAction, (state, { payload }) => ({
    ...state,
    notification: NotificationStatus.RemoveSubjectFail,
    isLoading: false,
    error: payload,
  })),
  on(FromActions.selectedSubjectAction, (state, action) => ({
    ...state,
    selectedSubject: getSubjectById(state.subjects, action.subjectId),
    isLoading: false,
  })),
  on(FromActions.selectedSubjectSuccessAction, (state, action) => ({
    ...state,
    selectedSubject: getSubjectById(state.subjects, action.subjectId),
    isLoading: false,
  })),
  on(FromActions.selectedSubjectFailureAction, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  })),
  on(FromActions.getSubjectActivationDetailsAction, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FromActions.getSubjectActivationDetailsSuccessAction, (state, action) => {
    const selectedSubject = {
      ...getSubjectById(state.subjects, action.selectedSubject.subjectId),
      shortcode: action.apiResponse.shortcode,
      activationDetails: {
        email: action.apiResponse?.email,
        mobileNumber: action.apiResponse?.mobileNumber,
        languageCode: action.apiResponse?.languageCode,
      },
    };
    return {
      ...state,
      selectedSubject,
      error: undefined,
      isLoading: false,
    };
  }),
  on(FromActions.getSubjectActivationDetailsFailureAction, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  })),
  on(FromActions.reInviteSubjectAction, (state, action) => ({
    ...state,
    selectedSubject: getSubjectById(state.subjects, action.subject.subjectId),
    isLoading: true,
  })),
  on(FromActions.getStudies, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
    subjects: undefined,
  })),
  on(FromActions.getStudiesSuccess, (state, { studies }) => ({
    ...state,
    error: undefined,
    isLoading: false,
    studies: studies,
  })),
  on(FromActions.getStudiesFailure, (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  })),
  on(FromActions.setSelectedStudySite, (state, { studyId, siteId }) => {
    const stateStudy = state.studies.find(
      ({ studyId }) => studyId === state.selectedStudyId
    );
    const ecoaLiveStandalone = stateStudy
      ? stateStudy.ecoaLiveStandalone
      : undefined;

    return {
      ...state,
      selectedStudyId: studyId,
      selectedSiteId: siteId,
      ecoaLiveStandardalone: ecoaLiveStandalone,
    };
  }),
  on(
    FromActions.setSelectedStudySiteForReselect,
    (state, { studyId, siteId }) => {
      const stateStudy = state.studies.find(
        ({ studyId }) => studyId === state.selectedStudyId
      );
      const ecoaLiveStandalone = stateStudy
        ? stateStudy.ecoaLiveStandalone
        : undefined;

      return {
        ...state,
        selectedStudyId: studyId,
        selectedSiteId: siteId,
        ecoaLiveStandardalone: ecoaLiveStandalone,
        subjects: [],
      };
    }
  ),

  on(
    FromActions.prevSetSelectedStudySiteForReselect,
    (state, { studyId, siteId }) => {
      return {
        ...state,
        prevSelectedStudyId: studyId,
        prevSelectedSiteId: siteId,
      };
    }
  ),

  on(FromActions.setSelectedStudy, (state, { studyId }) => ({
    ...state,
    selectedStudyId: studyId,
    selectedSiteId: undefined,
    deviceStatus: DeviceStatus.Unknown,
  })),
  on(FromActions.setSelectedSite, (state, { siteId }) => ({
    ...state,
    selectedSiteId: siteId,
    subjects: [],
  })),
  on(FromActions.getIsDeviceStatus, (state) => ({
    ...state,
    deviceStatus: DeviceStatus.Checking,
  })),
  on(FromActions.getIsDeviceStatusSuccess, (state, action) => {
    let deviceStatus: DeviceStatus;

    if (!action.isSetup) {
      deviceStatus = DeviceStatus.NotSetup;
    } else {
      deviceStatus = action.inUse ? DeviceStatus.InUse : DeviceStatus.Available;
    }

    return {
      ...state,
      deviceStatus: deviceStatus,
    };
  }),
  on(FromActions.getIsDeviceStatusFailure, (state) => ({
    ...state,
    deviceStatus: DeviceStatus.Error,
  }))
);

const getSubjectById = (
  subjects: Array<Subject>,
  subjectId: string
): Subject => {
  return subjects.find((item) => item.subjectId === subjectId);
};

export function siteReducer(state: SiteState | undefined, action: Action) {
  return reducer(state, action);
}
