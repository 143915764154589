import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { merge, Observable, Subject, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ServiceFailedDialogComponent } from './dialog/service-failed-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ServiceFailedDialogService {
  constructor(private dialog: MatDialog) {}

  showEmulationFailedDialog = (showContinue: boolean): Observable<boolean> => {
    const dialogConfig: MatDialogConfig = {
      closeOnNavigation: true,
      disableClose: true,
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      panelClass: 'session-timeout-dialog-panel',
      scrollStrategy: new NoopScrollStrategy(),
      data: { showContinue },
    };

    const ref = this.dialog.open(ServiceFailedDialogComponent, dialogConfig);

    return ref.afterClosed().pipe(take(1));
  };
}
