import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  CallSelectors,
  VisitActions,
  VisitState,
} from '@virtual-trials-workspace/store';

import { combineLatest, Observable, Subject, zip } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { VisitFacade } from '../../core';
import { DevicePermissionsService } from '../device-permissions/device-permissions.service';
import { RenderTarget, VidyoScriptService, VidyoService } from '../vidyo';

@Injectable({ providedIn: 'root' })
export class VideoCallFacade implements VisitFacade, OnDestroy {
  get hasGrantedBrowserPermissions(): Promise<boolean> {
    return this.permissions.grantsPermissions();
  }

  get mediaDeviceStates$(): Observable<{ mic: boolean; video: boolean }> {
    return combineLatest([
      this.vidyoService.micEnabled$,
      this.vidyoService.videoEnabled$,
    ]).pipe(
      map(([mic, video]: [boolean, boolean]) => ({
        mic,
        video,
      })),
      takeUntil(this.done$)
    );
  }

  get vidyoConnected$(): Observable<void> {
    return this.vidyoConnectedSubject$.asObservable();
  }

  // get defaultCameraId$(): Observable<void> {

  // }

  private vidyoConnectedSubject$ = new Subject<void>();

  private callroomKey$ = this.store.pipe(
    select(CallSelectors.getCallroomKey),
    filter((roomKey) => !!roomKey)
  );

  private callToken$ = this.store.pipe(
    select(CallSelectors.getCallToken),
    filter((token) => !!token)
  );

  private scriptReady$ = this.scriptService.scriptStatus$.pipe(
    filter((status) => status === 'READY')
  );

  private videoCallHasEnded$ = this.store.pipe(
    select(CallSelectors.getCallHasEnded),
    filter((hasEnded) => !!hasEnded)
  );

  private vidyoConnectorReady$ = this.vidyoService.serviceAvailable$;

  private done$ = new Subject<void>();

  constructor(
    private permissions: DevicePermissionsService,
    private store: Store<VisitState>,
    private scriptService: VidyoScriptService,
    private vidyoService: VidyoService
  ) {
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    this.subscribeToScriptReady();
    this.subscribeToVideoInit();
    this.subscribeToVideoServiceClientStatusReady();
    this.subscribeToVideoCallEnd();
  };

  disconnect = (): Observable<boolean> => {
    return this.vidyoService?.disconnect();
  };

  private subscribeToVideoCallEnd = (): void => {
    this.videoCallHasEnded$
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.resetMediaDevices());
  };

  private resetMediaDevices = (): void => {
    this.toggleMic(false);
    this.toggleVideo(false);
  };

  private subscribeToVideoInit = (): void => {
    zip(this.callroomKey$, this.callToken$)
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.scriptService.load());
  };

  private subscribeToScriptReady = (): void => {
    this.scriptReady$.pipe(takeUntil(this.done$)).subscribe(async () => {
      await this.vidyoService.createConnector();
    });
  };

  private subscribeToVideoServiceClientStatusReady = (): void => {
    zip(this.callroomKey$, this.callToken$, this.vidyoConnectorReady$)
      .pipe(
        map(([roomKey, token]) => [roomKey, token]),
        takeUntil(this.done$)
      )
      .subscribe(async ([roomKey, token]) => {
        await this.vidyoService.connect(roomKey, token).then(() => {
          this.initiateDefaultCameraSubscription();
          this.initiateChangeCameraSubscription();
        });
        this.dispatch(VisitActions.videoServiceReady());
        this.vidyoConnectedSubject$.next();
      });
  };

  private initiateDefaultCameraSubscription = (): void => {
    this.store
      .pipe(select(CallSelectors.selectedCameraId), takeUntil(this.done$))
      .subscribe((id) => {
        this.vidyoService.selectCamera(id);
      });
  };

  private initiateChangeCameraSubscription = (): void => {
    this.vidyoService.localCameraId$
      .pipe(takeUntil(this.done$))
      .subscribe((deviceId: string) => {
        this.store.dispatch(VisitActions.setCameraAction({ deviceId }));
      });
  };

  renderLocalCameraStream = (target: RenderTarget): void => {
    this.vidyoService.attachLocalViewToElement(target);
  };

  renderRemoteCameraStream = (target: RenderTarget): void => {
    this.vidyoService.attachRemoteCameraView(target);
  };

  toggleMic = (currentValue: boolean): void => {
    this.vidyoService.toggleMic(currentValue);
  };

  toggleVideo = (currentValue: boolean): void => {
    this.vidyoService.toggleVideo(currentValue);
  };

  switchCamera = (): void => {
    this.vidyoService.switchCamera();
  };

  dispatch = (action: VisitActions.VisitActionsUnion): void => {
    this.store.dispatch(action);
  };

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }
}
