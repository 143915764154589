
<div class="es-popup  -es-closeable -es-modal -es-open es-custom-dialog-panel" *transloco="let transl">
	<div class="es-popup-content">
		<header class="es-popup-header">
      <!-- <div class="header-icon successIcon"></div> -->
			<h2>Failed to start</h2>
		</header>
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <!-- <p>{{ transl('notification.subject_code_sent.description') }}</p> -->
              <div
                class="device-check-loading-spinner"
                *ngIf="(deviceStatus$ | async) === deviceStatusEnum.Checking"
              >
              <div class="es-loading-indicator -es-visible">
                <span class="es-loading-icon"></span>
              </div>
              </div>
              <p>{{ deviceInfo }}</p>
            </div>
          </div>
        </div>
      </div>
    <footer class="es-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">

            <button
              id="ok-btn"
              class="es-button -es-secondary"
              data-testid="ok-btn"
              [disabled]="(deviceStatus$ | async) === deviceStatusEnum.Checking"
              (click)="handleOkClick()"
            >
              OK
            </button>
          </li>
        </ul>
      </div>
    </footer>	  
  </div>
</div>


<!-- 
<ng-container>
  <h1 mat-dialog-title>Failed to start</h1>
  <div mat-dialog-content>
    <div
      class="device-check-loading-spinner"
      *ngIf="(deviceStatus$ | async) === deviceStatusEnum.Checking"
    >
      <mat-spinner
        mode="indeterminate"
        color="accent"
        diameter="50"
      ></mat-spinner>
    </div>
    <p>{{ deviceInfo }}</p>
  </div>
  <div mat-dialog-actions align="center">
    <button
      id="ok-btn"
      class="es-button -es-secondary"
      data-testid="ok-btn"
      [disabled]="(deviceStatus$ | async) === deviceStatusEnum.Checking"
      (click)="handleOkClick()"
    >
      OK
    </button>
  </div>
</ng-container> -->
