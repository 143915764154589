
<div class="es-popup  -es-closeable -es-modal -es-open es-custom-popup es-custom-dialog-panel" *transloco="let transl">
	<div class="es-popup-content">
		<!-- <header class="es-popup-header">
      <div class="header-icon warningIcon"></div>
			<h2>Declining the terms</h2>
		</header> -->
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <p>{{ transl('warning.decline') }}</p>
            </div>
          </div>
        </div>
      </div>
    <footer class="es-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          
          <li class="es-toolbar-item">
            <button
              id="confirmId"
              class="es-button  -es-danger -es-modal"
              (click)="handleConfirmClick()"
            >
              {{ transl('disclaimer.confirm') }}
            </button>
          </li>
          <li class="es-toolbar-item">
            <button id="cancelId" 
                    class="es-button  -es-modal" 
                    (click)="handleCancelClick()">
              {{ transl('general.cancel') }}
            </button>
          </li>
        </ul>
      </div>
    </footer>	  
  </div>
</div>
