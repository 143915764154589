import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  translocoConfig as initTranslocoConfigFunc,
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
} from '@ngneat/transloco';
import { BaseConfig } from '@virtual-trials-workspace/models';
import { TranslocoMissingKeyHandler } from './transloco-missing-key-handler';
import { TranslocoMissingKeyStore } from './transloco-missing-key-store';

export const translocoConfig = initTranslocoConfigFunc({
  reRenderOnLangChange: true,
  defaultLang: 'en-US',
  fallbackLang: 'en-US',
});

const translocoMissingKeyHandlerFactory = (
  translocoMissingKeyStore: TranslocoMissingKeyStore
) => new TranslocoMissingKeyHandler(translocoMissingKeyStore);

@NgModule({
  imports: [CommonModule],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: (config: BaseConfig) => () => config.production,
      deps: [BaseConfig],
    },
    { provide: TRANSLOCO_CONFIG, useValue: translocoConfig },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useFactory: translocoMissingKeyHandlerFactory,
      deps: [TranslocoMissingKeyStore],
    },
  ],
})
export class SharedTranslocoModule {
  constructor(private config: BaseConfig) {}
}
