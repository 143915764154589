import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@virtual-trials-workspace/shared-guards';

import { VisitDeactivateGuard } from '../visit.candeactivate.guard';
import { VisitComponent } from '../visit.component';

export const visitRoutes: Routes = [
  {
    path: '',
    component: VisitComponent,
    canDeactivate: [VisitDeactivateGuard],
    canActivate: [AuthenticationGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(visitRoutes)],
  exports: [RouterModule],
})
export class VisitRoutingModule {}
