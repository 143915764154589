
<div class="es-popup  -es-closeable -es-modal -es-open es-custom-popup es-custom-dialog-panel" *transloco="let transl">
	<div class="es-popup-content">
		<!-- <header class="es-popup-header">
      <div class="header-icon warningIcon"></div>
			<h2>Declining the terms</h2>
		</header> -->
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <p>{{ transl('warning.resend_code') }}</p>
            </div>
          </div>
        </div>
      </div>
    <footer class="es-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">
            <button
              id="no-button-id"
              class="es-button"
              (click)="closeDialog()"
            >
              {{ transl('general.no') }}
            </button>
          </li>
          <li class="es-toolbar-item">
            <button
              id="yes-button-id"
              class="es-button  -es-primary -es-modal"
              (click)="reInviteSubjectAccepted()"
            >
              {{ transl('general.yes') }}
            </button>
          </li>
        </ul>
      </div>
    </footer>	  
  </div>
</div>


<!-- <div class="md-dialog-container" *transloco="let transl">
  <div mat-dialog-content>
    <p>
      {{ transl('warning.resend_code') }}
    </p>
  </div>
  <mat-dialog-actions align="end">
    <button
      id="no-button-id"
      mat-flat-button
      color="secondary"
      (click)="closeDialog()"
    >
      {{ transl('general.no') }}
    </button>
    <button
      id="yes-button-id"
      mat-flat-button
      color="primary"
      (click)="reInviteSubjectAccepted()"
    >
      {{ transl('general.yes') }}
    </button>
  </mat-dialog-actions>
</div> -->
