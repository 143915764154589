import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  Translation,
  TranslocoService,
  TRANSLOCO_CONFIG,
} from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import {
  LanguageModel,
  TranslationFile,
} from '@virtual-trials-workspace/models';
import {
  ConfigurationService,
  LocalStorageService,
  RouteNavigationService,
} from '@virtual-trials-workspace/shared-core-services';
import {
  LanguageHttpService,
  SessionActions,
  SessionSelectors,
  SessionState,
} from '@virtual-trials-workspace/store';
import {
  combineLatest,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  flatMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { TranslocoMissingKeyStore } from './transloco-missing-key-store';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements OnDestroy {
  private _retries: Map<string, number> = new Map();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private store: Store<SessionState>,
    private routeNavigationService: RouteNavigationService,
    @Inject(TRANSLOCO_CONFIG) private translocoConfig: any,
    private translocoService: TranslocoService,
    private translocoMissingKeyStore: TranslocoMissingKeyStore,
    private languageHttpService: LanguageHttpService
  ) {
    this.subscribeToGetTranslations();
  }

  private get languages$(): Observable<LanguageModel[]> {
    return this.store.pipe(select(SessionSelectors.getAvailableLanguages));
  }

  private get translations$(): Observable<TranslationFile[]> {
    return this.store.pipe(select(SessionSelectors.getTranslationFiles));
  }

  private subscribeToGetTranslations = (): void => {
    this.translations$
      .pipe(
        filter((translations) => !!translations),
        withLatestFrom(
          this.store.pipe(select(SessionSelectors.getSelectedLanguageCode))
        ),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([translations, languageCode]) => {
        if (translations.length === 0) {
          this.fetchFallbacktoDefaultLanguage();
        } else if(languageCode) {
          this.translocoService.setTranslation(
                  translations,
                  languageCode || this.translocoConfig.defaultLang
                );
        }
        this.store.dispatch(SessionActions.translationsLoadDone());
      });
  };

  private fetchFallbacktoDefaultLanguage = (): void => {
    this.languageHttpService
      .getUITranslations$(this.translocoConfig.fallbackLang, true)
      .subscribe((translations: any[]) => {
        this.translocoService.setTranslation(
          translations,
          this.translocoConfig.fallbackLang
        );        
        this.translocoService.setActiveLang(this.translocoConfig.fallbackLang);        
        this.store.dispatch(SessionActions.translationsLoadDone())
      });
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
}
