import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-send-invitation-sheet',
  templateUrl: './send-invitation-sheet.component.html',
  styleUrls: ['./send-invitation-sheet.component.scss'],
})
export class SendInvitationSheetComponent {
  @Output()
  sendInvitationClicked = new EventEmitter<boolean>();

  sendInviteClick(): void {
    this.sendInvitationClicked.emit(true);
  }

  skipClick(): void {
    this.sendInvitationClicked.emit(false);
  }
}
