import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ServiceState, VisitView } from '../../models';
import { ParticipantType } from '@virtual-trials-workspace/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: 'header.component.html',
})
export class HeaderComponent {
  @Input()
  participantType: ParticipantType;

  @Input()
  customWelcomeClass = '';

  @Input()
  showControls: boolean;

  @Input()
  showSelectedServiceControl: boolean;
  
  @Input()
  isEcoaLiveStandalone: boolean;

  @Input()
  selectedServiceView: VisitView = 'video-call';

  @Input()
  selectedServiceState: ServiceState = 'available';

  @Input()
  emulationDeviceConnected: boolean;

  @Output()
  toggleService = new EventEmitter();

  @Output()
  sendInvite = new EventEmitter();

  @Input()
  isProvisioner: boolean;


  handleToggleService = (): void => {
    this.toggleService.emit();
  };

  handleSendInvite = (): void => {
    this.sendInvite.emit();
  }
}
