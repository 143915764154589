import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionTimeoutService } from '@virtual-trials-workspace/shared-ui';

@Component({
  selector: 'virtual-trials-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'virtual-visits';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private sessionTimeoutService: SessionTimeoutService
  ) {}

  ngOnInit(): void {
    this.registerCustomIcons();
  }

  private registerCustomIcons = (): void => {
    this.addSvgIcon('ert-logo', 'assets/web/icons/ert-logo.svg');
    this.addSvgIcon('robot-sleeping', 'assets/web/images/robot-sleeping.svg');
    this.addSvgIcon('language-iocn', 'assets/web/images/language-iocn.svg');
    this.addSvgIcon('settings-icon', 'assets/web/images/settings-icon.svg');
    this.addSvgIcon('timer-icon', 'assets/web/images/visit-timer.svg');
  };

  private addSvgIcon(name: string, path: string) {
    this.matIconRegistry.addSvgIcon(
      name,
      this.sanitizer.bypassSecurityTrustResourceUrl(path)
    );
  }
}
