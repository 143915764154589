import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  ConfigurationService,
  TokenService,
} from '@virtual-trials-workspace/shared-core-services';
import { RootSelectors, SessionActions } from '@virtual-trials-workspace/store';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {
  SessionRouteConfiguration,
  Token,
} from '@virtual-trials-workspace/models';
import { AuthenticationGuard } from '@virtual-trials-workspace/shared-guards';

import { SessionTimeoutDialogComponent } from './dialog/session-timeout-dialog.component';

@Injectable({ providedIn: 'root' })
export class SessionTimeoutService implements OnDestroy {
  private _timer: any;
  private _removeTokenTimer: any;
  private _openedSessionTimeoutDialog: MatDialogRef<any>;
  private _unsubscribeToken$ = new Subject();
  private _isAuthenticated$: Observable<boolean>;
  private readonly unsubscribe$ = new Subject<void>();
  participantType$ = this.store.pipe(select(RootSelectors.getParticipantType));
  constructor(
    private router: Router,
    private store: Store,
    private tokenService: TokenService,
    private dialog: MatDialog,
    private configurationService: ConfigurationService
  ) {
    
    this.participantType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((participantType) => {
        if (participantType === 'subject') {
          this.start();
        }
      });
  }

  private start = (): void => {
    this._isAuthenticated$ = this.store.pipe(
      select(RootSelectors.getIsAuthenticated)
    );

    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe(this.onNavigationStart.bind(this));

    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError
        ),
        switchMap(() => this._isAuthenticated$.pipe(take(1)))
      )
      .subscribe(this.onNavigationEnd.bind(this));
  };

  private onNavigationStart = (): void => {
    this.resetTimer();
    this._unsubscribeToken$.next();
  };

  private onNavigationEnd = (isAuthenticated: boolean): void => {
    if (isAuthenticated) {
      const route = this.router.routerState.snapshot.root;
      const config = SessionRouteConfiguration.resolveFrom(route);

      if (config || this.isGuarded(route)) {
        this.tokenService.tokenChange$
          .pipe(
            filter((t) => !!t),
            takeUntil(this._unsubscribeToken$),
            startWith(this.tokenService.getToken()),
            distinctUntilChanged()
          )
          .subscribe((tokenRaw) =>
            this.startTimer(
              tokenRaw,
              config ?? SessionRouteConfiguration.default
            )
          );
      }
    }
  };

  private startTimer = (
    tokenRaw: string,
    config: SessionRouteConfiguration
  ): void => {
    this.resetTimer();

    if (tokenRaw) {
      const token = this.tokenService.parse(tokenRaw);
      const timeoutTime = token.getTimeoutTime();

      if (timeoutTime > 0) {
        const warningTime = this.configurationService.sessionTimeoutWarningTime;
        this._timer = setTimeout(
          () => this.showDialog(config, token),
          timeoutTime - warningTime
        );
        this._removeTokenTimer = setTimeout(
          () => this.tokenService.removeToken(),
          timeoutTime
        );
      }
    }
  };

  private resetTimer = (): void => {
    clearTimeout(this._timer);
    clearTimeout(this._removeTokenTimer);
  };

  private showDialog = (
    config: SessionRouteConfiguration,
    token: Token
  ): void => {
    if (this._openedSessionTimeoutDialog) {
      return;
    }

    const dialogConfig: MatDialogConfig = {
      closeOnNavigation: true,
      disableClose: true,
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      panelClass: 'session-timeout-dialog-panel',
      scrollStrategy: new NoopScrollStrategy(),
      data: token,
    };

    this._openedSessionTimeoutDialog = this.dialog.open(
      SessionTimeoutDialogComponent,
      dialogConfig
    );
    this._openedSessionTimeoutDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => this.onDialogClose(result, config));
  };

  private onDialogClose = (
    result: boolean,
    config: SessionRouteConfiguration
  ): void => {
    this._openedSessionTimeoutDialog = undefined;

    switch (result) {
      case true: {
        this.store.dispatch(SessionActions.refreshToken());
        break;
      }
      case false: {
        this.router.navigate([config.redirectTo], {
          skipLocationChange: config.skipLocationChange,
        });
        break;
      }
    }
  };

  private isGuarded = (route: ActivatedRouteSnapshot): boolean => {
    const guard = route.routeConfig?.canActivate?.find(
      (g) => new g() instanceof AuthenticationGuard
    );

    if (guard) {
      return true;
    }

    return route.firstChild ? this.isGuarded(route.firstChild) : false;
  };
  ngOnDestroy(): void {
    console.log("******DESTROY SESSION TIME OUT*****")
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
    this.unsubscribe$.complete();
  }
}
