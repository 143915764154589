import { Action, ActionReducer } from '@ngrx/store';
import { merge, pick } from 'lodash';

import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';

// Used for retrieving state from local storage after page refreshes
export const genericMetaReducer = <S, A extends Action = Action>(
  saveKeys: Array<string>,
  localStorageKey: string,
  localStorageService: LocalStorageService
): any => {
  let onInit = true; // after load/refresh…

  return (reducer: ActionReducer<S, A>) => (state: S, action: A): S => {
    const nextState = reducer(state, action);

    if (onInit) {
      onInit = false;
      const savedState = localStorageService.get(localStorageKey);
      return merge(nextState, savedState);
    }

    const stateToSave = pick(nextState, saveKeys);
    localStorageService.save(stateToSave, localStorageKey);

    return nextState;
  };
};
