import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { WINDOW } from '@virtual-trials-workspace/shared-core-services';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'vt-ui-service-failed-dialog',
  templateUrl: './service-failed-dialog.component.html',
  styleUrls: ['./service-failed-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceFailedDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  private class = 'session-timeout-container';
  private _bodyOverflowValue: string;

  get showContinue() {
    return this.data?.showContinue;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this._bodyOverflowValue = this.window.document.body.style.overflow;
    this.setBodyOverflowTo('hidden');
  }

  private setBodyOverflowTo = (value: string): void => {
    this.window.document.body.style.overflow = value;
  };

  ngOnDestroy(): void {
    this.setBodyOverflowTo(this._bodyOverflowValue);
  }
}
