import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { DeviceStatus } from '@virtual-trials-workspace/models';

import * as FormUtils from '@virtual-trials-workspace/shared-utils';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import * as FromStore from '../../store';

@Component({
  selector: 'vt-site-journey-reselect-study-site',
  templateUrl: './reselect-study-site.component.html',
  styleUrls: ['./reselect-study-site.component.scss'],
})
export class ReselectStudySiteComponent implements OnInit, OnDestroy {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  studyFormControl: AbstractControl;

  @Input()
  siteFormControl: AbstractControl;

  @Input()
  isEcoaLive: boolean;

  @Output()
  studySiteChanged = new EventEmitter<boolean>();

  @Output()
  studySelectionChanged = new EventEmitter<{
    selectedStudyId: string;
  }>();

  @Output()
  siteSelectionChanged = new EventEmitter<{
    selectedSiteId: string;
  }>();

  @Output()
  cancelClick = new EventEmitter();

  @Output()
  retryDeviceStatusClick = new EventEmitter();

  studies$ = this.store.pipe(select(FromStore.Selectors.getStudies));
  sites$ = this.store.pipe(select(FromStore.Selectors.getSitesForStudy));
  deviceStatus$ = this.store.pipe(select(FromStore.Selectors.getDeviceStatus));
  isEcoaLive$ = this.store.pipe(
    select(FromStore.Selectors.getisECOALiveForSelectedStudy)
  );

  deviceStatusEnum = DeviceStatus;

  initialStudyId: string = undefined;
  initialSiteId: string = undefined;

  private readonly unsubscribe$ = new Subject<void>();

  get siteControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.siteFormControl.errors);
  }

  constructor(
    private dialogRef: MatDialogRef<ReselectStudySiteComponent>,
    private store: Store<FromStore.SiteState>
  ) {}

  ngOnInit() {
    this.studies$
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((studies) => {
        if (!studies || studies.length === 0) {
          this.store.dispatch(FromStore.Actions.getStudies());
        }
      });
    this.initialStudyId = this.studyFormControl.value;
    this.initialSiteId = this.siteFormControl.value;
  }

  handleStudySelectionChange = (): void => {
    this.studySelectionChanged.emit(this.studyFormControl.value);
  };

  handleSiteSelectionChange = (): void => {
    this.siteSelectionChanged.emit();
  };

  handleOkClick = (): void => {
    this.studySiteChanged.emit();
  };

  onClickHereLinkClick = (): void => {
    this.retryDeviceStatusClick.emit();
  };

  handleCancelClick = (): void => {
    if (
      this.studyFormControl.value !== this.initialStudyId ||
      this.siteFormControl.value !== this.initialSiteId
    ) {
      this.studyFormControl.setValue(this.initialStudyId);
      this.siteFormControl.setValue(this.initialSiteId);
      this.studySiteChanged.emit(true);
    } else {
      this.studySiteChanged.emit(false);
    }

    this.dialogRef.close();
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
}
