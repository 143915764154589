import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RouteBuilderService {
  private featureSegment = '';
  private featureRouteSegment = '';

  withLazyFeature = (feature: any): RouteBuilderService => {
    this.featureSegment = feature;
    return this;
  };

  withRoute = (featureRoute: any): RouteBuilderService => {
    this.featureRouteSegment = featureRoute.toString();
    return this;
  };

  build = (): string => {
    return `${this.featureSegment}/${this.featureRouteSegment}`;
  };
}
