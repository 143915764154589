import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import {
  DeviceConnection,
  EmulationSelectors,
  VisitActions,
  VisitSelectors,
  VisitState,
} from '@virtual-trials-workspace/store';

import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { VisitFacade } from '../../core';
import { AnboxService } from '../anbox/anbox.service';

@Injectable({ providedIn: 'root' })
export class EmulationFacade implements VisitFacade {
  private connectInProgress = false;
  private disconnectInProgress = false;
  private connectedToDeviceSessionBs$ = new BehaviorSubject<boolean>(false);
  private connectionLostBs$ = new BehaviorSubject<boolean>(false);
  private inflightRenewRequest: Subscription;

  get connectedToDeviceSession$(): Observable<boolean> {
    return this.connectedToDeviceSessionBs$.asObservable();
  }

  get connectionLost$(): Observable<boolean> {
    return this.connectionLostBs$.asObservable();
  }

  get deviceElementId(): string {
    return 'device-id';
  }

  get deviceConnection$(): Observable<DeviceConnection> {
    return this.store.pipe(select(EmulationSelectors.getDeviceConnection)).pipe(
      filter((connection) => !!connection),
      distinctUntilChanged()
    );
  }

  get deviceConnect$(): Observable<boolean> {
    return this.store.pipe(
      select(EmulationSelectors.getDeviceConnect),
      filter((deviceConnect) => !!deviceConnect)
    );
  }

  get hasDeviceControl$(): Observable<boolean> {
    return this.store
      .pipe(select(EmulationSelectors.getHasDeviceControl))
      .pipe(distinctUntilChanged());
  }

  get emulationServiceLost$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getEmulationServiceLost));
  }

  constructor(
    private anboxService: AnboxService,
    private store: Store<VisitState>,
    private actions$: Actions
  ) {
    this.deviceConnection$.subscribe(() => {
      this.dispatch(VisitActions.emulationServiceReady());
    });
  }

  connectToDeviceSession = (
    connection: DeviceConnection,
    reconnect?: boolean
  ): Observable<void | string> => {
    return this.connectedToDeviceSession$.pipe(
      take(1),
      switchMap((connected) => {
        if (this.connectInProgress) {
          return of('Connection already in progress.');
        }
        if (connected && !reconnect) {
          return of('Already connected.');
        }
        this.connectInProgress = true;
        return this.makeConnectionToDevice(connection);
      })
    );
  };

  private makeConnectionToDevice = (
    connection: DeviceConnection
  ): Observable<void> => {
    return this.anboxService
      .connect(
        connection,
        () => {
          this.connectedToDeviceSessionBs$.next(true);
          this.connectionLostBs$.next(false);
        },
        (error) => {
          window.onerror('Anbox Stream Error Callback', 'na', 1, 1, error);
          // catch and handle premature disconnection
          this.connectedToDeviceSessionBs$
            .pipe(
              take(1),
              filter((connected) => !!connected && !this.disconnectInProgress)
            )
            .subscribe(() => {
              this.connectionLostBs$.next(true);
              if (!this.connectInProgress) {
              this.renewVisitInfoAndConnect();
              }
            });
        }
      )
      .pipe(finalize(() => (this.connectInProgress = false)));
  };

  private renewVisitInfoAndConnect = (): void => {
    if (!this.inflightRenewRequest) {
      this.inflightRenewRequest = this.actions$
      .pipe(
        ofType(VisitActions.initEmulationService),
        take(1),
        filter((action) => !!action.deviceConnection)
      )
      .subscribe((action) => {
        this.anboxService
          .disconnect()
          .pipe(take(1))
          .subscribe(() => {
            this.connectToDeviceSession(action.deviceConnection, true)
                .pipe(
                  take(1),
                  finalize(() => (this.inflightRenewRequest = null))
                )
              .subscribe();
          });
      });

      this.store.dispatch(VisitActions.renewVisitServiceInfo());
    }
  };

  disconnectDeviceSession = (): Observable<boolean> => {
    this.disconnectInProgress = true;
    return this.anboxService.disconnect().pipe(
      tap(() => {
        this.disconnectInProgress = false;
        this.connectedToDeviceSessionBs$.next(false);
      })
    );
  };

  dispatch = (action: VisitActions.VisitActionsUnion): void => {
    this.store.dispatch(action);
  };
}
