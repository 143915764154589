import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-child-menu-item',
  templateUrl: './child-menu-item.component.html',
  styleUrls: ['./child-menu-item.component.scss'],
})
export class ChildMenuItemComponent {
  @Output()
  backClick = new EventEmitter();

  handleBackMenuItemClick = (): void => {
    this.backClick.emit();
  };
}
