import { Action, createReducer, on } from '@ngrx/store';

import { AccountState, initialAccountState } from '../account.state';
import * as FromActions from '../actions/account.actions';

const reducer = createReducer(
  initialAccountState,
  on(FromActions.getSecurityQuestion, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
  })),
  on(FromActions.getSecurityQuestionSuccess, (state, { question }) => ({
    ...state,
    error: undefined,
    isLoading: false,
    resetPasswordSecurityQuestion: question,
  })),
  on(FromActions.requestResetPassword, (state) => ({
    ...state,
    error: undefined,
  })),
  on(FromActions.requestResetPasswordSuccess, (state) => ({
    ...state,
    error: undefined,
  })),
  on(FromActions.resetPassword, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
    passwordResetSuccess: false,
  })),
  on(FromActions.resetPasswordSuccess, (state) => ({
    ...state,
    error: undefined,
    isLoading: false,
    passwordResetSuccess: true,
  })),
  on(FromActions.resetPasswordFail, (state, { payload: error }) => ({
    ...state,
    error,
    isLoading: false,
    passwordResetSuccess: false,
  })),
  on(
    FromActions.getSecurityQuestionFail,
    FromActions.requestResetPasswordFail,
    (state, { payload: error }) => ({
      ...state,
      error,
      isLoading: false,
    })
  )
);

export function accountReducer(
  state: AccountState | undefined,
  action: Action
) {
  return reducer(state, action);
}
