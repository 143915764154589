import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';

import * as FromStore from '@virtual-trials-workspace/store';
import { VisitActions } from '@virtual-trials-workspace/store';

import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ActiveVisitGuard  {
  constructor(
    private router: Router,
    private store: Store<FromStore.VisitState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const redirectUrl = '/visit';
    return this.store.pipe(
      select(FromStore.RootSelectors.getHasActiveVisit),
      tap((hasActiveVisit) => {
        if (hasActiveVisit === undefined) {
          this.store.dispatch(VisitActions.checkForActiveVisit());
        }
      }),
      filter((hasActiveVisit) => !!hasActiveVisit || hasActiveVisit === false),
      map((hasActiveVisit) =>
        hasActiveVisit ? this.router.parseUrl(redirectUrl) : true
      )
    );
  }
}
