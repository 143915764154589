import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-video-call-actions',
  templateUrl: './video-call-actions.component.html',
  styleUrls: ['./video-call-actions.component.scss'],
})
export class VideoCallActionsComponent {
  @Input()
  micIsEnabled: boolean;

  @Input()
  videoIsEnabled: boolean;

  @Output()
  mediaDeviceSettingsClick = new EventEmitter();

  @Output()
  micButtonClick = new EventEmitter();

  @Output()
  videoButtonClick = new EventEmitter();

  @Output()
  switchButtonClick = new EventEmitter();

  handleMediaDeviceSettingsClick = (): void => {
    this.mediaDeviceSettingsClick.emit();
  };

  handleMicButtonClick = (): void => {
    this.micButtonClick.emit();
  };

  handleVideoButtonClick = (): void => {
    this.videoButtonClick.emit();
  };

  handleSwitchCameraClick = (): void => {
    this.switchButtonClick.emit();
  };
}
