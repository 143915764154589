import { Injectable } from '@angular/core';
import { BaseConfig } from '@virtual-trials-workspace/models';

import { QueueingSubject } from 'queueing-subject';

import { Observable } from 'rxjs';
import makeWebSocketObservable, {
  GetWebSocketResponses,
} from 'rxjs-websockets';
import { retry, switchMap } from 'rxjs/operators';

export declare type WebSocketPayload = string | ArrayBuffer | Blob;

@Injectable({ providedIn: 'root' })
export class WebSocketService {
  private inputMessages$ = new QueueingSubject<WebSocketPayload>();
  private readonly RETRY_COUNT = 5;

  get baseSocketEndpoint(): string {
    return this.config.baseWebSocketEndpoint;
  }

  constructor(private config: BaseConfig) {}

  send = (payload: WebSocketPayload): void => {
    this.inputMessages$.next(payload);
  };

  subscribeToSocket$ = (uri: string): Observable<WebSocketPayload> => {
    return makeWebSocketObservable(uri).pipe(
      switchMap((getResponses: GetWebSocketResponses) =>
        getResponses(this.inputMessages$)
      ),
      retry(this.RETRY_COUNT)
    );
  };
}
