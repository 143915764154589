<ng-container *transloco="let transl">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    
    <div class="logo"></div>
    <vt-ui-language-select [setUserLanguage]="false"></vt-ui-language-select>
  </div>
  
  <div class="content"  *ngIf="siteMessage else elseBlock" >
    <div class="message">
      <main class="es-gateway-body">
        <section
          class="es-timer-popup-layout es-layout-columns -es-1-column es-gateway-column -es-flex-horizontal"
        >
          <div class="es-column es-visit-timer-column">
            <div class="es-content-box">
              <div class="es-panel es-custom-panel">
                <header class="es-card-header">
                  <h2>{{ transl('visit.request_control_dialog.site_title') }}</h2>
                </header>
                <div  class="es-card-body">
                  <div class="es-content-box">
                    <p id="body-text-paragraph-id">
                      {{ transl('visit.request_control_dialog.site_message') }}
                    </p>
                  </div>
                </div>

                <footer class="es-timer-popup-footer">
                  <div class="es-toolbar">
                    <ul class="es-right">
          
                      <li class="es-toolbar-item">
                        <button class="es-button -es-primary"  [mat-dialog-close]="true" (click)="handleRequestOKClicked()">
                            {{ transl('visit.site.request_control') }}
                        </button>
                      </li>
                      <li class="es-toolbar-item">
                        <button class="es-button " [mat-dialog-close]="false">
                          {{ transl('general.cancel') }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="content" >
      
      <div class="message">
        <main class="es-gateway-body">
          <section
            class="es-timer-popup-layout es-layout-columns -es-1-column es-gateway-column -es-flex-horizontal"
          >
            <div class="es-column es-visit-timer-column">
              <div class="es-content-box">
                <div class="es-panel es-custom-panel">
                  <header class="es-card-header">
                    <h2>
                      {{ transl('visit.request_control_dialog.subject_title') }}
                    </h2>
                  </header>
                  <div  class="es-card-body">
                    <div class="es-content-box">
                      <p id="body-text-paragraph-id">
                        {{ transl('visit.request_control_dialog.subject_message') }}
                      </p>
                    </div>
                  </div>
  
                  <footer class="es-timer-popup-footer">
                    <div class="es-toolbar">
                      <ul class="es-right">
            
                        <li class="es-toolbar-item">
                          <button class="es-button -es-primary"  [mat-dialog-close]="true">
                            {{ transl('visit.request_control_dialog.subject_confirm') }}
                          </button>
                        </li>
                        <li class="es-toolbar-item">
                          <button class="es-button " [mat-dialog-close]="false" (click)="handleDeclineClicked()">
                            {{ transl('visit.request_control_dialog.subject_deny') }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  </ng-template>
</ng-container>
