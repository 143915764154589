import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { SessionRouteConfiguration } from '@virtual-trials-workspace/models';
import { TokenService } from '@virtual-trials-workspace/shared-core-services';
import { RootSelectors } from '@virtual-trials-workspace/store';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {
  constructor(
    private tokenService: TokenService,
    private router: Router,
    private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(RootSelectors.getIsAuthenticated),
      switchMap(isAuthenticated => this.handle(isAuthenticated, route)),
      catchError(this.handleError.bind(this))
    );
  }

  private handle = (isAuthenticated: boolean, route: ActivatedRouteSnapshot): Promise<boolean> => {
    if (isAuthenticated) { 
      const tokenRaw = this.tokenService.getToken();

      if (tokenRaw && !this.tokenService.parse(tokenRaw).hasExpired()) {
        return Promise.resolve(true);
      }
    }

    const config = SessionRouteConfiguration.resolveFrom(route) ?? SessionRouteConfiguration.default;

    return this.router
      .navigate([config.redirectTo], { skipLocationChange: config.skipLocationChange })
      .then(() => false);
  }

  private handleError = (e): Observable<boolean> => {
    console.error(e);
    return throwError(e);
  }
}
