<mat-toolbar class="vv-toolbar" fxLayout="wrap" *transloco="let transl">
  <button id="sidenav-button-id" mat-icon-button (click)="sideNavClick()">
    <mat-icon class="menu">menu</mat-icon>
  </button>
  <div class="vv-toolbar__title">
    {{ transl('subject_list.subjects') }}
  </div>
  <div
    class="vv-toolbar__logo"
    fxFlex="1 1"
    fxLayout="wrap"
    fxLayoutAlign="center"
  >
    <!-- TODO:: svg clario white logo -->
  </div>
  <div class="vv-search" [ngClass]="status ? '__open' : ''">
    <form class="form">
      <mat-form-field>
        <input
          matInput
          placeholder="Search Subjects"
          (keyup)="handleSearch()"
          [(ngModel)]="searchText"
          name="search"
        />
      </mat-form-field>
    </form>
    <button
      id="search-button-id"
      mat-icon-button
      (click)="handleSearchButtonClick()"
    >
      <mat-icon class="search">search</mat-icon>
    </button>
  </div>

  <button id="add-button-id" mat-icon-button (click)="addSubjectClick()">
    <mat-icon class="add">add</mat-icon>
  </button>
</mat-toolbar>
