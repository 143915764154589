import { Routes } from '@angular/router';

import { ErrorComponent } from './error';
import {
  AnonymousParticipantGuard,
  ParticipantGuard,
  SiteUserGuard,
} from './guards';
import { ProvisionerGuard } from './guards/provisioner-journey/provisioner-journey.guard';
import { LoginComponent } from './login';
import { PlatformGuard } from './guards/platform.guard';
import { PlatformErrorComponent } from './platform-error/platform-error.component';
import { AutoDetectLanguageGuard } from './guards/anonymous/auto-detect-language.guard';
import {
  AuthPageTranslationsGuard,
  UnauthPageTranslationsGuard,
} from '@virtual-trials-workspace/shared-guards';
import { GeneralErrorComponent } from './general-error/general-error.component';

export const routes: Routes = [
  {
    canLoad: [PlatformGuard],
    path: 'account',
    loadChildren: () =>
      import('@virtual-trials-workspace/features/account').then(
        (m) => m.AccountModule
      ),
  },
  {
    canLoad: [PlatformGuard],
    path: 'activate',
    loadChildren: () =>
      import('@virtual-trials-workspace/features/activate').then(
        (m) => m.ActivateModule
      ),
  },
  {
    canLoad: [PlatformGuard],
    canActivate: [SiteUserGuard, AuthPageTranslationsGuard],
    path: 'sites',
    loadChildren: () =>
      import('@virtual-trials-workspace/features/site-journey').then(
        (m) => m.SiteJourneyModule
      ),
  },
  {
    canLoad: [PlatformGuard],
    canActivate: [ProvisionerGuard, AuthPageTranslationsGuard],
    path: 'provisioner',
    loadChildren: () =>
      import('@virtual-trials-workspace/features/provisioner-journey').then(
        (m) => m.ProvisionerJourneyModule
      ),
  },
  {
    path: 'gssologin',
    loadChildren: () =>
      import('@virtual-trials-workspace/features/gsso').then(
        (m) => m.GssoModule
      ),
  },
  {
    canLoad: [PlatformGuard, AnonymousParticipantGuard],
    canActivate: [AuthPageTranslationsGuard, ParticipantGuard],
    path: 'visit',
    loadChildren: () =>
      import('@virtual-trials-workspace/features/visit').then(
        (m) => m.VisitModule
      ),
  },
  {
    canLoad: [PlatformGuard, AutoDetectLanguageGuard],
    path: 'standalone',
    loadChildren: () =>
      import('@virtual-trials-workspace/features/standalone').then(
        (m) => m.StandaloneModule
      ),
  },
  {
    canActivate: [UnauthPageTranslationsGuard],
    path: 'error',
    children: [
      {
        path: '',
        component: ErrorComponent,
      },
      {
        path: 'platform',
        component: PlatformErrorComponent,
      },
    ],
  },
  {
    canActivate: [UnauthPageTranslationsGuard],
    path: 'generalerror',
    children: [
      {
        path: '',
        component: GeneralErrorComponent,
      }
    ],
  },
  {
    canActivate: [UnauthPageTranslationsGuard, PlatformGuard],
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
