<ng-container
  [ngTemplateOutlet]="!declinesBrowserPermission ? askTempl : deniedTempl"
>
</ng-container>

<ng-template #askTempl>
  <vt-visit-content-sheet
    id="ask"
    *transloco="let transl"
    [headerText]="transl('visit.video_call.device_permission.asking_title')"
    [primaryActionText]="transl('general.accept')"
    [secondaryActionText]="transl('general.decline')"
    (primaryAction)="acceptPermissionsClick()"
    (secondaryAction)="declinePermissionsClick()"
  >
    <div cardBody>
      <header class="es-card-header">
        <h2>{{transl('visit.video_call.device_permission.asking_title')}}</h2>
      </header>
      <div class="es-card-body">
        <div class="es-content-box">
          <div class="es-notification -es-info enable-camera-text">
            <span class="es-icon es-icon-info"></span>
            <p>
              {{
                transl('visit.video_call.device_permission.enable_camera_text')
              }}
            </p>
          </div>
          
          <p>{{ transl('visit.video_call.device_permission.accept_text') }}</p>
          <p>{{ transl('visit.video_call.device_permission.decline_text') }}</p>
        </div>
      </div>
    </div>
  </vt-visit-content-sheet>
</ng-template>

<ng-template #deniedTempl>
  <vt-visit-content-sheet
    id="deny"
    *transloco="let transl"
    [headerText]="transl('visit.video_call.device_permission.deny_title')"
    [primaryActionText]="
      transl('visit.video_call.device_permission.start_again')
    "
    [secondaryActionText]="
      transl('visit.video_call.device_permission.leave_visit')
    "
    (primaryAction)="startAgainClick()"
    (secondaryAction)="leaveVisitClick()"
  >
    <div cardBody>
      <header class="es-card-header">
        <h2>{{transl('visit.video_call.device_permission.deny_title')}}</h2>
      </header>
      <div class="es-card-body">
        
        <div class="es-content-box">
          <p>
            {{
              transl('visit.video_call.device_permission.security_video_audio')
            }}
          </p>
          <p>
            {{ transl('visit.video_call.device_permission.change_permission') }}
          </p>
        </div>
      </div>
    </div>
  </vt-visit-content-sheet>
</ng-template>
