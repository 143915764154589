import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import * as activateFromStore from '@virtual-trials-workspace/features/activate';
import { HttpError, SecurityQuestion } from '@virtual-trials-workspace/models';
import * as FormUtils from '@virtual-trials-workspace/shared-utils';
import {
  LanguageHttpService,
  SessionActions,
} from '@virtual-trials-workspace/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import * as FromStore from '../store';

@Component({
  selector: 'vt-account-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  readonly confirmPasswordControlName = 'confirmPassword';
  readonly passwordControlName = 'password';
  readonly securityQuestionAnswerControlName = 'securityQuestionAnswer';

  formGroup: UntypedFormGroup;
  maxAnswerAttemptsReached = false;
  securityQuestion: SecurityQuestion;

  isLoading$ = this.store.pipe(select(FromStore.Selectors.getIsLoading));
  passwordResetToken$ = this.store.pipe(
    select(FromStore.Selectors.getPasswordResetToken)
  );

  get answerFormControl(): AbstractControl {
    return this.getFormControl(this.securityQuestionAnswerControlName);
  }

  get answerControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.answerFormControl.errors);
  }

  get answerControlLengthError(): boolean {
    return FormUtils.hasLengthErrors(this.answerFormControl.errors);
  }

  get answerControlNoMatchApiError(): boolean {
    return this.answerFormControl.errors?.noMatchApiError;
  }

  get confirmPasswordFormControl(): AbstractControl {
    return this.getFormControl(this.confirmPasswordControlName);
  }

  get confirmPasswordControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.confirmPasswordFormControl.errors);
  }

  get confirmPasswordControlMatchError(): boolean {
    return FormUtils.hasValuesDontMatchError(
      this.confirmPasswordFormControl.errors
    );
  }

  get passwordFormControl(): AbstractControl {
    return this.getFormControl(this.passwordControlName);
  }

  get passwordControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.passwordFormControl.errors);
  }

  get passwordControlCriteriaError(): boolean {
    return FormUtils.hasRegexError(this.passwordFormControl.errors);
  }

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<FromStore.AccountState>,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private languageService: LanguageHttpService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initNoQueryStringValueStream();
    this.initHandleQueryStringStream();
    this.initHandleSecurityQuestionStream();
    this.initHandleApiErrorStream();
    this.initResetPasswordSuccessStream();
    this.initSubscriptionToQueryParams();
  }

  private initSubscriptionToQueryParams = (): void => {
    this.store
      .pipe(
        take(1),
        select(activateFromStore.Selectors.getLanguageCodeFromRouter),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (languageCode) =>
          languageCode && this.handleLanguageCodeInQueryString(languageCode)
      );
  };

  handleLanguageCodeInQueryString = (languageCode: string): void => {
    this.languageService.updateSelectedLanguage(languageCode);
    this.translocoService.setActiveLang(languageCode);
    this.store.dispatch(
      SessionActions.getTranslationFiles({ language: languageCode })
    );
  };

  private initHandleSecurityQuestionStream = (): void => {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromStore.Selectors.getSecurityQuestionForPasswordReset)
      )
      .subscribe(
        (securityQuestion) => (this.securityQuestion = securityQuestion)
      );
  };

  private initHandleApiErrorStream = (): void => {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromStore.Selectors.getError),
        filter((error) => !!error)
      )
      .subscribe((error) => this.handleApiError(error));
  };

  private handleApiError = (error: HttpError): void => {
    if (error.code === 403) {
      this.maxAnswerAttemptsReached = true;
      this.formGroup.disable({ onlySelf: true });
    }

    if (error.code === 400) {
      this.answerFormControl.setErrors({ noMatchApiError: true });
    }

    if (error.code === 500) {
      this.unsubscribe$.next();
      this.router.navigateByUrl('/error');
    }
  };

  private initResetPasswordSuccessStream = (): void => {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromStore.Selectors.getPasswordResetSuccess),
        filter((isSuccess) => !!isSuccess)
      )
      .subscribe(() => this.handleResetPasswordSuccess());
  };

  private handleResetPasswordSuccess = (): void => {
    this.snackBar.open(
      this.translocoService.translate('text.reset_password.reset_success'),
      null,
      {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      }
    );

    this.snackBar._openedSnackBarRef.afterDismissed().subscribe(() => {
      this.unsubscribe$.next();
      this.router.navigateByUrl('/login');
    });
  };

  private initForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      securityQuestionAnswer: this.initSecurityQuestionAnswerControl(),
      password: this.initPasswordControl(),
      confirmPassword: this.initConfirmPasswordControl(),
    });
  };

  private initSecurityQuestionAnswerControl = (): AbstractControl => {
    return new UntypedFormControl(
      {
        value: undefined,
        disabled: this.maxAnswerAttemptsReached,
      },
      [Validators.required, Validators.maxLength(256), Validators.minLength(2)]
    );
  };

  private initPasswordControl = (): AbstractControl => {
    return new UntypedFormControl(
      {
        value: undefined,
        disabled: this.maxAnswerAttemptsReached,
      },
      [Validators.required, FormUtils.doesNotMatchPasswordRegex()]
    );
  };

  private initConfirmPasswordControl = (): AbstractControl => {
    return new UntypedFormControl(
      {
        value: undefined,
        disabled: this.maxAnswerAttemptsReached,
      },
      [Validators.required, FormUtils.matchValues(this.passwordControlName)]
    );
  };

  private initHandleQueryStringStream = (): void => {
    this.passwordResetToken$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((queryStringValue) => !!queryStringValue)
      )
      .subscribe((token) =>
        this.store.dispatch(
          FromStore.Actions.getSecurityQuestion({
            passwordResetToken: token,
          })
        )
      );
  };

  private initNoQueryStringValueStream = (): void => {
    this.passwordResetToken$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((queryStringValue) => !queryStringValue)
      )
      .subscribe(() => this.router.navigateByUrl('/login'));
  };

  handleSubmitClick = (): void => {
    this.formGroup.valid
      ? this.resetPassword()
      : FormUtils.runFormFieldValidation(this.formGroup);
  };

  handleBackToLoginClick = (): void => {
    this.unsubscribe$.next(); // end subscriptions to querystring and router before redirecting
    this.router.navigateByUrl('/login');
  };

  private resetPassword = (): void => {
    this.store.dispatch(
      FromStore.Actions.resetPassword({
        answer: this.answerFormControl.value,
        password: this.passwordFormControl.value,
        questionId: this.securityQuestion.questionId,
      })
    );
  };

  handleNewResetPasswordRequestLinkClick = (): void => {
    this.unsubscribe$.next(); // end subscriptions to querystring and router before redirecting
    this.router.navigateByUrl('/resend-password');
  };

  getFormControl = (name: string): AbstractControl => {
    return this.formGroup.controls[name];
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
}
