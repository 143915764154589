import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max';

// since regex doesn't support AND use the OR to match invalid cases and then invert the test
// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
export const passwordRegExp = new RegExp(
  /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/
);

export const matchValues = (
  controlToMatchTo: string
): ((c: AbstractControl) => ValidationErrors | null) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;
    return !!parent &&
      !!parent.value &&
      control.value === parent.controls[controlToMatchTo].value
      ? null
      : { noMatch: true };
  };
};

export const doesNotMatchRegex = (
  regex: RegExp
): ((c: AbstractControl) => ValidationErrors | null) => {
  return (control: AbstractControl): ValidationErrors | null => {
    return !!control && !!control.value && !regex.test(control.value)
      ? null
      : { failsRegexCheck: true };
  };
};

export const doesNotMatchPasswordRegex = (): ((
  c: AbstractControl
) => ValidationErrors | null) => {
  return doesNotMatchRegex(passwordRegExp);
};

export const runFormFieldValidation = (formGroup: UntypedFormGroup): void => {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);

    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
    }

    if (control instanceof UntypedFormGroup) {
      runFormFieldValidation(control);
    }
  });
};

export const hasLengthErrors = (errors?: ValidationErrors): boolean => {
  return (errors && !!errors?.minlength) || !!errors?.maxlength;
};

export const hasRequiredError = (errors?: ValidationErrors): boolean => {
  return errors && !!errors?.required;
};

export const hasValuesDontMatchError = (errors?: ValidationErrors): boolean => {
  return errors && !!errors?.noMatch;
};

export const hasRegexError = (errors?: ValidationErrors): boolean => {
  return errors && !!errors?.failsRegexCheck;
};

export const hasEmailError = (errors?: ValidationErrors): boolean => {
  return errors && !!errors?.email;
};

export const internationalPhoneNumberValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  const parsedPhoneNumber = parseMax(`+${control.value}`);

  return parsedPhoneNumber?.isValid() ? null : { phoneInvalid: true };
};
