import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { RequestControlDialogComponent } from './dialog/request-control-dialog.component';
import {
  SessionSelectors,
  VisitActions,
  VisitSelectors,
} from '@virtual-trials-workspace/store';

@Injectable({ providedIn: 'root' })
export class RequestControlDialogService {
  private _showRequestSub$: Subscription;
  constructor(private dialog: MatDialog, private store: Store) {
    this.subscribeToCancelSendingRequest();
  }

  private requestControlDialogOutput$ = new BehaviorSubject<boolean>(false);
  private ref: MatDialogRef<RequestControlDialogComponent, any>;

  get requestControlOutput$(): Observable<boolean> {
    return this.requestControlDialogOutput$.asObservable();
  }

  private get isSiteUser$(): Observable<boolean> {
    return this.store.pipe(
      select(SessionSelectors.getParticipantType),
      map((participantType) => participantType === 'user')
    );
  }

  subscribeToShowRequestControlDialog = (): void => {
    if (!this._showRequestSub$) {
      this._showRequestSub$ = this.store
        .pipe(
          select(VisitSelectors.getEmulationRequestControlConfirmationPending),
          filter((pending) => !!pending),
          withLatestFrom(this.isSiteUser$)
        )
        .subscribe(([_, isSiteUser]) => this.showDialog(isSiteUser));
    }
  };

  private subscribeToCancelSendingRequest = (): void => {
    this.requestControlOutput$
      .pipe(filter((result) => !result))
      .subscribe(() =>
        this.store.dispatch(VisitActions.emulationRequestControlCancel())
      );
  };

  private showDialog = (isSiteUser: boolean): void => {
    const dialogConfig: MatDialogConfig = {
      closeOnNavigation: true,
      disableClose: true,
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      panelClass: 'session-timeout-dialog-panel',
      scrollStrategy: new NoopScrollStrategy(),
      data: { siteMessage: isSiteUser },
    };

    const ref = this.dialog.open(RequestControlDialogComponent, dialogConfig);

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => this.requestControlDialogOutput$.next(result));
  };
}
