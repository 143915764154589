<mat-card *transloco="let transl">
  <div class="card-title">
    {{ transl('create_account.title') }}
  </div>
  <div class="card-subtitle">
    {{ transl('create_account.subtitle') }}
  </div>
  <mat-card-content>
    <form class="form" [formGroup]="siteFormGroup">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>{{ transl('create_account.enter_username') }}</mat-label>
        <input
          matInput
          formControlName="userName"
          [errorStateMatcher]="siteApiErrorControlErrorMatcher()"
        />
        <mat-error *ngIf="getErrorForSiteFormControl('userName', 'required')">
          {{ userNameRequiredErrorMessage }}
        </mat-error>
        <mat-error *ngIf="!!apiError">
          {{ apiError }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="fill">
        <mat-label>{{transl('user.account.login_password_placeholder')}}</mat-label>
        <input
          matInput
          formControlName="password"
          type="password"
        />
        <mat-error *ngIf="getErrorForSiteFormControl('password', 'required')">
          {{ passwordRequiredErrorMessage }}
        </mat-error>
        <mat-error
          *ngIf="
            getErrorForSiteFormControl('password', 'passwordInvalid') &&
            !getErrorForSiteFormControl('password', 'required')
          "
        >
          {{ passwordDoesntMeetCriteriaErrorMessage }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="fill">
        <mat-label>{{transl('user.account.re_enter_password')}}</mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          type="password"
        />
        <mat-error
          *ngIf="getErrorForSiteFormControl('confirmPassword', 'required')"
        >
          {{ confirmPasswordRequiredErrorMessage }}
        </mat-error>
        <mat-error
          *ngIf="
            getErrorForSiteFormControl('confirmPassword', 'passwordsDontMatch')
          "
        >
          {{ passwordsDontMatchErrorMessage }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      color="primary"
      [disabled]="submitInProgress || siteFormGroup.invalid"
      (click)="createSiteAccountClick.emit()"
    >
    {{ transl('create_account.create') }}
    </button>
  </mat-card-actions>
</mat-card>

<!-- <section
  *transloco="let transl"
  class="es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal es-create-account-layout"
>
  <div class="es-column">
    <div class="es-content-box">
      <div class="es-panel es-custom-panel">
        <div class="es-form">
          <div class="es-content-box">
            <div class="es-text-group">
              <h2>{{ transl('create_account.title') }}</h2>
            </div>
          </div>
          <form class="form" [formGroup]="siteFormGroup">
            <div class="es-form-field">
              <label class="es-label -es-required">
                {{ transl('create_account.enter_username') }}
              </label>
              <input
                formControlName="userName"
                [errorStateMatcher]="siteApiErrorControlErrorMatcher()"
                type="password" 
                class="es-input" 
                [ngClass]="{'-es-invalid': 
                getErrorForSiteFormControl('email', 'required') ||
                !!apiError
                }"
                [placeholder]="transl('create_account.enter_username')"
              />

              <div class="error-container">
                <div 
                  *ngIf="
                    getErrorForSubjectFormControl('userName', 'required')
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ userNameRequiredErrorMessage }}</p>
                </div>
              </div>
              <div class="error-container">
                <div 
                  *ngIf="
                    !!apiError
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ apiError }}</p>
                </div>
              </div>
            </div>


            <div class="es-form-field">
              <label class="es-label -es-required">
                {{transl('user.account.login_password_placeholder')}}
              </label>
              <input
                formControlName="password"
                type="password"
                
                class="es-input" 
                [ngClass]="{'-es-invalid': 
                  getErrorForSubjectFormControl('email', 'required') ||
                  getErrorForSubjectFormControl('email', 'email') 
                }"
                [placeholder]="transl('user.account.login_password_placeholder')"
              />

              <div class="error-container">
                <div 
                  *ngIf="
                  getErrorForSiteFormControl('password', 'required')
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ passwordRequiredErrorMessage }}</p>
                </div>
              </div>
              <div class="error-container">
                <div 
                  *ngIf="getErrorForSiteFormControl('password', 'passwordInvalid') &&
                        !getErrorForSiteFormControl('password', 'required')
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ passwordDoesntMeetCriteriaErrorMessage }}</p>
                </div>
              </div>
            </div>


            <div class="es-form-field">
              <label class="es-label -es-required">
                {{transl('user.account.re_enter_password')}}
              </label>
              <input
                formControlName="confirmPassword"
                type="password"
                
                class="es-input" 
                [ngClass]="{'-es-invalid': 
                  getErrorForSubjectFormControl('email', 'required') ||
                  getErrorForSubjectFormControl('email', 'email') 
                }"
                [placeholder]="transl('user.account.re_enter_password')"
              />
              
              <div class="error-container">
                <div 
                  *ngIf="
                  getErrorForSiteFormControl('confirmPassword', 'required')
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ confirmPasswordRequiredErrorMessage }}</p>
                </div>
              </div>
              <div class="error-container">
                <div 
                  *ngIf="getErrorForSiteFormControl('confirmPassword', 'passwordsDontMatch')
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ passwordsDontMatchErrorMessage }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
        
        <div class="es-toolbar">
          <ul class="es-right">
            <li class="es-toolbar-item">
              <button
                  class="es-button  -es-primary "
                  [disabled]="submitInProgress || siteFormGroup.invalid"
                  (click)="createSiteAccountClick.emit()"
                >
                {{ transl('create_account.create') }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> -->