<ng-container *transloco="let transl">
    <div class="es-content-box">
        <div class="es-panel es-custom-panel" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon class="icon" aria-hidden="false" aria-label="viewport error">error_outline</mat-icon>
            <ng-content select="[before]"></ng-content>
            <div class="text" fxLayout="column" fxLayoutAlign="center center">
                <span>{{ transl(errorMessageKey, { fallback: errorMessage }) }}</span>
                <span>{{ transl(resolutionMessageKey, { fallback: resolutionMessage }) }}</span>
            </div>
            <ng-content select="[after]"></ng-content>
        </div>
    </div>
</ng-container>
