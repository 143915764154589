import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { DeviceStatus, LicenseModel } from '@virtual-trials-workspace/models';

@Component({
  selector: 'vt-site-journey-visit-options',
  templateUrl: './visit-options.component.html',
  styleUrls: ['./visit-options.component.scss'],
})
export class VisitOptionsComponent implements OnInit {
  @Input()
  studyLicenses$: Observable<Array<LicenseModel>>;

  @Input()
  deviceStatus$: Observable<DeviceStatus>;

  @Output()
  productOptionsSelected = new EventEmitter<{
    ecoaLive: boolean;
    virtualVisits: boolean;
  }>();

  @Output()
  clickHereLink = new EventEmitter();

  hasEcoaLiveOption: boolean;
  hasVirtualVisitsOption: boolean;
  formGroup: UntypedFormGroup;
  deviceStatus: DeviceStatus;
  deviceStatusEnum = DeviceStatus;
  ecoaLiveOptionChecked = false;
  virtualVisitsOptionChecked = false;

  private availableProductBorderHexValue = '#d8d8d8';
  private selectedProductBorderHexValue = '#a11268';

  get ecoaLiveFormControl(): AbstractControl {
    return this.getFormControl('ecoaLiveOption');
  }

  get virtualVisitsFormControl(): AbstractControl {
    return this.getFormControl('virtualVisitsOption');
  }

  constructor(private dialogRef: MatDialogRef<VisitOptionsComponent>) {}

  ngOnInit(): void {
    this.initProductOptions();
    this.initForm();
    this.formGroup?.controls.ecoaLiveOption.valueChanges.subscribe((checked) => {
      this.ecoaLiveOptionChecked = checked;
    });
    this.formGroup?.controls.virtualVisitsOption.valueChanges.subscribe((checked) => {
      this.virtualVisitsOptionChecked = checked;
    });
  }

  private initProductOptions = (): void => {
    this.studyLicenses$.subscribe((licenses) => {
      licenses.forEach((license) => this.setLicenseOptions(license));
    });
  };

  private subscribeToIsDeviceInUse = (): void => {
    this.deviceStatus$.subscribe((status: DeviceStatus) => {
      this.deviceStatus = status;
      this.setEcoaLiveOption(status);
    });
  };

  private setEcoaLiveOption(status: DeviceStatus) {
    switch (status) {
      case DeviceStatus.InUse:
      case DeviceStatus.NotSetup:
        this.formGroup?.controls.ecoaLiveOption.disable();
        break;

      case DeviceStatus.Available:
        this.formGroup?.controls.ecoaLiveOption.enable();
        if (!this.hasVirtualVisitsOption) {
          this.formGroup?.controls.ecoaLiveOption.setValue(true);
        }
        break;
    }
  }

  private setLicenseOptions = (license: LicenseModel): void => {
    const { licenseName, licenseStatus } = license;

    if (licenseName === 'Emulation') {
      this.subscribeToIsDeviceInUse();
    }

    this.hasEcoaLiveOption =
      licenseName === 'Emulation' ? licenseStatus : this.hasEcoaLiveOption;

    this.hasVirtualVisitsOption =
      licenseName === 'Call' ? licenseStatus : this.hasVirtualVisitsOption;
  };

  private initForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      ecoaLiveOption: this.createEcoaLiveFormControl(),
      virtualVisitsOption: this.createVirtualVisitsFormControl(),
    });
  };

  private createEcoaLiveFormControl = (): UntypedFormControl => {
    return new UntypedFormControl({
      value: false,
      disabled: true,
    });
  };

  private createVirtualVisitsFormControl = (): UntypedFormControl => {
    return new UntypedFormControl({
      value: !this.hasEcoaLiveOption && this.hasVirtualVisitsOption,
      disabled: !this.hasVirtualVisitsOption,
    });
  };

  getEcoaLiveBorderStyle = (): string => {
    return this.getBorderStyleForProduct(this.ecoaLiveFormControl);
  };

  onClickHereLinkClick = (): void => {
    this.clickHereLink.emit();
  };

  public get checkingDeviceInUse(): boolean {
    return (
      this.hasEcoaLiveOption && this.deviceStatus === DeviceStatus.Checking
    );
  }

  getVirtualVisitsBorderStyle = (): string => {
    return this.getBorderStyleForProduct(this.virtualVisitsFormControl);
  };

  private getBorderStyleForProduct = (control: AbstractControl): string => {
    const colourValue = control.value
      ? this.selectedProductBorderHexValue
      : this.availableProductBorderHexValue;

    return `solid 1px ${colourValue}`;
  };

  private getFormControl = (name: string): AbstractControl => {
    return this.formGroup.controls[name];
  };

  handleClose = (): void => {
    this.dialogRef.close();
  };

  disableNextButton = (): boolean => {
    return (
      this.ecoaLiveFormControl.value || this.virtualVisitsFormControl.value
    );
  };

  handleNextClick = (): void => {
    if (this.formGroup.valid) {
      this.emitSelectedProductValues();
      this.dialogRef.close();
    }
  };

  private emitSelectedProductValues = (): void => {
    this.productOptionsSelected.emit({
      ecoaLive: !!this.ecoaLiveFormControl.value,
      virtualVisits: !!this.virtualVisitsFormControl.value,
    });
  };
}
