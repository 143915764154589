import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vt-visit-mobile-restriction-sheet',
  templateUrl: './mobile-restriction-sheet.component.html',
  styleUrls: ['./mobile-restriction-sheet.component.scss']
})
export class MobileRestrictionSheetComponent {

}
