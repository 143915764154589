import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vt-site-journey-remove-subject-failure',
  templateUrl: './remove-subject-failure.component.html',
  styleUrls: ['./remove-subject-failure.component.scss'],
})
export class RemoveSubjectFailureComponent {
  constructor(private dialogRef: MatDialogRef<RemoveSubjectFailureComponent>) {}

  handleOkClicked = (): void => {
    this.dialogRef.close();
  };
}
