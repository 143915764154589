<ng-container *transloco="let transl">
  <div class="wrapper" fxLayout="column" fxLayoutAlign="center center">
    <main class="es-gateway-body">
      <section
        class="es-welcome-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
      >
      
        <div class="es-column"
          [ngClass]="es-visit-assessment-form"
        >
          <div class="es-content-box" >
            <div class="es-panel es-custom-panel es-welcome-panel">
              <div class="es-form">
                <div class="es-content-box">
                  <div class="es-text-group">
                    <ng-container
                      *ngIf="{ value: hasVisitTimedOut$ | async } as visitEndContext"
                    >
                      <ng-container
                        *ngIf="!!visitEndContext.value; else manualTmpl"
                        [ngTemplateOutlet]="timeoutTmpl"
                      ></ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>

  <ng-template #timeoutTmpl>
    <div class="-es-data">
      <div class="es-card-body">
        <div class="es-content-box">
          <div class="timer-icon"></div>
          <!-- <h2>{{
            transl('session_complete_screen.session_expired', {
              fallback: 'Session Expired.'
            })
          }}</h2> -->
          <div class="text" fxLayout="column" fxLayoutAlign="center center">
            <span>{{
                  transl('session_complete_screen.session_expired', {
                    fallback: 'Session Expired.'
                  })
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #manualTmpl>
    <div class="-es-data">
      <div class="es-card-body">
        <div class="es-content-box">
          <div class="session-complete-icon"></div>
          <!-- <lottie-player
            src="/assets/web/images/done.json"
            class="player"
            background=""
            speed="1"
            loop
            autoplay
            #player
          ></lottie-player> -->
          <span class="text text-pushdownX" [innerHtml]=" transl('session_complete_screen.session_complete', {
            fallback: 'Session Complete. Thank you!'
          }) ">
          </span>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
