import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { WINDOW } from '@virtual-trials-workspace/shared-core-services';

@Component({
  selector: 'vt-ui-preferred-language-control',
  templateUrl: './preferred-language-control.component.html',
  styleUrls: ['./preferred-language-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreferredLanguageControlComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  private class = 'session-timeout-container';
  private _bodyOverflowValue: string;
  constructor(  @Inject(WINDOW) private window: Window,) { }


  private setBodyOverflowTo = (value: string): void => {
    this.window.document.body.style.overflow = value;
  }
  ngOnInit(): void {
    this._bodyOverflowValue = this.window.document.body.style.overflow;
    this.setBodyOverflowTo('hidden');
  }

  ngOnDestroy(): void {
    this.setBodyOverflowTo(this._bodyOverflowValue);

  }
}
