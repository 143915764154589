import {
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WINDOW } from '@virtual-trials-workspace/shared-core-services';
import { Subject, timer } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'vt-ui-visit-session-extend-dialog',
  templateUrl: './visit-session-extend-dialog.component.html',
  styleUrls: ['./visit-session-extend-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisitSessionExtendDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  private class = 'session-timeout-container';
  private _bodyOverflowValue: string;
  private _stopCountDown$ = new Subject();
  private countDownValue: number;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.setCountDown();
    this._bodyOverflowValue = this.window.document.body.style.overflow;
    this.setBodyOverflowTo('hidden');
  }
  ngOnDestroy(): void {
    this.setBodyOverflowTo(this._bodyOverflowValue);
    this._stopCountDown$.next();
    this._stopCountDown$.complete();
  }

  private setBodyOverflowTo = (value: string): void => {
    this.window.document.body.style.overflow = value;
  };
  private setCountDown = (): void => {
    this.countDownValue = this.data.endTime - new Date().getTime();
    this.countDownValue = (this.countDownValue < 0 ? 0 : this.countDownValue);
    this._stopCountDown$.next();

    timer(0, 1000)
      .pipe(
        takeUntil(this._stopCountDown$),
        takeWhile(() => this.countDownValue > 0)
      )
      .subscribe(
        () =>
          (this.countDownValue =
            this.countDownValue >= 1000 ? this.countDownValue - 1000 : 0)
      );
  };

  @HostListener('window:visibilitychange')
  private onVisibilityChange = (): void => {
    if (!this.window.document.hidden) {
      this.setCountDown();
    }
  };
}
