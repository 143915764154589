<div class="video-call-actions">
  <div
    class="video-call-action-item"
    [ngClass]="
      videoIsEnabled
        ? 'video-call-action-item-on'
        : 'video-call-action-item-off'
    "
  >
    <button
      id="video-call-btn"
      data-testid="video-call-action"
      aria-label="toggle video on and off"
      (click)="handleVideoButtonClick()"
    >
      <mat-icon id="video-call-icon" data-testid="video-call-icon-id">{{
        videoIsEnabled ? 'videocam' : 'videocam_off'
      }}</mat-icon>
    </button>
  </div>
  <div
    class="video-call-action-item"
    [ngClass]="
      micIsEnabled ? 'video-call-action-item-on' : 'video-call-action-item-off'
    "
  >
    <button
      id="mic-btn"
      data-testid="mic-action"
      aria-label="toggle mic on and off"
      (click)="handleMicButtonClick()"
    >
      <mat-icon id="mic-icon" data-testid="mic-icon-id">{{
        micIsEnabled ? 'mic' : 'mic_off'
      }}</mat-icon>
    </button>
  </div>
  <div class="video-call-action-item video-call-action-item-on">
    <button
      id="switch-camera-btn"
      data-testid="switch-camera-action"
      aria-label="switch camera"
      (click)="handleSwitchCameraClick()"
    >
      <mat-icon id="switch-camera-icon" data-testid="switch-camera-icon-id"
        >flip_camera_ios</mat-icon
      >
    </button>
  </div>
</div>
