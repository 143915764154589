<ng-container *transloco="let transl">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <!-- <mat-icon class="logo" svgIcon="ert-logo"></mat-icon> -->
    <div class="logo"></div>
    <vt-ui-language-select [setUserLanguage]="false"></vt-ui-language-select>
  </div>
  <div class="content" fxLayout="column">
    <div
      class="message"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
    >
      <div class="emulation-error-icon">
        <mat-icon
          class="emulation-error-icon__tablet"
          aria-hidden="false"
          aria-label="visit error"
          >tablet</mat-icon
        >
        <mat-icon
          class="emulation-error-icon__error"
          aria-hidden="false"
          aria-label="visit error"
          >error_outline</mat-icon
        >
      </div>
      <div class="text" fxLayout="column" fxLayoutAlign="center center">
        <h4>
          {{ transl('visit.emulation.device_service_failed') }}
        </h4>
        <span>{{
          transl('visit.emulation.device_service_failed_instructions')
        }}</span>
      </div>
    </div>
    <div
      class="control-panel"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="12px"
    >
      <button id="logout" mat-button [mat-dialog-close]="true" color="warn">
        <span class="material-icons call-end">call_end</span>
        {{ transl('visit.site.end_session') }}
      </button>
      <button
        id="continue"
        mat-button
        [mat-dialog-close]="false"
        color="primary"
        *ngIf="showContinue"
      >
        {{ transl('general.continue') | titlecase }}
      </button>
    </div>
  </div>
</ng-container>
