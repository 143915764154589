import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GssoService } from '../http/gsso.service';

@Injectable()
export class AuthGuard  {
  constructor(private gssoService: GssoService) {}

  canActivate(): Observable<boolean> {
    return this.gssoService.isAuthenticated$().pipe(
      map((isAuthenticated) => {
        //Included for testing purpose
        //if (!isAuthenticated) {
        //this.navigationService.redirectToGssoCallback();
        //}

        return isAuthenticated;
      })
    );
  }
}
