import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LicenseType } from '@virtual-trials-workspace/models';
import { RouteBuilderService } from '@virtual-trials-workspace/shared-core-services';
import * as FromUtils from '@virtual-trials-workspace/shared-utils';
import {
  RootActions,
  SessionSelectors,
  SessionActions,
} from '@virtual-trials-workspace/store';
import { LanguageControleService } from '@virtual-trials-workspace/shared-ui';
import { combineLatest, Subject as RxjsSubject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  CodeSentFailureComponent,
  CodeSentSuccessComponent,
  ReInviteSubjectComponent,
  RemoveSubjectComponent,
  RemoveSubjectFailureComponent,
  ReselectStudySiteComponent,
  SubjectNotActivateComponent,
  VisitOptionsComponent,
} from '../../dialog';
import { Subject, SubjectActivationStatus } from '../../models';
import { SiteJourneyRoutes } from '../../site-journey-routes';
import * as siteActions from '../../store/actions/site.actions';
import { NotificationStatus } from '../../store/models';
import * as FromSelectors from '../../store/selectors/site.selectors';
import { SiteState } from '../../store/site.state';

@Component({
  selector: 'vt-site-journey-subject-list',
  templateUrl: './subject-list.component.html',
  styleUrls: ['./subject-list.component.scss'],
})
export class SubjectListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav, { static: true })
  sideNavViewChild: MatSidenav;

  @ViewChild(MatSort, { static: false })
  set content(sort: MatSort) {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'subjectName':
          return item.subjectName.toLowerCase();
        default:
          return item[property];
      }
    };
    this.dataSource.sort = sort;
  }
  private previousStudySiteSubscription$ = new Subscription();

  prevStudySite: {
    prevSelectedStudyId: string;
    prevSelectedSiteId: string;
  };

  isRtl: boolean;

  noData: Array<Subject> = new Array<Subject>();

  displayedColumns: string[] = ['subjectName', 'subjectStatus', 'actions'];

  dataSource: MatTableDataSource<Subject>;

  notification: NotificationStatus = NotificationStatus.None;

  isLoading: boolean;

  selectedSubjectId: string;

  studyName$ = this.store.pipe(select(FromSelectors.getStudyName));

  dataSubjectList: Subject[] = [];

  filteredSubjectList: Subject[] = [];

  deviceStatus$ = this.store.pipe(select(FromSelectors.getDeviceStatus));

  searchQuery: string;

  sortDirection : 'asc' | 'desc' | 'none' = 'none';
  
  sortingClassList: string[] = ['-es-sort', '-es-sort-ascending', '-es-sort-descending'];

  participantIdClassIndex = 0;

  participantStatusClassIndex = 0;

  private readonly unsubscribe$ = new RxjsSubject<void>();
  private languageSubscription$ = new Subscription();
  private studySiteForm: UntypedFormGroup;

  readonly siteControlName = 'site';
  readonly studyControlName = 'study';
  isECOALive = false;

  private setFormValues: () => void;

  reselectStudySiteDialogRef: MatDialogRef<ReselectStudySiteComponent>;

  get siteFormControl(): AbstractControl {
    return this.getFormControl(this.siteControlName);
  }

  get studyFormControl(): AbstractControl {
    return this.getFormControl(this.studyControlName);
  }

  constructor(
    private store: Store<SiteState>,
    private router: Router,
    private routeBuilder: RouteBuilderService,
    private dialog: MatDialog,
    private languageControleService: LanguageControleService
  ) {}

  mapActivationStatus = (statusFromApi: string): SubjectActivationStatus => {
    switch (statusFromApi.toUpperCase()) {
      case 'ACTIVATED':
        return SubjectActivationStatus.Activated;
      case 'PENDING INVITATION':
        return SubjectActivationStatus.PendingInvitation;
      case 'INVITATION SENT':
        return SubjectActivationStatus.InvitationSent;
      case 'ACTIVATION RESENT':
        return SubjectActivationStatus.ActivationResent;
      default:
        return SubjectActivationStatus.Unknown;
    }
  };

  ngOnInit(): void {
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(FromSelectors.getIsLoading))
      .subscribe((isLoading) => (this.isLoading = isLoading));
    this.store.dispatch(siteActions.getIsDeviceStatus());

    this.store
      .pipe(takeUntil(this.unsubscribe$), select(FromSelectors.getNotification))
      .subscribe((notification) => this.handleNotification(notification));

    this.store
      .pipe(takeUntil(this.unsubscribe$), select(FromSelectors.getSubjects))
      .subscribe((data) => this.initialiseData(data));

    this.initStudySiteModalForm();

    this.store
      .pipe(
        take(1),
        select(FromSelectors.getSelectedStudyAndSite),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ selectedSiteId, selectedStudyId }) => {
        if (!!selectedSiteId && !!selectedStudyId) {
          this.setFormValues = () => {
            this.siteFormControl.setValue(selectedSiteId);
            this.studyFormControl.setValue(selectedStudyId);
          };

          this.setFormValues();
        } else {
          this.handleReselectStudySite();
        }
      });

    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromSelectors.getPrevSelectedStudyAndSite)
      )
      .pipe(
        filter(
          (prevStudySite) =>
            !!prevStudySite &&
            !!prevStudySite.prevSelectedSiteId &&
            !!prevStudySite.prevSelectedStudyId
        )
      )
      .subscribe((prevStudySite) => {
        this.prevStudySite = prevStudySite;
      });

    this.store
      .pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        select(SessionSelectors.isPrefferedLanguageDialogOpen)
      )
      .pipe(filter((isDialogOpened) => !!isDialogOpened))
      .subscribe(() => {
        this.store.dispatch(
          siteActions.setSelectedStudySiteForReselect({
            studyId: this.prevStudySite.prevSelectedStudyId,
            siteId: this.prevStudySite.prevSelectedSiteId,
          })
        );
        this.store.dispatch(
          SessionActions.isPrefferedLanguageDialogOpen({
            isDialogOpen: false,
          })
        );
      });

    this.store.dispatch(siteActions.getSubjectsAction());

    this.subsribeToIsEcoaLiveStandalone();

    this.handlePreviousStudySite();

    this.subscribeToRecievedSelectedLanguage();
  }

  private subscribeToRecievedSelectedLanguage = (): void => {
    combineLatest([
      this.store.pipe(select(SessionSelectors.getSelectedLanguageCode)),
      this.store.pipe(select(SessionSelectors.getAllAvailableLanguages))
    ])
      .pipe(
        filter(
          ([selectedLanguageCode, allAvailableLanguages]) =>
            !!selectedLanguageCode && !!allAvailableLanguages
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res) => {
        
        const [selectedLanguageCode, allAvailableLanguages] = res;
        const selectedLanguage = allAvailableLanguages.find((language) => language.code === selectedLanguageCode);
        this.isRtl = selectedLanguage?.rightToLeft;
      });
  };

  handlePreviousStudySite = (): void => {
    this.previousStudySiteSubscription$ =
      this.languageControleService.selectPreviousStudySite.subscribe((data) => {
        if (data) {
          this.store.dispatch(
            siteActions.setSelectedStudySiteForReselect({
              studyId: this.prevStudySite?.prevSelectedStudyId,
              siteId: this.prevStudySite?.prevSelectedSiteId,
            })
          );
          this.store.dispatch(siteActions.getSubjectsAction());
        }
      });
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.languageControleService.ngOnDestroy();
    this.previousStudySiteSubscription$.unsubscribe();
  }

  onApplyClick(): void {
    if (!this.searchQuery) {
      this.filteredSubjectList = [...this.dataSubjectList || [] ];
    } else {
      this.filteredSubjectList = this.dataSubjectList.filter((subject) => {
        // console.log(subject.subjectId.toLowerCase());
        return subject.subjectName.toLowerCase().includes(this.searchQuery);
      });
    }
  }

  changeSortingClass(key: string) {

    switch(key) {
      case 'subjectName':
        this.participantIdClassIndex = (this.participantIdClassIndex + 1) % this.sortingClassList.length;
        break;
      case 'subjectStatus':
        this.participantStatusClassIndex = (this.participantStatusClassIndex + 1) % this.sortingClassList.length;
        break;
    }

  }

  sortSubjects(key: string) {
    if(this.sortDirection === 'none') {
      this.sortDirection = 'asc';
    } else if(this.sortDirection === 'asc') {
      this.sortDirection = 'desc'
    } else {
      this.sortDirection = 'none'
    }

    if(this.sortDirection === 'none') {
      this.onApplyClick();
    } else {
      this.filteredSubjectList.sort((a,b) => {
        const valueA = a[key];
        const valueB = b[key];

        if(valueA < valueB) {
          return this.sortDirection === 'asc' ? -1 : 1;
        } else if(valueA > valueB) {
          return this.sortDirection === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }

    this.changeSortingClass(key);

    // if(key === 'subjectName') {
    //   if(this.participantIdClass === '-es-sort') {
    //     this.participantIdClass = '-es-sort-ascending';
    //   } else if(this.participantIdClass === '-es-sort-ascending') {
    //     this.participantIdClass = '-es-sort-descending';
    //   } else {
    //     this.participantIdClass = '-es-sort';
    //   }
    // }
  }

  handleNotification = (notification: NotificationStatus): void => {
    const dialogConfig = {
      panelClass: 'es-custom-dialog-panel',
    };
    switch (notification) {
      case NotificationStatus.SendInviteToSubjectSuccess:
        this.dialog.open(CodeSentSuccessComponent, dialogConfig);
        break;
      case NotificationStatus.SendInviteToSubjectFail:
        this.dialog.open(CodeSentFailureComponent, dialogConfig);
        break;
      case NotificationStatus.RemoveSubjectFail:
        {
          const ref = this.dialog.open(RemoveSubjectFailureComponent, dialogConfig);
          ref.afterClosed().subscribe(() => {
            this.store.dispatch(siteActions.getSubjectsAction());
            ref.close();
          });
        }
        break;
    }
  };

  showEmptyList = (): boolean => {
    return !this.isLoading && this.dataSource?.data?.length === 0;
  };

  showTable = (): boolean => {
    return (
      this.dataSource?.data?.length > 0 &&
      !this.isLoading &&
      this.dataSource?.filteredData?.length !== 0
    );
  };

  showNoFilterResults = (): boolean => {
    return (
      this.dataSource?.data?.length > 0 &&
      this.dataSource?.filteredData?.length === 0 &&
      !this.isLoading
    ) || (
      this.dataSubjectList?.length > 0 &&
      this.filteredSubjectList?.length === 0 &&
      !this.isLoading
    );
  };

  private initialiseData(subjects: Subject[]): void {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromSelectors.getisECOALiveForSelectedStudy)
      )
      .subscribe((isECOALive) => {
        this.dataSubjectList = isECOALive ? null : subjects;

        this.filteredSubjectList = [...this.dataSubjectList || [] ];
        //subjects = isECOALive ? null : subjects;
        this.dataSource = new MatTableDataSource(
          subjects?.length ? subjects : this.noData
        );
        this.dataSource.filterPredicate = (
          subject: Subject,
          theFilter: string
        ) => {
          return subject.subjectName
            .toLowerCase()
            .includes(theFilter.toLowerCase());
        };
      });
  }

  handleRemoveSubjectActionClick = (subject: Subject) => {
    const dialogConfig = {
      panelClass: 'es-custom-dialog-panel',
    };
    const ref = this.dialog.open(RemoveSubjectComponent, dialogConfig);
    ref.componentInstance.confirmRemoveSubject.subscribe(() => {
      this.store.dispatch(
        siteActions.removeSubjectAction({ subjectId: subject.subjectId })
      );
      ref.close();
    });
  };

  handleToggleSidenav = (): void => {
    this.sideNavViewChild.toggle();
  };

  handleAddSubject = (): void => {
    const url = this.routeBuilder
      .withLazyFeature('sites')
      .withRoute(SiteJourneyRoutes.InviteSubject)
      .build();

    this.router.navigateByUrl(url);
  };

  handleReInviteSubject = (subject: Subject): void => {
    const dialogConfig = {
      panelClass: 'es-custom-dialog-panel',
    };
    const ref = this.dialog.open(ReInviteSubjectComponent, dialogConfig);
    ref.componentInstance.confirmReinviteSubject.subscribe(() => {
      this.store.dispatch(siteActions.reInviteSubjectAction({ subject }));
      ref.close();
    });
  };

  handleStartVisit = (subject: Subject): void => {
    const dialogConfig = {
      panelClass: 'es-custom-dialog-panel',
    };
    const status = this.mapActivationStatus(subject.subjectStatus);
    const activatedSubject = status === SubjectActivationStatus.Activated;

    activatedSubject
      ? this.openVisitOptions(subject.subjectId, subject.subjectParticipantId)
      : this.dialog.open(SubjectNotActivateComponent, dialogConfig);
  };

  private openVisitOptions = (
    subjectId: string,
    subjectParticipantId: string
  ): void => {
    this.dispatchActionForDeviceInUse();

    const ref = this.dialog.open(VisitOptionsComponent, {
      autoFocus: false,
    });

    ref.componentInstance.studyLicenses$ = this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(FromSelectors.getLicensesForSelectedStudy)
    );

    ref.componentInstance.deviceStatus$ = this.store.pipe(
      takeUntil(this.unsubscribe$),
      select(FromSelectors.getDeviceStatus)
    );

    ref.componentInstance.clickHereLink.subscribe(() => {
      this.store.dispatch(siteActions.getIsDeviceStatus());
    });

    ref.componentInstance.productOptionsSelected.subscribe(
      ({ ecoaLive, virtualVisits }) => {
        this.store.dispatch(
          RootActions.startVisitWithSubject({
            siteId: this.siteFormControl.value,
            subjectId: subjectId,
            subjectParticipantId: subjectParticipantId,
            modules: this.getVisitServices(ecoaLive, virtualVisits),
            isEcoaLiveStandalone: false,
            isDeviceProvisioned: undefined,
          })
        );
      }
    );
  };

  getVisitServices = (
    ecoaLiveSelected: boolean,
    virtualVisitsSelected: boolean
  ): Array<LicenseType> => {
    const visitServices = new Array<LicenseType>();

    if (ecoaLiveSelected) {
      visitServices.push('Emulation');
    }

    if (virtualVisitsSelected) {
      visitServices.push('Call');
    }

    return visitServices;
  };

  handleLogoutClick = (): void => {
    this.store.dispatch(RootActions.endActiveSession());
  };

  handleSearchTerm = (value: string): void => {
    this.dataSource.filter = value?.trim().toLowerCase();
  };

  selectSubject = (subject: Subject): void => {
    this.selectedSubjectId = subject?.subjectId;
  };

  unSelectSubject = (): void => {
    this.selectedSubjectId = null;
  };

  getIsOpenClass = (subjectId: string): string => {
    return this.selectedSubjectId === subjectId ? '__open' : '';
  };

  private dispatchActionForDeviceInUse = (): void => {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromSelectors.getLicensesForSelectedStudy)
      )
      .subscribe((licenses) => {
        if (
          licenses.find((license) => license.licenseName === 'Emulation')
            ?.licenseStatus === true
        ) {
          this.store.dispatch(siteActions.getIsDeviceStatus());
        }
      });
  };

  private initStudySiteModalForm = (): void => {
    this.studySiteForm = new UntypedFormGroup({
      site: new UntypedFormControl(undefined, Validators.required),
      study: new UntypedFormControl(undefined, Validators.required),
    });
  };

  private getFormControl = (name: string): AbstractControl => {
    return this.studySiteForm.controls[name];
  };

  handleReselectStudySite = (): void => {
    if (
      !this.reselectStudySiteDialogRef ||
      (!!this.reselectStudySiteDialogRef &&
        this.reselectStudySiteDialogRef.getState() === MatDialogState.CLOSED)
    ) {
      this.store.dispatch(
        siteActions.prevSetSelectedStudySiteForReselect({
          studyId: this.studySiteForm.value.study,
          siteId: this.studySiteForm.value.site,
        })
      );
      const dialogConfig = {
        panelClass: 'es-custom-dialog-panel',
      };
      this.reselectStudySiteDialogRef = this.dialog.open(
        ReselectStudySiteComponent, dialogConfig
      );
      this.reselectStudySiteDialogRef.updateSize('500px');
      this.setReselectStudySiteData(this.reselectStudySiteDialogRef);
      this.handleSiteUpdated(this.reselectStudySiteDialogRef);
      this.handleReselectStudySiteCancelClick(this.reselectStudySiteDialogRef);
      this.setHandleReselectStudySite(this.reselectStudySiteDialogRef);
      this.handleStudySiteChanged(this.reselectStudySiteDialogRef);
      this.subscribeToRetryDeviceStatusClick(this.reselectStudySiteDialogRef);
    }
  };

  private subscribeToRetryDeviceStatusClick = (
    ref: MatDialogRef<ReselectStudySiteComponent>
  ): void => {
    ref.componentInstance.retryDeviceStatusClick.subscribe(() => {
      this.store.dispatch(
        siteActions.setSelectedStudySiteForReselect({
          studyId: this.studyFormControl.value,
          siteId: this.siteFormControl.value,
        })
      );
      this.store.dispatch(siteActions.getSubjectsAction());
      this.store.dispatch(siteActions.getIsDeviceStatus());
    });
  };

  private setReselectStudySiteData = (
    ref: MatDialogRef<ReselectStudySiteComponent>
  ): void => {
    ref.componentInstance.formGroup = this.studySiteForm;
    ref.componentInstance.studyFormControl = this.studyFormControl;
    ref.componentInstance.siteFormControl = this.siteFormControl;
  };

  private handleReselectStudySiteCancelClick = (
    ref: MatDialogRef<ReselectStudySiteComponent>
  ): void => {
    ref.componentInstance.cancelClick.subscribe(() => {
      ref.close();
    });
  };

  private setHandleReselectStudySite = (
    ref: MatDialogRef<ReselectStudySiteComponent>
  ): void => {
    ref.componentInstance.studySelectionChanged.subscribe((studyId: string) => {
      this.siteFormControl.setValue(undefined);

      this.store.dispatch(siteActions.setSelectedStudy({ studyId }));
    });
  };

  private handleStudySiteChanged = (
    ref: MatDialogRef<ReselectStudySiteComponent>
  ): void => {
    ref.componentInstance.studySiteChanged.subscribe((isUpdated: boolean) => {
      if (isUpdated) {
        this.setSelectedStudy();
      }
      this.studySiteForm.valid
        ? this.studySiteUpdated(ref)
        : FromUtils.runFormFieldValidation(this.studySiteForm);
    });
  };
  private studySiteUpdated = (
    ref: MatDialogRef<ReselectStudySiteComponent>
  ): void => {
    this.languageSubscription$ = this.store
      .pipe(take(1), select(FromSelectors.getStudyLanguagesForSelectedStudy))
      .subscribe((studyLanguages) => {
        this.languageControleService.handleLanguageControlDialog(
          studyLanguages.toString()
        );
        this.languageSubscription$.unsubscribe();
        if (this.isECOALive) {
          this.startEcoaStandaloneVisit();
        }
      });
    ref.close();
  };

  private handleSiteUpdated = (
    ref: MatDialogRef<ReselectStudySiteComponent>
  ): void => {
    ref.componentInstance.siteSelectionChanged.subscribe(() => {
      this.store.dispatch(
        siteActions.setSelectedSite({
          siteId: ref.componentInstance.siteFormControl.value,
        })
      );
      this.store.dispatch(siteActions.getIsDeviceStatus());
    });
  };

  setSelectedStudy = (): void => {
    this.store.dispatch(
      siteActions.setSelectedStudySiteForReselect({
        studyId: this.studyFormControl.value,
        siteId: this.siteFormControl.value,
      })
    );
    this.store.dispatch(siteActions.getSubjectsAction());
  };

  private subsribeToIsEcoaLiveStandalone = (): void => {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(FromSelectors.getisECOALiveForSelectedStudy)
      )
      .subscribe((isECOALive) => {
        this.store.dispatch(
          RootActions.setEcoaLiveStandalone({
            isEcoaLiveStandalone: isECOALive,
          })
        );

        this.isECOALive = isECOALive;
      });
  };

  startEcoaStandaloneVisit = (): void => {
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(FromSelectors.getSubjects))
      .subscribe((subjects) => {
        if (subjects.length > 0) {
          this.store.dispatch(
            RootActions.startVisitWithSubject({
              siteId: this.siteFormControl.value,
              subjectId: subjects[0].subjectId,
              subjectParticipantId: subjects[0].subjectParticipantId,
              modules: this.getEcoaVisitService(),
              isEcoaLiveStandalone: true,
              isDeviceProvisioned: undefined,
            })
          );
        }
      });
  };

  getEcoaVisitService = (): Array<LicenseType> => {
    const visitServices = new Array<LicenseType>();
    visitServices.push('Emulation');
    return visitServices;
  };
}
