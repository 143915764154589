import { Injectable } from '@angular/core';
import { NotificationItem } from '@virtual-trials-workspace/models';
import { Observable, Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class NotificationService {
  private subject$ = new Subject<NotificationItem>();

  get notifications$(): Observable<NotificationItem> {
    return this.subject$.asObservable();
  }

  send = (event: NotificationItem): void => {
    this.subject$.next(event);
  };
}
