import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  autoDetectUserLanguage(availableLangs: Array<string>): string | undefined {
    try {
      let userLanguage: string;

      const browserLanguages = this.getBrowserLanguages()?.map(lang => lang.toLowerCase());

      if (!browserLanguages) {
        return undefined;
      }

      for (const language of browserLanguages) {
        userLanguage = this.matchLanguage(availableLangs, language);

        if (userLanguage)
          return userLanguage;
      }

      return undefined;
    }
    catch (error) {
      return undefined;
    }
  }

  private matchLanguage(availableLangs: Array<string>, language: string): string {
     return availableLangs.find(value => this.isFullMatch(value, language))
      ?? availableLangs.find(value => this.isMatchByLanguageCode(value, language))
      ?? availableLangs.find(value => this.isMatchByNativeLanguageCode(value, language))
      ?? availableLangs.find(value => this.isMatchByOnlyLanguageCode(value, language));
  }

  private isFullMatch(availableLanguage: string, browserLanguage: string): boolean {
    return availableLanguage.toLowerCase() === browserLanguage;
  }

  private isMatchByLanguageCode(availableLanguage: string, browserLanguage: string): boolean {
    return availableLanguage.toLowerCase() === this.getLanguageCode(browserLanguage);
  }

  private isMatchByNativeLanguageCode(availableLanguage: string, browserLanguage: string): boolean {
    return availableLanguage.toLowerCase() === this.getNativeLanguageCode(browserLanguage);
  }

  private isMatchByOnlyLanguageCode(availableLanguage: string, browserLanguage: string): boolean {
    return this.getLanguageCode(availableLanguage.toLowerCase()) === this.getLanguageCode(browserLanguage);
  }

  getBrowserLanguages(): readonly string[] {
    const languages = navigator.languages;
    return languages?.length > 0
      ? languages
      : [navigator.language];
  }

  private getLanguageCode(language: string): string {
    return language.split('-')[0];
  }

  private getNativeLanguageCode(language: string): string {
    const languageCode = this.getLanguageCode(language);
    return languageCode.concat('-', languageCode);
  }
}
