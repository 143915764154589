import { Injectable } from '@angular/core';
import {
  LoginResponse,
  StudyLanguagesModel,
} from '@virtual-trials-workspace/models';
import { RestApiService } from '@virtual-trials-workspace/shared-core-services';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionHttpService {
  readonly loginResource = '/account/login'; // To refactor out?
  readonly standaloneVisitResource = '/visit/standalone';
  readonly refreshTokenResource = '/account/keepalive';

  constructor(private restApiService: RestApiService) {}

  loginParticipant$ = (
    username: string,
    password: string
  ): Observable<LoginResponse> => {
    return this.restApiService.post(this.loginResource, {
      username,
      password,
    });
  };

  joinStandaloneVisit$ = (
    visitCode: string
  ): Observable<{
    participantId: string;
    participantType: string;
  }> => {
    if (!visitCode) {
      return throwError(new Error('Invalid code'));
    }

    return this.restApiService.get(
      `${this.standaloneVisitResource}?visitCode=${visitCode}`
    );
  };

  refreshToken$ = (): Observable<any> =>
    this.restApiService.get(this.refreshTokenResource);

  getSutdyLanguagesByParticipantId$ = (): Observable<string[]> => {
    const resource = `/account/studylanguages`;
    return this.restApiService.get<string[]>(resource);
  };
}
