import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { RouteBuilderService } from '../route-builder/route-builder.service';

@Injectable({
  providedIn: 'root',
})
export class RouteNavigationService {
  constructor(
    private routeBuilderService: RouteBuilderService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  //   navigatePage = (moduelBaseRoute: LazyFeatureBasePath, navigateRoute: AppRoutes): void => {
  //     const url = this.buildNavigationUrl(moduelBaseRoute, navigateRoute);
  //     this.ngZone.run(() => this.router.navigateByUrl(url));
  //   }

  navigateInternalErrorPage = (): Promise<boolean> => {
    return this.ngZone.run(() =>
      this.router.navigateByUrl('/internal-error', { skipLocationChange: true })
    );
  };

  //   private buildNavigationUrl = (baseRoute: LazyFeatureBasePath, navigatonRoute: AppRoutes) => {
  //     return this.routeBuilderService.withLazyFeature(baseRoute).withRoute(navigatonRoute).build();
  //   }
}
