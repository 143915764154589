import { Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Token } from '@virtual-trials-workspace/models';
import { WINDOW } from '@virtual-trials-workspace/shared-core-services';
import { Subject, timer } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'vt-ui-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SessionTimeoutDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  private class = 'session-timeout-container';
  private _bodyOverflowValue: string;
  private _stopCountDown$ = new Subject();

  countDownValue: number;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(MAT_DIALOG_DATA) private token: Token
  ) {}
  
  ngOnInit(): void {
    this.setCountDown();
    this._bodyOverflowValue = this.window.document.body.style.overflow;
    this.setBodyOverflowTo('hidden');
  }

  ngOnDestroy(): void {
    this.setBodyOverflowTo(this._bodyOverflowValue);
    this._stopCountDown$.next();
    this._stopCountDown$.complete();
  }

  private setBodyOverflowTo = (value: string): void => {
    this.window.document.body.style.overflow = value;
  }

  private setCountDown = (): void => {
    this.countDownValue = this.token.getTimeoutTime();
    this._stopCountDown$.next();

    timer(0, 1000).pipe(
      takeUntil(this._stopCountDown$),
      takeWhile(() => this.countDownValue > 0),
    ).subscribe(() => this.countDownValue -= 1000);
  }

  @HostListener('window:visibilitychange')
  private onVisibilityChange = (): void => {
    if (!this.window.document.hidden) {
      this.setCountDown();
    }
  }
}
