<section
  *transloco="let transl"
  class="es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
>
  <div class="es-column">
    <div class="es-content-box">
      <div class="es-panel es-custom-panel">
        <div class="es-form">
          <div class="es-content-box">
            <div class="es-text-group">
              <h2>{{ transl('user.account.title_proper_case') }}</h2>
            </div>
          </div>
          <form class="form subject-login-form" [formGroup]="formGroup">
            <div class="es-form-field">
              <label class="es-label -es-required">{{transl('user.account.login_username_placeholder')}}</label>
              <input
                formControlName="username"
                [errorStateMatcher]="usernameControlErrorMatcher()"
                pattern="^[\w\.\_\-]+[+]{0,1}[\w\.\_\-]+[@][\w\.\-]+[\w]+$"
                type="text" 
                class="es-input" 
                [ngClass]="{'-es-invalid': 
                  (formGroup.controls.username.hasError('required') ||
                  formGroup.controls.username.hasError('pattern') ) &&
                  formGroup.controls.username.touched
                }"
                [placeholder]="transl('user.account.login_username_placeholder')"
              />
              <div class="error-container">
                <div 
                  *ngIf="
                    (formGroup.controls.username.hasError('required') ||
                    formGroup.controls.username.hasError('pattern') ) &&
                    formGroup.controls.username.touched
                  "
                    class="es-form-message  -es-invalid">
                    <div class="error-icon"></div>
                  <p>{{ transl('error.login.incorrect_username') }}</p>
                </div>
              </div>
            </div>
            <div class="es-form-field">
              <label class="es-label -es-required">{{transl('user.account.login_password_placeholder')}}</label>
              <input 
                class="es-input " 
                [placeholder]="transl('user.account.login_password_placeholder')"
                type="password"
                formControlName="password"
                [errorStateMatcher]="passwordControlErrorMatcher()"
                [ngClass]="{
                  '-es-invalid': isPasswordRequiredErrorState() &&
                  formGroup.controls.password.touched
                }"
                (keydown.enter)="handleSubmitForm()"
              />
              <div class="error-container">
                <div 
                  *ngIf="isPasswordRequiredErrorState() &&
                  formGroup.controls.password.touched" 
                  class="es-form-message  -es-invalid" >
                  <div class="error-icon"></div>
                  <p>{{ transl('error.login.incorrect_password') }}</p>
                  
                </div>
              
                <div
                  *ngIf="
                    invalidCredentialsAPIErrorState() && !isPasswordRequiredErrorState() &&
                    formGroup.controls.password.touched
                  "
                  class="es-form-message  -es-invalid" >
                  <div class="error-icon"></div>
                  <p>{{ errorMessage }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
        
        <div class="es-toolbar">
          <ul class="es-right login-form-btn">
            <li class="es-toolbar-item">
              
              <button
                class="es-button  -es-primary "
                id="loginButtonId"
                [disabled]="!submitButtonIsEnabled"
                (key.enter)="handleSubmitForm()"
                (click)="handleSubmitForm()"
              >
                {{ transl('user.account.login', { fallback: 'LOGIN' }) }}
              </button>
            </li>
          </ul>
        </div>
        
        <div class="es-toolbar">
          <ul class="es-left subject-form-link">
            <li class="es-toolbar-item es-custom-toolbar-item">
              <a
                id="activateAccountId"
                href="javascript:void(0);"
                class="forgot-password"
                (click)="handleActivateAccount()"
                >{{ transl('user.account.active_account') }}</a
              >
            </li>
            <li class="es-toolbar-item es-custom-toolbar-item">
              <a
                id="forgotPasswordId"
                href="javascript:void(0);"
                class="forgot-password"
                (click)="handleForgottenPassword()"
                >{{ transl('user.account.forgot_password') }}</a
              >
            </li>
          </ul>
          <ul class="es-right subject-form-version">
            <li class="es-toolbar-item build-version">
              {{ buildVersion }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

