import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  SessionState,
  VisitSelectors,
  VisitState,
} from '@virtual-trials-workspace/store';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReadyToCreateVisitService {
  constructor(private store: Store<VisitState & SessionState>) {}

  private hasEmulationOnlyVisit$ = this.store.pipe(
    select(VisitSelectors.getHasOnlyEmulationService)
  );

  private visitHasVideoCallWithPermissions$ = this.store.pipe(
    select(VisitSelectors.getHasVideoCallServiceWithPermissions)
  );

  private siteUserHasActiveVisit$ = this.store.pipe(
    select(VisitSelectors.getSiteUserHasActiveVisit)
  );

  private hasSiteId$ = this.store
    .pipe(select(VisitSelectors.getSiteId))
    .pipe(filter((siteId) => !!siteId));

  private noActiveVisitForSite$ = combineLatest([
    this.siteUserHasActiveVisit$,
    this.hasSiteId$,
  ]).pipe(
    map(([hasActiveVisit, hasSiteId]) => !hasActiveVisit && !!hasSiteId),
    debounceTime(0)
  );

  private servicesPrepared$ = combineLatest([
    this.hasEmulationOnlyVisit$,
    this.visitHasVideoCallWithPermissions$,
  ]).pipe(
    map(([emulationOnlyVisit, visitWithVideoCallAndPermissions]) => {
      return !!emulationOnlyVisit || !!visitWithVideoCallAndPermissions;
    }),
    debounceTime(0)
  );

  readyToCreateVisit$ = combineLatest([
    this.noActiveVisitForSite$,
    this.servicesPrepared$
  ]).pipe(
    map(([noActiveVisitForSite, servicesPrepared]) => !!noActiveVisitForSite && !!servicesPrepared),
    debounceTime(0)
  );
}
