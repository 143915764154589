import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vt-activate-decline-terms-modal',
  templateUrl: './decline-terms.modal.html',
  styles:[`
  .es-popup-content {
    width:720px;
  }
  `]
})
export class DeclineTermsModalComponent {
  @Output()
  confirmDeclineTermsAndConditions = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<DeclineTermsModalComponent>) {}

  handleConfirmClick = (): void => {
    this.confirmDeclineTermsAndConditions.emit();
    this.dialogRef.close();
  };

  handleCancelClick = (): void => {
    this.dialogRef.close();
  };
}
