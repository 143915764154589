import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  delete = (key: string, useSessionStorage = false): void => {
    useSessionStorage
      ? sessionStorage.removeItem(key)
      : localStorage.removeItem(key);
  };

  save = <T>(
    item: T,
    key: string,
    useSessionStorage = false
  ): void => {
    useSessionStorage
      ? sessionStorage.setItem(key, JSON.stringify(item))
      : localStorage.setItem(key, JSON.stringify(item));
  };

  exists = (key: string): boolean => {
    return !!localStorage.getItem(key);
  };

  get = (key: string, useSessionStorage = false): any => {
    return JSON.parse(
      useSessionStorage
        ? sessionStorage.getItem(key)
        : localStorage.getItem(key)
    );
  };
  deleteAllExceptOther = (exceptionKeys): void => {
    for (let i = 0; i < localStorage.length; i++){
      const key = localStorage.key(i);

      if (exceptionKeys.indexOf(key) === -1) {
          localStorage.removeItem(key);
      }
    }
  }
  deleteAll = (): void => {
    localStorage.clear();
  };
}
