<div *transloco="let transl">
  <div class="security-question-answer">
    <div class="es-form-field">
      <label for="select" class="es-label">
        {{ label }}
      </label>
      <div class="es-select" 
        [ngClass]="{
          '-es-invalid': showErrorForQuestion && questionFormControl.touched
        }">
      
        <select [formControl]="questionFormControl">
            <option value=""></option>
            <option
              *ngFor="let option of questionOptions$ | async"
              [value]="option.questionId"
              >
              {{ transl('security_questions.' + option.questionId.toUpperCase()) }}
            </option>
        </select>
      </div>
      <div 
        id="questionError" class="es-form-message  -es-invalid"
        *ngIf="showErrorForQuestion && questionFormControl.touched">
        <div class="error-icon"></div>
        <p>{{ transl('error.question_required') }}</p>
      </div>
    </div>

    <div class="es-form-field">
      <label class="es-label -es-required">
        {{transl('security_questions.answerLabel')}}
      </label>
      <input
        [formControl]="answerFormControl"
        type="text" 
        class="es-input" 
        [ngClass]="{'-es-invalid': 
        ( showRequiredErrorForAnswer || 
        (showLengthErrorForAnswer && !showRequiredErrorForAnswer) ) && 
          answerFormControl.touched
        }"
        [placeholder]="transl('security_questions.answerLabel')"
      />

      <div 
        class="es-form-message  -es-invalid"
        id="answerRequiredError" 
        *ngIf="showRequiredErrorForAnswer && 
        answerFormControl.touched"
      >
        <div class="error-icon"></div>
        <p>{{ transl('error.answer_required') }}</p>
      </div>
      <div
        class="es-form-message  -es-invalid"
        id="answerLengthError"
        *ngIf="showLengthErrorForAnswer && !showRequiredErrorForAnswer && 
        answerFormControl.touched"
      >
        <div class="error-icon"></div>
        <p>{{ transl('error.answer_length') }}</p>
        
      </div>
    </div>
  </div>
</div>