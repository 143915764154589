import { getVisitCodeSuccess } from './../actions/visit.actions';
import { getVisitCode } from './../selectors/visit.selectors';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { fetch } from '@nx/angular';

import { interval, Observable, of, timer } from 'rxjs';
import {
  exhaustMap,
  map,
  mapTo,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  LicenseType,
  NotificationType,
} from '@virtual-trials-workspace/models';
import { NotificationService } from '@virtual-trials-workspace/shared-core-services';

import * as FromActions from '../actions';
import { VisitHttpService } from '../http';
import { SessionState, VisitState } from '../sub-states';
import { VisitWebSocketService } from '../web-socket';
import { TranslocoService } from '@ngneat/transloco';
import { SessionSelectors, VisitSelectors } from '../../index';
@Injectable()
export class VisitEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private router: Router,
    private store: Store,
    private visitHttpService: VisitHttpService,
    private visitSocketService: VisitWebSocketService,
    private translocoService: TranslocoService
  ) {}

  startVisitWithSubject = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActions.startVisitWithSubject),
        tap(async () => await this.router.navigateByUrl('/visit'))
      ),
    { dispatch: false }
  );

  createVisitFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActions.createVisitFail),
        withLatestFrom(this.store.select(VisitSelectors.getEcoaLiveStandalone)),
        tap(([action, isEcoaLiveStandalone]) => {
          if (isEcoaLiveStandalone) {
            this.router.navigateByUrl('/sites/select-study-site');
          } else {
            this.router.navigateByUrl('/sites/subject-list');
          }
        })
      ),
    { dispatch: false }
  );

  attemptCreateVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.createVisit),
      withLatestFrom(this.store.select(VisitSelectors.getSiteId), 
        this.store.select(VisitSelectors.getSubjectParticipantId), 
        this.store.select(VisitSelectors.getAllVisitServices)
      ),
      fetch({
        run: (action, siteId, subjectParticipantId,services) => {
        return this.createVisitSuccessHandler$(
          siteId,
          subjectParticipantId,
          services.map((service) => service.type)
        );
      },
      onError: (_, errorResponse) => {
        if (errorResponse.status === 409) {
          const siteTitle = this.translocoService.translate(
            'visit.could_not_create_visit',
            { fallback: 'Could not create visit' }
          );
          if (errorResponse.error.siteHasActiveEmulation) {
            const siteMessage = this.translocoService.translate(
              'visit.device_already_in_use',
              {
                fallback:
                  'The device is already in use for this site, try again later',
              }
            );
            this.notificationService.send({
              type: NotificationType.ActiveVisit,
              title: siteTitle,
              message: siteMessage,
            });
          } else if (errorResponse.error.subjectHasActiveVisit) {
            const subjectMessage = this.translocoService.translate(
              'visit.subject_in_visit',
              {
                fallback:
                  'The subject is currently in a visit, try again later.',
              }
            );
            this.notificationService.send({
              type: NotificationType.ActiveVisit,
              title: siteTitle,
              message: subjectMessage,
            });
          }
        }

        return FromActions.createVisitFail({
          payload: {
            code: errorResponse.status,
            message: errorResponse.statusText,
          },
        });
      },
    })
    )
  );

  getStudies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.getDeviceProvisioningStatus),
      withLatestFrom(this.store.select(VisitSelectors.getSiteId)),
      fetch({
        run: (action, siteId) => {
          return this.visitHttpService.getStudies$().pipe(
            map((studies) => {
              let isProvisioned: boolean = undefined;
              studies.every((study) => {
                const site = study.sites.find(
                  (site) => site.siteId === siteId
                );
                if (site) {
                  isProvisioned = site.provisioned;
                  return false;
                } else {
                  return true;
                }
              });

              return isProvisioned === undefined
                ? FromActions.getDeviceProvisioningStatusFailure()
                : FromActions.getDeviceProvisioningStatusSuccess({
                    provisioned: isProvisioned,
                  });
            })
          );
        },
        onError: (action, error) => {
          return FromActions.getDeviceProvisioningStatusFailure();
        },
      }
    )
    )
  );

  setProvisioningStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.setProvisioningStatus),
      withLatestFrom(this.store.select(VisitSelectors.getSiteId)),
      fetch({
        run: (action, siteId) => {
          return this.visitHttpService
            .setProvisionedState$(siteId, true)
            .pipe(
              map(() => {
                return FromActions.setProvisioningStatusSuccess();
              })
            );
        },
        onError: (_, error) => {
          return FromActions.setProvisioningStatusFailure();
        },
      }
    )
    )
  );

  getVisitCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.getVisitCode),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
          if (visitId != undefined) {
            return this.visitHttpService
              .getVisitCode$(visitId)
              .pipe(
                map((response) => {
                  return FromActions.getVisitCodeSuccess({
                    visitCode: response.visitCode,
                  });
                })
              );
          }
        },
        onError: (_, error) => {
          return FromActions.getVisitCodeFail();
        },
      })
    )
  );

  attemptStartVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.startVisit),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId), 
        this.store.select(SessionSelectors.getParticipantType),
        this.store.select(VisitSelectors.getSiteId)
      ),
      fetch({
        run: (action, visitId, participantType, siteId) => {
        this.visitSocketService.connect(
          visitId,
          participantType
        );
        this.visitSocketService.startVisit(
          visitId,
          siteId
        );
      },
    })
    )
  );

  attemptJoinVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.joinVisitReady),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
        this.visitSocketService.joinVisit(visitId);
      },
    })
    )
  );
  
  renewVisitServiceInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.renewVisitServiceInfo),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
          this.visitSocketService.joinVisit(visitId);
        },
      }
      )
    )
  );

  attemptEndVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.endVisit),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
        this.visitSocketService.endVisit(visitId);
      },
    })
    )
  );

  transferControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.emulationTransferControl),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
          this.visitSocketService.transferControl(visitId);
        },
      }
    ))
  );

  requestControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.emulationRequestControl),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
          this.visitSocketService.requestTransferControl(
            visitId
          );
        },
      }
    ))
  );

  declineTransferRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.emulationDeclineTransferRequest),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
          this.visitSocketService.declineTransferRequest(
            visitId
          );
        },
      }
    ))
  );

  visitJoinExtend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.extendVisitJoin),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
        this.visitSocketService.extendVisitJoinTimer(visitId);
      },
    })
    )
  );

  visitEndExtend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.extendVisitEnd),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
        this.visitSocketService.extendVisitEndTimer(visitId);
      },
    })
    )
  );

  endVisitResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.endVisitResponse),
      switchMap((action) => {
        const timeout = action.message.indexOf('The visit has timed out') >= 0;
        return action.success
          ? of(
              FromActions.endVisitResponseSuccess({
                visitEnd: timeout ? 'timeout' : 'manual',
              })
            )
          : of(FromActions.endVisitResponseFail());
      })
    )
  );

  endVisitResponseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.endVisitResponseSuccess),
      fetch({
        run: (action) => {
          this.visitSocketService.disconnect();
        },
      }
    )
    )
  );

  joinVisitResponseFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.joinVisitResponseFailure),
      withLatestFrom(this.store.select(SessionSelectors.getParticipantType)),
      fetch({
        run: (action, participantType) => {
          if (participantType === 'subject') {
            return timer(5000).pipe(mapTo(FromActions.joinVisitReady()));
          }
        },
        onError: (_, error) => {
          return FromActions.joinVisitResponseFailure({
            visitService: 'Emulation',
          });
        },
      }
    )
    )
  );

  checkHasDeviceControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.checkHasDeviceControl),
      withLatestFrom(this.store.select(SessionSelectors.getParticipantType),
        this.store.select(VisitSelectors.getVisitId)
      ),
      fetch({
        run: (action, participantType, visitId) => {
          if (participantType === 'provisioner') {
            return FromActions.checkHasDeviceControlSuccess({
              hasControl: true,
            });
          }
          return this.visitHttpService
            .hasDeviceControlCheck$(visitId)
            .pipe(
              map((response) =>
                FromActions.checkHasDeviceControlSuccess({
                  hasControl: response.hasControl,
                })
              )
            );
        },
        onError: (_, error) => {
          return FromActions.checkHasDeviceControlFailure();
        },
      }
    ))
  );

  checkIfActiveVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.checkForActiveVisit),
      withLatestFrom(
        this.store.select(VisitSelectors.getVisitEnd)
      ),
      fetch({
        run: (action, visitEnd) => {
          if (visitEnd !== 'error') {
            return this.visitHttpService.checkForActiveVisit$().pipe(
              map(
                ({
                  isActive,
                  modules,
                  visitId,
                  visitCode,
                  siteId,
                  isEcoaLiveStandalone,
                }) =>
                  isActive
                    ? FromActions.activeVisitExists({
                        visitId,
                        visitCode,
                        isEcoaLiveStandalone,
                        siteId,
                        visitServices: modules,
                      })
                    : FromActions.noActiveVisit()
              )
            );
          } else {
            return null;
          }
        },
        onError: (_, error) => {
          if (error.status === 404) {
            return FromActions.noActiveVisit();
          }
          // complete system error => navigate to error page
        },
      }
    )
    )
  );

  checkForSubjectActiveVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.checkForSubjectActiveVisit),
      withLatestFrom(
        this.store.select(SessionSelectors.getParticipantId), 
        this.store.select(VisitSelectors.getVisitEnd)
      ),
      fetch({
        run: (action, participantId, visitEnd) => {
          if (
            !participantId ||
            visitEnd === 'error'
          ) {
            return null;
          }

          return this.visitHttpService.checkForActiveVisit$().pipe(
            exhaustMap(
              ({
                isActive,
                modules,
                visitId,
                visitCode,
                isEcoaLiveStandalone,
              }) =>
                isActive
                  ? of(
                      FromActions.subjectVisitReady({
                        visitId,
                        visitCode,
                        isEcoaLiveStandalone,
                        visitServices: modules,
                      })
                    )
                  : interval(3000).pipe(
                      mapTo(FromActions.checkForSubjectActiveVisit()),
                      take(1)
                    )
            )
          );
        },
        onError: (_, error) => {
          return FromActions.subjectVisitReadyFailure();
        },
      }
    ))
  );

  sendInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.sendInvitation),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
          return this.visitHttpService
            .sendInvitation$(visitId)
            .pipe(map(() => FromActions.sendInvitationSuccess()));
        },
        onError: () => {
          return FromActions.sendInvitationFail();
        },
      }
    ))
  );

  startVisitComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.startVisitComplete),
      withLatestFrom(this.store.select(VisitSelectors.getVisitId)),
      fetch({
        run: (action, visitId) => {
          if (action.sendInvite) {
            return this.sendInvitationHandler$(visitId);
          } else {
            return FromActions.joinVisitReady();
          }
        },
        onError: () => {
          return FromActions.sendInvitationFail();
        },
      }
    ))
  );

  attemptConnectVisitWebSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActions.attemptConnectVisitWebSocket),
        withLatestFrom(this.store.select(VisitSelectors.getVisitId),
          this.store.select(SessionSelectors.getParticipantType)
        ),
        fetch({
          run: (action, visitId, participantType) => {
            this.visitSocketService.connect(
              visitId,
              participantType
            );
          },
        }
      )
    ),
    { dispatch: false }
  );

  disconnectVisitWebSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromActions.disconnectVisitWebSocket),
        fetch({
          run: (action) => {
            this.visitSocketService.disconnect();
          },
        }
      )),
    { dispatch: false }
  );

  pingSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.visitSocketPing),
      fetch({
        run: (action) => {
        this.visitSocketService.pingSocket();
      },
    })
    )
  );

  initHealthCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromActions.initHealthCheck),
      withLatestFrom(this.store.select(VisitSelectors.getVisitServices),
        this.store.select(VisitSelectors.getVisitId)
      ),
      fetch({
        run: (action, services, visitId) => {
        const isEmulation = services.some(
          (module) => module.type === 'Emulation'
        );
        if (isEmulation) {
          this.visitSocketService.initEmulationHealthcheck(
            visitId
          );
        }
      },
    })
    )
  );

  private sendInvitationHandler$ = (visitId: string): Observable<Action> => {
    return this.visitHttpService
      .sendInvitation$(visitId)
      .pipe(map(() => FromActions.sendInvitationSuccess()));
  };

  private createVisitSuccessHandler$ = (
    siteId: string,
    subjectParticipantId: string,
    modules: Array<LicenseType>
  ): Observable<Action> => {
    return this.visitHttpService
      .createVisit$(siteId, subjectParticipantId, modules)
      .pipe(
        map((response) =>
          FromActions.createVisitSuccess({
            ...response,
            visitServices: modules,
          })
        )
      );
  };
}
