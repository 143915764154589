// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../../typings/index.d.ts" />

import { noop } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { RenderTarget } from '../render-target';

export class VidyoRenderService {
  private localRenderOptions: RenderTarget;
  private localCamera: VidyoClientLib.VidyoClient.VidyoLocalCamera;
  private localCameraSub$ = new Subject<string>();
  private defaultCameraId: string;

  get localCameraId$(): Observable<string> {
    return this.localCameraSub$.asObservable();
  }

  private constructor(
    private connector: VidyoClientLib.VidyoClient.VidyoConnector
  ) {}

  static create = (
    connector: VidyoClientLib.VidyoClient.VidyoConnector
  ): VidyoRenderService => {
    return new VidyoRenderService(connector);
  };

  attachLocalViewToElement = async (
    renderOptions: RenderTarget
  ): Promise<boolean> => {
    return this.connector.RegisterLocalCameraEventListener({
      onAdded: (localCamera) => {
        if (this.isDefaultCamera(localCamera.id)) {
          this.connector.SelectLocalCamera({ localCamera });
        }
      },
      onRemoved: () => {
        noop();
      },
      onSelected: (localCamera) => {
        if (this.isDefaultCameraChanged(localCamera.id)) {
          this.localRenderOptions = renderOptions;
          this.localCamera = localCamera;
          this.attachLocalView();
        }

        this.localCameraSub$.next(localCamera.id);
      },
      onStateUpdated: (localCamera, state) => {
        noop();
      },
    });
  };

  attachRemoteCameraView = async (
    renderOptions: RenderTarget
  ): Promise<boolean> => {
    return this.connector.RegisterRemoteCameraEventListener({
      onAdded: (remoteCamera, remoteParticipant) => {
        if (remoteParticipant.userId) {
          this.connector
            .HideView({
              viewId: renderOptions?.viewId,
            })
            .then(() => {
              this.connector.AssignViewToRemoteCamera({
                remoteCamera: remoteCamera,
                viewId: renderOptions?.viewId,
                allowZoom: true,
                displayCropped: true,
              });
            });
        }
      },
      onRemoved: (remoteCamera, remoteParticipant) => {
        if (remoteParticipant.userId) {
          this.connector.HideView({
            viewId: renderOptions?.viewId,
          });
        }
      },
      onStateUpdated: (remoteCamera, state) => {
        noop();
      },
    });
  };

  setLocalView = (currentValue): void => {
    currentValue ? this.hideLocalView() : this.attachLocalView();
  };

  setLocalCamera = (id: string): void => {
    if (id) {
      this.defaultCameraId = id;
    }
  };

  private attachLocalView = (): void => {
    this.connector.AssignViewToLocalCamera({
      viewId: this.localRenderOptions?.viewId,
      localCamera: this.localCamera,
      displayCropped: true,
      allowZoom: false,
    });
    this.connector.ShowViewAt(this.localRenderOptions);
  };

  private hideLocalView = (): void => {
    this.connector.HideView({
      viewId: this.localRenderOptions?.viewId,
    });
  };

  private isDefaultCamera = (cameraId: string): boolean => {
    return this.defaultCameraId && cameraId === this.defaultCameraId;
  };

  private isDefaultCameraChanged = (cameraId): boolean => {
    return !this.localCamera || this.localCamera.id !== cameraId;
  };
}
