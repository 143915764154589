import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VisitServiceOptions } from '@virtual-trials-workspace/models';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'vt-visit-service-failed-sheet',
  templateUrl: './service-failed-sheet.component.html',
  styleUrls: ['./service-failed-sheet.component.scss'],
})
export class VisitServiceFailedSheetComponent {
  @Input()
  failedVisitService: VisitServiceOptions;
  @Output()
  acknowledged = new EventEmitter();

  headerText = 'Failed to Start Visit';

  callText = 'Virtual Visit';
  emulationText = '';
  constructor(private translocoService: TranslocoService) {
  }
  get failedServiceText(): string {
    const videoEmulation = this.translocoService.translate(
      'visit.site.visit_start_failed.description',
      { fallback: 'Both Virtual Visit and eCOA Live services failed to start.'}
    );
    const videoOrEmulation = (service) => this.translocoService.translate(
      'visit.site.visit_start_failed.description_or',
      { service: service }
    );
    const videoCall = this.translocoService.translate(
      'visit.start_visit.virtual_visit_product',
      { fallback: 'Virtual Visit'});
    const emulation = this.translocoService.translate(
      'visit.start_visit.eCOA_live_product',
      { fallback: 'eCOA Live'});

    switch (this.failedVisitService) {
      case 'all':
        return videoEmulation;
      case 'video-call':
        return videoOrEmulation(videoCall);
      case 'emulation':
        return videoOrEmulation(emulation);
      default:
        return videoOrEmulation('')
    }
  }

  getServiceString(service: VisitServiceOptions): string {
    const serviceStrings: { [key in VisitServiceOptions]?: string } = {
      'video-call': 'Virtual Visit',
      emulation: 'eCOA Live',
    };
    return serviceStrings[service] ? serviceStrings[service] : service;
  }

  logoutClick(): void {
    this.acknowledged.emit();
  }
}
