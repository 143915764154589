import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import * as FromStore from '@virtual-trials-workspace/store';
import { Observable } from 'rxjs';

import { JourneyGuard } from '../journey.guard';

@Injectable({ providedIn: 'root' })
export class ParticipantGuard extends JourneyGuard  {
  constructor(
    protected router: Router,
    protected store: Store<FromStore.RootState>
  ) {
    super(router, store);
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.canNavigateToJourney(['user', 'subject', 'provisioner']);
  }
}
