<div class="es-gateway" *transloco="let transl">
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select siteUser">
    <vt-ui-language-select class="top-right"></vt-ui-language-select>
  </div>
  
  <!-- LOGIN screen START-->
    <main class="es-gateway-body">

      <section
        *transloco="let transl"
        class="es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
      >
        <div class="es-column" *ngIf="!showSubjectAlreadyInvited">
          <div class="es-content-box">
            <div class="es-panel es-custom-panel">
              <div class="es-form">
                <div class="es-content-box">
                  <div class="es-text-group">
                    <h2>{{ transl('site_user.subject.invite.invite_subject') }}</h2>
                    <p>{{ transl('site_user.subject.invite.please_enter_subject_id') }}</p>
                  </div>
                </div>
                <form class="form" [formGroup]="inviteSubjectForm">
                  <div class="es-form-field">
                    <label class="es-label -es-required">
                      {{ transl('subject_list.subject_id') }}
                    </label>

                    <input
                      type="text" 
                      class="es-input" 
                      formControlName="subjectId"
                      [ngClass]="{'-es-invalid': (inviteSubjectForm.controls.subjectId.hasError('required') ||
                        inviteSubjectForm.controls.subjectId.hasError('invalidSubjectIdFormat') ) &&
                        inviteSubjectForm.controls.subjectId.touched
                      }"
                      [placeholder]="transl('site_user.subject.invite.enter_subject_id')"

                    />
                    <div class="error-container">
                      <div *ngIf="inviteSubjectForm.controls.subjectId.hasError('required') && 
                        inviteSubjectForm.controls.subjectId.touched" 
                        class="es-form-message  -es-invalid">
                          <div class="error-icon"></div>
                          <p>{{ transl('error.subject_id_is_required') }}</p>
                      </div>
                      <div
                        class="es-form-message  -es-invalid"
                        *ngIf="inviteSubjectForm.controls.subjectId.hasError(
                            'invalidSubjectIdFormat') &&
                            inviteSubjectForm.controls.subjectId.touched">
                            <div class="error-icon"></div>
                            <p>{{ transl('error.subject_id_is_invalid') }}</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              
              <div class="es-toolbar">
                <ul class="es-right">
                  <li class="es-toolbar-item">
                    <button
                      class="es-button"
                      (click)="handleCancelInviteSubjectClick()">
                      {{ transl('general.cancel') }}
                    </button>
                  </li>
                  <li class="es-toolbar-item">
                    <button
                      class="es-button  -es-primary "
                      [disabled]="inviteSubjectForm.invalid"
                      (click)="handleNextClick()"
                    >
                      {{ transl('general.next') }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div class="es-column" *ngIf="showSubjectAlreadyInvited">
          <div class="es-content-box">
            <div class="es-panel es-custom-panel">
              <div class="es-form">
                <div class="es-content-box">
                  <div class="es-text-group">
                    <h2>{{ transl('site_user.subject.invite.subject_already_exists') }}</h2>
                    <p>{{
                      transl('site_user.subject.invite.subject_already_exists_resend')
                    }}</p>
                  </div>
                </div>
              </div>
              <div class="es-toolbar">
                <ul class="es-right">
                  <li class="es-toolbar-item">
                    <button
                      class="es-button"
                      (click)="handleCancelSubjectAlreadyExistsClick()">
                      {{ transl('general.cancel') }}
                    </button>
                  </li>
                  <li class="es-toolbar-item">
                    <button
                      class="es-button  -es-primary "
                      (click)="handleYesClick()"
                    >
                      {{ transl('general.yes') }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </section>
    </main>

</div>

<!-- <div *transloco="let transl">
  <vt-ui-loading-overlay *ngIf="isLoading$ | async"></vt-ui-loading-overlay>
  <div class="page-wrapper">
    <div fxLayout="row" fxLayoutAlign="flex-end">
      <vt-ui-language-select class="top-right"></vt-ui-language-select>
    </div>
    <div class="page-container">
      <div class="page-header">
        <vt-ui-header-logo></vt-ui-header-logo>
      </div>
      <div class="page-content">
        <mat-card *ngIf="!showSubjectAlreadyInvited">
          <div class="card-title">
            {{ transl('site_user.subject.invite.invite_subject') }}
          </div>
          <div class="card-subtitle">
            {{ transl('site_user.subject.invite.please_enter_subject_id') }}
          </div>
          <mat-card-content>
            <form class="form" [formGroup]="inviteSubjectForm">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{transl('site_user.subject.invite.enter_subject_id')}}</mat-label>
                <input
                  matInput
                  formControlName="subjectId"
                />
                <mat-error
                  *ngIf="
                    inviteSubjectForm.controls.subjectId.hasError('required')
                  "
                >
                  {{ transl('error.subject_id_is_required') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    inviteSubjectForm.controls.subjectId.hasError(
                      'invalidSubjectIdFormat'
                    )
                  "
                >
                  {{ transl('error.subject_id_is_invalid') }}
                </mat-error>
              </mat-form-field>
            </form>
          </mat-card-content>
          <mat-card-actions align="end">
            <button
              mat-flat-button
              color="secondary"
              (click)="handleCancelInviteSubjectClick()"
            >
              {{ transl('general.cancel') }}
            </button>
            <button
              mat-flat-button
              color="primary"
              [disabled]="inviteSubjectForm.invalid"
              (click)="handleNextClick()"
            >
              {{ transl('general.next') }}
            </button>
          </mat-card-actions>
        </mat-card>

        <mat-card *ngIf="showSubjectAlreadyInvited" fxLayout="column">
          <div class="wrap-center">
            <div class="card-title">
              {{ transl('site_user.subject.invite.subject_already_exists') }}
            </div>
            <div class="card-subtitle">
              {{
                transl('site_user.subject.invite.subject_already_exists_resend')
              }}
            </div>
          </div>
          <mat-card-actions>
            <button
              mat-flat-button
              color="secondary"
              (click)="handleCancelSubjectAlreadyExistsClick()"
            >
              {{ transl('general.cancel') }}
            </button>
            <button mat-flat-button color="primary" (click)="handleYesClick()">
              {{ transl('general.yes') }}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div> -->
