<div class="es-popup popup-reset-password"
  [ngClass]="{'-es-open': maxAnswerAttemptsReached }"
  *transloco="let transl">
  <div class="es-popup-content">
    <header class="es-popup-header">
			<h2>{{ transl('general.actions') }}</h2>
		</header>
    <div class="es-popup-body">
      <div class="es-content-box">
        <div class="es-text-group">
          <p>
            {{ transl('text.reset_password.max_attempts.part_one') }}
            <a href="javascript:void(0);" (click)="handleClick()">{{ transl('text.reset_password.max_attempts.link') }}</a>
            {{ transl('text.reset_password.max_attempts.part_two') }}
          </p>
        </div>
      </div>
    </div>
    <footer class="es-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">
            <button class="es-button -es-secondary" (click)="handleBackToLoginClick()">
              {{ transl('general.go_back_text') }}
            </button>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</div>