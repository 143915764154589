// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../typings/index.d.ts" />

import { Injectable, OnDestroy } from '@angular/core';
import { noop } from 'lodash';

import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { VidyoRenderService } from './render-service/vidyo-render.service';
import { RenderTarget } from './render-target';

@Injectable({ providedIn: 'root' })
export class VidyoService implements OnDestroy {
  private done$ = new Subject<void>();

  private connector: VidyoClientLib.VidyoClient.VidyoConnector;
  private renderer: VidyoRenderService;

  private scriptLoadedSuccess$ = new Subject<void>();

  get serviceAvailable$(): Observable<void> {
    return this.scriptLoadedSuccess$.asObservable();
  }

  private micEnabledBs$ = new BehaviorSubject<boolean>(true);

  get micEnabled$(): Observable<boolean> {
    return this.micEnabledBs$.asObservable();
  }

  private videoEnabledBs$ = new BehaviorSubject<boolean>(true);

  get videoEnabled$(): Observable<boolean> {
    return this.videoEnabledBs$.asObservable();
  }

  private localCameraSub$ = new Subject<string>();

  get localCameraId$(): Observable<string> {
    return this.localCameraSub$.asObservable();
  }

  createConnector = async (): Promise<void> => {
    await new VidyoClientLib.VidyoClient('', () => noop)
      .CreateVidyoConnector(this.createConnectorDefaultConfig())
      .then(this.handleCreateVidyoConnector);
  };

  selectCamera = (id: string): void => {
    this.renderer.setLocalCamera(id);
  };

  connect = async (roomKey: string, roomPin: string): Promise<boolean> => {
    return this.connector.ConnectToRoomAsGuest({
      host: 'ert.platform.vidyo.io',
      roomKey: roomKey,
      roomPin: roomPin,
      displayName: 'Participant',
      onSuccess: () => {
        console.log('success');
      },
      onFailure: (reason) => {
        console.log(reason);
      },
      onDisconnected: () => noop,
    });
  };

  disconnect = (): Observable<boolean> => {
    return this.getVidyoConnectorCleanUp();
  };

  private getVidyoConnectorCleanUp = (): Observable<boolean> => {
    if (this.connector) {
      return from(this.connector.GetState()).pipe(
        switchMap((state) => {
          if (state === 'VIDYO_CONNECTORSTATE_Connected') {
            return from(this.connector.Disconnect()).pipe(
              tap(() => {
                this.connector.Disable();
                this.connector.Destruct();
              }),
              map(() => true),
              catchError(() => {
                return of(true);
              })
            );
          } else {
            this.connector.Disable();
            this.connector.Destruct();
            return of(true);
          }
        })
      );
    } else {
      return of(true);
    }
  };

  private handleCreateVidyoConnector = (
    connector: VidyoClientLib.VidyoClient.VidyoConnector
  ): void => {
    this.connector = connector;
    this.renderer = VidyoRenderService.create(connector);
    this.attachLocalMicrophoneListener();
    this.attachLocalSpeakersListener();
    this.registerParticipantEventListener();
    this.scriptLoadedSuccess$.next();
  };

  private createConnectorDefaultConfig = () => ({
    viewId: null,
    viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
    remoteParticipants: 1,
    logFileFilter: 'fatal error',
    logFileName: 'VidyoClientLog.log',
    userData: 0,
  });

  private attachLocalMicrophoneListener = (): void => {
    this.connector
      .RegisterLocalMicrophoneEventListener({
        onAdded: () => noop,
        onRemoved: () => noop,
        onSelected: () => noop,
        onStateUpdated: () => {
          // Microphone state was updated
        },
      })
      .then(() => {
        console.log('RegisterLocalMicrophoneEventListener Success');
      })
      .catch(() => {
        console.error('RegisterLocalMicrophoneEventListener Failed');
      });
  };

  private attachLocalSpeakersListener = (): void => {
    this.connector
      .RegisterLocalSpeakerEventListener({
        onAdded: noop,
        onRemoved: () => noop,
        onSelected: () => noop,
        onStateUpdated: () => {
          // Speaker state was updated
        },
      })
      .then(() => {
        console.log('RegisterLocalSpeakerEventListener Success');
      })
      .catch(() => {
        console.error('RegisterLocalSpeakerEventListener Failed');
      });
  };

  private registerParticipantEventListener = (): void => {
    this.connector.RegisterParticipantEventListener({
      onJoined: () => noop,
      onLeft: () => noop,
      onDynamicChanged: () => noop,
      onLoudestChanged: () => noop,
    });
  };

  private subscribeToCameraChange = (): void => {
    this.renderer.localCameraId$
      .pipe(takeUntil(this.done$))
      .subscribe((id: string) => {
        this.localCameraSub$.next(id);
      });
  };

  attachLocalViewToElement = (target: RenderTarget): Promise<boolean> => {
    return this.renderer.attachLocalViewToElement(target);
  };

  attachRemoteCameraView = (target: RenderTarget): Promise<boolean> => {
    return this.renderer.attachRemoteCameraView(target);
  };

  toggleMic = (currentValue: boolean): void => {
    this.connector
      ?.SetMicrophonePrivacy({ privacy: currentValue })
      .then(() => this.micEnabledBs$.next(!currentValue));
  };

  toggleVideo = (currentValue: boolean): void => {
    this.connector
      ?.SetCameraPrivacy({ privacy: currentValue })
      .then(() => this.videoEnabledBs$.next(!currentValue));
    this.renderer?.setLocalView(currentValue);
  };

  switchCamera = (): void => {
    this.subscribeToCameraChange();
    this.connector.CycleCamera();
  };

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }
}
