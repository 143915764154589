<div *transloco="let transl">

  <main class="es-gateway-body">
    <section
      class="es-welcome-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
    >
    
      <div class="es-column"
        [ngClass]="es-visit-assessment-form"
      >
        <div class="es-content-box" >
          <div class="es-panel es-custom-panel es-welcome-panel">
            <div class="es-form">
              <div class="es-content-box">
                <div class="es-text-group">

                  <!-- <div class="es-card1 -es-data"> -->
                    <vt-visit-content-sheet
                      data-testid="emulation-waiting-id"
                      [headerText]="transl('visit.emulation.assessment_form.title')"
                    >
                      <ng-container cardBody>
                        <div class="es-card1 -es-data">
                          <div class="es-card-body">
                            <div class="es-content-box">
                              <h2 class="waiting-text" *ngIf="!deviceHasControl">
                                {{
                                  transl('subject_waiting_screen.setting_up_session', {
                                    fallback: 'Setting up session'
                                  })
                                }}
                              </h2>
                              <div class="es-loading-indicator -es-visible">
                                <span class="es-loading-icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </vt-visit-content-sheet>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
