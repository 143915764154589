import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseConfig } from '@virtual-trials-workspace/models';
import { RouteNavigationService } from '@virtual-trials-workspace/shared-core-services';
import {
  SharedTranslocoModule,
  translocoConfigurationFactory,
  TranslocoHttpLoader,
} from '@virtual-trials-workspace/shared/transloco';
import { LanguageHttpService } from '@virtual-trials-workspace/store';
import { VirtualTrialsWebModule } from '@virtual-trials-workspace/virtual-trials-web';
import { environment } from '../environments/environment';
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { AppComponent } from './app.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.GATrakingCode),
    NgxGoogleAnalyticsRouterModule,
    CommonModule,
    RouterModule,
    VirtualTrialsWebModule,
    HttpClientModule,
    SharedTranslocoModule,
  ],
  providers: [
    { provide: BaseConfig, useValue: environment },
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: translocoConfigurationFactory,
      deps: [
        LanguageHttpService,
        TranslocoService,
        RouteNavigationService,
        Store,
        TranslocoHttpLoader,
      ],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule {}
