import { InjectionToken, Provider } from '@angular/core';

import { routerReducer } from '@ngrx/router-store';
import { MetaReducer, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';

import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';

import * as FromReducers from './reducers';
import * as FromRouter from './router';
import * as FromSubStates from './sub-states';
import { genericMetaReducer } from './utils';
import * as FromVisit from './visit';

const SESSION_STATE_STORAGE_KEYS = new InjectionToken<
  keyof Array<FromSubStates.SessionState>
>('SessionStateStorageKeys');

const SESSION_STATE_LOCAL_STORAGE_KEY = new InjectionToken<Array<string>>(
  'SessionStateStorageState'
);

const getStateMetaReducers = (
  sessionStateSaveKeys: Array<string>,
  sessionStateLocalStorageKey: string,
  visitStateSaveKeys: Array<string>,
  visitStateLocalStorageKey: string,
  storageService: LocalStorageService
): MetaReducer[] => [
  genericMetaReducer(
    sessionStateSaveKeys,
    sessionStateLocalStorageKey,
    storageService
  ),
  genericMetaReducer(
    visitStateSaveKeys,
    visitStateLocalStorageKey,
    storageService
  ),
];

export const baseReducerMap = {
  [FromRouter.ROUTER_STATE_KEY]: routerReducer,
  [FromSubStates.SESSION_STATE_KEY]: FromReducers.sessionReducer,
  [FromSubStates.VISIT_STATE_KEY]: FromReducers.visitReducer,
};

export const persistentStateProviders = (): Array<Provider> => [
  ...FromVisit.Config.visitPersistentStateProviders(),
  {
    provide: SESSION_STATE_LOCAL_STORAGE_KEY,
    useValue: FromSubStates.SESSION_STATE_KEY,
  },
  {
    provide: SESSION_STATE_STORAGE_KEYS,
    useValue: [
                'SESSION_STATE.participantType', 
                'SESSION_STATE.participantId', 
                'SESSION_STATE.isAnonymous',
                'SESSION_STATE.filterLanguages',
                'SESSION_STATE.defaultSetLanguageCode',
                'SESSION_STATE.isPrefferedLanguageDialogOpen'],
  },
  {
    provide: USER_PROVIDED_META_REDUCERS,
    useFactory: getStateMetaReducers,
    deps: [
      SESSION_STATE_STORAGE_KEYS,
      SESSION_STATE_LOCAL_STORAGE_KEY,
      FromVisit.Config.VISIT_STATE_STORAGE_KEYS,
      FromVisit.Config.VISIT_STATE_LOCAL_STORAGE_KEY,
      LocalStorageService,
    ],
  },
];
