export { ConfigurationService } from './lib/config/config.service';
export * from './lib/core-services.module';
export { LocalStorageService } from './lib/local-storage/local-storage.service';
export { MediaDevicesService } from './lib/media-devices/media-devices.service';
export { NotificationService } from './lib/notification/notification.service';
export { RestApiService } from './lib/rest-api/rest-api.service';
export { RouteBuilderService } from './lib/route-builder/route-builder.service';
export { RouteNavigationService } from './lib/route-navigation/route-navigation.service';
export { ScopesService } from './lib/scopes/scopes.service';
export { ScriptBuilderService } from './lib/script-builder/script-builder.service';
export { TokenService, TOKEN_KEY } from './lib/token/token.service';
export { LanguageService } from './lib/language/language.service';
export {
  WebSocketPayload,
  WebSocketService,
} from './lib/web-socket/web-socket.service';
export * from './lib/window/window.providers';
export * from './lib/platform/paltform.service';
