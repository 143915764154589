import { Data, Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';

export interface RouteState {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export type RouteReducerState = RouterReducerState<RouteState>;

export const ROUTER_STATE_KEY = 'ROUTER_STATE';
