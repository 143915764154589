import { animate, style, transition, trigger } from "@angular/animations";

export class Animations {
    static fadeIn = (timings: string | number, name = 'fadeIn') => {
        return trigger(name, [
            transition('void => *', [
                style({ opacity: 0 }),
                animate(timings, style({ opacity: 1 })),
            ]),
        ]);
    }
}
