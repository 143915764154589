import { Injectable } from '@angular/core';
import { BaseConfig, ParticipantType } from '@virtual-trials-workspace/models';

import {
  TokenService,
  WebSocketPayload,
  WebSocketService,
} from '@virtual-trials-workspace/shared-core-services';

import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { VisitHttpService } from '../http';

import { VisitWebSocketMessageService } from './visit-web-socket-message.service';

@Injectable({ providedIn: 'root' })
export class VisitWebSocketService {
  private done$ = new Subject<boolean>();
  private currentVisitMessageSubscription: Subscription;
  private healthcheckIntervalSubscription: Subscription;

  constructor(
    private socketService: WebSocketService,
    private tokenService: TokenService,
    private messageService: VisitWebSocketMessageService,
    private visitHttpService: VisitHttpService,
    private config: BaseConfig
  ) {}

  connect = (visitId: string, participantType: ParticipantType): void => {
    if (
      !this.currentVisitMessageSubscription ||
      this.currentVisitMessageSubscription.closed
    ) {
      this.subscribeToSocketEvents(visitId);
      this.initPing(participantType);
    }
  };

  startVisit = (visitId: string, siteId: string): void => {
    const message = this.messageService.toStartVisitPayload(siteId, visitId);
    this.socketService.send(message);
  };

  private getWebSocketUri = (visitId: string): string => {
    const token = this.tokenService.getToken();
    return `${this.socketService.baseSocketEndpoint}?visitId=${visitId}&token=${token}`;
  };

  joinVisit = (visitId: string): void => {
    const message = this.messageService.toJoinVisitPayload(visitId);
    this.socketService.send(message);
  };

  transferControl = (visitId: string): void => {
    const message = this.messageService.toTransferControlPayload(visitId);
    this.socketService.send(message);
  };

  requestTransferControl = (visitId: string): void => {
    const message = this.messageService.toVisitActionMessagePayload(
      visitId,
      'requesthandover'
    );
    this.socketService.send(message);
  };

  declineTransferRequest = (visitId: string): void => {
    const message = this.messageService.toVisitActionMessagePayload(
      visitId,
      'declinehandover'
    );
    this.socketService.send(message);
  };

  endVisit = (visitId: string): void => {
    const message = this.messageService.toEndVisitPayload(visitId);
    this.socketService.send(message);
  };

  extendVisitJoinTimer = (visitId: string): void => {
    const message = this.messageService.toVisitJoinExtend(visitId);
    this.socketService.send(message);
  };

  extendVisitEndTimer = (visitId: string): void => {
    const message = this.messageService.toVisitEndExtend(visitId);
    this.socketService.send(message);
  };

  disconnect = (): void => {
    this.done$.next(true);
    this.done$.complete();
    this.currentVisitMessageSubscription?.unsubscribe();
    this.healthcheckIntervalSubscription?.unsubscribe();
  };

  pingSocket = (): void => {
    const message = this.messageService.pingPayload();
    this.socketService.send(message);
  };

  private initPing = (participantType: ParticipantType): void => {
    // Ping to keep websocket open, avoiding AWS 10 minute idle timeout
    interval(this.config.websocketKeepAliveTimerMinutes * 6e4)
      .pipe(takeWhile(() => !this.currentVisitMessageSubscription.closed))
      .subscribe(() => {
        this.visitHttpService.keepAuthTokenAlive(participantType);
        //socket ping isn't required if we're regularly performing healthcheck
        if (!this.healthcheckIntervalSubscription) {
          this.pingSocket();
        }
      });
  };

  initEmulationHealthcheck = (visitId: string): void => {
    this.healthcheckIntervalSubscription?.unsubscribe();
    this.healthcheckIntervalSubscription = interval(
      this.config.emulationHealthCheckIntervalMinutes * 6e4
    )
      .pipe(
        takeWhile(() => !this.currentVisitMessageSubscription.closed),
        takeUntil(this.done$)
      )
      .subscribe(() => {
        const message = this.messageService.healthcheckPayload(visitId);
        this.socketService.send(message);
      });
  };

  private subscribeToSocketEvents = (visitId: string): void => {
    this.currentVisitMessageSubscription = this.socketService
      .subscribeToSocket$(this.getWebSocketUri(visitId))
      .pipe(takeUntil(this.done$))
      .subscribe(this.onVisitSockedMessageReceived, (error) =>
        this.onVisitSocketError(error, visitId)
      );
  };

  private onVisitSockedMessageReceived = (payload: WebSocketPayload): void => {
    this.messageService.handlePayload(payload);
  };

  private onVisitSocketError = (error: any, visitId: string): void => {
    console.log(
      `error in web socket service for visit: ${visitId} - ${error}}`
    );
    this.messageService.handleSocketConnectionFailed();
  };
}
