import { Injectable } from '@angular/core';
import {
  LocalStorageService,
  ScriptBuilderService,
} from '@virtual-trials-workspace/shared-core-services';
import { GssoNavigationService } from '../services/gsso-navigation.service';
import { Observable } from 'rxjs';
import { GssoService } from '../http/gsso.service';
import { User } from '../models/user';
import { SessionState, RootActions } from '@virtual-trials-workspace/store';
import { Store } from '@ngrx/store';
import {
  BaseConfig,
  ParticipantType,
  ScriptModel,
} from '@virtual-trials-workspace/models';
@Injectable({ providedIn: 'root' })
export class GssoFacade {
  /**
   *
   */
  constructor(
    private gssoService: GssoService,
    private navigationService: GssoNavigationService,
    private store: Store<SessionState>,
    private scriptBuilder: ScriptBuilderService,
    private config: BaseConfig,
    private localStorageService: LocalStorageService
  ) {}

  deleteAll = (): void => {
    this.localStorageService.deleteAll();
  };
  deleteAllExceptOther = (exclude): void => {
    this.localStorageService.deleteAllExceptOther(exclude);
  }

  isAuthenticated$ = (): Observable<boolean> => {
    return this.gssoService.isAuthenticated$();
  };

  authenticate$ = (): Observable<User | undefined> => {
    return this.gssoService.authenticate$();
  };

  redirectToGssoLogin = (): void => {
    this.navigationService.redirectToGssoCallback();
  };

  validateGssoToken = (): Observable<{
    participantId: string;
    participantType: ParticipantType;
    encryptionKey: string;
    languageCode: string;
  }> => {
    return this.gssoService.validateGssoToken$();
  };

  loadNavigatorScript = (): void => {
    const script: ScriptModel = {
      type: 'text/javascript',
      src: this.config.navigatorHeaderScriptUrl,
      id: this.config.navigatorHeaderScriptId,
      attributes: {
        name: 'data-client-id',
        value: 'virtualvisits',
      },
      name: 'navigator',
    };
    this.scriptBuilder.addScript(script, () => {
      console.log('Error loading script');
    });
  };

  dispatchGssoLoginAction = (
    participantId: string,
    participantType: ParticipantType,
    encryptionKey: string,
    languageCode: string
  ): void => {
    this.store.dispatch(
      RootActions.loginSuccess({
        participantId,
        participantType,
        encryptionKey,
        languageCode,
      })
    );
  };
}
