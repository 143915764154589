import { InjectionToken, Provider } from '@angular/core';
import {
  initialVisitState,
  VisitState,
  VISIT_STATE_KEY,
} from '../sub-states/visit.state';

import { getPersistentKeys } from '../utils';

export const VISIT_STATE_STORAGE_KEYS = new InjectionToken<
  keyof Array<VisitState>
>('VisitStateStorageKeys');

export const VISIT_STATE_LOCAL_STORAGE_KEY = new InjectionToken<Array<string>>(
  'VisitStateStorageState'
);

export const visitPersistentStateProviders = (): Array<Provider> => [
  {
    provide: VISIT_STATE_LOCAL_STORAGE_KEY,
    useValue: VISIT_STATE_KEY,
  },
  {
    provide: VISIT_STATE_STORAGE_KEYS,
    useValue: getPersistentKeys<VisitState>(
      initialVisitState(),
      VISIT_STATE_KEY,
      ['inviteStatus', 'roomKey', 'roomPin']
    ),
  },
];
