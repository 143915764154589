import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vt-ui-viewport-error',
  templateUrl: './viewport-error.component.html',
  styleUrls: ['./viewport-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewportErrorComponent {
  @HostBinding('class')
  private class = 'viewport-error-container';
  readonly errorMessageKey = 'chrome_only_error_screen.switch_device_with_bigger_screen';
  readonly resolutionMessageKey = 'chrome_only_error_screen.minimum_screen_resolution';
  readonly errorMessage = 'Please switch to a device with a bigger screen';
  readonly resolutionMessage = '(Minimum screen resolution 1024x768)';
}
