import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GssoFacade } from '../facade/gsso.facade';

@Component({
  selector: 'vt-gsso-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  constructor(private gssoFacade: GssoFacade) {}

  private readonly unsubscribe$ = new Subject<void>();
  ngOnInit(): void {
    // this.gssoFacade.deleteAll();
    this.gssoFacade.deleteAllExceptOther(['filterLanguages', '__User_Language_Code_Key__']);
    this.gssoFacade.loadNavigatorScript();
  }

  ngAfterViewInit(): void {
    this.checkForUserAuthentication();
  }

  private checkForUserAuthentication = (): void => {
    this.gssoFacade
      .isAuthenticated$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated: boolean) => {
        isAuthenticated
          ? this.authenticateWithGsso()
          : this.redirectToGssoLogin();
      });
  };

  private authenticateWithGsso = (): void => {
    this.gssoFacade
      .authenticate$()
      .toPromise()
      .then((user) => {
        this.validateGssoToken();
      });
  };

  private redirectToGssoLogin = (): void => {
    this.gssoFacade.redirectToGssoLogin();
  };

  private dispatchGssoLoginAction = (response: any): void => {
    this.gssoFacade.dispatchGssoLoginAction(
      response.participantId,
      response.participantType,
      response.encryptionKey,
      response.languageCode
    );
  };

  private validateGssoToken = (): void => {
    this.gssoFacade
      .validateGssoToken()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.dispatchGssoLoginAction(response);
      });
  };
}
