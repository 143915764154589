import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject as RxjsSubject } from 'rxjs';
import { DeviceStatus } from '@virtual-trials-workspace/models';
import { takeUntil } from 'rxjs/operators';
import { SiteListItem } from '../models';
import { ProvisionerState } from '../store';
import * as FromSelectors from '../store/selectors/provisioner.selector';
import * as provisionerActions from '../store/actions/provisioner.actions';

@Component({
  selector: 'vt-provisioner-journey-set-provisioned-dialog',
  templateUrl: './set-provisioned-dialog.component.html',
  styleUrls: ['./set-provisioned-dialog.component.scss'],
})
export class SetProvisionedDialogComponent implements OnInit, OnDestroy {
  @Input()
  site: SiteListItem;

  @Output()
  markAsDoneClick = new EventEmitter();

  @Output()
  cancelClick = new EventEmitter();

  deviceStatusMessage: string;
  deviceStatusEnum: DeviceStatus;

  private readonly unsubscribe$ = new RxjsSubject<void>();

  constructor(private store: Store<ProvisionerState>) {}

  ngOnInit(): void {
    this.store.dispatch(
      provisionerActions.getDeviceStatus({
        siteId: this.site.siteId,
        provisioned: undefined,
      })
    );
    this.subscribeToDeviceStatus();
  }

  private subscribeToDeviceStatus = (): void => {
    this.store
      .pipe(takeUntil(this.unsubscribe$), select(FromSelectors.getDeviceStatus))
      .subscribe((data: DeviceStatus) => {
        switch (data) {
          case DeviceStatus.Checking:
            this.deviceStatusMessage = 'Checking';
            break;
          case DeviceStatus.Error:
            this.deviceStatusMessage = 'Error';
            break;
          case DeviceStatus.InUse:
            this.deviceStatusMessage = 'In use';
            break;
          case DeviceStatus.NotSetup:
            this.deviceStatusMessage = 'Not setup';
            break;
          case DeviceStatus.Available:
            this.deviceStatusMessage = 'Available';
            break;
        }
      });
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
