import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vt-visit-end-provisioner-sheet',
  templateUrl: './provisioner-visit-end-sheet.component.html',
  styleUrls: ['./provisioner-visit-end-sheet.component.scss'],
})
export class ProvisionerVisitEndSheetComponent {
  @Input()
  retryMessage: string;

  @Output()
  YesClick = new EventEmitter();

  @Output()
  NoClick = new EventEmitter();

  endVisitHeaderText = 'Mark as provisioned';
  provisionedYesText = 'Yes';
  provisionedNoText = 'Skip';
  endVisitDesc = 'Do you want to mark this site as provisioned?';

  yesButtonClick(): void {
    if (this.retryMessage) {
      this.NoClick.emit();
    } else {
      this.YesClick.emit();
    }
  }

  noButtonClick(): void {
    this.NoClick.emit();
  }
}
