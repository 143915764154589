import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LanguageModel } from '@virtual-trials-workspace/models';

@Component({
  selector: 'vt-site-journey-code-form',
  templateUrl: './invite-code-form.component.html',
  styleUrls: ['./invite-code-form.component.scss'],
})
export class InviteCodeFormComponent {
  @Input()
  inviteCodeForm: UntypedFormGroup;

  @Input()
  languages: LanguageModel[];

  @Input()
  showEmailAddressInvalidControlError: boolean;

  @Input()
  showMobilePhoneInvalidControlError: boolean;

  @Input()
  showEmailRequiredControlError: boolean;

  @Input()
  showPhoneRequiredControlError: boolean;

  @Input()
  showLanguageRequiredControlError: boolean;

  @Output()
  emailInputClick = new EventEmitter();

  @Output()
  phoneInputClick = new EventEmitter();

  get emailElement$(): HTMLInputElement {
    return document.querySelector("#email_radio") 
  }

  get phoneElement$(): HTMLInputElement {
    return document.querySelector("#phone_radio"); 
  }

  handleEmailClick = (): void => {
    this.emailElement$.checked = true
    this.emailInputClick.emit();
  };

  handlePhoneClick = (): void => {
    this.phoneElement$.checked = true
    this.phoneInputClick.emit();
  };
  handleCheckbox = (event): void => {
    if(event.target.id === 'email_radio') {
      this.handleEmailClick()
    } else {
      this.handlePhoneClick();
    }
  }
}
