import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store';

import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';
import { genericMetaReducer } from '@virtual-trials-workspace/store';

import { siteActions } from '../actions/site.actions';
import { SiteState } from '../site.state';

export const SITE_STATE_STORAGE_KEYS = new InjectionToken<
  keyof Array<SiteState>
>('SiteStateStorageKeys');

export const SITE_STATE_LOCAL_STORAGE_KEY = new InjectionToken<Array<string>>(
  'SiteStateStorageState'
);

export const SITE_STATE_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<SiteState, typeof siteActions>
>('SiteStateConfigToken');

export const getSiteStateConfig = (
  saveKeys: Array<string>,
  localStorageKey: string,
  storageService: LocalStorageService
): any => ({
  metaReducers: [genericMetaReducer(saveKeys, localStorageKey, storageService)],
});
