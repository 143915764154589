import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as FromStore from '../store';
import { DeclineTermsModalComponent } from './modal/decline-terms.modal';

@Component({
  selector: 'vt-activate-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
  hasScrolledToBottomOfPage: boolean;
  readonly ertPortalLink: string = `<a href="https://ertprivacy.exterro.net/portal/dsar.htm?target=ertprivacy" target="_blank">https://ertprivacy.exterro.net/portal/dsar.htm?target=ertprivacy</a>`;
  readonly clarioPrivacyPolicyLink: string = `<a href="https://clario.com/about/legal-and-privacy/privacy-and-integrity-policy/" target="_blank">https://clario.com/about/legal-and-privacy/privacy-and-integrity-policy/</a>`;
  readonly vidyoPrivacyPolicyLink: string = `<a href="https://www.vidyo.com/privacy-policy" target="_blank">https://www.vidyo.com/privacy-policy</a>`;
  readonly googlePrivacyPolicyLink: string = `<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>`;
  readonly privacyMailAddress: string = `<a href="mailto:privacy@clario.com">privacy@clario.com</a>`;

  constructor(
    public dialog: MatDialog,
    private store: Store<FromStore.ActivateState>,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.scrollDispatcher
      .scrolled()
      .pipe(filter(() => !this.hasScrolledToBottomOfPage))
      .subscribe((event: CdkScrollable | void) =>
        this.zone.run(() => {
          // run inside a zone otherwise Angular's change detection won't update
          this.hasScrolledToBottomOfPage =
            !!event && event.measureScrollOffset('bottom') < 10;
        })
      );
  }

  handleAcceptTermsAndConditions = (): void => {
    this.store.dispatch(FromStore.Actions.acceptTermsAndConditions());
  };

  handleDeclineTermsAndConditions = (): void => {
    const dialogConfig = {
      panelClass: 'es-custom-dialog-panel',
    };
    const ref = this.dialog.open(DeclineTermsModalComponent, dialogConfig);
    ref.componentInstance.confirmDeclineTermsAndConditions.subscribe(() => {
      this.store.dispatch(FromStore.Actions.declineTermsAndConditions());
    });
  };
}
