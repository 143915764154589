import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { TransferControlResult } from '@virtual-trials-workspace/store';
import { TranslocoService } from '@ngneat/transloco';

import { EmulationNotification } from './emulation-notificstion';
import { CustomSnackbarComponent } from '@virtual-trials-workspace/shared-ui';

@Injectable({ providedIn: 'root' })
export class VisitSnackbarNotificationService implements EmulationNotification {
  readonly failedTitle = 'RETRY';
  readonly failedMessage = `Can't send invite. Retry in 5 seconds.`;

  readonly deviceControlRecievedMessage = 'You now have control of the device.';
  readonly deviceControlTransferredMessage =
    'Device control transferred successfully.';
  readonly deviceControlTransferFailedMessage =
    'Device control transfer failed.';
  readonly deviceErrorMessage =
    'There is a problem connecting to Emulation. Please try later.';

  readonly successMessage = 'Invite sent';
  readonly visitCodeCopiedsuccessMessage = 'Copied Visit Link.';

  private deviceSnackbarConfig: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
    panelClass: ['custom-snackbar'],
  };

  constructor(
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService
  ) {}

  showInviteFailed = (): MatSnackBarRef<CustomSnackbarComponent> => {
    const failedMessage = this.translocoService.translate(
      'visit.core.visit_notification.failed_message',
      { fallback: "Can't send invite. Retry in 5 seconds." }
    );
    const failedTitle = this.translocoService.translate(
      'visit.core.visit_notification.retry',
      { fallback: 'RETRY' }
    );

    return this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: failedMessage, title: failedTitle, type: 'danger' },
      }
    );

    // return this.snackBar.open(failedMessage, failedTitle, {
    //   duration: 5000,
    //   horizontalPosition: 'center',
    //   verticalPosition: 'top',
    // });
    
  };

  showInviteSuccess = (): MatSnackBarRef<CustomSnackbarComponent> => {
    const successMessage = this.translocoService.translate(
      'visit.core.visit_notification.invite_sent',
      { fallback: 'Invite sent' }
    );
    
    return this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: successMessage, type: 'success' },
      }
    );
    
    // return this.snackBar.open(successMessage, null, this.deviceSnackbarConfig);
  };

  showVisitCodeCopiedSuccess = (): MatSnackBarRef<CustomSnackbarComponent> => {
    const visitCodeCopiedsuccessMessage = this.translocoService.translate(
      'visit.Visit.Copied_Visit_Link',
      { fallback: 'Copied Visit Link.' }
    );

    return this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: visitCodeCopiedsuccessMessage, type: 'info' },
      }
    );

    // return this.snackBar.open(
    //   visitCodeCopiedsuccessMessage,
    //   null,
    //   this.deviceSnackbarConfig
    // );
  };

  showControlRecieved = (): void => {
    const deviceControlRecievedMessage = this.translocoService.translate(
      'visit.core.visit_notification.control_device',
      { fallback: 'You now have control of the device.' }
    );

    this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: deviceControlRecievedMessage, type: 'info' },
      }
    );

    // this.snackBar.open(
    //   deviceControlRecievedMessage,
    //   null,
    //   this.deviceSnackbarConfig
    // );
  };

  showControlTransferred = (): void => {
    const deviceControlTransferredMessage = this.translocoService.translate(
      'visit.core.visit_notification.device_control_transferred_success',
      { fallback: 'Device control transferred successfully.' }
    );

    this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: deviceControlTransferredMessage, type: 'success' },
      }
    );

    // this.snackBar.open(
    //   deviceControlTransferredMessage,
    //   null,
    //   this.deviceSnackbarConfig
    // );
  };

  showDeviceErrorNotification = (): void => {
    const deviceErrorMessage = this.translocoService.translate(
      'visit.core.visit_notification.device_error',
      {
        fallback:
          'There is a problem connecting to Emulation. Please try later.',
      }
    );

    this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: deviceErrorMessage, type: 'danger' },
      }
    );

    // this.snackBar.open(deviceErrorMessage, null, this.deviceSnackbarConfig);
  };

  showAttemptTransferDeviceControlError = (
    transferControlResult: TransferControlResult
  ): void => {
    const deviceControlTransferredMessage = this.translocoService.translate(
      'visit.core.visit_notification.device_control_transferred_success',
      { fallback: 'Device control transferred successfully.' }
    );
    const deviceControlTransferFailedMessage = this.translocoService.translate(
      'visit.core.visit_notification.device_control_transferred_failed',
      { fallback: 'Device control transfer failed.' }
    );

    const message = transferControlResult.success
      ? deviceControlTransferredMessage
      : deviceControlTransferFailedMessage;

    const messageType = transferControlResult.success
    ? "success" : "danger";
    this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: message, type: messageType },
      }
    );
    
    // this.snackBar.open(message, null, this.deviceSnackbarConfig);
  };

  showDeviceControlRequestRejected = (): void => {
    const deviceControlRequestRejectedMessage = this.translocoService.translate(
      'visit.request_control_dialog.subject_declined',
      { fallback: 'The Subject declined your transfer request.' }
    );

    this.snackBar.openFromComponent(
      CustomSnackbarComponent,
      { ...this.deviceSnackbarConfig,
        data: { message: deviceControlRequestRejectedMessage, type: "danger" },
      }
    );

    // this.snackBar.open(
    //   deviceControlRequestRejectedMessage,
    //   null,
    //   this.deviceSnackbarConfig
    // );
  };
}
