import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceDetailsService {

  constructor(private deviceDetectorService: DeviceDetectorService) { }

  isMobileDevice = (): boolean => {
    return this.deviceDetectorService.userAgent.indexOf('Mobile') > -1 && this.deviceDetectorService.isMobile();
  }

  getWindowWidth = (): number => {
    return window.innerWidth;
  }

  isDesktopDevice = (): boolean =>  this.deviceDetectorService.isDesktop();

  isTabletDevice = (): boolean =>  this.deviceDetectorService.userAgent.indexOf('Mobile') === -1 && this.deviceDetectorService.isMobile();

  getDeviceScreenHeight = (): number => {
    return this.isTabletDevice() ? window.screen.height : window.innerHeight;
  }
}
