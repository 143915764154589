import { AfterViewInit, Component, ElementRef, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';

import { Animations } from '../animations';

@Component({
  selector: 'vt-standalone-visit-wait, div[vt-standalone-visit-wait]',
  templateUrl: './visit-wait.component.html',
  styleUrls: ['./visit-wait.component.scss'],
  animations: [Animations.fadeIn(600)],
  encapsulation: ViewEncapsulation.None
})
export class VisitWaitComponent implements AfterViewInit {
  @HostBinding('class')
  private class = 'visit-wait-container';

  @ViewChild('player')
  private player: ElementRef;

  hasAnimationStartedPlaying = false;

  ngAfterViewInit(): void {
    this.player.nativeElement.addEventListener('play', this.onPlay.bind(this));
  }

  private onPlay = () => {
    this.hasAnimationStartedPlaying = true;
  }
}
