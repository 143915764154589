<section
  *transloco="let transl"
  class="es-custom-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
  >
  <div class="es-column">
  <div class="es-content-box">
    <div class="es-panel es-custom-panel">
      <div class="es-form">
        <div class="es-content-box">
          <div class="es-text-group">
            <h2>{{ titleText }}</h2>
            <p>
              {{ subTitleText }}
            </p>
            
            <div class="progress-bar">
              <div class="progress-fill"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
