import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LanguageModel } from '@virtual-trials-workspace/models';
import * as FormUtils from '@virtual-trials-workspace/shared-utils';
import { SessionSelectors } from '@virtual-trials-workspace/store';
import { Observable, Subject as RxjsSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject, SubjectActivationDetails } from '../../models';
import { SiteState } from '../../store';
import * as siteActions from '../../store/actions/site.actions';
import * as siteSelectors from '../../store/selectors/site.selectors';

@Component({
  selector: 'vt-site-journey-code',
  templateUrl: './invite-code.component.html',
  styleUrls: ['./invite-code.component.scss'],
})
export class InviteCodeComponent implements OnInit, OnDestroy {
  isLoading$ = this.store.pipe(select(siteSelectors.getIsLoading));

  subject: Subject;
  inviteCodeForm: UntypedFormGroup;
  languages$: Observable<LanguageModel[]>;

  readonly languageControlName = 'languageCode';
  readonly emailControlName = 'email';
  readonly phoneControlName = 'phone';
  readonly selectionControlName = 'selection';
  readonly emailSelectionOption = 'subjectEmail';
  readonly phoneSelectionOption = 'subjectPhone';

  private readonly unsubscribe$ = new RxjsSubject<void>();

  get languageControl(): AbstractControl {
    return this.getFormControl(this.languageControlName);
  }

  get emailControl(): AbstractControl {
    return this.getFormControl(this.emailControlName);
  }

  get emailControlHasRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.emailControl.errors);
  }

  get emailControlHasEmailError(): boolean {
    return FormUtils.hasEmailError(this.emailControl.errors);
  }

  get phoneControl(): AbstractControl {
    return this.getFormControl(this.phoneControlName);
  }

  get phoneControlHasRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.phoneControl.errors);
  }

  get phoneControlHasInvalidFormatError(): boolean {
    return this.phoneControl.errors?.phoneInvalid;
  }

  get selectionControl(): AbstractControl {
    return this.getFormControl(this.selectionControlName);
  }

  get selectionControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.selectionControl.errors);
  }

  get languageControlRequiredError(): boolean {
    return FormUtils.hasRequiredError(this.languageControl.errors);
  }

  get emailRadioIsSelected(): boolean {
    return this.selectionControl.value === this.emailSelectionOption;
  }

  get phoneRadioIsSelected(): boolean {
    return this.selectionControl.value === this.phoneSelectionOption;
  }

  constructor(private store: Store<SiteState>, private router: Router) {}

  ngOnInit(): void {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(siteSelectors.getSelectedSubject)
      )
      .subscribe((subject) => this.handleSelectedSubject(subject));
    this.languages$ = this.store.pipe(
      select(SessionSelectors.getAvailableLanguages)
    );
  }

  private handleSelectedSubject = (selectedSubject: Subject): void => {
    this.subject = selectedSubject;
    this.initForm();
    this.initSubscriptionToSelectionChanges();
  };

  private initForm = (): void => {
    this.inviteCodeForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.subject?.activationDetails?.email),
      phone: new UntypedFormControl(this.getMobileNumberMinusPrefix()),
      selection: new UntypedFormControl(
        this.getRadioValue(this.subject?.activationDetails),
        [Validators.required]
      ),
      languageCode: new UntypedFormControl(
        this.subject?.activationDetails?.languageCode,
        [Validators.required]
      ),
    });
  };

  private getMobileNumberMinusPrefix = (): string => {
    return this.subject?.activationDetails?.mobileNumber?.substr(1);
  };

  private initSubscriptionToSelectionChanges = (): void => {
    this.selectionControl.valueChanges.subscribe(() => {
      this.emailRadioIsSelected
        ? this.handleEmailRadioSelected()
        : this.handlePhoneRadioSelected();
    });
  };

  handleEmailInputClick = (): void => {
    this.selectionControl.setValue(this.emailSelectionOption);
  };

  handlePhoneInputClick = (): void => {
    this.selectionControl.setValue(this.phoneSelectionOption);
  };

  private handleEmailRadioSelected = (): void => {
    this.phoneControl.setValidators([]);
    this.phoneControl.updateValueAndValidity();
    this.emailControl.setValidators([Validators.required, Validators.email]);
    this.emailControl.updateValueAndValidity();
  };

  private handlePhoneRadioSelected = (): void => {
    this.phoneControl.setValidators([
      Validators.required,
      FormUtils.internationalPhoneNumberValidator,
    ]);
    this.phoneControl.updateValueAndValidity();
    this.emailControl.setValidators([]);
    this.emailControl.updateValueAndValidity();
  };

  private getRadioValue = (activationDetails: SubjectActivationDetails) => {
    if (activationDetails?.email) {
      return this.emailSelectionOption;
    }

    if (activationDetails?.mobileNumber) {
      return this.phoneSelectionOption;
    }

    return undefined;
  };

  handleClickSkip = (): void => {
    this.router.navigateByUrl('/sites/subject-list');
  };

  handleClickSend = (): void => {
    this.inviteCodeForm.valid
      ? this.sendInviteToSubject()
      : FormUtils.runFormFieldValidation(this.inviteCodeForm);
  };

  private sendInviteToSubject = (): void => {
    this.store.dispatch(
      siteActions.sendInviteToSubjectAction({
        apiRequest: {
          email: this.emailRadioIsSelected ? this.emailControl.value : '',
          mobileNumber: this.phoneRadioIsSelected
            ? this.getFormattedPhoneNumber()
            : '',
          shortcode: this.subject.shortcode,
          subjectId: this.subject.subjectId,
          languageCode: this.languageControl.value,
        },
      })
    );
  };

  private getFormattedPhoneNumber = (): string => {
    return `+${this.phoneControl.value}`;
  };

  private getFormControl = (name: string): AbstractControl => {
    return this.inviteCodeForm.controls[name];
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
