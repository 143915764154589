import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  ParticipantType,
  ProvisioningStatus,
  VisitInviteStatus,
  VisitServiceOptions,
  VisitSessionTimer,
} from '@virtual-trials-workspace/models';
import { RequestControlDialogService } from '@virtual-trials-workspace/shared-ui';
import {
  BaseVisitSelectors,
  CallSelectors,
  DeviceConnection,
  EmulationSelectors,
  RootActions,
  RootSelectors,
  SessionState,
  TransferControlResult,
  VisitActions,
  VisitEnd,
  VisitSelectors,
  VisitState,
} from '@virtual-trials-workspace/store';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { VisitFacade, VisitSnackbarNotificationService } from '../../core';

import { EmulationFacade } from '../../emulation/facade/emulation.facade';
import { VideoCallFacade } from '../../video-call/facade/video-call.facade';

import { RenderTarget } from '../../video-call/vidyo';
import { ReadyToCreateVisitService } from './ready-to-create.service';
import { UserDeviceDetailsService } from '../../emulation';

@Injectable()
export class SiteFacadeService implements VisitFacade, OnDestroy {
  get askUserForPermissions$(): Observable<boolean> {
    return combineLatest([
      this.visitIncludesVideoCall$,
      this.hasMediaDevicesPermissions$,
    ]).pipe(map(([a, b]) => a && !b));
  }

  get skipSendInvite$(): Observable<boolean> {
    return combineLatest([
      this.hasOnlyEmulationService$,
      this.siteUserHasActiveVisit$,
      this.allServicesServerReady$,
      this.allServicesClientReady$,
      this.visitInviteStatus$,
      this.participantType$,
      this.isEcoaLiveStandalone$,
    ]).pipe(
      map(([a, b, c, d, e, f, g]) => {
        return (
          a && b && c && !d && e === 'unset' && (f === 'provisioner' || !!g)
        );
      })
    );
  }

  get canSendInvite$(): Observable<boolean> {
    return combineLatest([
      this.hasOnlyEmulationService$,
      this.hasVideoCallWithPermissions$,
      this.siteUserHasActiveVisit$,
      this.allServicesServerReady$,
      this.allServicesClientReady$,
      this.visitInviteStatus$,
      this.siteUserRejoinVisit$,
    ]).pipe(
      map(([a, b, c, d, e, f, g]) => {
        return (
          (a && c && d && !e && f === 'unset' && !g) ||
          (b && c && d && !e && f === 'unset' && !g)
        );
      }),
      filter((canStartVisit) => !!canStartVisit)
    );
  }

  get emulationConnectionLost$(): Observable<boolean> {
    return this.emulationFacade.connectionLost$;
  }

  get deviceConnection$(): Observable<DeviceConnection> {
    return this.emulationFacade.deviceConnection$;
  }

  get hasDeviceControl$(): Observable<boolean> {
    return this.emulationFacade.hasDeviceControl$;
  }

  get transferControlResult$(): Observable<TransferControlResult> {
    return this.store.pipe(select(VisitSelectors.getTransferControlResult));
  }

  get transferControlDeclined$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getEmulationRequestControlDeclined));
  }

  get recievedDeviceControl$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.recievedDeviceControl));
  }

  get deviceElementId(): string {
    return this.emulationFacade.deviceElementId;
  }

  get hasGrantedBrowserPermissions(): Promise<boolean> {
    return this.videoCallFacade.hasGrantedBrowserPermissions;
  }

  get isLoading$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getIsLoading));
  }

  get isEcoaLiveStandalone$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getEcoaLiveStandalone));
  }

  get mediaDeviceStates$(): Observable<{ mic: boolean; video: boolean }> {
    return this.videoCallFacade.mediaDeviceStates$;
  }

  get participantType$(): Observable<ParticipantType> {
    return this.store.pipe(select(RootSelectors.getParticipantType));
  }

  get connectedToDeviceSession$(): Observable<boolean> {
    return this.emulationFacade.connectedToDeviceSession$;
  }

  get videoServiceIsReady$(): Observable<void> {
    return this.videoCallFacade.vidyoConnected$;
  }

  get hasVisitEnded$(): Observable<boolean> {
    return this.store.pipe(
      select(BaseVisitSelectors.getVisitEnd),
      map((visitEnd) => !!visitEnd)
    );
  }

  get visitEnd$(): Observable<VisitEnd> {
    return this.store.pipe(select(BaseVisitSelectors.getVisitEnd));
  }

  get isDeviceProvisioned$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getIsDeviceProvisioned));
  }

  get deviceProvisioningStatus$(): Observable<ProvisioningStatus> {
    return this.store.pipe(select(VisitSelectors.getDeviceProvisioningStatus));
  }

  get videoEnded$(): Observable<boolean> {
    return this.store.pipe(
      select(CallSelectors.getCallHasEnded),
      filter((hasEnded) => !!hasEnded)
    );
  }

  get visitServices$(): Observable<VisitServiceOptions> {
    return this.store.pipe(select(VisitSelectors.getVisitServiceOption));
  }

  get visitServiceFailedToStart$(): Observable<boolean> {
    return this.store.pipe(
      select(VisitSelectors.getVisitServiceFailed),
      filter((hasFailed) => !!hasFailed),
      takeUntil(this.allServicesServerReady$.pipe(filter((ready) => !!ready)))
    );
  }

  get failedVisitService$(): Observable<VisitServiceOptions> {
    return this.store.pipe(select(VisitSelectors.getFailedVisitService));
  }

  get siteId$(): Observable<string> {
    return this.store.pipe(select(VisitSelectors.getSiteId));
  }

  get visitTimers$(): Observable<VisitSessionTimer> {
    return this.store.pipe(
      select(VisitSelectors.getVisitTimers),
      filter((timers) => !!timers)
    );
  }

  get hasOnlyEmulationService$(): Observable<boolean> {
    return this.store.pipe(select(VisitSelectors.getHasOnlyEmulationService));
  }

  get emulationServiceLost$(): Observable<boolean> {
    return this.emulationFacade.emulationServiceLost$;
  }

  private allServicesClientReady$ = this.store.pipe(
    select(VisitSelectors.getAllVisitServicesClientReady)
  );

  private allServicesServerReady$ = this.store.pipe(
    select(VisitSelectors.getAllVisitServicesServerReady)
  );

  private allServicesServerUnknown$ = this.store.pipe(
    select(VisitSelectors.getAllVisitServicesServerUnknown)
  );

  private hasMediaDevicesPermissions$ = this.store.pipe(
    select(VisitSelectors.getHasMediaDevicesPermissions)
  );

  private hasSiteId$ = this.store
    .pipe(select(VisitSelectors.getSiteId))
    .pipe(filter((siteId) => !!siteId));

  private hasVideoCallWithPermissions$ = this.store.pipe(
    select(VisitSelectors.getHasVideoCallServiceWithPermissions)
  );

  private siteUserHasActiveVisit$ = this.store.pipe(
    select(VisitSelectors.getSiteUserHasActiveVisit)
  );

  private siteUserRejoinVisit$ = this.store.pipe(
    select(VisitSelectors.getRejoinVisit)
  );

  private visitIncludesVideoCall$ = this.store.pipe(
    select(VisitSelectors.getVisitIncludesVideoCall)
  );

  private missingCallConnection$ = this.store.pipe(
    select(CallSelectors.missingCallConnection)
  );

  private visitIncludesEmulation$ = this.store.pipe(
    select(EmulationSelectors.getHasEmulation)
  );

  private missingDeviceConnection$ = this.store.pipe(
    select(EmulationSelectors.missingDeviceConnection)
  );

  private visitInviteStatus$ = this.store.pipe(
    select(VisitSelectors.getInvitationStatus)
  );

  private visitIsJoined$ = this.store.pipe(
    select(VisitSelectors.getAllVisitServicesClientReady)
  );

  private readyToCreateVisit$ = this.readyToCreateVisitService.readyToCreateVisit$.pipe(
    filter((ready) => !!ready),
    distinctUntilChanged()
  );

  private readyToStartVisit$ = combineLatest([
    this.hasOnlyEmulationService$,
    this.hasVideoCallWithPermissions$,
    this.siteUserHasActiveVisit$,
    this.allServicesServerUnknown$,
    this.allServicesClientReady$,
  ]).pipe(
    filter(([a, b, c, d, e]) => (a && c && d && !e) || (b && c && d && !e))
  );

  private readyToRejoinVisit$ = combineLatest([
    this.hasOnlyEmulationService$,
    this.hasVideoCallWithPermissions$,
    this.siteUserHasActiveVisit$,
    this.allServicesServerReady$,
    this.allServicesClientReady$,
    this.siteUserRejoinVisit$,
    this.participantType$,
  ]).pipe(
    filter(
      ([a, b, c, d, e, f, g]) =>
        f &&
        g !== 'provisioner' &&
        ((a && c && !!d && !e) || (b && c && !!d && !e))
    ),
    distinctUntilChanged()
  );

  private startVisitInviteSent$ = combineLatest([
    this.visitInviteStatus$,
    this.visitIsJoined$,
  ]).pipe(
    filter(
      ([status, visitIsJoined]) =>
        status !== 'unset' && status !== 'skipped' && !visitIsJoined
    ),
    map(([status]) => status)
  );

  private done$ = new Subject<void>();

  constructor(
    private emulationFacade: EmulationFacade,
    private notificationService: VisitSnackbarNotificationService,
    private store: Store<VisitState & SessionState>,
    private videoCallFacade: VideoCallFacade,
    private readyToCreateVisitService: ReadyToCreateVisitService,
    private ngZone: NgZone,
    private requestControlDialogService: RequestControlDialogService,
    private userDeviceDetailsService : UserDeviceDetailsService
  ) {
    this.subscribeToPageRefreshed();
    this.subscribeToCanCreateVisit();
    this.subscribeToCanStartVisit();
    this.subscribeToCanRejoinVisit();
    this.subscribeToSendInvite();
    this.subscribeToGetInvitationStatus();
    this.subscribeToVisitEnded();
    this.subscribeToVideoCallEnded();
    this.subscribeToReadyToConnectVisitWebSocket();
    this.subscribeToTransferControlResult();
    this.requestControlDialogService.subscribeToShowRequestControlDialog();
    this.subscribeToDeviceControlRequestRejected();
  }

  private subscribeToPageRefreshed = (): void => {
    this.store.dispatch(VisitActions.clearDeviceConnection());
    this.store.dispatch(VisitActions.clearVideoService());
    combineLatest([
      this.siteUserHasActiveVisit$,
      this.allServicesServerReady$,
      this.allServicesClientReady$,
      this.missingDeviceConnection$,
      this.missingCallConnection$,
    ])
      .pipe(
        take(1),
        filter(([a, b, c, d, e]) => a && !!b && !!c && (d || e)),
        tap(() => this.store.dispatch(VisitActions.joinVisitReady())),
        takeUntil(this.done$)
      )
      .subscribe();
  };

  private subscribeToReadyToConnectVisitWebSocket = (): void => {
    this.siteUserHasActiveVisit$
      .pipe(
        filter((hasActiveVisit) => !!hasActiveVisit),
        takeUntil(this.done$),
        switchMap(() =>
          this.askUserForPermissions$.pipe(
            filter((askForPermissions) => !askForPermissions),
            takeUntil(this.done$),
            take(1)
          )
        )
      )
      .subscribe(() =>
        this.dispatch(VisitActions.attemptConnectVisitWebSocket())
      );
  };

  private subscribeToVideoCallEnded = (): void => {
    this.videoEnded$.pipe(takeUntil(this.done$)).subscribe(() => {
      this.disconnectVideoCall();
    });
  };

  private subscribeToVisitEnded = (): void => {
    combineLatest([
      this.hasVisitEnded$.pipe(filter((hasVisitEnded) => !!hasVisitEnded)),
      this.visitIncludesVideoCall$.pipe(
        filter((visitIncludesVideoCall) => !!visitIncludesVideoCall)
      ),
    ])
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.dispatch(VisitActions.videoServiceEnded()));

    combineLatest([
      this.hasVisitEnded$.pipe(filter((hasVisitEnded) => !!hasVisitEnded)),
      this.visitIncludesEmulation$.pipe(
        filter((visitIncludesEmulation) => !!visitIncludesEmulation)
      ),
    ])
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.dispatch(VisitActions.emulationServiceEnded()));
  };

  private subscribeToGetInvitationStatus = (): void => {
    this.store
      .pipe(
        select(VisitSelectors.getInvitationStatus),
        filter((status) => status !== 'unset'),
        takeUntil(this.done$)
      )
      .subscribe((inviteStatus) => this.handleNotifications(inviteStatus));
  };

  public subscribeToDeviceConnection = (): void => {
    combineLatest([this.deviceConnection$, this.participantType$])
      .pipe(
        filter((connection) => !!connection),
        take(1),
        tap(() => {
          this.dispatch(VisitActions.checkHasDeviceControl());
        }),
        takeUntil(this.done$)
      )
      .subscribe();

    this.deviceConnection$
      .pipe(
        filter((connection) => !!connection),
        take(1),
        tap(() => this.dispatch(VisitActions.checkHasDeviceControl())),
        takeUntil(this.done$)
      )
      .subscribe();

    combineLatest([
      this.deviceConnection$,
      this.hasDeviceControl$,
      this.hasVisitEnded$,
    ])
      .pipe(
        filter(
          ([deviceConnection, hasDeviceControl, hasVisitEnded]) =>
            !!deviceConnection && !!hasDeviceControl && !hasVisitEnded
        ),
        tap(([deviceConnection]) => {
          this.connectToDeviceSession(deviceConnection);
        }),
        takeUntil(this.done$)
      )
      .subscribe();
  };

  private subscribeToTransferControlResult = (): void => {
    this.transferControlResult$
      .pipe(
        filter((transferControlResult) => !!transferControlResult),
        takeUntil(this.done$)
      )
      .subscribe((transferControlResult) => {
        this.notificationService.showAttemptTransferDeviceControlError(
          transferControlResult
        );
      });
  };

  private subscribeToCanCreateVisit = (): void => {
    this.readyToCreateVisit$.pipe(takeUntil(this.done$)).subscribe((ready) => {
      this.dispatch(VisitActions.createVisit());
    });
  };

  private subscribeToCanStartVisit = (): void => {
    this.readyToStartVisit$
      .pipe(takeUntil(this.done$))
      .subscribe(() => this.dispatch(VisitActions.startVisit()));
  };

  private subscribeToCanRejoinVisit = (): void => {
    this.readyToRejoinVisit$
      .pipe(
        takeUntil(this.done$),
        withLatestFrom(this.isEcoaLiveStandalone$),
        filter(([_, isEcoaLiveStandalone]) => !isEcoaLiveStandalone),
        take(1)
      )
      .subscribe(() => this.dispatch(VisitActions.joinVisitReady()));
  };

  private subscribeToSendInvite = (): void => {
    this.startVisitInviteSent$
      .pipe(
        tap((inviteStatus) => this.handleNotifications(inviteStatus)),
        takeUntil(this.done$)
      )
      .subscribe(() => this.dispatch(VisitActions.joinVisitReady()));
  };

  private subscribeToDeviceControlRequestRejected = (): void => {
      this.transferControlDeclined$
        .pipe(
          filter((declined) => !!declined),
        )
      .subscribe(() => this.notificationService.showDeviceControlRequestRejected());
  };

  private handleNotifications = (status: VisitInviteStatus): void => {
    if (status === 'fail') {
      this.showInviteFail();
    }
    if (status === 'success') {
      this.showInviteSuccess();
    }
  };

  private showInviteSuccess = (): void => {
    this.notificationService.showInviteSuccess();
  };

  private showInviteFail = (): void => {
    this.notificationService
      .showInviteFailed()
      .onAction()
      .subscribe(() => this.dispatch(VisitActions.sendInvitation()));
  };

  onDeviceControlRecieved = (): void => {
    this.notificationService.showControlRecieved();
  };

  connectToDeviceSession = (connection: DeviceConnection): void => {
    this.ngZone.run(() => {
      this.emulationFacade
        .connectToDeviceSession(connection)
        .pipe(
          take(1),
          catchError(() => {
            this.notificationService.showDeviceErrorNotification();
            return of();
          })
        )
        .subscribe();
    });
  };

  disconnectDeviceSession = (handover = false): void => {
    this.emulationFacade
      .disconnectDeviceSession()
      .subscribe((isStreamExist) => {
        if (handover && isStreamExist) {
          this.dispatch(VisitActions.emulationTransferControl());
        }
      });
  };

  disconnectVideoCall = (): Observable<boolean> => {
    return this.videoCallFacade.disconnect();
  };

  renderLocalCameraStream = (target: RenderTarget): void => {
    this.videoCallFacade.renderLocalCameraStream(target);
  };

  renderRemoteCameraStream = (target: RenderTarget): void => {
    this.videoCallFacade.renderRemoteCameraStream(target);
  };

  toggleMic = (currentValue: boolean): void => {
    this.videoCallFacade.toggleMic(currentValue);
  };

  toggleVideo = (currentValue: boolean): void => {
    this.videoCallFacade.toggleVideo(currentValue);
  };

  switchCamera = (): void => {
    this.videoCallFacade.switchCamera();
  };

  isMobileDevice = (): boolean => this.userDeviceDetailsService.isMobileDevice();

  getWindowWidth = (): number => this.userDeviceDetailsService.getWindowWidth();

  getDeviceHeight = (): number => this.userDeviceDetailsService.getDeviceScreenHeight();

  dispatch = (
    action: RootActions.VisitActionsUnion | RootActions.SessionActionsUnion
  ): void => {
    this.store.dispatch(action);
  };

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }
}
