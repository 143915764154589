import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { RootSelectors, RootState } from '@virtual-trials-workspace/store';


@Component({
  selector: 'vt-web-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss']
})
export class GeneralErrorComponent {
  failedErrorMsg$ = this.store.pipe(select(RootSelectors.getFailedErrorMsg));
  
  constructor(private store: Store<RootState>, private router: Router) {}

}
