import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoTestingModule } from '@ngneat/transloco';
// import { NgxAnimationsModule } from 'ngx-animations';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { NotificationDisplayDialogComponent } from './notification-display-dialog/notification-display-dialog.component';
import { SlidePanelComponent } from './slide-panel/slide-panel.component';
import { ViewportLayoutComponent } from './viewport-layout/viewport-layout.component';
import { ViewportErrorComponent } from './viewport-error/viewport-error.component';
import { LanguageControleService } from './language-controle/language-controle-service.service';
import {
  EmulationDeviceComponent,
  BottomPanelDirective,
  TopPanelDirective,
} from './emulation-device';
import {
  SessionTimeoutService,
  SessionTimeoutDialogComponent,
} from './session-timeout';
import { VisitSessionExtendDialogComponent } from './visit-session-extend';
import { PreferredLanguageControlComponent } from './language-controle/preferred-language-control/preferred-language-control.component';
import { SelectLanguageControlComponent } from './language-controle/select-language-control/select-language-control.component';
import { ServiceFailedDialogComponent } from './service-failed-dialog/dialog/service-failed-dialog.component';
import { RequestControlDialogComponent } from './request-control-dialog';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { PaginationComponent } from './pagination/pagination.component';
import { SubjectHeaderComponent } from './subject-header/subject-header.component';

@NgModule({
  declarations: [
    HeaderLogoComponent,
    LoadingOverlayComponent,
    SlidePanelComponent,
    NotificationDisplayDialogComponent,
    LanguageSelectComponent,
    ViewportLayoutComponent,
    ViewportErrorComponent,
    EmulationDeviceComponent,
    BottomPanelDirective,
    TopPanelDirective,
    SessionTimeoutDialogComponent,
    VisitSessionExtendDialogComponent,
    PreferredLanguageControlComponent,
    SelectLanguageControlComponent,
    ServiceFailedDialogComponent,
    RequestControlDialogComponent,
    CustomSnackbarComponent,
    PaginationComponent,
    SubjectHeaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatChipsModule,
    MatBottomSheetModule,
    MatMenuModule,
    MatStepperModule,
    // NgxAnimationsModule,
    TranslocoModule,
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatChipsModule,
    HeaderLogoComponent,
    LoadingOverlayComponent,
    MatBottomSheetModule,
    MatMenuModule,
    MatStepperModule,
    SlidePanelComponent,
    // NgxAnimationsModule,
    NotificationDisplayDialogComponent,
    LanguageSelectComponent,
    TranslocoTestingModule,
    ViewportLayoutComponent,
    ViewportErrorComponent,
    EmulationDeviceComponent,
    BottomPanelDirective,
    TopPanelDirective,
    VisitSessionExtendDialogComponent,
    PreferredLanguageControlComponent,
    SelectLanguageControlComponent,
    ServiceFailedDialogComponent,
    RequestControlDialogComponent,
    CustomSnackbarComponent,
    PaginationComponent,
    SubjectHeaderComponent
  ],
  providers: [
    SessionTimeoutService,
    LanguageControleService,
    {
      provide: MatSnackBarRef,
      useValue: {},
    },
    {
      provide: MAT_SNACK_BAR_DATA,
      useValue: {}, // Add any data you wish to test if it is passed/used correctly
    },
  ],
})
export class UiModule {}
