import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vt-site-journey-code-sent-failure',
  templateUrl: './code-sent-failure.component.html',
  styleUrls: ['./code-sent-failure.component.scss'],
})
export class CodeSentFailureComponent {
  constructor(private dialogRef: MatDialogRef<CodeSentFailureComponent>) {}

  closeDialog = (): void => {
    this.dialogRef.close();
  };
}
