import { Injectable } from '@angular/core';
import { ConfigurationService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class ScopesService {
  constructor(private configurationService: ConfigurationService) {}
  readonly protectedRoutes = [
    '/sites',
    '/subject',
    '/create-account',
    '/security-questions',
    '/visit',
    '/provisioner',
    '/activate'
  ];

  readonly protectedResources = ['/account/resetpassword', '/visit/check'];
  readonly tokenExcludeResources = ['/studylanguages'];

  routeRequiresToken = (route: string): boolean => {
    return this.protectedRoutes.some((protectedRoute) =>
      route.includes(protectedRoute)
    );
  };

  resourceRequiresResource = (resource: string): boolean => {
    return this.protectedResources.some((protectedResource) =>
      resource.includes(protectedResource)
    );
  };
  tokenExcludeResource = (resource: string): boolean => {
    return this.tokenExcludeResources.some((protectedResource) =>
      resource.includes(protectedResource)
    );
  };

  resourceIsInternal = (resource: string): boolean => {
    return resource.includes(this.configurationService.baseApiAddress);
  };
}
