<ng-container *transloco="let transl">
  <div class="es-gateway">
    <main class="es-gateway-body">
      <section
        class="es-lang-control-popup es-timer-popup-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
      >
        <div class="es-column es-visit-timer-column">
          <div class="es-content-box">
            <div class="es-panel es-custom-panel">
              <header class="es-card-header">
                <h2>{{ transl('preferred_language.default_language') }}</h2>
              </header>
              <div  class="es-card-body">
                <div class="es-content-box">
                  <div class="es-toolbar">
                    <ul class="es-right">
                      <li class="es-toolbar-item">
                        <button 
                          class="es-button" 
                          id="cancel" 
                          mat-button 
                          [mat-dialog-close]="false"
                        >
                          {{ transl('general.no') }}
                        </button>
                      </li>
                      <li class="es-toolbar-item">
                        <button
                          id="continue"
                          mat-button
                          [mat-dialog-close]="true"
                          class="es-button  -es-primary "
                        >
                          {{ transl('general.yes') }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</ng-container>
