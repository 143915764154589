import { ClientVersion } from './client-version';

export type SupportedClientType = { [name: string]: ClientVersion };
export type SupportedClientsType = { [name: string]: SupportedClientType };
export type SupportedViewPortType = {
  minWidth: number;
  minHeight: number;
};
export type PlatformUpdateUrls = {
  ios: string;
  android: string;
  default: string;
};
export type Session = {
  timeoutWarningTime: number;
};
export type Auth = {
  loginUrl: string;
  clientId: string;
  logoutUrl: string;
  checkSessionUrl: string;
  rolesUrl: string;
  redirectUrl: string;
};

export abstract class BaseConfig {
  abstract readonly name: string;
  abstract readonly production: boolean;
  abstract readonly baseApiEndpoint: string;
  abstract readonly baseWebSocketEndpoint: string;
  abstract readonly appVersion: string;
  abstract readonly deviceStreamGatewayUrl: string;
  abstract readonly deviceStreamGatewayAuthToken: string;
  abstract readonly websocketKeepAliveTimerMinutes: number;
  abstract readonly emulationHealthCheckIntervalMinutes: number;
  abstract readonly translationsBaseApiEndpoint: string;
  abstract readonly translationsApiKey: string;
  abstract readonly visitCodeAPIEndpoint: string;
  abstract readonly GATrakingCode: string;
  abstract readonly airbrakeProjectKey: string;
  abstract readonly supportedClients: SupportedClientsType;
  abstract readonly supportedViewPort: SupportedViewPortType;
  abstract readonly platformUpdateUrls: PlatformUpdateUrls;
  abstract readonly session: Session;
  abstract readonly auth: Auth;
  abstract readonly navigatorHeaderScriptId: string;
  abstract readonly navigatorHeaderScriptUrl: string;
}
