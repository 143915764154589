import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';
import {
  LanguageHttpService,
  SessionActions,
  SessionSelectors,
  SessionState,
} from '@virtual-trials-workspace/store';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

function waitForTranslationsLoaded(
  store: Store,
  languageService: LanguageHttpService,
  hasToken: boolean
): Observable<boolean> {
  return store.pipe(
    select(SessionSelectors.getTranslationsAuthType),
    switchMap((type) => {
      if (
        !((type === 'auth' && hasToken) || (type === 'unauth' && !hasToken))
      ) {
        const storedLang = languageService.getStoredLanguage()
          ? languageService.getStoredLanguage()
          : 'en-US';
        store.dispatch(
          SessionActions.getTranslationFiles({ language: storedLang })
        );
      }
      return store.pipe(
        select(SessionSelectors.getTranslationsLoaded),
        filter((t) => !!t)
      );
    })
  );
}

@Injectable({
  providedIn: 'root',
})
export class UnauthPageTranslationsGuard  {
  constructor(
    private localStorage: LocalStorageService,
    private languageService: LanguageHttpService,
    private store: Store<SessionState>
  ) {}

  canActivate(): Observable<boolean> {
    this.languageService.unSubscribeToTokenChange();

    const hasToken = !!this.localStorage.get('token');

    if (hasToken) {
      this.localStorage.delete('token');
    }

    return waitForTranslationsLoaded(this.store, this.languageService, false);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthPageTranslationsGuard  {
  constructor(
    private localStorage: LocalStorageService,
    private languageService: LanguageHttpService,
    private store: Store<SessionState>
  ) {}

  canActivate(): Observable<boolean> {
    this.languageService.subscribeToTokenChange();

    const hasToken = !!this.localStorage.get('token');

    return waitForTranslationsLoaded(
      this.store,
      this.languageService,
      hasToken
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }
}
