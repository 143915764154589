import { createFeatureSelector, createSelector, State } from '@ngrx/store';
import {
  LicenseType,
  VisitInviteStatus,
  VisitService,
} from '@virtual-trials-workspace/models';
import * as SessionSelectors from './session.selectors';
import { VisitState, VISIT_STATE_KEY } from '../sub-states';
import { getIsSiteUser, getIsSubjectUser } from './session.selectors';

export const getVisitState = createFeatureSelector<VisitState>(VISIT_STATE_KEY);

export const getError = createSelector(
  getVisitState,
  (state: VisitState) => state.error
);

export const getIsLoading = createSelector(
  getVisitState,
  SessionSelectors.getIsLoading,
  (state: VisitState, sessionIsLoading: boolean) =>
    state.isLoading || sessionIsLoading
);
export const getEcoaLiveStandalone = createSelector(
  getVisitState,
  (state: VisitState) => state.isEcoaLiveStandalone
);

export const getIsDeviceProvisioned = createSelector(
  getVisitState,
  (state: VisitState) => state.isDeviceProvisioned
);

export const getVisitEnd = createSelector(
  getVisitState,
  (state: VisitState) => state.visitEnd
);

export const getDeviceProvisioningStatus = createSelector(
  getVisitState,
  (state: VisitState) => state.deviceProvisioningStatus
);

export const getVisitServices = createSelector(
  getVisitState,
  (state: VisitState) => state.services
);

export const getSubjectId = createSelector(
  getVisitState,
  (state: VisitState) => state.subjectId
);

export const getSiteId = createSelector(
  getVisitState,
  (state: VisitState) => state.siteId
);

export const getProvisioningStatus = createSelector(
  getVisitState,
  (state: VisitState) => state.deviceProvisioningStatus
);

export const getHasActiveVisit = createSelector(
  getVisitState,
  (state: VisitState) => state.hasActiveVisit
);

export const getRejoinVisit = createSelector(
  getVisitState,
  (state: VisitState) => state.rejoinVisit
);

export const getVisitServiceOption = createSelector(
  getVisitState,
  (state: VisitState) => {
    const { services } = state;

    if (hasAllServices(services)) {
      return 'all';
    }

    if (hasEmulation(services)) {
      return 'emulation';
    }

    if (hasVideoCall(services)) {
      return 'video-call';
    }
  }
);

export const getSubjectParticipantId = createSelector(
  getVisitState,
  (state: VisitState) => state.subjectParticipantId
);

export const getVisitId = createSelector(
  getVisitState,
  (state: VisitState) => state.visitId
);

export const getVisitCode = createSelector(
  getVisitState,
  (state: VisitState) => state.visitCode
);

export const getAllAvailableUserMicrophones = createSelector(
  getVisitState,
  ({ allUserDevices }) =>
    allUserDevices.filter((device) => mediaDevicesByType(device, 'audioinput'))
);

export const getAllAvailableUserSpeakers = createSelector(
  getVisitState,
  ({ allUserDevices }) =>
    allUserDevices.filter((device) => mediaDevicesByType(device, 'audiooutput'))
);

export const getAllAvailableUserCameras = createSelector(
  getVisitState,
  ({ allUserDevices }) =>
    allUserDevices.filter((device) => mediaDevicesByType(device, 'videoinput'))
);

export const mediaDevicesByType = (
  device: MediaDeviceInfo,
  type: MediaDeviceKind
) =>
  device.kind === type &&
  device.deviceId !== 'default' &&
  device.deviceId !== 'communications';

export const getVisitIncludesVideoCall = createSelector(
  getVisitState,
  (state: VisitState) => {
    return hasVideoCall(state.services) || hasAllServices(state.services);
  }
);

export const getHasMediaDevicesPermissions = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.hasMediaDevicesPermissions;
  }
);

export const getInvitationStatus = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.inviteStatus;
  }
);

export const getEmulationServiceLost = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.emulationServiceLost;
  }
);

const hasVideoCall = (services: Array<VisitService>): boolean => {
  return services.some((s) => s.type === 'Call');
};

const hasEmulation = (services: Array<VisitService>): boolean => {
  return services.some((s) => s.type === 'Emulation');
};

const hasAllServices = (services: Array<VisitService>): boolean => {
  return hasEmulation(services) && hasVideoCall(services);
};

const hasOnlyEmulation = (services: Array<VisitService>): boolean => {
  return services.some((s) => s.type === 'Emulation') && services.length === 1;
};

export const getSiteUserHasActiveVisit = createSelector(
  getHasActiveVisit,
  getIsSiteUser,
  (hasActiveVisit, isSiteUser) => {
    return hasActiveVisit && isSiteUser;
  }
);

export const getSubjectUserHasActiveVisit = createSelector(
  getHasActiveVisit,
  getIsSubjectUser,
  (hasActiveVisit, isSubjectUser) => {
    return hasActiveVisit && isSubjectUser;
  }
);

export const getHasVideoCallServiceWithPermissions = createSelector(
  getHasMediaDevicesPermissions,
  getVisitState,
  (hasPermissions: boolean, state: VisitState) => {
    return (
      !!hasPermissions &&
      !hasOnlyEmulation(state.services) &&
      hasVideoCall(state.services)
    );
  }
);

export const getHasOnlyEmulationService = createSelector(
  getVisitState,
  (state: VisitState) => {
    return hasOnlyEmulation(state.services);
  }
);

export const getAllVisitServices = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.services
  }
);


export const getAllVisitServicesServerReady = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.services.every((s) => s.serverStatus === 'ready');
  }
);

export const getAllVisitServicesServerUnknown = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.services.every((s) => s.serverStatus === 'unknown');
  }
);

export const getAllVisitServicesClientReady = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.services.every((s) => s.clientStatus === 'ready');
  }
);

export const getReadyToJoinVisit = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.readyToJoinVisit;
  }
);

export const getVisitTimers = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.visitSessionTimer;
  }
);

export const getTransferControlResult = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.transferControlResult;
  }
);

export const recievedDeviceControl = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.deviceConnect;
  }
);

export const deviceConnection = createSelector(
  getVisitState,
  (state: VisitState) => {
    return state.deviceConnection;
  }
);

export const getVisitServiceFailed = createSelector(
  getVisitState,
  (state: VisitState) => {
    return (
      !state.services.some((s) => s.serverStatus === 'unknown') &&
      state.services.some((s) => s.serverStatus === 'errored')
    );
  }
);

export const getFailedVisitService = createSelector(
  getVisitState,
  (state: VisitState) => {
    const { services } = state;

    if (
      services.length > 1 &&
      services.every((s) => s.serverStatus === 'errored')
    ) {
      return 'all';
    }

    if (
      hasEmulation(services) &&
      services.find((s) => s.type === 'Emulation').serverStatus === 'errored'
    ) {
      return 'emulation';
    }

    if (
      hasVideoCall(services) &&
      services.find((s) => s.type === 'Call').serverStatus === 'errored'
    ) {
      return 'video-call';
    }

    return null;
  }
);

export const getEcoaLiveStandaloneVisitCode = createSelector(
  getVisitState,
  (state: VisitState) => state.ecoaLiveStandaloneVisitCode
);

export const getEmulationRequestControlConfirmationPending = createSelector(
  getVisitState,
  (state: VisitState) => state.emulationRequestControlConfirmationPending
);

export const getEmulationRequestControlDeclined = createSelector(
  getVisitState,
  (state: VisitState) => state.emulationRequestControlDeclined
);