import { Injectable, OnDestroy } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fetch } from '@nx/angular';
import {
  LanguageModel,
  LoginResponse,
  ParticipantType,
  TranslationFile,
} from '@virtual-trials-workspace/models';
import { TokenService } from '@virtual-trials-workspace/shared-core-services';
import * as FromVisitActions from '../actions/visit.actions';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import * as FromSessionActions from '../actions/session.actions';
import { LanguageHttpService, SessionHttpService } from '../http';
import { SessionState, VisitState } from '../sub-states';
import { combineLatest, Subject } from 'rxjs';
import * as SessionSelectors from '../selectors/session.selectors';

@Injectable()
export class SessionEffects implements OnDestroy {
  private done$ = new Subject<void>();
  constructor(
    private actions$: Actions,
    private sessionHttpService: SessionHttpService,
    private router: Router,
    private languageHttpService: LanguageHttpService,
    private translocoService: TranslocoService,
    private store: Store<SessionState>,
    private tokenService: TokenService
  ) {}

  attemptLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSessionActions.login),
      fetch({
        run: (action) => {
          return this.sessionHttpService
            .loginParticipant$(action.username, action.password)
            .pipe(
              map((response) =>
                FromSessionActions.loginSuccess({
                  ...response,
                })
              )
            );
        },
        onError: (action, error) => {
          return FromSessionActions.loginFailure({
            payload: { code: error.status, message: error.message },
          });
        },
      })
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSessionActions.loginSuccess),
        tap(async (action) => {
          await this.handleLoginRedirect(action.participantType);
        }),
        map((response: LoginResponse) => {
          const languageToUse = this.languageHttpService.getStoredLanguage()
            ? this.languageHttpService.getStoredLanguage()
            : response.languageCode;
          this.store.dispatch(
            FromSessionActions.setDefaultLanguageCode({
              defaultSetLanguageCode: response.languageCode,
            })
          );

          this.store.dispatch(FromVisitActions.clearVisitEndState());
          this.handleFailedLanguagesFallback(languageToUse);
        })
      ),
    { dispatch: false }
  );

  attemptJoinStandaloneVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSessionActions.joinStandaloneVisit),
      fetch({
        run: (action) => {
          return this.sessionHttpService
            .joinStandaloneVisit$(action.visitCode)
            .pipe(
              map((response) =>
                FromSessionActions.joinStandaloneVisitSuccess({
                  ...response,
                  participantType: 'subject',
                  visitCode: action.visitCode,
                })
              )
            );
        },
        onError: (action, error) => {
          return FromSessionActions.joinStandaloneVisitFailure({
            payload: { code: error.status, message: error.message },
          });
        },
      })
    )
  );

  joinStandaloneVisitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSessionActions.joinStandaloneVisitSuccess),
        tap(async (action) => {
          await this.router.navigate(['.', 'standalone', 'visit', 'room'], {
            skipLocationChange: true,
          });
        })
      ),
    { dispatch: false }
  );

  joinStandaloneVisitFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSessionActions.joinStandaloneVisitFailure),
        tap(async (action) => {
          await this.router.navigate(['.', 'standalone', 'visit', 'error'], {
            skipLocationChange: true,
          });
        })
      ),
    { dispatch: false }
  );

  endStandaloneVisitOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSessionActions.endStandaloneVisitOnError),
        tap(async (action) => {
          await this.router.navigate(['.', 'standalone', 'visit', 'error'], {
            skipLocationChange: true,
          });
        })
      ),
    { dispatch: false }
  );

  getLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSessionActions.getLanguages),
      fetch({
        run: () => {
          return this.languageHttpService.getLanguages$().pipe(
            map((languages: LanguageModel[]) => {
              this.store.dispatch(
                FromSessionActions.setLanguagesFallback({
                  isLanguagesFallback: languages.length === 0 ? true : false,
                })
              );

              if (languages.length === 0) {
                languages = [
                  {
                    code: 'en-US',
                    displayName: 'English, United States',
                    displayOrder: 1,
                    name: 'English, United States',
                    rightToLeft: false,
                  },
                ];
              }
              return FromSessionActions.getLanguagesSuccess({
                languages: languages,
              });
            })
          )
        },
        onError: (action, response) => {
          return FromSessionActions.getLanguagesFailure();
        },
      })
    )
  );

  getTranslationFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSessionActions.getTranslationFiles),
      fetch({
        run: (action) => {
          return this.languageHttpService
            .getUITranslations$(action.language)
            .pipe(
              map((translations: TranslationFile[]) => {
                this.addOrRemoveDirTag(action.language);
                return FromSessionActions.getTranslationFilesSuccess({
                  translationFiles: translations,
                });
              })
            );
        },
        onError: (action, error) => {
          return FromSessionActions.getTranslationFilesFailure({
            payload: error,
          });
        },
      })
    )
  );

  endStandaloneVisit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSessionActions.endStandaloneVisit),
        tap(
          async () =>
            await this.router.navigate(['.', 'standalone', 'visit', 'end'], {
              skipLocationChange: true,
            })
        )
      ),
    { dispatch: false }
  );

  refreshToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSessionActions.refreshToken),
        fetch({
          run: () => {
            return this.sessionHttpService.refreshToken$();
          },
          onError: (action, error) => {
            return FromSessionActions.refreshTokenFailure();
          },
        })
      ),
    { dispatch: false }
  );

  getSutdyLanguagesByParticipantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSessionActions.getSutdyLanguagesByParticipantId),
      fetch({
        run: (action) => {
          return this.sessionHttpService.getSutdyLanguagesByParticipantId$().pipe(
            map((studyLanguages) => {
              return FromSessionActions.getSutdyLanguagesByParticipantIdSuccess({
                studyLanguages,
              });
            })
          )
        },
        onError: (action, error) => {
          return FromSessionActions.getSutdyLanguagesByParticipantIdFailure({
            payload: error,
          });
        },
      })
    )
  );

  endActiveSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FromSessionActions.endActiveSession),
        tap(async () => {
          this.tokenService.removeToken();
          FromSessionActions.clearSessionData();
          await this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  endActiveSessionOnInitApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromSessionActions.endActiveSession),
      tap(async () => await this.router.navigate(['/login'])),
      map(() => FromSessionActions.clearSessionData())
    )
  );

  private handleFailedLanguagesFallback(langCode: string): void {
    combineLatest([
      this.store.pipe(select(SessionSelectors.getSelectedLanguageCode)),
      this.store.pipe(select(SessionSelectors.getLanguagesFallback)),
    ])
      .pipe(
        filter(([languageCode]) => !!languageCode),
        takeUntil(this.done$),
        take(1)
      )
      .subscribe(([languageCode, isLanguagesFallback]) => {
        if (isLanguagesFallback) {
          this.setOrUpdatePreferredLanguage(languageCode);
          this.addOrRemoveDirTag(languageCode);
        } else {
          this.setOrUpdatePreferredLanguage(langCode);
          this.addOrRemoveDirTag(langCode);
        }
      });
  }

  private addOrRemoveDirTag = (languageCode: string): void => {
    this.store
      .pipe(select(SessionSelectors.getAllAvailableLanguages))
      .pipe(takeUntil(this.done$), take(1))
      .subscribe((languages) => {
        if (
          languages.find((l) => l.code === languageCode).rightToLeft &&
          !document.getElementsByTagName('html')[0].hasAttribute('dir')
        ) {
          document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
        } else if (
          !languages.find((l) => l.code === languageCode).rightToLeft &&
          document.getElementsByTagName('html')[0].hasAttribute('dir')
        ) {
          document.getElementsByTagName('html')[0].removeAttribute('dir');
        }
      });
  };

  private handleLoginRedirect = (
    participantType: ParticipantType
  ): Promise<boolean> => {
    let url = '';
    switch (participantType) {
      case 'user':
        url = '/sites/select-study-site';
        break;
      case 'subject':
        url = '/visit';
        break;
      case 'provisioner':
        url = '/provisioner';
        break;
    }
    return this.router.navigateByUrl(url);
  };

  private setOrUpdatePreferredLanguage(preferredLangage: string): void {
    const storedLanguage: string = this.languageHttpService.getStoredLanguage();
    const isLanguageChanged: boolean =
      this.languageHttpService.getLanguageChangeState();

    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe((_) => {
        let languageToUse: string;

        if (
          !storedLanguage &&
          preferredLangage &&
          preferredLangage !== this.languageHttpService.defaultLanguage
        ) {
          this.languageHttpService.updateSelectedLanguage(
            preferredLangage,
            false
          );
          this.translocoService.setActiveLang(preferredLangage);
          languageToUse = preferredLangage;
        } else if (
          isLanguageChanged &&
          storedLanguage &&
          preferredLangage &&
          storedLanguage !== preferredLangage
        ) {
          this.languageHttpService.updateSelectedLanguage(storedLanguage);
          languageToUse = storedLanguage;
        } else if (
          !isLanguageChanged &&
          storedLanguage &&
          preferredLangage &&
          storedLanguage !== preferredLangage
        ) {
          this.languageHttpService.updateSelectedLanguage(
            preferredLangage,
            false
          );
          this.translocoService.setActiveLang(preferredLangage);
          languageToUse = preferredLangage;
        }

        if (languageToUse) {
          this.store.dispatch(
            FromSessionActions.getTranslationFiles({
              language: languageToUse,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }
}
