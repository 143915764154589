import { createSelector } from '@ngrx/store';
import { VisitService } from '@virtual-trials-workspace/models';

import { VisitState } from '../../sub-states';
import { getVisitState } from './visit-selectors';

export const getCallroomKey = createSelector(
  getVisitState,
  (state: VisitState) => state.roomKey
);

export const getCallToken = createSelector(
  getVisitState,
  (state: VisitState) => state.roomPin
);

export const getCallClientStatus = createSelector(
  getVisitState,
  (state: VisitState) =>
    state.services.find((s) => s.type === 'Call').clientStatus
);

export const getCallHasEnded = createSelector(
  getVisitState,
  (state: VisitState) => state.videoServiceEnded
);

export const missingCallConnection = createSelector(
  getVisitState,
  (state: VisitState) => hasCall(state.services) && !state.roomKey
);

export const selectedCameraId = createSelector(
  getVisitState,
  (state: VisitState) => state.selectedCameraId
);

const hasCall = (services: Array<VisitService>): boolean => {
  return services.some((s) => s.type === 'Call');
};
