import {
  LanguageModel,
  ParticipantType,
  TranslationFile,
} from '@virtual-trials-workspace/models';
import { RootState } from '../root.state';

export interface SessionState extends RootState {
  participantId: string;
  participantType: ParticipantType;
  languages?: LanguageModel[];
  filterLanguages?: LanguageModel[];
  selectedLanguageCode?: string;
  translationFiles?: TranslationFile[];
  translationsAuthType: 'auth' | 'unauth';
  translationsLoaded: boolean;
  isEcoaLiveStandalone: boolean;
  isAnonymous: boolean;
  allLanguages?: LanguageModel[];
  studyLanguages?: string[];
  isPrefferedLanguageDialogOpen: boolean;
  defaultSetLanguageCode?: string;
  genericErrorMessage?: string;
  isLanguagesFallback?: boolean;
}

export const initialSessionState: SessionState = {
  error: undefined,
  isLoading: false,
  participantId: undefined,
  participantType: 'none',
  languages: undefined,
  filterLanguages: undefined,
  selectedLanguageCode: undefined,
  translationFiles: undefined,
  translationsAuthType: undefined,
  translationsLoaded: false,
  isEcoaLiveStandalone: undefined,
  isAnonymous: undefined,
  allLanguages: undefined,
  isPrefferedLanguageDialogOpen: false,
  defaultSetLanguageCode: undefined,
  genericErrorMessage: undefined,
  isLanguagesFallback: false
};

export const SESSION_STATE_KEY = 'SESSION_STATE';
