import { Injectable } from '@angular/core';
import { Translation } from '@ngneat/transloco';

function stripAuth(url: string) {
  return url.split('?')[0];
}
@Injectable({ providedIn: 'root' })
export class TranslocoMissingKeyStore {
  private _fallbackTranslations: Translation;
  private _cdnUrl: string;

  get fallbackTranslations(): Translation {
    return this._fallbackTranslations?.translations;
  }

  hasFallbackTranslations(cdnUrl: string): boolean {
    return this._cdnUrl && stripAuth(cdnUrl) === stripAuth(this._cdnUrl);
  }

  setFallbackTranslations = (
    translations: Translation,
    cdnUrl: string
  ): void => {
    this._fallbackTranslations = {
      translations: { ...translations },
    };
    this._cdnUrl = cdnUrl;
  };
}
