import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ParticipantType,
  SecurityQuestion,
} from '@virtual-trials-workspace/models';
import { RestApiService } from '@virtual-trials-workspace/shared-core-services';

import * as FromModels from '../../models';

@Injectable({ providedIn: 'root' })
export class ActivateHttpService {
  readonly activateUserResource = '/account/activate';
  readonly createAccountForSiteUserResource = '/user/setpassword';
  readonly createAccountForSubjectResource = '/subject/setcredentials';
  readonly getSecurityQuestionsResource = '/account/securityquestions';

  constructor(private restApiService: RestApiService) {}

  activateParticipant$ = (
    shortcode: string
  ): Observable<{
    codeStatus: FromModels.ShortCodeStatus;
    participantId: string;
    participantType: ParticipantType;
  }> => {
    return this.restApiService.post(this.activateUserResource, {
      shortcode,
    });
  };

  createSiteUserAccount$ = (
    participantId: string,
    username: string,
    password: string,
    securityQuestions: Array<FromModels.SecurityQuestionAnswer>
  ): Observable<any> => {
    return this.restApiService.post(this.createAccountForSiteUserResource, {
      participantId,
      username,
      password,
      securityQuestions,
    });
  };

  createSubjectUserAccount$ = (
    participantId: string,
    email: string,
    password: string,
    securityQuestions: Array<FromModels.SecurityQuestionAnswer>
  ): Observable<any> => {
    return this.restApiService.post(this.createAccountForSubjectResource, {
      participantId,
      email,
      password,
      securityQuestions,
    });
  };

  getSecurityQuestions$ = (): Observable<Array<SecurityQuestion>> => {
    return this.restApiService.get(this.getSecurityQuestionsResource);
  };
}
