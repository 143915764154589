import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RootSelectors,
  RouteState,
  SessionState,
  SessionSelectors
} from '@virtual-trials-workspace/store';
import { SiteState, SITE_STATE_KEY } from '../site.state';

export const getSiteState = createFeatureSelector<SiteState>(SITE_STATE_KEY);

export const getError = createSelector(
  getSiteState,
  (state: SiteState) => state.error
);

export const getIsLoading = createSelector(
  getSiteState,
  SessionSelectors.getIsLoading,
  (state: SiteState, sessionIsLoading: boolean) =>
    state.isLoading || sessionIsLoading
);

export const getSubjects = createSelector(
  getSiteState,
  (state: SiteState) => state.subjects
);

export const getSelectedSubject = createSelector(
  getSiteState,
  (state: SiteState) => state.selectedSubject
);

export const getNotification = createSelector(
  getSiteState,
  (state: SiteState) => state.notification
);

export const getSubjectsAreLoaded = createSelector(
  getSiteState,
  (state: SiteState) => state.subjectsLoaded
);

export const getErrorMessage = createSelector(
  getSiteState,
  (state: SiteState) => (state.error ? state.error.message : undefined)
);

export const getSubjectByName = createSelector(
  getSiteState,
  (state: SiteState, props: { subjectName: string }) => {
    const subject = state.subjects.find(
      (source) => source.subjectName === props.subjectName
    );
    return subject ? subject : undefined;
  }
);

export const getActivationCallDetailsForSubject = createSelector(
  getSiteState,
  (state: SiteState, props: { subjectId: string }) => {
    const subject = state.subjects.find(
      (source) => source.subjectId === props.subjectId
    );
    return subject?.activationDetails ? subject.activationDetails : undefined;
  }
);

export const getSubjectFromRouteParam = createSelector(
  getSiteState,
  RootSelectors.getRouterReducerState,
  (state: SiteState, routerState: RouterReducerState<RouteState>) => {
    const subject = state.subjects.find(
      (source) => source.subjectName === routerState.state.params.subjectName
    );
    return subject ? subject : undefined;
  }
);

export const getParticipantId = createSelector(
  RootSelectors.getSessionState,
  (state: SessionState) => state.participantId
);

export const getSelectedStudy = createSelector(
  getSiteState,
  (state: SiteState) =>
    state.studies
      ? state.studies.find(({ studyId }) => studyId === state.selectedStudyId)
      : undefined
);

export const getSelectedSite = createSelector(
  getSiteState,
  getSelectedStudy,
  (state, study) =>
    study
      ? study.sites.find(({ siteId }) => siteId === state.selectedSiteId)
      : undefined
);

export const getSelectedStudyAndSite = createSelector(
  getSiteState,
  ({ selectedStudyId, selectedSiteId }) => ({
    selectedStudyId,
    selectedSiteId,
  })
);

export const getPrevSelectedStudyAndSite = createSelector(
  getSiteState,
  ({ prevSelectedStudyId, prevSelectedSiteId }) => ({
    prevSelectedStudyId,
    prevSelectedSiteId,
  })
);

export const getStudies = createSelector(getSiteState, ({ studies }) =>
  studies.map(({ studyId, studyName }) => ({
    studyId,
    studyName,
  }))
);

export const getSiteId = createSelector(getSelectedSite, (site) =>
  site ? site.siteId : undefined
);

export const getSubjectIdRegex = createSelector(
  getSelectedStudy,
  (site) => site ? site.studyRegex : undefined
);

export const getStudyName = createSelector(getSelectedSite, (site) =>
  site ? site.siteName : undefined
);

export const getSitesForStudy = createSelector(
  getSiteState,
  ({ studies }) => (selectedStudyId: string) => {
    const empty = [];

    if (!studies) {
      return empty;
    }

    const match = studies.find((s) => s.studyId === selectedStudyId);

    return match ? match.sites : empty;
  }
);

export const getisECOALiveForSelectedStudy = createSelector(
  getSiteState,
  ({ studies, selectedStudyId }) => {
    return studies.find(({ studyId }) => studyId === selectedStudyId)?.ecoaLiveStandalone;
  }
);

export const getLicensesForSelectedStudy = createSelector(
  getSiteState,
  ({ studies, selectedStudyId }) => {
    const empty = [];
    if(!studies) {
      return empty;
    }
    const match = studies.find(({ studyId }) => studyId === selectedStudyId);
    return match ? match.licenses : empty;
  }
);

export const getStudyLanguagesForSelectedStudy = createSelector(
  getSiteState,
  ({ studies, selectedStudyId }) => {
    const empty = [];
    if(!studies) {
      return empty;
    }
    const match = studies.find(({ studyId }) => studyId === selectedStudyId);
    return match ? match.studyLanguages : empty;
  }
);

export const getDeviceStatus = createSelector(getSiteState, (site) => {
  return site.deviceStatus;
});
