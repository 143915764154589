import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { WebSocketPayload } from '@virtual-trials-workspace/shared-core-services';
import { noop } from 'lodash';
import { timer } from 'rxjs';

import * as VisitActions from '../actions/visit.actions';
import { VisitState } from '../sub-states';

export declare type VisitMessageType = 'visitstart' | 'visitjoin';

@Injectable({ providedIn: 'root' })
export class VisitWebSocketMessageService {
  constructor(private store: Store<VisitState>) {}

  toStartVisitPayload = (siteId: string, visitId: string): WebSocketPayload => {
    return JSON.stringify({
      action: 'visitstart',
      data: { siteId, visitId },
    });
  };

  toJoinVisitPayload = (visitId: string): WebSocketPayload => {
    return JSON.stringify({
      action: 'visitjoin',
      data: { visitId },
    });
  };

  toTransferControlPayload = (visitId: string): WebSocketPayload => {
    return JSON.stringify({
      action: 'devicehandovercontrol',
      data: { visitId },
    });
  };

  toVisitActionMessagePayload = (
    visitId: string,
    messageAction: string
  ): WebSocketPayload => {
    return JSON.stringify({
      action: 'visitactionmessage',
      data: { visitId, messageAction },
    });
  };

  toEndVisitPayload = (visitId: string): WebSocketPayload => {
    return JSON.stringify({
      action: 'visitend',
      data: { visitId },
    });
  };

  toVisitJoinExtend = (visitId: string): WebSocketPayload => {
    return JSON.stringify({
      action: 'visitjoinwaitextend',
      data: { visitId },
    });
  };

  toVisitEndExtend = (visitId: string): WebSocketPayload => {
    return JSON.stringify({
      action: 'visitendextend',
      data: { visitId },
    });
  };

  pingPayload = (): WebSocketPayload => {
    return JSON.stringify({
      action: 'ping',
    });
  };

  healthcheckPayload = (visitId: string): WebSocketPayload => {
    return JSON.stringify({
      action: 'healthcheck',
      data: { visitId },
    });
  };

  handlePayload = (payload: WebSocketPayload): void => {
    try {
      const parsed = JSON.parse(payload.toString());
      const type = Object.prototype.hasOwnProperty.call(parsed, 'action')
        ? parsed.action
        : 'unknown';

      if (type === 'checkconnection') {
        this.handleCheckConnectionPaylod();
        return;
      }
      if (!this.responseIsValidFormat(parsed)) {
        console.log('visit message response is not in expected format');
        return;
      }

      if (!this.isKnownMessageType(type)) {
        console.log('unknown message action type');
        return;
      }

      if (type === 'visitend') {
        this.handleEndVisitPayload(payload);
      } else if (type === 'devicehandovercontrol') {
        this.handleDeviceHandoverControlResponse(payload);
      } else if (type === 'deviceconnect') {
        this.handleDeviceConnect(payload);
      } else if (type === 'updatetimers') {
        this.handleVisitTimersPayload(payload);
      } else if (type === 'healthcheck') {
        this.handleHealthcheckResponse(payload);
      } else if (type === 'visitactionmessage') {
        this.handleActionMessage(payload);
      } else if (type === 'visitactionmessageserverresponse') {
        noop;
      } else {
        type === 'visitstart'
          ? this.handleStartVisitPayload(payload)
          : this.handleJoinVisitPayload(payload);
      }
    } catch (e) {
      throw new Error(e + '. WebSocket payload : ' + payload?.toString());
    }
  };

  private isKnownMessageType = (value: string): boolean => {
    return [
      'visitstart',
      'visitjoin',
      'visitend',
      'deviceconnect',
      'visitactionmessage',
      'devicehandovercontrol',
      'updatetimers',
      'checkconnection',
      'updatetimers',
      'healthcheck',
      'visitactionmessageserverresponse',
    ].includes(value);
  };

  private handleCheckConnectionPaylod = (): void => {
    this.store.dispatch(VisitActions.visitSocketPing());
  };

  private handleVisitTimersPayload = (payload: WebSocketPayload): void => {
    const parsedPayload = JSON.parse(payload.toString());
    const parsedMessage = JSON.parse(parsedPayload.data.message);
    this.store.dispatch(
      VisitActions.setVisitSessionTimers({
        visitSessionTimer: parsedMessage,
      })
    );
  };

  private handleHealthcheckResponse = (payload: WebSocketPayload): void => {
    const parsedPayload = JSON.parse(payload.toString());

    if (!parsedPayload.data.success) {
      this.store.dispatch(VisitActions.emulationServiceLost());
    }
  };

  private handleDeviceHandoverControlResponse = (
    payload: WebSocketPayload
  ): void => {
    const parsed = JSON.parse(payload.toString());
    if (parsed.data.success) {
      this.store.dispatch(VisitActions.emulationTransferControlSuccess());
    } else {
      this.store.dispatch(
        VisitActions.emulationTransferControlFailure({
          message: parsed.data.message,
        })
      );
    }
  };

  private handleActionMessage = (payload: WebSocketPayload): void => {
    const parsed = JSON.parse(payload.toString());
    if (parsed.data.success) {
      if (parsed.data.message === 'requesthandover') {
        console.log(
          'open a dialog on participant to accept or decline request'
        );
        this.store.dispatch(VisitActions.emulationRequestControlConfirmation());
      } else if (parsed.data.message === 'declinehandover') {
        console.log('user has a declined the request');
        this.store.dispatch(
          VisitActions.emulationNotifyTransferRequestDeclined()
        );
      }
    } else {
      console.log('data failed' + parsed.data.message);
    }
  };

  private handleDeviceConnect = (payload: WebSocketPayload): void => {
    const parsed = JSON.parse(payload.toString());

    this.store.dispatch(
      VisitActions.emulationDeviceConnect({
        deviceConnection: {
          id: parsed.data.session_id,
          stunServers: parsed.data.stun_servers,
          websocket: parsed.data.url,
        },
      })
    );
  };

  private handleEndVisitPayload = (payload: WebSocketPayload): void => {
    const parsed = JSON.parse(payload.toString());

    if (!this.endResponseIsValid(parsed.data)) {
      console.log('visitend response is not in expected format');
      return;
    }

    this.store.dispatch(
      VisitActions.endVisitResponse({
        message: parsed.data.message,
        success: parsed.data.success,
      })
    );
  };

  public handleSocketConnectionFailed = (): void => {
    this.store.dispatch(VisitActions.endVisitClientSideOnError());
  };

  private endResponseIsValid = (data: any): boolean => {
    return (
      Object.prototype.hasOwnProperty.call(data, 'message') &&
      Object.prototype.hasOwnProperty.call(data, 'success')
    );
  };

  private handleStartVisitPayload = (payload: WebSocketPayload): void => {
    const parsed = JSON.parse(payload.toString());

    if (!this.startResponseIsValid(parsed.data)) {
      console.log('visitstart response is not in expected format');
      return;
    }

    const startVisitServiceModule = { visitService: parsed.data.module };
    this.store.dispatch(
      parsed.data.success
        ? VisitActions.startVisitServiceSuccess(startVisitServiceModule)
        : VisitActions.startVisitServiceFail(startVisitServiceModule)
    );
  };

  private handleJoinVisitPayload = (payload: WebSocketPayload): void => {
    const parsed = JSON.parse(payload.toString());

    if (!this.joinResponseIsValid(parsed.data)) {
      console.log('visitjoin response is not in expected format');

      const serviceModule = {
        visitService: parsed.data.modules[0].type,
      };

      if (serviceModule) {
        this.store.dispatch(
          VisitActions.joinVisitResponseFailure(serviceModule)
        );
      }

      return;
    }

    this.store.dispatch(
      VisitActions.setVisitSessionTimers({
        visitSessionTimer: parsed.data.timers,
      })
    );

    this.store.dispatch(VisitActions.initHealthCheck());

    this.store.dispatch(VisitActions.startVisitEndTimer());

    parsed.data.modules.forEach((module) => this.handleJoinService(module));
  };

  private handleJoinService = ({ info, type }): void => {
    type === 'Call' ? this.handleCall(info) : this.handleDevice(info);
  };

  private handleDevice = (info: any): void => {
    this.store.dispatch(
      VisitActions.initEmulationService({
        deviceConnection: {
          id: info.id,
          stunServers: info.stun_servers,
          websocket: info.url,
        },
      })
    );
  };

  private handleCall = (info: any): void => {
    this.store.dispatch(
      VisitActions.initVideoService({
        token: info.roomPin,
        roomKey: info.roomKey,
      })
    );
  };

  private joinResponseIsValid = (data: any): boolean => {
    const hasModules = Object.prototype.hasOwnProperty.call(data, 'modules');
    const knownType = data.modules
      .map((m) => m.type)
      .some((t) => t === 'Call' || t === 'Emulation');
    const hasInfo = data.modules.every(
      (m) => Object.prototype.hasOwnProperty.call(m, 'info') && !!m['info']
    );

    return hasModules && knownType && hasInfo;
  };

  private startResponseIsValid = (data: any): boolean => {
    return (
      Object.prototype.hasOwnProperty.call(data, 'module') &&
      Object.prototype.hasOwnProperty.call(data, 'success')
    );
  };

  private responseIsValidFormat = (payload: any): boolean => {
    return (
      Object.prototype.hasOwnProperty.call(payload, 'action') &&
      Object.prototype.hasOwnProperty.call(payload, 'data')
    );
  };
}
