<mat-list-item
  id="back-menu-item-id"
  data-testid="back-menu-item-id"
  (click)="handleClick()"
>
  <div class="back-menu-item-container" *transloco='let transl'>
    <div class="back-menu-item-icon">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="back-menu-item-text">
      {{ transl('visit.menu.go_back_text')}}
    </div>
  </div>
</mat-list-item>
