import { Injectable } from '@angular/core';
import { EmulationNotification } from '@virtual-trials-workspace/features/visit';
import { TransferControlResult } from '@virtual-trials-workspace/store';

@Injectable()
export class VisitSnackbarNotificationService implements EmulationNotification {
  showControlRecieved: () => { noop };
  showControlTransferred: () => { noop };
  showDeviceErrorNotification: () => { noop };
  showAttemptTransferDeviceControlError: (
    transferControlResult: TransferControlResult
  ) => { noop };
}
