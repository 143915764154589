import {
  AfterViewInit,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SessionActions, VisitActions } from '@virtual-trials-workspace/store';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { VisitFacadeService } from './facade/visit-facade.service';
import { Animations } from '../animations';
import { Router } from '@angular/router';
import { ParticipantType } from '@virtual-trials-workspace/models';

@Component({
  selector: 'vt-standalone-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss'],
  animations: [Animations.fadeIn(700)],
  providers: [VisitFacadeService],
  encapsulation: ViewEncapsulation.None,
})
export class VisitComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class')
  private class = 'standalone-visit-container';
  private done$ = new Subject<void>();

  get deviceId(): string {
    return this.visitFacadeService.deviceElementId;
  }

  get connectedToDeviceSession$(): any {
    return this.visitFacadeService.connectedToDeviceSession$;
  }

  get hasVisitEnded$(): Observable<boolean> {
    return this.visitFacadeService.hasVisitEnded$.pipe(
      filter((visitEnded) => !!visitEnded)
    );
  }

  get hasVisitEndedOnError$(): Observable<boolean> {
    return this.visitFacadeService.hasVisitEndedOnError$;
  }

  get emulationServiceLost$(): Observable<boolean> {
    return this.visitFacadeService.emulationServiceLost$;
  }

  get emulationConnectionLost$(): Observable<boolean> {
    return this.visitFacadeService.emulationConnectionLost$;
  }

  get hasDeviceControl$(): Observable<boolean> {
    return this.visitFacadeService.hasDeviceControl$;
  }

  get participantType$(): Observable<ParticipantType> {
    return this.visitFacadeService.participantType$;
  }

  constructor(
    private visitFacadeService: VisitFacadeService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeToVisitEnd();
    this.subscribeToDeviceError();
  }

  ngAfterViewInit(): void {
    this.visitFacadeService.subscribeToDeviceConnection();
  }

  onTransferControlClick = (): void => {
    this.visitFacadeService.disconnectDeviceSession(true);
  };

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
    this.store.dispatch(VisitActions.disconnectVisitWebSocket());
  }

  private subscribeToVisitEnd = (): void => {
    this.hasVisitEnded$
      .pipe(
        switchMap(() => this.hasVisitEndedOnError$),
        takeUntil(this.done$)
      )
      .subscribe((visitEndedOnError) => {
        this.visitFacadeService.disconnectDeviceSession(false);
        visitEndedOnError
          ? this.store.dispatch(SessionActions.endStandaloneVisitOnError())
          : this.store.dispatch(SessionActions.endStandaloneVisit());
      });
  };

  private subscribeToDeviceError = (): void => {
    this.emulationServiceLost$
      .pipe(
        takeUntil(this.done$),
        filter((errored) => !!errored),
        tap(() =>
          this.router.navigate(['.', 'standalone', 'visit', 'error'], {
            skipLocationChange: true,
          })
        )
      )
      .subscribe();
  };
}
