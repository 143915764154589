import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationItem } from '@virtual-trials-workspace/models';

@Component({
  selector: 'vt-ui-notification-display-dialog',
  templateUrl: './notification-display-dialog.component.html',
  styleUrls: ['./notification-display-dialog.component.scss'],
})
export class NotificationDisplayDialogComponent {
  @Input()
  notification: NotificationItem;

  constructor(
    private dialogRef: MatDialogRef<NotificationDisplayDialogComponent>
  ) {}

  handleOkClicked = (): void => {
    this.dialogRef.close();
  };
}
