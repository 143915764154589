import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vt-site-journey-remove-subject',
  templateUrl: './remove-subject.component.html',
  styleUrls: ['./remove-subject.component.scss'],
})
export class RemoveSubjectComponent {
  @Output()
  confirmRemoveSubject = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<RemoveSubjectComponent>) { }

  showFinalWarning: boolean;

  closeDialog = (): void => {
    this.showFinalWarning = false;
    this.dialogRef.close();
  };

  showFinalWarningTemplate = (): void => {
    this.showFinalWarning = true;
  };

  removeSubjectFromService = (): void => {
    this.confirmRemoveSubject.emit();
    this.dialogRef.close();
  };
}
