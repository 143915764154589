import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UiModule } from '@virtual-trials-workspace/shared-ui';

import { AccountRoutingModule } from './account-routing.module';
import { ConfirmPasswordComponent } from './confirm-password/confirm-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordMaxAttemptsComponent } from './reset-password-max-attempts/reset-password-max-attempts.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecurityQuestionAnswerComponent } from './security-question-answer/security-question-answer.component';
import { ACCOUNT_STATE_KEY, Effects, Reducers } from './store';

@NgModule({
  imports: [
    AccountRoutingModule,
    CommonModule,
    EffectsModule.forFeature([Effects.AccountEffects]),
    StoreModule.forFeature(ACCOUNT_STATE_KEY, Reducers.accountReducer),
    UiModule,
  ],
  declarations: [
    ConfirmPasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetPasswordMaxAttemptsComponent,
    SecurityQuestionAnswerComponent,
  ],
})
export class AccountModule {}
