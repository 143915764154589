import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type PaneType = 'left' | 'right';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-ui-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300)),
    ]),
  ],
})
export class SlidePanelComponent {
  @Input()
  activePane: PaneType = 'left';
}
