import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Router } from '@angular/router';
import {
  LicenseType,
  ParticipantType,
  StudyModel,
  VisitResponse,
} from '@virtual-trials-workspace/models';
import { RestApiService } from '@virtual-trials-workspace/shared-core-services';
import { catchError, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VisitHttpService {
  private visitResource = '/visit';
  private keepAliveResource = '/account/keepalive';

  testCount = 0;

  constructor(private restApiService: RestApiService, private router: Router) {}

  checkForActiveVisit$ = (): Observable<{
    isActive: boolean;
    modules: Array<LicenseType>;
    visitId: string;
    visitCode: string;
    siteId: string;
    isEcoaLiveStandalone: boolean;
  }> => {
    return this.restApiService.get(`${this.visitResource}/check`);
  };

  createVisit$ = (
    siteId: string,
    subjectParticipantId: string,
    modules: Array<LicenseType>
  ): Observable<{
    visitId: string;
  }> => {
    return this.restApiService.post(this.visitResource, {
      siteId,
      subjectParticipantId,
      modules,
    });
  };

  getVisitCode$ = (visitId: string): Observable<{ visitCode: string }> => {
    const url = `${this.visitResource}/${visitId}/visitcode`;
    return this.restApiService.post(url, {});
  };

  hasDeviceControlCheck$ = (
    visitId: string
  ): Observable<{ hasControl: boolean }> => {
    return this.restApiService.get(
      `${this.visitResource}/${visitId}/devicecontrol`
    );
  };

  sendInvitation$ = (visitId: string): Observable<boolean> => {
    const url = `${this.visitResource}/${visitId}/invite`;
    return this.restApiService.post(url, {});
  };

  getStudies$ = (): Observable<Array<StudyModel>> => {
    const resource = `/account/studies`;
    return this.restApiService.get<Array<StudyModel>>(resource);
  };

  setProvisionedState$ = (
    siteId: string,
    provisionedState: boolean
  ): Observable<boolean> => {
    const resource = `/site/provision`;
    return this.restApiService.post(resource, {
      provisionedState: provisionedState,
      siteId,
    });
  };

  keepAuthTokenAlive = (participantType: ParticipantType): void => {
    this.restApiService
      .get(this.keepAliveResource)
      .pipe(
        retry(2),
        catchError(() => {
          participantType === 'subject' || participantType === 'none'
            ? this.router.navigateByUrl('/login')
            : this.router.navigateByUrl('/gssologin');
          return of(true);
        })
      )
      .subscribe();
  };
}
