<div>
  <vt-visit-content-sheet
    [headerText]="endVisitHeaderText"
    [primaryActionText]="retryMessage ? 'OK' : provisionedYesText"
    (primaryAction)="yesButtonClick()"
    [secondaryActionText]="retryMessage ? '' : provisionedNoText"
    (secondaryAction)="noButtonClick()"
  >
  <div cardBody>
    <header class="es-card-header">
      <h2>{{endVisitHeaderText}}</h2>
    </header>
    <div class="es-card-body">
      <div class="es-content-box">
        <p *ngIf="!retryMessage">{{ endVisitDesc }}</p>
        <p>{{ retryMessage }}</p>
      </div>
    </div>
  </div>
    
  </vt-visit-content-sheet>
</div>
