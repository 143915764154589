import { DeviceStatus, StudyModel } from '@virtual-trials-workspace/models';
import { RootState } from '@virtual-trials-workspace/store';
import { SiteListItem } from '../models/site-list-item';

export interface ProvisionerState extends RootState {
  sites: Array<SiteListItem>;
  selectedSite: SiteListItem;
  sitesLoaded: boolean;
  selectedSiteId: string;
  isSelectedDeviceProvisioned: boolean;
  selectedStudyId: string;
  deviceStatus: DeviceStatus;
}

export const initialProvisionerState: ProvisionerState = {
  isLoading: false,
  error: undefined,
  sites: undefined,
  selectedSite: undefined,
  sitesLoaded: false,
  selectedSiteId: undefined,
  selectedStudyId: undefined,
  deviceStatus: DeviceStatus.Unknown,
  isSelectedDeviceProvisioned: undefined,
};

export const PROVISIONER_STATE_KEY = 'PROVISIONER_STATE';
