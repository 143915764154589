import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';

import {
  RootSelectors,
  VisitActions,
  VisitState,
} from '@virtual-trials-workspace/store';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SiteComponent } from './site';
import { SubjectComponent } from './subject';

@Component({
  selector: 'vt-visit-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss'],
})
export class VisitComponent implements OnDestroy {
  @ViewChild(SubjectComponent)
  subjectComponent: SubjectComponent;

  @ViewChild(SiteComponent)
  siteComponent: SiteComponent;

  private done$ = new Subject<void>();

  participantType$ = this.store.pipe(select(RootSelectors.getParticipantType));

  constructor(private store: Store<VisitState>) {}

  disconnectVisit(): Observable<boolean | UrlTree> {
    this.store.dispatch(VisitActions.disconnectVisitWebSocket());

    return this.participantType$.pipe(
      takeUntil(this.done$),
      switchMap((participantType) =>
        participantType === 'user' || participantType === 'provisioner'
          ? this.siteComponent?.disconnectVisitServices()
          : this.subjectComponent?.disconnectVisitServices()
      )
    );
  }

  ngOnDestroy() {
    this.done$.next();
    this.done$.complete();
  }
}
