<div *transloco="let transl">
  <form
      *ngIf="inviteCodeForm"
      [formGroup]="inviteCodeForm"
  >
    <div class="es-form-field es-invite-code-field">
      <div class="es-radio">
        <input 
          name="emailPhone" 
          type="radio" 
          id="email_radio"  
          (change)="handleCheckbox($event)">
        <label for="email_radio"></label>
      </div>
      <div class="field__container">
        <label class="es-label -es-required" for="email_radio">
          {{
            transl(
              'site_journey.subject.invite_code.invite_code_form.subject_email'
            )
          }}
        </label>
        <input
          for="email_radio"
          type="text" 
          id="emailId"
          class="es-input" 
          formControlName="email"
          (click)="handleEmailClick()"
          [ngClass]="{'-es-invalid': ( (showEmailRequiredControlError ) || 
            (showEmailAddressInvalidControlError &&
            !showEmailRequiredControlError )) &&
            inviteCodeForm.controls.email.touched
          }"
          [placeholder]="transl(
            'site_journey.subject.invite_code.invite_code_form.subject_email'
          )"
        />
        <div class="error-container">
          <div
            *ngIf="(
              showEmailAddressInvalidControlError &&
              !showEmailRequiredControlError ) &&
              inviteCodeForm.controls.email.touched
            "
            id="emailError"
            class="es-form-message  -es-invalid"
          >
            <div class="error-icon"></div>
            <p>{{ transl('error.email_invalid') }}</p>
          </div>
          <div *ngIf="showEmailRequiredControlError  &&
                inviteCodeForm.controls.email.touched" 
              id="emailError"
              class="es-form-message  -es-invalid">
            <div class="error-icon"></div>
            <p>{{ transl('error.email_required') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="es-form-field es-invite-code-field">
      <div class="es-radio">
        <input 
          name="emailPhone" 
          type="radio" 
          id="phone_radio"
          (change)="handleCheckbox($event)">
        <label for="phone_radio"></label>
      </div>
      <div class="field__container">
        <label class="es-label -es-required" for="phone_radio">
          {{
            transl(
              'site_journey.subject.invite_code.invite_code_form.subject_phone'
            )
          }}
        </label>
        <div class="input-icon">
          <input
            for="phone_radio"
            id="phoneId"
            formControlName="phone"
            (click)="handlePhoneClick()"
            type="text" 
            class="es-input" 
            [ngClass]="{'-es-invalid': ((showPhoneRequiredControlError) || (
              showMobilePhoneInvalidControlError &&
              !showPhoneRequiredControlError ) ) &&
              inviteCodeForm.controls.phone.touched
            }"
            [placeholder]="transl(
              'site_journey.subject.invite_code.invite_code_form.subject_phone'
            )"
          />
          <i>+</i>
        </div>
        <div class="hintLabel">{{transl('site_user.subject.invite.send.country_code')}}</div>
        <div class="error-container">
          <div
            *ngIf="(
              showMobilePhoneInvalidControlError &&
              !showPhoneRequiredControlError ) &&
              inviteCodeForm.controls.phone.touched
            "
            id="phoneError"
            class="es-form-message  -es-invalid"
          >
            <div class="error-icon"></div>
            <p>{{ transl('error.phone_invalid_error_message') }}</p>
          </div>
          <div *ngIf="showPhoneRequiredControlError  &&
                inviteCodeForm.controls.phone.touched" 
              id="phoneError"
              class="es-form-message  -es-invalid">
            <div class="error-icon"></div>
            <p>{{ transl('error.phone_required_error_message') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="es-form-field">
      <label for="select" class="es-label">
        {{
          transl('site_user.subject.invite.send.select_language')
        }}
      </label>
      <div class="es-select" 
        >
      
        <select 
        [ngClass]="{
          '-es-invalid': showLanguageRequiredControlError && 
              inviteCodeForm.controls.languageCode.touched
        }"
        formControlName="languageCode">
            <option selected value=""></option>
            <option *ngFor="let language of languages"
              [value]="language.code"
              [id]="language.code"
            >
            {{ language.displayName }}
            </option>
        </select>
        <div class="hintLabel">{{transl('site_user.subject.invite.send.language_hint')}}</div>
      </div>
      <div class="error-container">
        <div  
          id="languageError"
          class="es-form-message  -es-invalid"
          *ngIf="showLanguageRequiredControlError && 
          inviteCodeForm.controls.languageCode.touched">
          
          <div class="error-icon"></div>
          <p>{{ transl('error.language_not_selected') }} </p>
        </div>
      </div>
    </div>

  </form>  
</div>
