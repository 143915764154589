import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'vt-visit-device-permissions-sheet',
  templateUrl: './device-permissions-sheet.component.html',
  styleUrls: ['./device-permissions-sheet.component.scss'],
})
export class DevicePermissionsSheetComponent {
  @Input()
  declinesBrowserPermission: boolean;

  @Output()
  acceptAccessDevices = new EventEmitter();

  @Output()
  declinesAccessDevices = new EventEmitter();

  @Output()
  leaveVisit = new EventEmitter();

  @Output()
  startAgain = new EventEmitter();

  // readonly askHeaderText = 'We need to set up';
  // readonly denyHeaderText = `We can't start your visit`;

  acceptPermissionsClick = (): void => {
    this.acceptAccessDevices.emit();
  };

  declinePermissionsClick = (): void => {
    this.declinesAccessDevices.emit();
  };

  leaveVisitClick = (): void => {
    this.leaveVisit.emit();
  };

  startAgainClick = (): void => {
    this.startAgain.emit();
  };
}
