import { createAction, props, union } from '@ngrx/store';

import {
  ParticipantType,
  SecurityQuestion,
} from '@virtual-trials-workspace/models';
import { RootUtils as StoreUtils } from '@virtual-trials-workspace/store';

import * as FromModels from '../../models';

export const activateParticipant = createAction(
  '[Activate] Activate Participant',
  props<{ shortcode: string }>()
);

export const activateParticipantSuccess = createAction(
  '[Activate] Activate Participant Success',
  props<{
    codeStatus: FromModels.ShortCodeStatus;
    participantId: string;
    participantType: ParticipantType;
  }>()
);

export const activateParticipantFail = StoreUtils.createHttpErrorAction(
  '[Activate] Activate Participant Fail'
);

export const activateParticipantComplete = createAction(
  '[Activate] Activate Participant Complete',
  props<{ acceptedShortcode: string }>()
);

export const acceptTermsAndConditions = createAction(
  '[Activate] Accept Terms and Conditions'
);

export const clearState = createAction('[Activate] Clear State');

export const declineTermsAndConditions = createAction(
  '[Activate] Decline Terms and Conditions'
);

export const createSiteUserAccount = createAction(
  '[Activate] Create Site User Account',
  props<{ username: string; password: string }>()
);

export const createSiteUserAccountSuccess = createAction(
  '[Activate] Create Site User Account Success'
);

export const createSiteUserAccountFail = StoreUtils.createHttpErrorAction(
  '[Activate] Create Site User Account Fail'
);

export const createSubjectUserAccount = createAction(
  '[Activate] Create Subject User Account',
  props<{ email: string; password: string }>()
);

export const createSubjectUserAccountSuccess = createAction(
  '[Activate] Create Subject User Account Success'
);

export const createSubjectUserAccountFail = StoreUtils.createHttpErrorAction(
  '[Activate] Create Subject User Account Fail'
);

export const createAccountComplete = createAction(
  '[Activate] Account Creation Complete'
);

export const getSecurityQuestions = createAction(
  '[Activate] Get Security Questions'
);

export const getSecurityQuestionsSuccess = createAction(
  '[Activate] Get Security Questions Success',
  props<{ payload: Array<SecurityQuestion> }>()
);

export const getSecurityQuestionsFail = StoreUtils.createHttpErrorAction(
  '[Activate] Get Security Questions Fail'
);

export const setSecurityQuestionsAnswers = createAction(
  '[Activate] Set Security Questions Answers',
  props<{ payload: Array<FromModels.SecurityQuestionAnswer> }>()
);

const activateActions = union({
  activateParticipant,
  activateParticipantSuccess,
  activateParticipantFail,
  activateParticipantComplete,
  acceptTermsAndConditions,
  declineTermsAndConditions,
  createSiteUserAccount,
  createSiteUserAccountSuccess,
  createSiteUserAccountFail,
  createSubjectUserAccount,
  createSubjectUserAccountSuccess,
  createSubjectUserAccountFail,
  createAccountComplete,
  getSecurityQuestions,
  getSecurityQuestionsSuccess,
  getSecurityQuestionsFail,
  setSecurityQuestionsAnswers,
  clearState,
});

export type ActivateActionsUnion = typeof activateActions;
