import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocalStorageService } from '@virtual-trials-workspace/shared-core-services';
import { UiModule } from '@virtual-trials-workspace/shared-ui';
import {
  CodeSentFailureComponent,
  CodeSentSuccessComponent,
  ReInviteSubjectComponent,
  RemoveSubjectComponent,
  RemoveSubjectFailureComponent,
  ReselectStudySiteComponent,
  SubjectNotActivateComponent,
  VisitOptionsComponent,
} from './dialog';
import { StatusDisplayNamePipe } from './pipes';
import { SelectStudySiteComponent } from './select-study-site/select-study-site.component';
import { SiteJourneyRoutingModule } from './site-journey-routing.module';
import { Config, Effects, Reducers, SITE_STATE_KEY } from './store';
import {
  InviteCodeComponent,
  InviteCodeFormComponent,
  InviteSubjectComponent,
  SubjectListActionComponent,
  SubjectListComponent,
  SubjectListStatusComponent,
  SubjectListToolbarComponent,
} from './subject';

const components = [
  SubjectListComponent,
  SubjectListActionComponent,
  SubjectListStatusComponent,
  SubjectListToolbarComponent,
  SubjectNotActivateComponent,
  CodeSentSuccessComponent,
  CodeSentFailureComponent,
  RemoveSubjectFailureComponent,
  ReInviteSubjectComponent,
  InviteSubjectComponent,
  RemoveSubjectComponent,
  InviteCodeComponent,
  InviteCodeFormComponent,
  SelectStudySiteComponent,
  ReselectStudySiteComponent,
];

@NgModule({
  imports: [
    SiteJourneyRoutingModule,
    CommonModule,
    StoreModule.forFeature(
      SITE_STATE_KEY,
      Reducers.siteReducer,
      Config.SITE_STATE_CONFIG_TOKEN
    ),
    EffectsModule.forFeature([Effects.SiteEffects]),
    UiModule,
    TranslocoModule,
  ],
  declarations: [...components, StatusDisplayNamePipe, VisitOptionsComponent],
  providers: [
    {
      provide: Config.SITE_STATE_LOCAL_STORAGE_KEY,
      useValue: SITE_STATE_KEY,
    },
    {
      provide: Config.SITE_STATE_STORAGE_KEYS,
      useValue: [
                 'selectedStudyId', 
                 'selectedSiteId', 
                 'studies', 
                 'prevSelectedStudyId', 
                 'prevSelectedSiteId',
                ],
    },
    {
      provide: Config.SITE_STATE_CONFIG_TOKEN,
      useFactory: Config.getSiteStateConfig,
      deps: [
        Config.SITE_STATE_STORAGE_KEYS,
        Config.SITE_STATE_LOCAL_STORAGE_KEY,
        LocalStorageService,
      ],
    },
  ],
})
export class SiteJourneyModule {}
