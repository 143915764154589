<div *transloco="let transl">
  <vt-visit-content-sheet
    [headerText]="transl('visit.error_establish_connection')"
    [primaryActionText]="transl('user.account.logout')"
    (primaryAction)="logoutClick()"
  >
  <div cardBody>
    <header class="es-card-header">
      <h2>{{transl('visit.error_establish_connection')}}</h2>
    </header>
    <div class="es-card-body">
      <div class="es-content-box">
        <p>
          {{ failedServiceText }}
        </p>
        <p>{{ transl('visit.one_active_visit_error') }}</p>
      </div>
    </div>
  </div>
    
  </vt-visit-content-sheet>
</div>
