<div class="es-gateway participant" *transloco="let transl">
  
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading"></vt-ui-loading-overlay>

  
  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select participant siteUser">
    <div class="heading-text">
      <h2>Sites</h2>
    </div>
  </div>
  <!-- Subject list screen START-->
    <main class="es-gateway-body participant provisioner">
      <div class="es-panel"  >
        <div class="es-content-box">
          <vt-provisioner-journey-toolbar
            (toggleSideNav)="handleToggleSidenav()"
            (searchTerm)="handleSearchTerm($event)"
          >
          
          <ng-container pagination-list>
            <div class="es-toolbar">
              <ul class="es-right">
                <li class="es-toolbar-item">
                  <vt-ui-pagination
                  [current]="current"
                  [total]="total"
                  [startIndex]="startIndex"
                  [endIndex]="endIndex"
                  [totalPages]="totalPages"
                  [pageSizeOptions]="pageSizeOptions"
                  (goTo)="onGoTo($event)"
                  (next)="onNext($event)"
                  (previous)="onPrevious($event)"
                  (changeRowsPerPage)="onChangeRowsPerPage($event)"
                  ></vt-ui-pagination>
                </li>
              </ul>
            </div>
          </ng-container>
          </vt-provisioner-journey-toolbar>
          <div class="es-content-box  es-provisioner-content">
            
            <table class="es-table -es-full -es-selectable" >
              <thead class="es-provisoner-list-table-head">
                <tr>
                  <th  style="width: 5%; padding-left: 3rem;"></th>
                  <th  style="width: 35%;" (click)="sortSubjects('studyName')" [class]="sortingClassList[participantIdClassIndex]">
                    Study
                  </th>
                  <th  style="width: 35%;" (click)="sortSubjects('siteName')" [class]="sortingClassList[participantStatusClassIndex]">
                    Site
                  </th>
                  <th  style="width: 25%;">Provisioned</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let site of filteredSiteList;">
                  <td style="width: 5%;">
                    <span class="play-button es-tooltip-right" 
                      data-tooltip="Start"
                      (click)="handleStartVisit(site.siteId, site.provisioned)"
                    >
                      <img src="assets/web/images/play.svg" alt="start" />
                    </span>
                  </td>
                  <td  style="width: 35%;">
                    {{ site.studyName }}
                  </td>
                  <td  style="width: 35%;">
                    {{ site.siteName }}
                  </td>

                  <td  style="width: 25%;">
                    <ng-container *ngIf="site.provisioned">
                      <img src="/assets/web/images/green_ellipse.svg" />
                      <span style="margin-left: 10px;">Marked</span>
                      
                    </ng-container>
                    <ng-container *ngIf="!site.provisioned">
                      <button
                        id="markProvisionedBtnId"
                        class="es-button"
                        (click)="onSetProvisioned(site)"
                      >
                       <span> Mark as Done</span>
                      </button>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
</div>

<!-- <div class="page-wrapper">
  
  <mat-sidenav-container class="vv-dash__container">
    <mat-sidenav #sidenav mode="side">
      <div class="vv-dash__profile">
        <img
          src="assets/web/images/Site.svg"
          mat-card-avatar
          style="width: 60px; height: 60px; background: white"
        />
        <div style="padding-top: 10px; padding-bottom: 10px">
          <mat-label
            style="padding-left: 22px; color: white; padding-right: 22px"
            >Provisioning User
          </mat-label>
        </div>
      </div>
    </mat-sidenav>
    <vt-provisioner-journey-toolbar
      (toggleSideNav)="handleToggleSidenav()"
      (searchTerm)="handleSearchTerm($event)"
    >
    </vt-provisioner-journey-toolbar>
    <vt-ui-loading-overlay *ngIf="isLoading"></vt-ui-loading-overlay>
    <mat-card class="vv-table__card">
      <mat-card-content>
        <mat-paginator #paginator
          [pageSizeOptions]="pageSizeOptions" 
          [pageSize]="pageSize"
          showFirstLastButtons>
        </mat-paginator>
        <table class="es-table -es-full -es-selectable" mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="studyName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="vv-site-list--visible-sm"
            >
              Study
            </th>
            <td
              mat-cell
              *matCellDef="let site"
              class="vv-site-list--visible-sm"
            >
              <div class="ellipsis">
                <span>
                  {{ site.studyName }}
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="siteName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="vv-site-list--visible-sm"> Site </span>
            </th>
            <td mat-cell *matCellDef="let site">
              <span class="vv-site-list--visible-sm">
                <div class="ellipsis">
                  <span>
                    {{ site.siteName }}
                  </span>
                </div>
              </span>

              <div class="vv-site-list__value-group vv-site-list--hidden-sm">
                <div class="vv-site-list__value-pair">
                  <div class="vv-site-list__label">Study:</div>
                  <div class="ellipsis">
                    <span>{{ site.studyName }}</span>
                  </div>
                </div>
                <div class="vv-site-list__value-pair">
                  <div class="vv-site-list__label">Site:</div>
                  <div class="ellipsis">
                    <span>{{ site.siteName }}</span>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="provisioned">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Provisioned
            </th>
            <td
              mat-cell
              *matCellDef="let site"
              class="vv-site-list__action-cell"
            >
              <ng-container *ngIf="site.provisioned">
                <mat-icon>done</mat-icon>
              </ng-container>
              <ng-container *ngIf="!site.provisioned">
                <button
                  id="markProvisionedBtnId"
                  mat-flat-button
                  color="secondary"
                  (click)="onSetProvisioned(site)"
                >
                  Mark as Done
                </button>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let site"
              align="end"
              class="vv-site-list__action-cell"
            >

              <button
                mat-flat-button
                color="primary"
                title="Start"
                (click)="handleStartVisit(site.siteId, site.provisioned)"
              >
                <span class="vv-site-list--visible-md">Start</span>
                <mat-icon>play_arrow</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card-content>
    </mat-card>
    <div class="back-to-top-container" (click)="backToTop($event)">
      <button
        mat-fab
        type="button"
        matTooltip="Back to Top"
      >
        <mat-icon>arrow_upward</mat-icon>
      </button>
      
    </div>
  </mat-sidenav-container>
</div> -->
