<div class="es-gateway participant" *transloco="let transl">
  
  <!-- Loading bar overlay -->
  <vt-ui-loading-overlay *ngIf="isLoading"></vt-ui-loading-overlay>

  
  <!-- Language select drop down -->
  <div fxLayout="row" fxLayoutAlign="flex-end" class="es-lang-select participant siteUser">
    <div class="heading-text">
      <h2 *transloco="let transl">{{ transl('subject_list.subjects') }}</h2>
    </div>
    <div class="btn-container">
      <button id="" class="es-button reselect-study-site-button" (click)="handleReselectStudySite()">
        <span class="es-icon es-icon-function-edit"></span
        ><span>{{
           transl('subject_list.change_study_site')
        }}</span>
      </button>
      <vt-ui-language-select class="top-right language-select-dropdown"></vt-ui-language-select>
    </div>
  </div>
  <!-- Subject list screen START-->
    <main class="es-gateway-body participant">
      <div *transloco="let transl">
        <div class="es-panel" *ngIf="!isLoading && showEmptyList()" >
          <div class="es-content-box es-no-participant">
            <div class="es-content__btn-container">
              <button id="" class="es-button" (click)="handleAddSubject()">
                <mat-icon class="add">add</mat-icon>
                <span>{{
                  transl('site_user.subject.invite.invite_subject')
                }}</span>
              </button>
            </div>
            <div class="es-icon-text-container">
              <div class="es-participant-icon"></div>
              <div class="es-no-participant-text">{{ transl('subject_list.empty.header') }}</div>
              <div class="es-no-participant-sub-text">
                {{ transl('subject_list.empty.description_initial') }}
                <mat-icon class="add">add</mat-icon>
                {{ transl('subject_list.empty.description_end') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      <div class="es-panel" *ngIf="!showEmptyList()" >
        <div class="es-content-box">
          <div class="es-toolbar">
            <ul>
              <li class="es-toolbar-item">
                <div class="es-dropdown" data-es-dropdown="">
                  <button id="" class="es-button" (click)="handleAddSubject()">
                    <span class="es-icon es-icon-user-plus"></span
                    ><span>{{
                      transl('site_user.subject.invite.invite_subject')
                    }}</span>
                  </button>
                </div>
              </li>
              <li class="es-toolbar-item">
                <input
                  type=""
                  [(ngModel)]="searchQuery"
                  class="es-input"
                  placeholder="{{
                    transl(
                      'site_journey.subject.subject_list.subject_list_toolbar.search_subjects'
                    )
                  }}"
                />
              </li>
              <li class="es-toolbar-item">
                <button id="" class="es-button" (click)="onApplyClick()"><span class="es-icon es-icon-search"></span></button>
              </li>
            </ul>
          </div>
          <div class="es-content-box">
            <table class="es-table -es-full" *transloco="let transl">
              <thead>
                <tr>
                  <th>{{ transl('subject_list.actions') }}</th>
                  <th (click)="sortSubjects('subjectName')" [class]="sortingClassList[participantIdClassIndex]">
                    {{ transl('subject_list.subject_id') }}
                  </th>
                  <th (click)="sortSubjects('subjectStatus')" [class]="sortingClassList[participantStatusClassIndex]">
                    {{ transl('general.status') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                
                <ng-container *ngIf="showNoFilterResults()">
                  <tr >
                    <td colspan="3">
                    <div class="es-panel"  >
                      <div class="es-content-box es-no-participant">
                        <div class="es-icon-text-container">
                          <div class="es-participant-icon"></div>
                          <div class="es-no-participant-text">{{ transl('subject_list.empty.search.header') }}</div>
                          <div class="es-no-participant-sub-text">
                            {{ transl('subject_list.empty.search.description') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  </tr>
                </ng-container>

                <tr *ngFor="let subject of filteredSubjectList">
                  <td>
                    <vt-site-journey-action
                      [isOpenClass]="getIsOpenClass(subject.subjectId)"
                      [subjectName]="subject.subjectName"
                      [isRtl]="isRtl"
                      (callSubject)="handleStartVisit(subject)"
                      (inviteSubject)="handleReInviteSubject(subject)"
                      (removeSubject)="handleRemoveSubjectActionClick(subject)"
                      (mobileChevronLeftClick)="unSelectSubject()"
                      (mobileChevronRightClick)="selectSubject(subject)"
                    >
                    </vt-site-journey-action>
                  </td>
                  <td>
                    {{ subject.subjectName }}
                  </td>
                  <td>
                    <vt-site-journey-status
                      [status]="mapActivationStatus(subject.subjectStatus)"
                    ></vt-site-journey-status>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <mat-card class="vv-table__card" *ngIf="false">
        <div class="es-content-box" *ngIf="showTable()">
          <table class="es-table -es-full" *transloco="let transl">
            <thead>
              <tr>
                <th>{{ transl('subject_list.actions') }}</th>
                <th class="-es-sort" (click)="sortSubjectList('subjectName')">{{ transl('subject_list.subject_id') }}</th>
                <th class="-es-sort">
                  {{ transl('general.status') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let subject of subjectList">
                <td></td>
                <td>
                  {{ subject.subjectName }}
                </td>
                <td>
                  <vt-site-journey-status
                    [status]="mapActivationStatus(subject.subjectStatus)"
                  ></vt-site-journey-status>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <mat-card-content *transloco="let transl">
          <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="vv-table"
            *ngIf="false"
          >
            <ng-container matColumnDef="subjectName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ transl('subject_list.subject_id') }}
              </th>
              <td mat-cell *matCellDef="let subject">
                {{ subject.subjectName }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="subjectStatus" class="vv-status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ transl('general.status') }}
              </th>
              <td mat-cell *matCellDef="let subject">
                <vt-site-journey-status
                  [status]="mapActivationStatus(subject.subjectStatus)"
                ></vt-site-journey-status>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef align="end">
                <span>
                  {{ transl('subject_list.actions') }}
                </span>
              </th>
  
              <td mat-cell *matCellDef="let subject" align="end">
                <vt-site-journey-action
                  [isOpenClass]="getIsOpenClass(subject.subjectId)"
                  [subjectName]="subject.subjectName"
                  (callSubject)="handleStartVisit(subject)"
                  (inviteSubject)="handleReInviteSubject(subject)"
                  (removeSubject)="handleRemoveSubjectActionClick(subject)"
                  (mobileChevronLeftClick)="unSelectSubject()"
                  (mobileChevronRightClick)="selectSubject(subject)"
                >
                </vt-site-journey-action>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </main>
  <!-- Subject list screen END -->
</div>

