<div fxLayout="row" fxLayoutAlign="flex-end" class="pad-10">

  <div class="page-wrapper" *transloco="let transl">
    <div
      class="page-container"
      fxFlex="1 1"
      fxLayout="wrap"
      fxLayoutAlign="center center"
    >
      <div class="page-content">
        <mat-card class="vv-error">
          <img src="/assets/web/images/error-splash.png" />
          <div class="card-title">
            {{(failedErrorMsg$ | async )}}
          </div>
        </mat-card>
      </div>
    </div>
</div>
  