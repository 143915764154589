import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MediaDevicesService {
  userGivesDevicePermissions = async (): Promise<boolean> => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      return Promise.resolve(true);
    } catch (e) {
      return Promise.resolve(false);
    }
  };
}
