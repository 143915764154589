
<div class="es-popup  -es-closeable -es-modal -es-open es-custom-dialog-panel" *transloco="let transl">
	<div class="es-popup-content">
		<header class="es-popup-header">
      <!-- <div class="header-icon successIcon"></div> -->
			<h2>Provisioning</h2>
      <span class="close-icon" (click)="cancelClick.emit()"><img src="assets/web/images/close-icon.svg" /></span>
		</header>
      <div class="es-popup-body">
        <div class="es-form -es-aligned">
          <div class="es-content-box">
            <div class="es-text-group">
              <!-- <p>{{ transl('notification.subject_code_sent.description') }}</p> -->
              <div class="text-align-left" *ngIf="site">
                <label for="">Study:</label>
                <p>{{ site.studyName }}</p>
                <label for="">Site:</label>
                <p>{{ site.siteName }}</p>
                <label for="">Device Status:</label>
                <p>{{ deviceStatusMessage }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    <footer class="es-popup-footer">
      <div class="es-toolbar">
        <ul class="es-right">
          <li class="es-toolbar-item">
            <button
              id="next-btn"
              class="es-button -es-primary mark-as-done"
              data-testid="confirm-btn"
              [disabled]="
                deviceStatusMessage !== 'Available' && deviceStatusMessage !== 'In use'
              "
              (click)="markAsDoneClick.emit()"
            >
              Mark as Done
            </button>
          </li>
        </ul>
      </div>
    </footer>	  
  </div>
</div>



<!-- 
<div class="popup-content"><h3 class="provisioning-title">Provisioning</h3>
<span class="close-icon" (click)="cancelClick.emit()"><img src="assets/web/images/close-icon.svg" /></span>

<div class="dialog-content">
  <div class="text-align-left" *ngIf="site">
    <label for="">Study:</label>
    <p>{{ site.studyName }}</p>
    <label for="">Site:</label>
    <p>{{ site.siteName }}</p>
    <label for="">Device Status:</label>
    <p>{{ deviceStatusMessage }}</p>
  </div>
</div>
<div>
  <button
    id="next-btn"
    class="es-button -es-primary mark-as-done"
    color="primary"
    data-testid="confirm-btn"
    [disabled]="
      deviceStatusMessage !== 'Available' && deviceStatusMessage !== 'In use'
    "
    (click)="markAsDoneClick.emit()"
  >
    Mark as Done
  </button>
</div>
</div> -->
