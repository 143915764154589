import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'vt-activate-site-user-form',
  templateUrl: './site-user-form.component.html',
  styleUrls: ['./site-user-form.component.scss'],
})
export class SiteUserFormComponent {
  @Input()
  apiError: string;

  @Input()
  siteFormGroup: UntypedFormGroup;

  @Input()
  submitInProgress: boolean;

  @Input()
  userNameRequiredErrorMessage: string;

  @Input()
  passwordRequiredErrorMessage: string;

  @Input()
  passwordDoesntMeetCriteriaErrorMessage: string;

  @Input()
  confirmPasswordRequiredErrorMessage: string;

  @Input()
  passwordsDontMatchErrorMessage: string;

  @Output()
  createSiteAccountClick = new EventEmitter();

  get userNameControl() {
    return this.siteFormGroup.controls.userName;
  }

  getErrorForSiteFormControl = (
    controlName: string,
    error: string
  ): ValidationErrors => {
    return this.siteFormGroup.controls[controlName]?.errors?.[error];
  };

  siteApiErrorControlErrorMatcher = (): ErrorStateMatcher => ({
    isErrorState: (): boolean =>
      !!this.apiError || this.userNameControlRequiredErrorMatch(),
  });

  userNameControlRequiredErrorMatch = (): boolean => {
    return (
      this.userNameControl.touched &&
      !!this.getErrorForSiteFormControl('userName', 'required')
    );
  };
}
