import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { BaseConfig } from '@virtual-trials-workspace/models';
import {
  ANDROID_PLATFORM_CODE,
  ConfigurationService,
  DEFAULT_PLATFORM_CODE,
  IOS_PLATFORM_CODE,
  PlatformService,
} from '@virtual-trials-workspace/shared-core-services';

@Component({
  selector: 'vt-web-platform-error',
  templateUrl: './platform-error.component.html',
  styleUrls: ['./platform-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlatformErrorComponent {
  @HostBinding('class')
  private class = 'platform-error-container';
  private platformUpdateLinks: Map<string, string>;
  readonly errorKey = 'chrome_only_error_screen.update_version_google_continue';
  readonly unSupportedBrowswerErrorKey = 'unSupported_browswer_error_screen.update_version_browswer_continue'
  readonly minViewPortWidth: number;
  readonly minViewPortHeight: number;
  readonly platformUpdateLink: string;
  readonly errorMessage: string;

  constructor(
    private configurationService: ConfigurationService,
    private config: BaseConfig,
    private platformService: PlatformService
  ) {
    this.minViewPortWidth = configurationService.viewPort.minWidth;
    this.minViewPortHeight = configurationService.viewPort.minHeight;
    this.initPlatformUpdateLinks();
    this.platformUpdateLink = this.platformUpdateLinks.get(
      this.platformService.getPlatformCode()
    );
    this.errorMessage = `Please use the latest version of <a href="${this.platformUpdateLink}" target="_blank">Google Chrome</a> or Safari`;
  }

  private initPlatformUpdateLinks = (): void => {
    this.platformUpdateLinks = new Map([
      [IOS_PLATFORM_CODE, this.config.platformUpdateUrls.ios],
      [ANDROID_PLATFORM_CODE, this.config.platformUpdateUrls.android],
      [DEFAULT_PLATFORM_CODE, this.config.platformUpdateUrls.default],
    ]);
  };
}
