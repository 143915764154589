import { Action, createReducer, on } from '@ngrx/store';
import * as sessionActions from '../actions';
import { initialSessionState, SessionState } from '../sub-states';
import { ParticipantType } from '@virtual-trials-workspace/models';

const reducer = createReducer(
  initialSessionState,
  on(sessionActions.login, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
  })),
  on(
    sessionActions.loginSuccess,
    (state, { participantId, participantType, languageCode }) => ({
      ...state,
      isLoading: false,
      error: undefined,
      participantId,
      participantType,
      languageCode,
    })
  ),
  on(sessionActions.loginFailure, (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  })),
  on(sessionActions.failureErrorMessage, (state, action) => ({
    ...state,
    genericErrorMessage: action.genericErrorMessage,
  })),
  on(
    sessionActions.setParticipantId,
    (state, { participantId, participantType }) => ({
      ...state,
      participantId,
      participantType,
    })
  ),
  on(sessionActions.startVisitWithSubject, (state, action) => ({
    ...state,
    isEcoaLiveStandalone: action.isEcoaLiveStandalone,
  })),
  on(sessionActions.endActiveSessionOnInitApp, (state) => ({
    ...state,
    participantType: 'none' as ParticipantType,
  })),
  on(sessionActions.clearSessionData, (state) => ({
    ...state,
    error: undefined,
    isLoading: undefined,
    participantId: undefined,
    participantType: 'none' as ParticipantType,
    isAnonymous: undefined,
  })),
  on(sessionActions.setDefaultLanguageCode, (state, action) => ({
    ...state,
    error: undefined,
    isLoading: false,
    defaultSetLanguageCode: action.defaultSetLanguageCode,
  })),
  on(sessionActions.getLanguages, (state) => ({
    ...state,
    error: undefined,
    isLoading: true,
    languages: undefined,
  })),
  on(sessionActions.setFilterLanguagesSuccess, (state, action) => ({
    ...state,
    error: undefined,
    filterLanguages: action.languages,
    isLoading: false,
  })),
  on(sessionActions.getLanguagesSuccess, (state, action) => ({
    ...state,
    error: undefined,
    languages: action.languages,
    allLanguages: action.languages,
    isLoading: false,
  })),
  on(sessionActions.setAllLanguagesSuccess, (state, action) => ({
    ...state,
    error: undefined,
    languages: action.languages,
    isLoading: false,
  })),
  on(sessionActions.setLanguagesFallback, (state, action) => ({
    ...state,
    error: undefined,
    isLoading: false,
    isLanguagesFallback: action.isLanguagesFallback
  })),
  on(sessionActions.getTranslationFiles, (state, action) => ({
    ...state,
    error: undefined,
    isLoading: true,
    translationFiles: undefined,
    selectedLanguageCode: action.language,
    translationsAuthType: localStorage.getItem('token') ? 'auth' : 'unauth' as ('auth' | 'unauth'),
    translationsLoaded: false,
  })),
  on(sessionActions.getTranslationFilesSuccess, (state, action) => ({
    ...state,
    error: undefined,
    translationFiles: action.translationFiles,
    isLoading: false,
  })),
  on(sessionActions.getTranslationFilesFailure, (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
    translationsAuthType: undefined,
  })),
  on(sessionActions.translationsLoadDone, (state) => ({
    ...state,
    translationsLoaded: true,
  })),
  on(sessionActions.isPrefferedLanguageDialogOpen, (state, action) => ({
    ...state,
    error: undefined,
    isLoading: false,
    isPrefferedLanguageDialogOpen: action.isDialogOpen,
  })),
  on(
    sessionActions.joinStandaloneVisitSuccess,
    (state, { participantId, participantType }) => ({
      ...state,
      error: undefined,
      participantId,
      participantType,
      isAnonymous: true,
    })
  ),
  on(sessionActions.joinStandaloneVisitFailure, (state, { payload }) => ({
    ...state,
    error: payload,
  })),
  on(
    sessionActions.getSutdyLanguagesByParticipantIdSuccess,
    (state, { studyLanguages }) => ({
      ...state,
      error: undefined,
      isLoading: false,
      studyLanguages: studyLanguages,
    })
  ),
  on(
    sessionActions.getSutdyLanguagesByParticipantIdFailure,
    (state, { payload }) => ({
      ...state,
      error: payload,
      isLoading: false,
    })
  )
);

export function sessionReducer(
  state: SessionState | undefined,
  action: Action
) {
  return reducer(state, action);
}
