import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from "@ngneat/transloco";
import { CoreServicesModule } from '@virtual-trials-workspace/shared-core-services';
import { UiModule } from '@virtual-trials-workspace/shared-ui';
import { StoreModule } from '@virtual-trials-workspace/store';
import { GuardsModule } from '@virtual-trials-workspace/shared-guards';

import { ErrorComponent } from './error';
import * as FromLogin from './login';
import { NotificationDisplayService } from './notification-display/notification-display.service';
import { routes } from './routes';
import { TokenInterceptor } from './token-interceptor/token.interceptor';
import { PlatformErrorComponent } from './platform-error/platform-error.component';
import { GeneralErrorComponent } from './general-error/general-error.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    CoreServicesModule,
    StoreModule,
    UiModule,
    TranslocoModule,
    PlatformModule,
    GuardsModule
  ],
  exports: [RouterModule],
  declarations: [
    ErrorComponent,
    FromLogin.FormComponent,
    FromLogin.LoginComponent,
    PlatformErrorComponent,
    GeneralErrorComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (nds: NotificationDisplayService) => () =>
        nds.subscribeToNotifications(),
      deps: [NotificationDisplayService],
      multi: true,
    }
  ],
})
export class VirtualTrialsWebModule {}
