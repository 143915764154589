import { Pipe, PipeTransform } from '@angular/core';
import { SubjectActivationStatus } from '../models';

@Pipe({ name: 'statusDisplayName' })
export class StatusDisplayNamePipe implements PipeTransform {
  readonly statusToDisplayNameMap = new Map<SubjectActivationStatus, string>([
    [SubjectActivationStatus.PendingInvitation, 'Pending Invite'],
    [SubjectActivationStatus.InvitationSent, 'Invite Sent'],
    [SubjectActivationStatus.Activated, 'Ready'],
    [SubjectActivationStatus.ActivationResent, 'Activation Resent'],
  ]);

  transform(value: SubjectActivationStatus): string {
    return this.statusToDisplayNameMap.get(value);
  }
}
