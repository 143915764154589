import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ScriptModel, ScriptName } from '@virtual-trials-workspace/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScriptBuilderService {
  readonly scriptType = 'text/javascript';
  readonly scriptElement = 'script';

  isScriptLoaded$ = new BehaviorSubject<{
    name: ScriptName;
    isLoaded: boolean;
  }>({ name: undefined, isLoaded: false });

  constructor(@Inject(DOCUMENT) private document: Document) {}

  addScript = (scriptModel: ScriptModel, onErrorHandle: () => void): void => {
    const script = this.document.createElement('script');
    script.type = scriptModel.type;
    script.src = scriptModel.src;
    script.id = scriptModel.id;
    if (scriptModel.attributes) {
      script.setAttribute(
        scriptModel.attributes.name,
        scriptModel.attributes.value
      );
    }
    script.onerror = onErrorHandle;
    script.onload = (): void => {
      this.isScriptLoaded$.next({ isLoaded: true, name: scriptModel.name });
    };
    this.appendScriptToDom(script);
  };

  private appendScriptToDom = (script: HTMLScriptElement): void => {
    this.document.getElementsByTagName('head')[0].appendChild(script);
  };
}
