<section
  *transloco="let transl"
  class="es-custom-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
  >
  <div class="es-column">
    <div class="es-content-box">
      <div class="es-panel es-custom-panel">
        <div class="es-form">
          <div class="es-content-box">
            <div class="es-text-group">
              <h2 class="looks-good-text">{{ titleText }}</h2>
              <div class="vv-icon">
                <div class="vv-icon__success"></div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>