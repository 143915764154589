import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootSelectors, RouteState, SessionState, SessionSelectors } from '@virtual-trials-workspace/store';
import { ActivateState, ACTIVATE_STATE_KEY } from '../activate.state';

export const getActivateState = createFeatureSelector<ActivateState>(
  ACTIVATE_STATE_KEY
);

export const getParticipantType = createSelector(
  getActivateState,
  (state: ActivateState) => state.participantType
);

export const getParticipantId = createSelector(
  getActivateState,
  (state: ActivateState) => state.participantId
);

export const getShortCode = createSelector(
  getActivateState,
  (state: ActivateState) => state.shortcode
);

export const getError = createSelector(
  getActivateState,
  (state: ActivateState) => state.error
);

export const getIsLoading = createSelector(
  getActivateState,
  SessionSelectors.getIsLoading,
  (state: ActivateState, sessionIsLoading: boolean) =>
    state.isLoading || sessionIsLoading
);

export const getIsShortCodeInActive = createSelector(
  getActivateState,
  (state: ActivateState) => state.codeStatus === 'inactive'
);

export const getUserActivationStatus = createSelector(
  getActivateState,
  (state: ActivateState) => state.userActivationStatus
);

export const getShortcodeFromRouter = createSelector(
  RootSelectors.getRouteState,
  (state: RouteState) => state.queryParams.shortcode
);

export const getLanguageCodeFromRouter = createSelector(
  RootSelectors.getRouteState,
  (state: RouteState) => state.queryParams.languageCode
);

export const getSecurityQuestions = createSelector(
  getActivateState,
  (state: ActivateState) => state.securityQuestions
);

export const getCreateAccountStatus = createSelector(
  getActivateState,
  (state: ActivateState) => state.createAccountStatus
);

export const getSecurityQuestionsAnswers = createSelector(
  getActivateState,
  (state: ActivateState) => state.securityQuestionsAnswers
);
