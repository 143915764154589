import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vt-provisioner-journey-action',
  templateUrl: './site-list-action.component.html',
  styleUrls: ['./site-list-action.component.scss'],
})
export class SiteListActionComponent {
  @Input()
  isOpenClass: string;

  @Input()
  siteName: string;

  @Output()
  removeSubject = new EventEmitter();

  @Output()
  callSubject = new EventEmitter();

  @Output()
  inviteSubject = new EventEmitter();

  @Output()
  mobileChevronLeftClick = new EventEmitter();

  @Output()
  mobileChevronRightClick = new EventEmitter();
}
