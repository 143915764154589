import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { ConfigurationService } from '@virtual-trials-workspace/shared-core-services';

@Component({
  selector: 'vt-standalone-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent {
  @HostBinding('class')
  private class = 'standalone-layout-container';
  readonly minViewPortWidth: number;
  readonly minViewPortHeight: number;

  constructor(configurationService: ConfigurationService) {
    this.minViewPortWidth = configurationService.viewPort.minWidth;
    this.minViewPortHeight = configurationService.viewPort.minHeight;
  }
}
