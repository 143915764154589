import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeviceStatus } from '@virtual-trials-workspace/models';
import { Observable } from 'rxjs';
import { ProvisionerState } from '../../store';
import * as FromSelectors from '../../store/selectors/provisioner.selector';

@Component({
  selector: 'vt-provisioner-journey-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss'],
})
export class DeviceInfoComponent implements OnInit {
  @Input()
  deviceStatus$: Observable<DeviceStatus>;

  @Output()
  isDeviceAvailable = new EventEmitter<boolean>();

  deviceInfo = '';

  deviceStatusEnum = DeviceStatus;

  constructor(private store: Store<ProvisionerState>) {}

  ngOnInit(): void {
    this.subscribeToDeviceStatus();
  }

  handleOkClick = (): void => {
    this.isDeviceAvailable.emit(false);
  };

  private subscribeToDeviceStatus = (): void => {
    this.deviceStatus$.subscribe((deviceStatus) => {
      switch (deviceStatus) {
        case DeviceStatus.Checking:
          this.deviceInfo = '';
          break;
        case DeviceStatus.Available:
          this.isDeviceAvailable.emit(true);
          break;
        case DeviceStatus.InUse:
          this.deviceInfo = 'Device is already in use.';
          break;
        case DeviceStatus.Error:
          this.deviceInfo = 'Error fetching device status. Please try again.';
          break;
        case DeviceStatus.NotSetup:
          this.deviceInfo = "Emulation is enabled. But the device isn't setup.";
          break;
      }
    });
  };
}
