<div *transloco="let transl">
  <div class="es-gateway-body">
    <section
      class="es-device-restriction-popup-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
    >
      <div class="es-column es-visit-mobile-restriction-column">
        <div class="es-content-box">
          <div class="es-panel es-custom-panel">
            
            <header class="es-card-header">
              <div class="warning-icon"></div>
              <h2>{{ transl('chrome_only_error_screen.switch_device_with_bigger_screen') }}</h2>
              <h2>{{ transl('chrome_only_error_screen.minimum_screen_resolution') }}</h2>
            </header>
            
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
