import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'vt-activate-subject-user-form',
  templateUrl: './subject-user-form.component.html',
  styleUrls: ['./subject-user-form.component.scss'],
})
export class SubjectUserFormComponent {
  @Input()
  subjectFormGroup: UntypedFormGroup;

  @Input()
  submitInProgress: boolean;

  @Input()
  emailRequiredErrorMessage: string;

  @Input()
  emailPatternErrorMessage: string;

  @Input()
  passwordRequiredErrorMessage: string;

  @Input()
  passwordDoesntMeetCriteriaErrorMessage: string;

  @Input()
  confirmPasswordRequiredErrorMessage: string;

  @Input()
  passwordsDontMatchErrorMessage: string;

  @Output()
  createSubjectAccountClick = new EventEmitter();

  constructor(private location: Location) {}

  getErrorForSubjectFormControl = (
    controlName: string,
    error: string
  ): ValidationErrors => {
    return this.subjectFormGroup.controls[controlName]?.errors?.[error];
  };
  handleCancelClick() {
    this.location.back();
  }
}
