<div
  class="header"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *transloco="let transl"
>

  <!-- <mat-icon class="logo" svgIcon="ert-logo"></mat-icon> -->
  <div class="logo"></div>
</div>
<ng-container *transloco="let transl">
  <ng-container
    *ngIf="{
      connectedToDeviceSession: connectedToDeviceSession$ | async,
      hasDeviceControl: hasDeviceControl$ | async
    } as visitContext"
  >
    <!-- Waiting room -->
    <main class="es-gateway-body">
      <section
        class="es-welcome-layout es-layout-columns -es-2-column es-gateway-column -es-flex-horizontal"
      >
      
        <div class="es-column"
          [ngClass]="(esColumnClass) ? esColumnClass : 'es-visit-assessment-form'"
        >
          <div class="es-content-box" *ngIf="!visitContext.connectedToDeviceSession">
            <div class="es-panel es-custom-panel es-welcome-panel">
              <div class="es-form">
                <div class="es-content-box">
                  <div class="es-text-group">
                    <div
                      vt-standalone-visit-wait
                      *ngIf="!visitContext.connectedToDeviceSession"
                    >
                      <span
                        *ngIf="
                          visitContext.hasDeviceControl !== undefined &&
                          !visitContext.hasDeviceControl &&
                          (participantType$ | async) === 'subject'
                        "
                        class="text"
                        >{{
                          transl('subject_waiting_screen.wait_site_has_control', {
                            fallback: 'Please wait, site has control'
                          })
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- Emulation -->
    <vt-ui-emulation-device
      @fadeIn
      [deviceId]="deviceId"
      [hidden]="!visitContext.connectedToDeviceSession"
      [errored]="emulationServiceLost$ | async"
      [networkLost]="emulationConnectionLost$ | async"
    >
      <div
        vtUIBottomDevicePanel
        fxLayout="row"
        fxLayoutAlign="end center"
        class="es-custom-visit-btn"
        *ngIf="hasDeviceControl$ | async"
      >
        <button id="transfer-control-button-id" class="es-button transfer-control-button" (click)="onTransferControlClick()">
          <div class="transfer_icon"></div>
          {{
            transl('visit.transfer_control', {
              fallback: 'Transfer control'
            })
          }}
        </button>
      </div>
    </vt-ui-emulation-device>
  </ng-container>
</ng-container>
