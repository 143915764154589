import {
  ProvisioningStatus,
  VisitInviteStatus,
  VisitService,
  VisitServiceOptions,
  VisitUiState,
  VisitSessionTimer,
} from '@virtual-trials-workspace/models';
import { DeviceConnection, TransferControlResult } from '../models';
import { VisitEnd } from '../models/visit-end';
import { RootState } from '../root.state';

export interface VisitState extends RootState {
  hasActiveVisit: boolean;
  rejoinVisit: boolean;
  subjectId: string;
  siteId: string;
  services: Array<VisitService>;
  visitId: string;
  visitCode: string;
  allUserDevices: Array<MediaDeviceInfo>;
  hasMediaDevicesPermissions: boolean;
  selectedMicrophoneId: string;
  selectedCameraId: string;
  selectedSpeakerId: string;
  subjectParticipantId: string;
  inviteStatus: VisitInviteStatus;
  readyToJoinVisit: boolean;
  uiState: VisitUiState;
  selectedServiceType: VisitServiceOptions;
  roomKey: string;
  roomPin: string;
  visitEnd: VisitEnd;
  videoServiceEnded: boolean;
  emulationServiceEnded: boolean;
  emulationServiceLost: boolean;
  deviceConnection: DeviceConnection;
  hasDeviceControl: boolean;
  deviceConnect: boolean;
  transferControlResult: TransferControlResult;
  isEcoaLiveStandalone: boolean;
  ecoaLiveStandaloneVisitCode: string;
  isDeviceProvisioned: boolean;
  deviceProvisioningStatus: ProvisioningStatus;
  visitSessionTimer: VisitSessionTimer;
  emulationRequestControlConfirmationPending: boolean;
  emulationRequestControlDeclined: boolean;
}

export const initialVisitState = (): VisitState => {
  return {
    hasActiveVisit: undefined,
    rejoinVisit: false,
    error: undefined,
    isLoading: false,
    services: [],
    visitId: undefined,
    visitCode: undefined,
    allUserDevices: [],
    hasMediaDevicesPermissions: false,
    selectedMicrophoneId: undefined,
    selectedCameraId: undefined,
    selectedSpeakerId: undefined,
    subjectId: undefined,
    siteId: undefined,
    subjectParticipantId: undefined,
    inviteStatus: 'unset' as VisitInviteStatus,
    readyToJoinVisit: false,
    uiState: 'idle',
    selectedServiceType: 'unknown',
    roomKey: undefined,
    roomPin: undefined,
    deviceConnection: undefined,
    visitEnd: undefined,
    videoServiceEnded: false,
    emulationServiceEnded: false,
    emulationServiceLost: false,
    hasDeviceControl: undefined,
    deviceConnect: undefined,
    transferControlResult: undefined,
    isEcoaLiveStandalone: undefined,
    ecoaLiveStandaloneVisitCode: undefined,
    isDeviceProvisioned: undefined,
    deviceProvisioningStatus: 'unset' as ProvisioningStatus,
    visitSessionTimer: undefined,
    emulationRequestControlConfirmationPending: undefined,
    emulationRequestControlDeclined: undefined
  };
};

export const VISIT_STATE_KEY = 'VISIT_STATE';

interface SiteVisit {
  siteId: string;
  subjectParticipantId: string;
}

interface VisitBaseState extends RootState {
  emulationService: EmulationService;
  isActive: boolean;
  services: Array<VisitService>;
  site: SiteVisit;
  videoService: VideoService;
  visitId: string;
}

interface VideoService {
  hasMediaDeviceAccess: boolean;
  roomPin: string;
  roomKey: string;
  selectedCameraId: string;
  selectedMicrophoneId: string;
  selectedSpeakerId: string;
  userDevices: Array<string>;
}

interface EmulationService {
  joinable: boolean;
}
