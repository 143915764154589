import { createAction, props, union } from '@ngrx/store';
import { StudyModel } from '@virtual-trials-workspace/models';
import { RootUtils as StoreUtils } from '@virtual-trials-workspace/store';
import { Subject } from '../../models';
import {
  CreateSubjectResponseModel,
  GetActivtaionDetailsResponse,
  SendInviteToSubjectRequestModel,
} from '../models';
import { SiteActionTypes } from './site.actions.type';

export const getSubjectsAction = createAction(
  SiteActionTypes.SITE_GET_SUBJECTS
);

export const getSubjectsSuccessAction = createAction(
  SiteActionTypes.SITE_GET_SUBJECTS_SUCCESS,
  props<{ subjects: Array<Subject> }>()
);

export const getSubjectsFailureAction = StoreUtils.createHttpErrorAction(
  SiteActionTypes.SITE_GET_SUBJECTS_FAILURE
);

export const createSubjectAction = createAction(
  SiteActionTypes.SITE_CREATE_SUBJECT,
  props<{ subjectName: string }>()
);

export const createSubjectSuccessAction = createAction(
  SiteActionTypes.SITE_CREATE_SUBJECT_SUCCESS,
  props<{
    apiResponse: CreateSubjectResponseModel;
    resource: string;
    subjectName: string;
  }>()
);

export const createSubjectFailureAction = StoreUtils.createHttpErrorAction(
  SiteActionTypes.SITE_CREATE_SUBJECT_FAILURE
);

export const sendInviteToSubjectAction = createAction(
  SiteActionTypes.SITE_SEND_INVITE_TO_SUBJECT,
  props<{ apiRequest: SendInviteToSubjectRequestModel }>()
);

export const sendInviteToSubjectSuccessAction = createAction(
  SiteActionTypes.SITE_SEND_INVITE_TO_SUBJECT_SUCCESS,
  props<{ subjectId: string }>()
);

export const sendInviteToSubjectFailureAction = StoreUtils.createHttpErrorAction(
  SiteActionTypes.SITE_SEND_INVITE_TO_SUBJECT_FAILURE
);

export const removeSubjectAction = createAction(
  SiteActionTypes.SITE_REMOVE_SUBJECT,
  props<{ subjectId: string }>()
);

export const removeSubjectSuccessAction = createAction(
  SiteActionTypes.SITE_REMOVE_SUBJECT_SUCCESS
);

export const removeSubjectFailureAction = StoreUtils.createHttpErrorAction(
  SiteActionTypes.SITE_REMOVE_SUBJECT_FAILURE
);

export const selectedSubjectAction = createAction(
  SiteActionTypes.SITE_SELECT_SUBJECT,
  props<{ subjectId: string }>()
);

export const selectedSubjectSuccessAction = createAction(
  SiteActionTypes.SITE_SELECT_SUBJECT_SUCCESS,
  props<{ subjectId: string }>()
);

export const selectedSubjectFailureAction = StoreUtils.createHttpErrorAction(
  SiteActionTypes.SITE_SELECT_SUBJECT_FAILURE
);

export const reInviteSubjectAction = createAction(
  SiteActionTypes.SITE_SEND_REINVITE_TO_SUBJECT,
  props<{ subject: Subject }>()
);

export const getSubjectActivationDetailsAction = createAction(
  SiteActionTypes.SITE_GET_SUBJECT_ACTIVATION_DETAILS,
  props<{ subject: Subject }>()
);

export const getSubjectActivationDetailsSuccessAction = createAction(
  SiteActionTypes.SITE_GET_SUBJECT_ACTIVATION_DETAILS_SUCCESS,
  props<{
    apiResponse: GetActivtaionDetailsResponse;
    selectedSubject: Subject;
  }>()
);

export const getSubjectActivationDetailsFailureAction = StoreUtils.createHttpErrorAction(
  SiteActionTypes.SITE_GET_SUBJECT_ACTIVATION_DETAILS_FAILURE
);

export const getStudies = createAction('[Site] Get Studies');

export const getStudiesSuccess = createAction(
  '[Site] Get Studies Success',
  props<{ studies: Array<StudyModel> }>()
);

export const getStudiesFailure = StoreUtils.createHttpErrorAction(
  '[Site] Get Studies Failure'
);

export const setSelectedStudySite = createAction(
  '[Site] Set Selected Study Site',
  props<{ studyId: string; siteId: string }>()
);

export const setSelectedStudySiteForReselect = createAction(
  '[Site] Set Selected Study Site for reselect',
  props<{ studyId: string; siteId: string }>()
);

export const prevSetSelectedStudySiteForReselect = createAction(
  SiteActionTypes.SET_PREVIOUSLY_SELECTED_SITE_AND_STUDY,
  props<{ studyId: string; siteId: string }>()
);

export const setSelectedStudy = createAction(
  '[Site] Set Selected Study',
  props<{ studyId: string }>()
);
export const setSelectedSite = createAction(
  '[Site] Set Selected Site',
  props<{ siteId: string }>()
);

export const getIsDeviceStatus = createAction(
  SiteActionTypes.SITE_GET_DEVICE_STATUS
);

export const getIsDeviceStatusSuccess = createAction(
  SiteActionTypes.SITE_GET_DEVICE_STATUS_SUCCESS,
  props<{ inUse: boolean; isSetup: boolean }>()
);

export const getIsDeviceStatusFailure = createAction(
  SiteActionTypes.SITE_GET_DEVICE_STATUS_FAILURE
);

export const siteActions = union({
  getSubjectsAction,
  getSubjectsSuccessAction,
  getSubjectsFailureAction,
  createSubjectAction,
  createSubjectSuccessAction,
  createSubjectFailureAction,
  sendInviteToSubjectAction,
  sendInviteToSubjectSuccessAction,
  sendInviteToSubjectFailureAction,
  removeSubjectAction,
  removeSubjectSuccessAction,
  removeSubjectFailureAction,
  selectedSubjectAction,
  reInviteSubjectAction,
  getSubjectActivationDetailsAction,
  getSubjectActivationDetailsSuccessAction,
  getSubjectActivationDetailsFailureAction,
  getStudies,
  getStudiesSuccess,
  getStudiesFailure,
  getIsDeviceInUse: getIsDeviceStatus,
  getIsDeviceInUseSuccess: getIsDeviceStatusSuccess,
  getIsDeviceInUseFailure: getIsDeviceStatusFailure,
});
