import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LanguageModel } from '@virtual-trials-workspace/models';
import {
  LanguageHttpService,
  SessionActions,
  SessionSelectors,
  SessionState,
} from '@virtual-trials-workspace/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { LanguageControleService } from '../language-controle';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'vt-ui-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent implements OnDestroy {
  private _languages: Observable<LanguageModel[]>;

  @Input()
  setUserLanguage = true;

  selectedLanguageCode$ = this.languageService.selectedLanguageChanges$;

  get languages$(): Observable<LanguageModel[]> {
    return this.store.select(SessionSelectors.getAvailableLanguages);
  }
  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<SessionState>,
    private languageService: LanguageHttpService,
    private languageControleService: LanguageControleService,
    private translocoService: TranslocoService
  ) {}

  handleChangeLanguage(language: string): void {
    this.languageControleService.handleChangeLanguage(language, true);
  }

  ngOnDestroy() {
    this.languageControleService?.ngOnDestroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
